import Rectangles from '../../../assets/images/rectangles.png';
import Rating from '@mui/material/Rating';
import LinkSvg from '../../../assets/images/link.svg';
import { Link } from 'react-router-dom';

const RefCompany = ({ info }) => {
  return (
    <div className="pl-md-0">
      <div className="ref-com pt-4 pb-2 ml-0 d-flex">
        <div className="ref-com-img">
          <div className="rectangle-block">
            <img src={Rectangles} className="icon-img" alt="" />
          </div>
          <div className={`"ttext-center"${info.tool_name}`}>
            <a
              href={info.url}
              id="software-link"
              className={`"text-center"${info.tool_name}`}
              target="_blank"
            >
              <span>{info.tool_name}</span>
              <img src={LinkSvg} alt="" className="ml-2" />
            </a>
          </div>
        </div>
        <div>
          <ul className="company-detail">
            <li className="d-flex">
              {/* <span>
                Super User/User: {info.super_users}/{info.users}{' '}
              </span>
            </li>
            <li> */}

              <span>
                Super User/User:{' '}
                {((info.super_users / info.users) * 100).toFixed(2)}%{' '}
              </span>
            </li>

            <li>
              <span>
                Dominate Role:{' '}
                {info && info.dominant_role
                  ? info.dominant_role.toUpperCase()
                  : null}
              </span>
            </li>
            <li>
              <span>Dominate Function: {info.dominant_category}</span>
            </li>
            <li>
              <span>Dominate Strategy: {info.dominant_strategy}</span>
            </li>
            <li className="d-flex">
              <span>Rating</span>
              <div className="d-flex ml-2">
                <Rating
                  name="read-only"
                  value={info.dominant_rating ? info.dominant_rating : 0}
                  readOnly
                  style={{ color: '#E5D85E', fontSize: '20px' }}
                />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default RefCompany;
