import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import stakkidGroupPhoto from '../assets/images/09-TechPeople-iStock-517015280-cropped.jpg'
import mainStakkidPhoto from '../assets/images/home_img-top.png'
import cardIndivivualPhoto from '../assets/icons/home_individual.svg'
import footerImage from '../assets/images/AdobeStock_309009238.jpg'
import footerImage2 from '../assets/images/AdobeStock_229945720_Preview.jpeg'
import Pending from '../assets/logos/iStock-1371728740-0.png'
import cardTeamPhoto from '../assets/icons/home_team.svg'
import cardOrganizationPhoto from '../assets/icons/home_company.svg'
import Modal from 'react-bootstrap/Modal'
import fire from './firebase'
import Billing from './Billing'
import { IndividualHttpService } from '../http_services/individual.http_service'
import Navbar from 'react-bootstrap/Navbar'

import stakkidGroupPhoto2 from '../assets/images/home_img-bottom.a678d0c4-2.png'
import mainStakkidPhoto2 from '../assets/images/home_img-top.3bd98f4d-2.png'
import handleShowLoginModal from './LoginDialogBox'
import { EventEmitter } from 'events'
import LoginDialogBox from './LoginDialogBox'
import './LandingPage.css'
import { navigate, redirectTo } from '@reach/router'
import '../App.css'
import StakkidImage from '../assets/logos/Asset 2.png'
// import HeroSection from './HeroSection'
import { Button } from './Button'
// import './HeroSection.css'
import CarouselSlide from './Carousel'
import Slider from './Slider'
import Sliderr from './Slider-2'


export default class LandingPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
      password: '',
      showBundle: false,
      showCreateAccount: false,
      showAccountConfirmed: false,
      shouldShowModal: true,
      ShowLoginModal: false,
      companySize: null,
      showLogin: false,
      showCreateAccount: false,
      showAccountConfirmed: false,
      teamInfo: { price: 100, typeofbusiness: 'team' },
      groupInfo: { price: 1500, typeofbusiness: 'group' },
      companyInfo: { price: 5000, typeofbusiness: 'company' },
      bundle: '',
    }

    this.signup = this.signup.bind(this)
    this.login = this.login.bind(this)
    this.handleShowLoginModal = this.handleShowLoginModal.bind(this)
    this.handleShowCreateAccount = this.handleShowCreateAccount.bind(this)
    this.handleShouldShowModal = this.handleShouldShowModal.bind(this)
  }

  async login(email, password) {
    try {
      await fire.auth().signInWithEmailAndPassword(email.trim(), password)
      const user = await fire.auth().currentUser
      await new IndividualHttpService().upsertByFirebaseUid({
        firebase_uid: user.uid,
        email: user.email,
      })
      if (user) {
        // window.location.href = "https://community.stakkid.com";
        window.location.href = '/initial'
      }
      // window.location.href = "/initial";
    } catch (error) {
      console.log(error)
      alert(error.message)
    }

    this.handleShowLoginModal()
  }

  async signup(email, password) {
    try {
      await fire.auth().createUserWithEmailAndPassword(email.trim(), password)
      const user = await fire.auth().currentUser
      const response = await new IndividualHttpService().upsertByFirebaseUid({
        firebase_uid: user.uid,
        email: user.email,
      })
      if (user) {
        // window.location.href = "https://community.stakkid.com";
        window.location.href = '/initial'
      }
    } catch (error) {
      console.log(error)
      alert(error.message)
    }

    this.handleShowAccountConfirmed()
  }

  async sendPasswordResetEmail(email) {
    try {
      await fire.auth().sendPasswordResetEmail(email)
      alert('Password reset link sent!')
    } catch (error) {
      console.log(error)
      alert(error.message)
    }
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleShowBundle() {
    this.setState({
      showBundle: !this.state.showBundle,
    })
  }

  handleShowCreateAccount() {
    this.setState({
      showLogin: false,
    })
    this.setState({
      showCreateAccount: !this.state.showCreateAccount,
    })
  }

  handleShouldShowModal() {
    this.setState({
      shouldShowModal: !this.state.shouldShowModal,
    })
  }

  handleShowLoginModal() {
    this.setState({
      showLogin: !this.state.showLogin,
    })
  }

  handleShowAccountConfirmed() {
    this.setState({
      showCreateAccount: false,
    })
    this.setState({
      showAccountConfirmed: !this.state.showAccountConfirmed,
    })
  }

  updateBundle(e) {
    this.setState({
      bundle: e.target.id,
    })
  }

  render() {
    return (
      <div>
        <LoginDialogBox
          fireErrors={this.state.fireErrors}
          showLogin={this.state.showLogin}
          handleShowCreateAccount={this.handleShowCreateAccount}
          handleShowLoginModal={this.handleShowLoginModal}
          login={this.login}
          showCreateAccount={this.state.showCreateAccount}
          signup={this.signup}
          handleShowAccountConfirmed={this.handleShowAccountConfirmed}
          showAccountConfirmed={this.state.showAccountConfirmed}
        />

       

        <Modal
          id='account-confirmed-modal'
          centered='true'
        // show={this.state.showAccountConfirmed}
        >
          <Modal.Header
            closeButton
            onClick={() => this.handleShowAccountConfirmed()}
          ></Modal.Header>
          <Modal.Body className='modal-body row d-flex justify-content-center'>
            <h4 style={{ textAlign: 'center' }} className='col-md-12'>
              Account Created
            </h4>
            <p>You will be redirected to your scoring modal shortly.</p>{' '}
          </Modal.Body>
        </Modal>

        <div className='landingpage'>
          <div className='hero-container'>
            <Slider />
            
          </div>

          

          {/* <Navbar expand='lg' id='main-navbar-container-3'>
            <div className='wrapperr'>
              <button className='nav-buttonn'>
                <a
                  onClick={() => (window.location.href = '/my-score')}
                  className='home-fontt'
                >
                  How It Works
                </a>

                <div className='play-btnn'>
                  <div class='group'></div>
                  <i
                    className='fa fa-youtube-play'
                    aria-hidden='true'
                    onClick={() => (window.location.href = '/my-score')}
                    style={{ fontSize: '25px', color: 'red' }}
                  ></i>
                </div>
              </button>
            </div>
          </Navbar> */}

          <div
            className='row card-container'
            style={{ marginBottom: '55px', marginTop: '5px' }}
          >
            <div
              className='card-deck'
              style={{ width: '80%', margin: '0 auto' }}
            >
              <div className='card col-lg-4' id='card-styles'>

                <div className='card-body' style={{ textAlign: 'center' }}>
                  <div className='buttons-card-1'>
                    <button
                      style={{
                        fontWeight: 'bold',
                        width:'250px',
                      fontSize: '20px'
                        // fontSize: '30px'
                        // whiteSpace: 'nowrap',
                      }}
                      id='main-card-stakkid-button'
                      // onClick={this.signOutBtn}
                      onClick={() => this.handleShowCreateAccount()}
                    >
                     BUILD MY STACKLR
                    </button>

                  </div>

                  <p className='card-text' style={{ paddingTop: '20px' }}
                  >Know your MarTech depth</p>
                  <p className='card-text text-left'>
                    <small>
                      Know your stack? You are accomplished in Martech. Your
                      Stacklr score reflects your unique mastery as a Beast
                      (breadth) or as a Crusher (concentration).
                    </small>
                  </p>{' '}

                </div>
                <div style={{ textAlign: 'center' }}
                >
                  <img
                    src={Pending}
                    style={{ height: '80px', width: '80px' }}
                    className='card-img-top'
                    alt='...'
                  />
                </div>
              </div>
              <div className='card col-lg-4' id='card-styles'>

                <div className='card-body' style={{ textAlign: 'center' }}>
                  <button
                    style={{
                      fontWeight: 'bold',
                      width:'250px',
                      fontSize: '20px'
                      // fontSize: '30px'
                      // whiteSpace: 'nowrap',
                    }}
                    id='main-card-stakkid-button'
                    onClick={() => {
                      (window.location.href = '/user-rating')}}
                  >
                    RATE A TOOL                  </button>



                  <p className='card-text' style={{ paddingTop: '20px' }}>Master your tool

                  </p>
                  <p className='card-text text-left'>
                    <small>
                      As a Martech software User, your experience is what makes your rating of Martech software so valuable.  Rate the tools you have hands on experience with.  It’s fast and easy.  And your perspective matters.

                    </small>
                  </p>


                </div>
                <div style={{ textAlign: 'center' }}>

                  <img
                    src={Pending}
                    style={{ height: '80px', width: '80px', }}
                    className='card-img-top'
                    alt='...'
                  />
                </div>
              </div>

              <div className='card col-lg-4' id='card-styles'>


                <div className='card-body' style={{ textAlign: 'center' }}>
                  <button
                    style={{
                      fontWeight: 'bold',
                      width:'250px',
                      fontSize: '20px'
                      // whiteSpace: 'nowrap',
                    }}
                    id='main-card-stakkid-button'
                    onClick={() => {
                      (window.location.href = '/software-clients')}}
                  >
                    SOFTWARE CLIENTS                 </button>

                  <p className='card-text' style={{ paddingTop: '20px' }}>Benchmark for transformation</p>
                  <p className='card-text text-left'>
                    <small>
                      Get insights on how your user's rate, think, and experience, your software with Stacklr’s 100+ Points of Clarity.
                      Stacklr’s online advertising solutions help you target the ideal customers for your software.


                    </small>
                  </p>
                  <div className='buttons-card-3'>


                  </div>
                </div>
                
                <div style={{ textAlign: 'center' }}>

                  <img
                    src={Pending}
                    style={{ height: '80px', width: '80px' }}
                    className='card-img-top'
                    alt='...'
                  />
                </div>
              </div>
              
            </div>
            <div style={{ width: '70%', margin: '0 auto' }}>
            <p
              style={{
                textAlign: 'center',
                marginTop: '40px',
                marginBottom: '10px',
                fontSize: '18px',
              }}
            >
              <i id='bold-fonte'>
                Millions have mastered thousands of Martech tools. <br />
                Stacklr unleashes the community’s digital aspirations.
              </i>
            </p>
          </div>
          </div>
          
        </div>
        
      </div>
    )
  }
}