import React from 'react'
import './Circles.css'

function Circles() {
  return (
    <>
      <div class='circles'>
        <div class='circle'>
          <p class='text'>Ad-Promo</p>
        </div>
        <div class='circle2'>
          <p class='text'>Exp-Content</p>
        </div>

        <div class='circle4'>
          <p class='text'>Social</p>
        </div>
        <div class='circle5'>
          <p class='text'>Commerce</p>
        </div>
        <div class='circle6'>
          <p class='text'>Data</p>
        </div>
        <div class='circle3'>
          <p class='text'>Management</p>
        </div>
      </div>
    </>
  )
}

export default Circles
