import React, { Component } from 'react'
import { XYPlot, HeatmapSeries, YAxis, XAxis } from 'react-vis'
import 'react-vis/dist/style.css'
import { PlatformService } from '../../../services/platform.service'
import './heatmap.css'

const xAxis = ['Attraction', 'Engagement', 'Analyst']

const yAxis = [
  'Commerce-Sales',
  'Social',
  'Exp-Content',
  'Management',
  'Data',
  'Ad-Promo',
]

class HeatMap extends Component {
  formatData() {
    const summary = this.props.data
    const data = [
      {
        x: 1,
        y: 0,
        color: parseFloat(
          this.makeSkillScoreOneIfZero(
            summary['["Commerce & Sales", "Attraction"]']
          )
        ),
      },
      {
        x: 1,
        y: 1,
        color: parseFloat(
          this.makeSkillScoreOneIfZero(
            summary['["Social & Relationship", "Attraction"]']
          )
        ),
      },
      {
        x: 1,
        y: 2,
        color: parseFloat(
          this.makeSkillScoreOneIfZero(
            summary['["Content & Experience", "Attraction"]']
          )
        ),
      },
      {
        x: 1,
        y: 3,
        color: parseFloat(
          this.makeSkillScoreOneIfZero(summary['["Management", "Attraction"]'])
        ),
      },
      {
        x: 1,
        y: 4,
        color: parseFloat(
          this.makeSkillScoreOneIfZero(summary['["Data", "Attraction"]'])
        ),
      },
      {
        x: 1,
        y: 5,
        color: parseFloat(
          this.makeSkillScoreOneIfZero(summary['["Ad & Promo", "Attraction"]'])
        ),
      },

      {
        x: 2,
        y: 0,
        color: parseFloat(
          this.makeSkillScoreOneIfZero(
            summary['["Commerce & Sales", "Engagement"]']
          )
        ),
      },
      {
        x: 2,
        y: 1,
        color: parseFloat(
          this.makeSkillScoreOneIfZero(
            summary['["Social & Relationship", "Engagement"]']
          )
        ),
      },
      {
        x: 2,
        y: 2,
        color: parseFloat(
          this.makeSkillScoreOneIfZero(
            summary['["Content & Experience", "Engagement"]']
          )
        ),
      },
      {
        x: 2,
        y: 3,
        color: parseFloat(
          this.makeSkillScoreOneIfZero(summary['["Management", "Engagement"]'])
        ),
      },
      {
        x: 2,
        y: 4,
        color: parseFloat(
          this.makeSkillScoreOneIfZero(summary['["Data", "Engagement"]'])
        ),
      },
      {
        x: 2,
        y: 5,
        color: parseFloat(
          this.makeSkillScoreOneIfZero(summary['["Ad & Promo", "Engagement"]'])
        ),
      },

      {
        x: 3,
        y: 0,
        color: parseFloat(
          this.makeSkillScoreOneIfZero(
            summary['["Commerce & Sales", "Analysis"]']
          )
        ),
      },
      {
        x: 3,
        y: 1,
        color: parseFloat(
          this.makeSkillScoreOneIfZero(
            summary['["Social & Relationship", "Analysis & Optimization"]']
          )
        ),
      },
      {
        x: 3,
        y: 2,
        color: parseFloat(
          this.makeSkillScoreOneIfZero(
            summary['["Content & Experience", "Analysis & Optimization"]']
          )
        ),
      },
      {
        x: 3,
        y: 3,
        color: parseFloat(
          this.makeSkillScoreOneIfZero(
            summary['["Management", "Analysis & Optimization"]']
          )
        ),
      },
      {
        x: 3,
        y: 4,
        color: parseFloat(
          this.makeSkillScoreOneIfZero(
            summary['["Data", "Analysis & Optimization"]']
          )
        ),
      },
      {
        x: 3,
        y: 5,
        color: parseFloat(
          this.makeSkillScoreOneIfZero(
            summary['["Ad & Promo", "Analysis & Optimization"]']
          )
        ),
      },
    ]
    return data
  }

  makeSkillScoreOneIfZero(skill) {
    if ([0, undefined].includes(skill)) {
      return 1
    }
    return skill
  }

  setPadding() {
    if (PlatformService.mobile()) {
      return '80px'
    }
    return '160px'
  }

  setHeight() {
    if (PlatformService.mobile()) {
      return 400
    }
    return 380
  }

  setWidth() {
    if (PlatformService.mobile()) {
      return 350
    }
    return 300
  }

  render() {
    return (
      <XYPlot
        className={'heatmap'}
        style={{
          paddingLeft: `${this.setPadding()}`,
          overflow: 'visible',
        }}
        width={this.setWidth()}
        height={this.setHeight()}
        xType='ordinal'
      >
        <XAxis
          color='#75AADB'
          orientation={'top'}
          tickFormat={(v) => `${xAxis[v - 1]}`}
        />
        <YAxis
          color='#75AADB'
          tickTotal={5}
          tickFormat={(v) => `${yAxis[v]}`}
        />
        <HeatmapSeries
          animate
          colorRange={['#d6e2ff', '#2A428C']}
          data={this.formatData()}
        />
      </XYPlot>
    )
  }
}

export default HeatMap
