import React, { Component } from 'react'
import fire from './firebase'
import { IndividualHttpService } from '../http_services/individual.http_service'



class Login extends Component {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
      password: '',
      errors: '',
    }

    this.login = this.login.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.signup = this.signup.bind(this)
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value })
  }

  async login() {
    try {
      await fire
        .auth()
        .signInWithEmailAndPassword(
          this.state.email.trim(),
          this.state.password
        )
      const user = await fire.auth().currentUser
      await new IndividualHttpService().upsertByFirebaseUid({
        firebase_uid: user.uid,
        email: user.email,
      })
      console.log('here?')
      window.location.href = '/initial'
    } catch (error) {
      console.log(error)
      alert(error.message)
    }

    // this.handleShowLoginModal()
  }

  async signup() {
    try {
      await fire
        .auth()
        .createUserWithEmailAndPassword(
          this.state.email.trim(),
          this.state.password
        )
      const user = await fire.auth().currentUser
      const response = await new IndividualHttpService().upsertByFirebaseUid({
        firebase_uid: user.uid,
        email: user.email,
      })
      window.location.href = '/initial'
    } catch (error) {
      console.log(error)
      alert(error.message)
    }

    this.handleShowAccountConfirmed()
  }

  render() {
    return (
      <div className='col-md-6' style={{ padding: '20px 0' }}>
        <div className='form-group'>
          <label htmlFor='exampleInputEmail1'>Email address</label>
          <input
            value={this.state.email}
            onChange={this.handleChange}
            type='email'
            name='email'
            className='form-control'
            id='exampleInputEmail1'
            aria-describedby='emailHelp'
            placeholder='Enter email'
          />
          <small id='emailHelp' className='form-text text-muted'>
            We'll never share your email with anyone else.
          </small>
        </div>
        <div className='form-group'>
          <label htmlFor='exampleInputPassword1'>Password</label>
          <input
            value={this.state.password}
            onChange={this.handleChange}
            type='password'
            name='password'
            className='form-control'
            id='exampleInputPassword1'
            placeholder='Password'
          />
        </div>
        <button type='button' onClick={this.login} className='btn btn-primary'>
          Login
        </button>
        <button
          type='button'
          onClick={this.signup}
          style={{ marginLeft: '25px' }}
          className='btn btn-success'
        >
          Signup
        </button>
      </div>
    )
  }
}
export default Login
