import React, { useState } from 'react'
import ScottBrinkerPhoto from '../assets/images/Screen Shot 2021-10-26 at 9.08.12 PM-removebg-preview-removebg-preview (1).jpg'
import DavidRaabPhoto from '../assets/images/Screen Shot 2021-10-26 at 9.07.41 PM-removebg-preview-removebg-preview (1).jpg'
import DarrellFranklinPhoto from '../assets/images/Screen Shot 2021-10-26 at 9.07.22 PM-removebg-preview-removebg-preview (1)-removebg-preview.jpg'
import './InfluencerCard.css'

function Card() {
  const [name, setName] = useState('Your Name')
  const [job, setJob] = useState('Job Title')
  const [about, setAbout] = useState('hi')

  return (
    <div className='influencer-stack'>
      <div className='Card-1'>
        <div className='upper-container'>
          <div className='image-container'>
            <img
              className='image'
              src={ScottBrinkerPhoto}
              alt=''
              height='100px'
              width='100px'
            />
          </div>
        </div>
        <div className='lower-container'>
          <h3> Scott Brinker </h3>
          <h4> Founder, ChiefMartec.com </h4>
          <p>
            {' '}
            “Don’t buy more tools until you fully use the ones you have first.”
            Check out{' '}
            <a href='https://chiefmartec.com/2022/01/matrixed-tech-stacks-with-both-horizontal-and-vertical-aggregation-platforms/?utm_source=rss&utm_medium=rss&utm_campaign=matrixed-tech-stacks-with-both-horizontal-and-vertical-aggregation-platforms'>
              <u>
                Matrixed Tech Stacks with Both Horizontal and Vertical
                Aggregation Platforms
              </u>
            </a>
          </p>
        </div>
      </div>

      <div className='Card-2'>
        <div className='upper-container'>
          <div className='image-container'>
            <img
              src={DarrellFranklinPhoto}
              alt=''
              height='100px'
              width='100px'
            />
          </div>
        </div>
        <div className='lower-container'>
          <h3> Darrell Franklin</h3>
          <h4> Head of Digital Product & Growth Strategy </h4>
          <p>
            {' '}
            "I like Stacklr because it provides clarity on the <b>WHAT</b> and intentionality on the <b>HOW</b> we
            need to proceed. It’s a true game changer."
          </p>
        </div>
      </div>


    

      <div className='Card-3'>
        <div className='upper-container'>
          <div className='image-container'>
            <img src={DavidRaabPhoto} alt='' height='100px' width='100px' />
          </div>
        </div>
        <div className='lower-container'>
          <h3> David Raab</h3>
          <h4> Founder, CDP Institute</h4>
          <p>
            {' '}
            Talking about{' '}
            <a href='https://martechvibe.com/staff-articles/how-not-to-buy-marketing-technology/'>
              <u>How Not to Pick Marketing Technology.</u>
            </a>{' '}
          </p>
        </div>
      </div>
    </div>

    
  )
}

export default Card
