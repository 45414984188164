import React, { useEffect, useState } from 'react';
import Modal from '@material-ui/core/Modal';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

function Softwaremodal() {
    const [ratingModal, setRatingModal] = useState(true);


  const [companyInfo, setCompanyInfo] = useState({
    id: 1,
    tool_name: '',
    short_des: '',
    long_des: '',
    url: '',
    super_users: 0,
    users: 0,
    dominant_role: '',
    dominant_strategy: '',
    dominant_category: '',
  });


  return (

    <div> <Modal
    style={{ top: '20%' }}
    className={`custom-modal software-modal`}
    size="lg"
    open={ratingModal}
  >
    <Box
      sx={{ width: '330px' }}
      style={{
        background: '#fff',
        margin: '0px auto',
        padding: '10px 20px',
        borderRadius: '20px',
      }}
    >
      <IconButton
        aria-label="Close"
        className="MuiButtonBase-root MuiIconButton-root Component-closeButton-14"
        // onClick={onClose}
        onClick={() => {
          (window.location.href = '/')
          return false
        }}
      >
        <CloseIcon />
      </IconButton>
     
        <>
          <p id="rate-description"                   style={{ textAlign: 'center' }}
>
           {companyInfo.tool_name} has not activated their content yet
          </p>
    
          <div className="text-center">
            <button
              className="software-stakkid-button-rate"
              // onClick={() => goNext()}
              onClick={() =>
                window.open("https://stacklr.zoholandingpage.com/Activation/")}
            >
              {' '}
              Request Activation 
            </button>
          </div>
        </>
        
    </Box>
  </Modal> </div>
  )
}

export default Softwaremodal