import Axios from "axios";

export class CrudHttpService {
  constructor(apiUrl) {
    this.url = `${global.apiBaseUrl}/${apiUrl}`;
  }

  async where(params) {
    const queryString = Object.keys(params)
      .map((key) => key + "=" + params[key])
      .join("&");
    const fullUrl = `${this.url}?${queryString}`;

    return await this.getRequest(fullUrl);
  }

  async find(id) {
    const fullUrl = `${this.url}/${id}`;
    return await this.getRequest(fullUrl);
  }

  async update(payload) {
    if (payload.id === null || payload.id === undefined) {
      return;
    }
    const fullUrl = `${this.url}/${payload.id}`;
    const response = await fetch(fullUrl, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      mode: "cors",
      body: JSON.stringify(payload),
    });
    return await response.json();
  }

  async newPost(action, payload) {
    return this.postRequest(payload, { action: action }, "new");
  }

  async upsert(payload) {
    return await this.newPost("upsert", payload);
  }

  async delete(id) {
    const fullUrl = `${this.url}/${id}`;
    const response = await fetch(fullUrl, {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
      mode: "cors",
    });
    return await response;
  }

  async create(payload) {
    const fullUrl = `${this.url}`;
    const response = await fetch(fullUrl, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      mode: "cors",
      body: JSON.stringify(payload),
    });

    return await response.json();
  }

  async postRequest(payload, params, subRoute) {
    let fullUrl = `${this.url}`;

    if (subRoute !== undefined) {
      fullUrl = `${fullUrl}/new`;
    }

    const additionalHeaders = {};
    if (params !== undefined) {
      const queryString = Object.keys(params)
        .map((key) => key + "=" + params[key])
        .join("&");
      fullUrl = `${fullUrl}?${queryString}`;
    }
    try {
      await fetch(fullUrl, {
        method: "POST",
        redirect: "follow", // manual, *follow, error

        headers: { "Content-Type": "application/json", ...additionalHeaders },
        mode: "cors",
        body: JSON.stringify(payload),
      });
    } catch (error) {
      console.error(error);
    }
  }

  async getRequest(fullUrl) {
    try {
      // console.log('fullUrl=>', fullUrl);

      // const response2 = await fetch(fullUrl, {
      //   method: 'GET'
      // })
      // console.log('ssi response11 ?', response2);

      const response = await Axios.get(fullUrl);

      // console.log('ssi response ?', response);
      // console.log('here?', response);
      if (response.status === 204) {
        return [];
      } else if ([403, 401].includes(response.status)) {
        console.error("not authorized");
        // eslint-disable-next-line no-restricted-globals
        location.pathname = "/";
      } else if (response.status === 500) {
        throw new Error("Internal Server Error");
      } else if (response.status === 503) {
        throw new Error("Build in progress");
      } else {
        // const data = await JSON.parse(response.data[0]);
        console.log("data", response.data);
        return response.data;
      }
    } catch (error) {
      console.error(error);
      // eslint-disable-next-line no-restricted-globals
      if (location.hash.includes("errors")) {
        return;
      }
    }
  }
}
