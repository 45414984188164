import Coffee from '../../../assets/images/coffee.png';

const SurveyCard = ({ companyId }) => {
  return (
    <div className="col-md-8 text-center survey-panel title_color p-0">
      <div className="d-flex flex-wrap align-items-center justify-content-center">
        <div className="p-4">
          <p className="font-weight-bold">
            {' '}
            Let us send you a Starbucks gift card for two minutes of your time
          </p>

          <button
            className={`software-stakkid-button ${companyId}`}
            onClick={() => window.open(`/survey/${companyId}`, '_self')}
          >
            {' '}
            Survey{' '}
          </button>
        </div>
      </div>
      <div className="d-flex flex-wrap align-items-center justify-content-center rate-card-brands">
        <img src={Coffee} alt={''} className="mx-0" />
      </div>
    </div>
  );
};

export default SurveyCard;
