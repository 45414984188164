import React from 'react';
import { Button } from './Button';
import './LoginBanner.css'

import dashPhoto2 from '../assets/images/Screen Shot 2022-08-26 at 9.30.42 AM.png'
import { Link } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import fire from './firebase'
import Billing from './Billing'
import { IndividualHttpService } from '../http_services/individual.http_service'
import { render } from '@testing-library/react'
import './YoutubeEmbed.css'
import YoutubeEmbed from './YoutubeEmbed'
import LoginDialogBox from './LoginDialogBox'
import handleShowLoginModal from './LoginDialogBox'
import { navigate, redirectTo } from '@reach/router'

export default class LoginBanner extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
      password: '',
      showBundle: false,
      showCreateAccount: false,
      showAccountConfirmed: false,
      shouldShowModal: true,
      ShowLoginModal: false,
      companySize: null,
      showLogin: false,
      showCreateAccount: false,
      showAccountConfirmed: false,
      bundle: '',
    }

    this.signup = this.signup.bind(this)
    this.login = this.login.bind(this)
    this.handleShowLoginModal = this.handleShowLoginModal.bind(this)
    this.handleShowCreateAccount = this.handleShowCreateAccount.bind(this)
    this.handleShouldShowModal = this.handleShouldShowModal.bind(this)
  }

  async login() {
    try {
      await fire
        .auth()
        .signInWithEmailAndPassword(this.state.email, this.state.password)
      const user = await fire.auth().currentUser
      await new IndividualHttpService().upsertByFirebaseUid({
        firebase_uid: user.uid,
        email: user.email,
      })
      if (user) {
        window.location.href = '/initial'
      }
      // window.location.href = '/initial'
    } catch (error) {
      console.log(error)
      alert(error.message)
    }

    this.handleShowLoginModal()
  }

  showBilling() {
    return <Billing />
  }

  async signup() {
    try {
      await fire
        .auth()
        .createUserWithEmailAndPassword(this.state.email, this.state.password)
      const user = await fire.auth().currentUser
      const response = await new IndividualHttpService().upsertByFirebaseUid({
        firebase_uid: user.uid,
        email: user.email,
      })
      window.location.href = '/initial'
    } catch (error) {
      console.log(error)
      alert(error.message)
    }

    this.handleShowAccountConfirmed()
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleShowBundle() {
    this.setState({
      showBundle: !this.state.showBundle,
    })
  }

  handleShowCreateAccount() {
    this.setState({
      showCreateAccount: !this.state.showCreateAccount,
    })
  }

  handleShouldShowModal() {
    this.setState({
      shouldShowModal: !this.state.shouldShowModal,
    })
  }

  handleShowLoginModal() {
    this.setState({
      showLogin: !this.state.showLogin,
    })
  }
  handleShowAccountConfirmed() {
    this.setState({
      showCreateAccount: false,
    })
    this.setState({
      showAccountConfirmed: !this.state.showAccountConfirmed,
    })
  }

  updateBundle(e) {
    this.setState({
      bundle: e.target.id,
    })
  }

  render() {
    return (
      <div>


        <div className='page-padding'>
          <div style={{

            paddingTop: '18%'
          }}>
            <div
              style={{
                float: 'left',
                // height: '100px',
                paddingLeft: '15%',
              }}
            >

              <h2 style={{ fontSize: '36px' }}>Would you like to <br /> rate your tools?</h2>
              <Button
                className='rating-button'
                buttonStyle='btn--primary'
                buttonSize='btn--large'
                // onClick={this.signOutBtn}
                // onClick={() => this.handleShowLoginModal()}
                onClick={() => (window.location.href = '/user-rating')}



              >
                Rate Tools
              </Button>
            </div>
            {/* <div className='line'></div> */}
            <div
              style={{
                float: 'right',
                // height: '100px',
                paddingRight: '15%',

              }}
            >

              <h2 style={{ fontSize: '36px' }}>Would you like to <br /> build your Stacklr ?</h2>
              <Button
                className='rating-button'
                buttonStyle='btn--primary'
                buttonSize='btn--large'
                // onClick={this.signOutBtn}
                // onClick={() => this.handleShowLoginModal()}
                onClick={() => (window.location.href = '/initial')}




              >
                Score Tools
              </Button>

            </div>
          </div>
          
        </div>
      </div>
    )
  }
}


