import React, { Component, useState } from 'react'
import IndividualSegmentedContainer from './data-visualizations/individual-segmented-container/segmented-container'
import RadialChart from './data-visualizations/pie-chart/PieChart'
import HeatMap from './data-visualizations/heatmap/heatmap'
import fire from './firebase'
import { loadingController } from '@ionic/core'
import ScoreImg from '../assets/images/IMG_0072.jpg'
import { Divider, Form, Label, Button } from 'semantic-ui-react'

import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonItem,
  IonLabel,
  IonPopover,
} from '@ionic/react'
import { IndividualHttpService } from '../http_services/individual.http_service'
import InfoIcon from '@material-ui/icons/Info'
import { color } from 'd3'
import './ad-info.css'
import FormAd from './formAd'

export default class IndividualDashboard extends Component {
  constructor(props) {
    super(props)

    this.state = {
      user: {},
      data: {},
      showRolePopover: false,
      showRolePopoverr: false,
      showRolePopoverrr: false,
      shouldShowModal: false,
      setShouldShowModal: false,
    }

    // this.setData()
  }

  async componentWillMount() {
    console.log('component will mount')
    const spinner = await loadingController.create({
      spinner: 'crescent',
      message: 'Loading Score...',
    })
    await spinner.present()
    try {
      const user = await fire.auth().currentUser
      const railsUser = await new IndividualHttpService().where({
        firebase_uid: user.uid,
      })
      this.setState({ user: railsUser[0] })
      await this.fetchData(railsUser[0].id)
    } catch (e) {
      console.log(e)
      await spinner.dismiss()
      return
    }
    await spinner.dismiss()
  }
  async handleClick(firstName, lastName) {
    // const user_id = userId;
    // console.log("log user_id:");
    // console.log(user_id);
    // let response = '';
    // const res = await new IndividualHttpService().where({
    //   firebase_uid: user_id,
    // });
    // const user = res[0];
    let response = ''
    const firebase_user = await fire.auth().currentUser
    const res = await new IndividualHttpService().where({
      firebase_uid: firebase_user.uid,
    })
    const user = res[0]
    console.log('user.id from await' + user.id)
    console.log(
      'The first name value is ' +
      firstName +
      'the last name value is ' +
      lastName
    )

    response = await new IndividualHttpService().update({
      id: user.id,
      first_name: firstName,
      last_name: lastName,
    })
    return response
    //  await new IndividualHttpService().update({
    //    id: user.id,
    //    team_id: response.id,
    //    team_admin: true,
    //  });
    // updateName(this.state.firstName, this.state.lastName);
    // put call to update individual
    // this.handleSubmision()
  }

  async fetchData(individual_id) {
    const url =
      // 'https://stakkid.herokuapp.com/skills/new?action=individual_dashboard'
      // const local = 'http://localhost:3000/skills/new?action=individual_dashboard'
      global.apiBaseUrl + '/skills/new?action=individual_dashboard'
    fetch(url, {
      method: 'POST',
      redirect: 'follow', // manual, *follow, error
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors',
      body: JSON.stringify({ individual_id: individual_id }),
    })
      .then((resp) => resp.json())
      .then((data) => this.setState({ data: data }))
  }

  renderSegmentedContainer() {
    return [
      <IndividualSegmentedContainer
        marketData={this.state.data.strategy}
        roleData={this.state.data.roles}
        toolData={this.state.data.tools}
      />,
    ]
  }

  renderName() {
    if (
      this.state.user.first_name === null ||
      this.state.user.last_name === null
    ) {
      return
    }
    return [
      <ion-item lines='none'>
        <ion-title style={{ textAlign: 'center', fontSize: '32px' }}>
          {this.state.user.first_name} {this.state.user.last_name}
        </ion-title>
      </ion-item>,
    ]
  }

  renderMaxRole(obj) {
    if (Object.keys(obj).length === 0) {
      return
    }
    return Object.keys(obj)
      .reduce((a, b) => (obj[a] > obj[b] ? a : b))
      .toUpperCase()
  }

  render() {
    if (Object.keys(this.state.data).length === 0) {
      console.log('render function')
      console.log(this.state.data)
      return []
    }

    return [
      <div style={{
        marginBottom: '16%',
      }}>
        <div className='flex-child green'  >

          {' '}
          <FormAd user={this.state.user} onClick={this.handleClick} />
        </div>,
      </div>
    ]
  }
}
