import React, { createRef } from "react";
import html2canvas from "html2canvas"
import jsPDF from "jspdf"
import IndividualDashboard from "./dashboards/individual-dashboard";


function PDFContent() {
  const exportPDF = () => {
    const input = document.getElementById("PDFContent")
    html2canvas(input, {logging: true, letterRendering: 1, useCORS: true}).then(canvas => {
      const imgWidth = 208;
      const imgHeight = canvas.height * imgWidth / canvas.width
      const imgData = canvas.toDataURL('img/png')
      const pdf = new jsPDF('p', 'mm', 'a4')
      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight)
      pdf.save("stacklrscorecard.pdf")


    })
  }

  return (
    <div className="PDFContent">
      <button onClick={() => exportPDF()}>Print pdf</button>
      <header id="PDFContent" className="PDFContent-Header">
      <IndividualDashboard />
      </header>
    </div>
  )
}

export default PDFContent