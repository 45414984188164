import React, { useCallback } from 'react';
import 'survey-core/defaultV2.css';
import { StylesManager, Model } from 'survey-core';
import { Survey } from 'survey-react-ui';

StylesManager.applyTheme('defaultV2');

const surveyJson = {
  pages: [
    {
      name: 'PersonalDetails',
      elements: [
        {
          name: 'innovation',
          title: 'Innovation',
          type: 'rating',
          isRequired: true,

          mininumRateDescription: 'Least',
          maximumRateDescription: 'Most',
          rateMin: 1,
          rateMax: 5,
        },
        {
          name: 'support',
          title: 'Support',
          type: 'rating',
          isRequired: true,

          mininumRateDescription: 'Least',
          maximumRateDescription: 'Most',
          rateMin: 1,
          rateMax: 5,
        },
        {
          name: 'customer_experience',
          title: 'Customer experience',
          type: 'rating',
          isRequired: true,

          mininumRateDescription: 'Least',
          maximumRateDescription: 'Most',
          rateMin: 1,
          rateMax: 5,
        },
        {
          name: 'pricing_high',
          title: 'Pricing too high?',
          type: 'radiogroup',
          choices: [
            { value: 2, text: 'Yes' },
            { value: 1, text: 'No' },
          ],
          isRequired: true,
        },
        {
          name: 'previous_software',
          title:
            'Did you switch from another software? If so, what software did you transition to and why?',
          type: 'text',
          // "isRequired": true,
        },
        {
          name: 'what_software',
          title: 'What software do you integrate this tool with?',
          type: 'text',
          isRequired: true,

          columns: [
            {
              //   "name": "name",
              cellType: 'text',
              //   "isRequired": true
            },
          ],
        },
        {
          name: 'email',
          type: 'text',
          title:
            'Thanks a latte! Please enter your work email, click complete, and your Starbucks card is on its way.',
          placeholder: 'Enter work email here',
          isRequired: true,
        },
        {},
      ],
    },
  ],
  showQuestionNumbers: false,
};

const Surveyy = (props) => {
  const { id } = props.match.params;
  console.log('company_id2', id);
  const survey = new Model(surveyJson);

  const SURVEY_ID = 1;
  const surveyComplete = useCallback((sender) => {
    saveSurveyResults(id, sender.data);
  }, []);

  survey.onComplete.add(surveyComplete);

  return <Survey model={survey} />;
};
  
async function saveSurveyResults(tool_id, formData) {
  const url = global.apiBaseUrl + '/surveys/new?action=add_survey';
  const response = await fetch(url, {
    method: 'POST',
    redirect: 'follow', // manual, *follow, error
    headers: { 'Content-Type': 'application/json' },
    mode: 'cors',
    body: JSON.stringify({
      tool_id,
      innovation: formData.innovation,
      support: formData.support,
      customer_experience: formData.customer_experience,
      pricing_high: formData.pricing_high == "yes" ? true : false,
      previous_software: formData.previous_software,
      what_software: formData.what_software,
      email: formData.email
    }),
  })
    .then((resp) => resp.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log('error', error);
    });
}

export default Surveyy;
