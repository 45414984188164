import React from 'react'

import './SoftwareClients2.css'
import inmarket from '../assets/images/adobehandshack.png'
import inmarket2 from '../assets/images/My project-1 (1).jpg'

import samplesoftware from '../assets/images/Sample Software.jpg'
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


const SoftwareClients = () => {

    return (
        <div className='clientPage1'>
             <div className='darkscreen'>
                    
                    </div>
            <div className='header-one'>
           
                <img
                    className='header-img'
                    src={inmarket2}

                />
                
                <h2 className='header-text'>How Can Stacklr Help
                    You Capture New Clients
                    In-Market?</h2>
            </div>
            <div className='body-one'>

                <div className='body-para'>
                    
                    <h2 className='para-title-body'>How Can Stacklr Help You ?</h2>

                    <ul className='para-group-body'>
                        <li className='para-one-body'> <span style={{ fontSize: '24px', paddingTop: "-5px" }}>1.</span> 
                            Activate your software highlights                        </li>
                        <li className='para-two-body'> <span style={{ fontSize: '24px' }}>2.</span> Engage your customers to rate your tool on Stacklr</li>
                        <li className='para-three-body'> <span style={{ fontSize: '24px' }}>3.</span> Track buying interest on your software highlights
                        </li>
                        <li className='para-four-body'> <span style={{ fontSize: '24px' }}>4.</span> Promote your ranking in your category
                        </li>
                    </ul>
                </div>
                <div className='para-body-img'>
                    <img
                        className='body-img'
                        src={samplesoftware}

                    />
                </div>
            </div>

            <div className='body-two'>
                <div className='body-para-one'>
                    <h2 className='body-h2-one'>Does your advertising strictly target the Martech community for affective ROI?</h2>
                    <p className='body-p-one'>Our sign up is simple. We only require your basic company information and what type of data storage you want. Our sign up is dead simple. We only require your basic company information and what type of data storage you want.</p>
                    </div>

                    <div className='body-para-two'>
                    <h2 className='body-h2-two'>Are your leads for buyer, influencers and decision maker who are in-market?</h2>
                    <p className='body-p-two'>We support bulk uploading via SQL, integrations with most data storage products, or you can use our API. Simply select where you'd like to transfer your data and we'll being the process of migrating it instantly.</p>
                </div>

                <div className='rectangle-button-o'><button className='rectangle-button-two'>Lets Talk</button></div>
            </div>

            <div className='rectangle-one'>
                <div className='rectangle-title'>Go deeper</div>
                <div className='rectangle-para'>Connect with people who use your software the most. Your Go Deeper page is where Stacklr users go to learn more about your software. Claim your free Go Deeper page to get started!</div>
                <button  onClick={() => window.open(
                  'https://stacklr.zoholandingpage.com/contactus'
                ) } className='rectangle-button'>Review your highlights</button>
            </div>


        </div>








    )
}

export default SoftwareClients