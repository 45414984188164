import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import StrategyCard from './StrategyCard'

import Grid from '@material-ui/core/Grid'
import Modal from '@material-ui/core/Modal'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import StepIcon from '@material-ui/core/StepIcon'
import ClearIcon from '@material-ui/icons/Clear'
import StakkidImage from '../assets/logos/1Asset 11.png'
import './Initial.css'
import AddTool from './add-tool';
import UpdateTool from './UpdateTool';
import ProgressBar from '@ramonak/react-progress-bar';
import Joyride from 'react-joyride';


import { loadAllAvgRatingsAction, loadScoreAction, loadSkillsAction, loadTopSkillsAction } from '../actions'

import { CATEGORY_OBJECTS } from '../constants'

import Popup from './Modal'
// import { Steps } from './steps'





const useStylesStepIcon = makeStyles(
  {
    root: {
      color: 'rgba(232, 69, 33, 0.2)',
      '&$completed': {
        color: '#e84521',
      },
      borderRadius: '20px'
    },
    completed: {},
  },
  { name: 'MuiStepIcon' }
)

const useStylesInitialAssessment = makeStyles({
  root: {
    color: 'white',
    backgroundColor: '#911af9',
    '&:hover': {
      backgroundColor: 'rgba(0, 139, 192, 1.0)',
    },
    borderRadius: '20px',
    buttonStyle: {
      borderRadius: '20px',
    },
  },
  stepper: {
    '& .MuiStepConnector-line': {
      borderColor: '#911af9',
      borderTopWidth: 18,
      borderRadius: 9,
      width: '100%',
    },
    '& .MuiStepConnector-alternativeLabel': {
      top: 3,
      left: 'calc(-50% + 11px)',
      right: 'calc(50% + 11px)',
    },
    '& .MuiStepIcon-root.MuiStepIcon-active': {
      color: '#e84521',
    },
  },
  inactiveStep: {
    '& .MuiStepConnector-line': {
      borderColor: '#fff',
      borderTopWidth: 18,
      borderRadius: 9,
    },
    '& .MuiStepConnector-alternativeLabel': {
      top: 3,
      left: 'calc(-50% + 12px)',
      right: 'calc(50% + 12px)',
    },
    '& .MuiStepIcon-root.MuiStepIcon-active': {
      color: '#e84521',
    },
  },

  modal: {
    margin: 'auto',
    width: '55%',
  },
  unorderedList: {
    padding: '40px',
    '& li': {
      padding: '20px',
    },
  },
});

function GreenStepIcon(props) {
  const classes = useStylesStepIcon()
  return <StepIcon {...props} classes={{ root: classes.root }} />
}

function InitialAssessment() {
  const classes = useStylesInitialAssessment()
  const currentScore = useSelector((store) => store.currentScore)
  const sortedSkills = useSelector((store) => store.sortedSkills)
  const topSkills = useSelector((store) => store.topSkills)
  const toolAction = useSelector((store) => store.toolAction);
  const selectedTool = useSelector((store) => store.tool);
  const [skill, setSkill] = useState(selectedTool);
  const [percentage, setPercentage] = useState(10);
  const [includeTool, setIncludeTool] = useState(0);
  const [score, setScore] = useState(currentScore);


  useEffect(() => {
    if (currentScore != null && currentScore != undefined) {
      setScore(currentScore)
    }
  }, [currentScore]);


  const dispatch = useDispatch()

  const [shouldShowModal, setShouldShowModal] = useState(true)
  const [currentCategoryObject, setCurrentCategoryObject] = useState(
    CATEGORY_OBJECTS[1]
  )

  useEffect(() => {
    if (sortedSkills) handleStep(currentCategoryObject.step - 1);
  }, [sortedSkills]);


  useEffect(() => {
    setSkill(selectedTool)
  }, [selectedTool]);

  useEffect(() => {
    dispatch(loadAllAvgRatingsAction)
    dispatch(loadSkillsAction)
    dispatch(loadScoreAction)
    dispatch(loadTopSkillsAction)

  }, [])

  let justifyContent = 'space-between'
  if (currentCategoryObject.step == 2) {
    justifyContent = 'flex-end'
  }
  const topSkillsIdsArray = () => {
    let array = []

    Object.keys(topSkills).forEach((category) => {
      topSkills[category].forEach((skill) => {
        array.push(skill.id)
      })
    })
    return array
  }

  const skills = () => {
    const topSkills = topSkillsIdsArray()
    let sortedSkillsCopy = JSON.parse(JSON.stringify(sortedSkills))
    for (var category in sortedSkills) {
      Object.keys(sortedSkills[category]).map((strategy) => {
        sortedSkillsCopy[category].forEach((skill) => {
          skill.isTopSkill = [...topSkills].includes(skill.id)
        })
      })
    }

    return sortedSkillsCopy
  }
  const skillsList = skills()
  const backButtonOnClick = (e) => {
    setCurrentCategoryObject(CATEGORY_OBJECTS[currentCategoryObject.step - 2])
    handleStep(currentCategoryObject.step - 2);
  }
  const nextButtonOnClick = (e) => {
    setCurrentCategoryObject(CATEGORY_OBJECTS[currentCategoryObject.step])
    handleStep(currentCategoryObject.step);
  }
  const doneButtonOnClick = (e) => {
    window.location.href = '/individual-dashboard'
  }

  const handleStep = (step) => {
    let currentProgress = 10;
    for (let i = 0; i < step; i++) {
      const skillLists = Object.values(sortedSkills)[i];
      let skilPercent = 0;
      if (skillLists && skillLists.length > 0) {
        skilPercent = 5;
      }
      currentProgress = 10 + skilPercent + currentProgress;
    }
    setPercentage(currentProgress);
  }


  const checkToolStep = (step) => {
    const skillLists = Object.values(sortedSkills)[step];
    let skilPercent = 0;
    if (skillLists && skillLists.length > 0) {
      skilPercent = 5;
    }
    setIncludeTool(skilPercent);

  
  }

  const customLocale = {
    next: 'Got it', // Change the 'next' property to 'Got it'
    last: 'Got it'  // Change the 'last' property to 'Got it'

    
  };

  const [steps, setSteps] = useState([
    {
      
      disableBeacon: true,
      // isFixed: true,
      placement: "center",
      target: ".row-one",
      content: "Follow the prompts to build a quick Stacklr assessment",
  
    },
    {
      title: 'Step 1',
      disableBeacon: true,
      // isFixed: true,
      // placement: "top-end",
      placement: "right",
      showSkipButton: false,
      target: "#tool-name",
      content: "Select the tools you have the most experience with",
  
    },
    {
      title: "Step 2",
      disableBeacon: true,
      showSkipButton: false,
      placement: "right",
      target: "#job",
      content: "Do you use this tool on your current job?",
    },
    {
      title: "Step 3",
      disableBeacon: true,
      showSkipButton: false,
      placement: "right",
      target: "#role",
      content: "What is your role (see hovers)?",
    },
    {
      title: "Step 4",
      disableBeacon: true,
      showSkipButton: false,
      placement: "right",

      // placement: "top-end",
      target: "#strategy",
      content: "Select the strategy the tool supported",
    }, {
      title: "Step 5",
      disableBeacon: true,
      showSkipButton: false,
      // placement: "top-end",
      target: "#score",
      content: "Score your competency on this tool",
    }, {
      title: "Step 6",
      disableBeacon: true,
      showSkipButton: false,
      // placement: "top-end",
      target: "#rating",
      content: "Rate the tool",
    }, {
      title: "Step 7",
      disableBeacon: true,
      showSkipButton: false,
      // placement: "top-end",
      target: "#save_btn",
      content: "Save your selection",
    }, {
      title: "Step 8",
      disableBeacon: true,
      showSkipButton: false,
      placement: "top-right",
      target: "#add-tool",
      content: "You can add more tool before you move on to the next function",
    }, {
  
      title: "Step 9",
      disableBeacon: true,
      showSkipButton: false,
      // placement: "top-end",
      target: "#next-tool",
      content: "Go to the next function and repeat",
    }
  ])
  
  



  return (

    <div className="page-padding">
   <Joyride
        continuous={true}
        hideCloseButton
        // disableBeacon
        hideBackButton
        disableOverlay
        // scrollToFirstStep={true}
        // showProgress
        showSkipButton
        steps={steps}
        locale={customLocale} // Set the custom locale


      />

      {/* <div  style={{ float:'right', marginTop:'-4%'
                      
                    }}>
          <Button classes={{ root: classes.root }} onClick={doneButtonOnClick}>
            See Dashboard
          </Button>
          </div> */}
      <div className="progress-stepper">
        <div style={{ flex: 1 }}>
          <Stepper
            className="custom-stepper"
            alternativeLabel
            activeStep={currentCategoryObject.step - 1}
          >
            {CATEGORY_OBJECTS.map((categoryObject, i) => {
              // This next line starts off by checking if it is adding the first stepper in the stepper list.
              // If it is then the onClick should do nothing as there is no real Category for this stepper
              //
              // The second half of the ternary creates a closure since we are iterating over all categories via .map
              // to save the current category index and then use that in an onClick handler it returns to eventually set
              // the state for currentCategoryObject.
              return [
                <Step
                  classes={{
                    root:
                      i > currentCategoryObject.step - 1
                        ? classes.inactiveStep
                        : classes.stepper,
                  }}
                  onClick={
                    i == 0
                      ? (e) => { }
                      : ((categoryIndex) => (e) => {
                        setCurrentCategoryObject(
                          CATEGORY_OBJECTS[categoryIndex]
                        );
                        // handleStep(categoryIndex);
                        // checkToolStep(categoryIndex);
                      })(i)
                  }
                  key={i}
                >
                  <StepLabel StepIconComponent={GreenStepIcon}>
                    {categoryObject.categoryTitle}
                  </StepLabel>
                </Step>,
              ];
            })}
          </Stepper>
        </div>
        <div style={{ textAlign: 'center' }}>
          <h6 style={{ fontWeight: 'bold' }}>My Current Stacklr Score </h6>
          <div className="my-score">{score || 0}</div>
        </div>
      </div>

      <hr />
      {/* <ProgressBar
        completed={percentage}
        bgColor={'#71BB53'}
        baseBgColor={'#3f51b5'}
      /> */}
      <Grid
        className="row-one"
        container
        justify="center"
        spacing={2}
        style={{ marginTop: '20px' }}
      >
        <Grid key={`grid-1`} md={6} item>
          <UpdateTool
            exitModal={(e) => {
              console.log('exit modal');
            }}
            category={currentCategoryObject.categoryName}
            strategy={skill}
            skill={skill}
          />
        </Grid>
        {skillsList[currentCategoryObject.categoryName] && (
          <Grid key={`grid-2`} md={6} item>
            <StrategyCard
              id="add-tools"

              key={`card-1`}
              strategy={''}
              category={currentCategoryObject.categoryTitle}
              skills={skillsList[currentCategoryObject.categoryName]}
              currentCategoriesList={
                skillsList[currentCategoryObject.categoryName]
              }
              type="initial"
            />
          </Grid>
        )
          // Object.keys(skillsList[currentCategoryObject.categoryName]).map(
          //   (strategy, index) => (
          //     <Grid key={`grid-${index}`} md={4} item>
          //       <StrategyCard
          //         key={`card-${index}`}
          //         strategy={strategy}
          //         category={currentCategoryObject.categoryName}
          //         skills={
          //           skillsList[currentCategoryObject.categoryName]
          //         }
          //         currentCategoriesList={
          //           skillsList[currentCategoryObject.categoryName]
          //         }
          //         type='initial'
          //       />
          //     </Grid>
          //   )
          // )
        }
      </Grid>

      <div style={{ marginTop: '20px', display: 'flex', justifyContent }}>
        {currentCategoryObject.step > 2 && (
          <Button classes={{ root: classes.root }} onClick={backButtonOnClick}>
            Back
          </Button>
        )}
        {currentCategoryObject.step != CATEGORY_OBJECTS.length && (
          <Button id="next-tool"
            classes={{ root: classes.root }} onClick={nextButtonOnClick}>
            Next
          </Button>
        )}
        {currentCategoryObject.step == CATEGORY_OBJECTS.length && (
          <Button classes={{ root: classes.root }} onClick={doneButtonOnClick}>
            Done
          </Button>
        )}
      </div>
      <div>
        {/* <stepperss/> */}

        {/* <Popup /> */}
      </div>
    </div>
  );

}

export default InitialAssessment