import firebase from 'firebase'
import React, { useState } from 'react'
import { Divider, Form, Label, Button } from 'semantic-ui-react'
import { IndividualHttpService } from '../http_services/individual.http_service'
import './ad-info.css'
import fire from './firebase'

const FormAd = ({ user, onClick }) => {
  console.log(user);
  const [firstName, setFirstName] = useState(user.first_name)

  const [lastName, setLastName] = useState(user.last_name)

  async function handleSubmit() {
    const submitted = await onClick(firstName, lastName)
    if (submitted) {
      console.log('info updated successfully')
      window.location.href = '/individual-dashboard'
    } else {
      console.log('error updating info try again')
      // handle error
    }
  }

  // const handleClick = async () => {
  //   const res = await new IndividualHttpService().where({
  //     firebase_uid: userId,
  //   });
  //   const user = res[0];
  //   console.log("user.id from await" + user.id);
  //   console.log(
  //     "The first name value is " +
  //       firstName +
  //       "the last name value is " +
  //       lastName
  //   );
  //   await new IndividualHttpService().update({
  //     id: userId,
  //     first_name: firstName,
  //     last_name: lastName,
  //   });
  //   // updateName(this.state.firstName, this.state.lastName);
  //   // put call to update individual
  //   window.location.href = "/individual-dashboard";
  // };
  // const getToken = async () => {
  //     const headers = {
  //       Authorization: authProps.idToken // using Cognito authorizer
  //     };
  //     const response = await axios.post(
  //       "https://MY_ENDPOINT.execute-api.us-east-1.amazonaws.com/v1/",
  //       API_GATEWAY_POST_PAYLOAD_TEMPLATE,
  //       { headers }
  //     );
  //     const data = await response.json();
  //     setToken(data.access_token);
  //   };

  // const updateName = async (id,first_name, last_name) => {
  //   const url = global.apiBaseUrl + '/individuals/new?action=update_individual_info'
  //   fetch(url, {
  //     method: 'PUT',
  //     redirect: 'follow',
  //     headers: { 'Content-Type': 'application/json' },
  //     mode: 'cors',
  //     body: JSON.stringify({ first_name: first_name, last_name: last_name}),
  //   })
  //     .then((resp) => {resp.json()})
  //     .then((newInfo) => {
  //       console.log('log newInfo in .then()')
  //       console.log(newInfo);
  //     })

  // }

  // async fetchData(individual_id) {
  //   const url = global.apiBaseUrl + '/skills/new?action=individual_dashboard'
  //   fetch(url, {
  //     method: 'POST',
  //     redirect: 'follow', // manual, *follow, error
  //     headers: { 'Content-Type': 'application/json' },
  //     mode: 'cors',
  //     body: JSON.stringify({ individual_id: individual_id }),
  //   })
  //     .then((resp) => resp.json())
  //     .then((data) => this.setState({ data: data }))
  // }

  return (
    <Form>
      <ion-title
        style={{
          fontSize: '25px',
          fontWeight: 'bolder',
          marginBottom: '35px',
          marginTop: '10%',
        }}
      >
        My free Stacklr <br />
        custom dashboard
      </ion-title>
      <Form.Field>
        <input
          // ref='first-name'
          type='text'
          placeholder='First name'
          onChange={(e) => setFirstName(e.target.value)}
          value={firstName}
        />
      </Form.Field>
      <Divider />
      <br />

      <Form.Field>
        <input
          type='text'
          // ref="last-name"
          placeholder='Last Name'
          onChange={(e) => setLastName(e.target.value)}
          value={lastName}
        />
      </Form.Field>
      <Divider />
      <br />
      <Button
        id='main-card-stakkid-button-4'
        disabled={!firstName}
        onClick={() => handleSubmit()}
      >
        View Now
      </Button>
    </Form>
  )
}
export default FormAd
