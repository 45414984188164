import React from 'react'
import mainStakkidPhoto from '../assets/images/AdobeStock_315070495.jpg'
import mainStakkidPhoto2 from '../assets/images/AdobeStock_222880408 (1).png'
import mainStakkidPhoto3 from '../assets/images/AdobeStock_285756278.jpg'
import 'pure-react-carousel/dist/react-carousel.es.css'
import StakkidImage from '../assets/logos/stacklr-final-logo.png'
import LoginDialogBox from './LoginDialogBox'
import fire from './firebase'
import Billing from './Billing'
import { IndividualHttpService } from '../http_services/individual.http_service'
import Carousel from 'react-bootstrap/Carousel'
import './Carousel.css'
import { Button } from './Button'
import '../App.css'
import './Button.css'
import "@fontsource/dm-sans";
import Slidertext from './Slider-text'

export default class Slider extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      fireErrors: '',
      showLogin: false,
      showCreateAccount: false,
      showAccountConfirmed: false,
    }

    this.signup = this.signup.bind(this)
    this.login = this.login.bind(this)
    this.handleShowLoginModal = this.handleShowLoginModal.bind(this)
    this.handleShowCreateAccount = this.handleShowCreateAccount.bind(this)
  }

  async login() {
    try {
      await fire
        .auth()
        .signInWithEmailAndPassword(this.state.email, this.state.password)
      const user = await fire.auth().currentUser
      await new IndividualHttpService().upsertByFirebaseUid({
        firebase_uid: user.uid,
        email: user.email,
      })
      if (user) {
        window.location.href = '/initial'
      }
      // window.location.href = '/initial'
    } catch (error) {
      console.log(error)
      alert(error.message)
    }

    this.handleShowLoginModal()
  }

  async signup(firstName, lastName, email, password) {
    try {
      await fire.auth().createUserWithEmailAndPassword(email.trim(), password)
      const user = await fire.auth().currentUser
      const response = await new IndividualHttpService().upsertByFirebaseUid({
        firebase_uid: user.uid,
        email: user.email,
        first_name: firstName,
        last_name: lastName
      })
      if (user) {
        // window.location.href = "https://community.stakkid.com";
        window.location.href = '/initial'
      }
    } catch (error) {
      console.log(error)
      alert(error.message)
    }

    this.handleShowAccountConfirmed()
  }

  async sendPasswordResetEmail(email) {
    try {
      await fire.auth().sendPasswordResetEmail(email)
      alert('Password reset link sent!')
    } catch (error) {
      console.log(error)
      alert(error.message)
    }
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleShowBundle() {
    this.setState({
      showBundle: !this.state.showBundle,
    })
  }

  handleShowCreateAccount() {
    this.setState({
      showLogin: false,
    })
    this.setState({
      showCreateAccount: !this.state.showCreateAccount,
    })
  }

  handleShouldShowModal() {
    this.setState({
      shouldShowModal: !this.state.shouldShowModal,
    })
  }

  handleShowLoginModal() {
    this.setState({
      showLogin: !this.state.showLogin,
    })
  }

  handleShowAccountConfirmed() {
    this.setState({
      showCreateAccount: false,
    })
    this.setState({
      showAccountConfirmed: !this.state.showAccountConfirmed,
    })
  }

  updateBundle(e) {
    this.setState({
      bundle: e.target.id,
    })
  }

  render() {
    let errorNotification = this.state.fireErrors ? (
      <div className='Error'>{this.state.fireErrors}</div>
    ) : null
    return (
      <div className='hero-container'>
        <LoginDialogBox
          showLogin={this.state.showLogin}
          handleShowCreateAccount={this.handleShowCreateAccount}
          handleShowLoginModal={this.handleShowLoginModal}
          login={this.login}
          showCreateAccount={this.state.showCreateAccount}
          signup={this.signup}
          handleShowAccountConfirmed={this.handleShowAccountConfirmed}
          showAccountConfirmed={this.state.showAccountConfirmed}
        />

          <div className='slide1'>
          < Slidertext />
            <img
              className='slide-studying'
              src={mainStakkidPhoto2}
              alt='studying in park'
              width='100%'
              height='auto'
            />


            
          </div>
         
        </div>
    )
  }
}