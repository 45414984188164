import React from 'react'
import { Link } from 'react-router-dom'
import stakkidLogo from '../assets/logos/stacklr-final-logo.png'
import { navigate } from '@reach/router'

function Footer() {
  return (
    <footer className='page-footer font-small' id='main-navbar-container'>
      <div
        className='footer-copyright py-3 d-flex justify-content-start'
        style={{ color: 'white' }}
      >
        <Link to='/'>
          <img
            src={stakkidLogo}
            onClick={() => navigate('/')}
            id='stakkid-nav-logo-footer'
            style={{ margin: '7px 15px' }}
          />
        </Link>
        <Link to='/termsofservice' id='footer-links'>
          <small>Terms and Agreement</small>
        </Link>
        <Link to='/privacy-policy' id='footer-links'>
          <small>Privacy Policy</small>
        </Link>
        <Link id='footer-links-contact'>
        <small >Phone: 602-820-7879</small>
        </Link>
        <Link id='footer-links-contact'>
        <small>Email: contactstacklr@stacklr.com</small>
        </Link>
      </div>
    </footer>
  )
}

export default Footer
