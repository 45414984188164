import React from 'react';

function PrivacyPolicy(){
    return(
        <div className="page-padding">
            <h1>Privacy Policy</h1>
            <br />
            <p>At STACKLR we are committed to the best practices in protecting the privacy of visitors to our website. This privacy policy discloses the practices for STACKLR and applies solely to information collected by this website and sets out how STACKLR uses and protects any information that visitors give STACKLR when using this website.
            </p>
            <p>
            Should we ask you to provide certain information by which you can be identified when using this website, you can be certain that it will be used in accordance with this privacy policy. Your information will remain anonymous.  Your personal information will not be shared or sold to competitors without your permission. STACKLR can change this policy at any time by updating this page. Changes are denoted by the “last updated” date at the bottom of the page. Visitors are encouraged to check this page from time to time to be notified of privacy policy changes.


            </p>

            <p>This policy is effective from January 19, 2022.</p>
            <p>The privacy policy of STACKLR will notify you of the following:</p>
            <p>
What personally identifiable information is collected from you through the website, how it is used and with whom it may be shared. What choices are available to you regarding the use of your data. The security procedures in place to protect the misuse of your information. How you can correct any inaccuracies in the information.
            </p>
            <p>INFORMATION COLLECTION, USE, AND SHARING</p>
            <p>We are the sole owners of the information collected on this site. We only have access to/collect information that you voluntarily 
                give us via email or other direct contact from you.
            </p>
            <p>
We will use your information to respond to you, regarding the reason you contacted us. At certain times, we do share your information with third-party advertisers and partners as it is necessary to fulfill your request. Unless you ask us not to, we may contact you via email in the future to inform you about current or new services.
            </p>
            <p>YOUR ACCESS TO AND CONTROL OVER INFORMATION</p>
            <p>You may opt out of any future contacts from us at any time. You can do the following at any time by contacting us via the email address 
                or phone number given on our website:
            </p>
            <p>See what data we have about you, if any Change/correct any data we have about you Have us delete any data we have about you Express any concern you have about our use of your data
            </p>
            <p>Under the CCPA (California Consumer Privacy Act), residents of California have specific rights regarding their personal information. STACKLR collects information that identifies, relates to, describes, references, is capable of or could be associated with, directly or indirectly, with a consumer (“personal information”).
            </p>
            <p>You have the right to request that we disclose certain information to you about our collection and use of your personal information over the past 12 months. You have the right to request that we delete any of your personal information that we collected from you and retained, subject to certain exceptions.
            </p>
            <p>SECURITY AND ENCRYPTION</p>
            <p>We take precautions to protect your information. When you submit sensitive information via the website, your information is protected both online and offline.</p>
            <p>Wherever we collect sensitive information including credit card data or personally identifiable data, that information is encrypted and transmitted to us in a 
                secure way. You can verify this by looking for a lock icon in the address bar and looking for “https” at the beginning of the address of the Web page.
            </p>
            <p>While we use encryption to protect sensitive information transmitted online, we also protect your information offline. Only employees who need the information 
                to perform a specific job are granted access to personally identifiable information. The computers/servers in which we store personally identifiable information 
                are kept in a secure environment.
            </p>
            <p>COOKIES</p>
            <p>We use “cookies” on this site. A cookie is a piece of data stored on a site visitor’s hard drive to help us improve your access to our site and identify repeat visitors to our site. For instance, when we use a cookie to identify you, you would not have to log in a password more than once, thereby saving time while on our site. Cookies can also enable us to track and target the interests of our users to enhance the experience on our site. Usage of a cookie is in no way linked to any personally identifiable information on our site.
            </p>
            <p>Some of our business partners, including advertisers, may use cookies on our site. However, we have no access to or control over these cookies.</p>
            <p>If you feel that we are not abiding by this privacy policy, you should contact us immediately via EMAIL ADDRESS.</p>
        </div>
    )
};

export default PrivacyPolicy;