import React from 'react';
import {makeStyles, useTheme } from '@material-ui/core/styles';
import './Rating.css'
import Rating from '@material-ui/lab/Rating'

// import React from 'react'
import './Rating.css'

const RatingStar = (props) => {
  const score = (props.score / 5) * 100
  const name = props.name
  const wrapperClassName = `${name}-wrapper`
  return (
    <span className={wrapperClassName}>
      <span className={name} style={{ width: score + '%' }}></span>
    </span>
  )
}

export default RatingStar

export const SkillRating = (props) => {
  const rating = props.rating
  return (
    <Rating
    readOnly={true}
    style={{ display: 'inline-flex', alignSelf: 'center', height: '100%', alignItems: 'center',position: 'relative', top: '11%', }}
      size="medium"
      value={rating}
      />
  )
}