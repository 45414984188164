import React from 'react';
// import firebase from "firebase";

export default class IndividualProfile extends React.Component {
    constructor(props){
        super(props)

        this.state = {
            firstName: '',
            lastName: '',
            job: ''
        }
    };

//     testGetIndividualSkills() {
//   var getIndividualSkillsFunctions = firebase
//     .functions()
//     .httpsCallable("getIndividualSkills");
//   getIndividualSkillsFunctions({})
//     .then(function (result) {
//       console.log("result is:");
//       console.log(result);
//     })
//     .catch((err) => {
//       console.log("!!!err is:");
//       console.log(err);
//     });
// }
    
    onChangeHandle(event){
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    render(){
        return(
            <div className="page-padding">
                <h1>Your Profile</h1>
                <hr />
                {/* <button onClick={this.testGetIndividualSkills}>test</button> */}
                <form>
                    <label id="profile-text">
                        Username
                        <br />
                        <p style={{fontSize: 'smaller'}}>
                            {/* {this.props.userData.email} */}
                            Your email
                        </p>
                    </label>
                    <br />
                    <button id="disabled-stakkid-button" >
                        Edit
                    </button>
                    <button id="disabled-stakkid-button" >
                        Update Password
                    </button>
                    <br />
                    <br />
                    <label id="profile-text">
                        First Name <sup>*</sup>
                        <br />
                        <input
                            id="profile-input"
                            type="text"
                            name="firstName"
                            placeholder="First Name"
                            value={this.state.firstName}
                            onChange={(event) => this.onChangeHandle(event)}
                        />
                    </label>
                    <br />
                    <label id="profile-text">
                        Last Name <sup>*</sup>
                        <br />
                        <input
                            id="profile-input"
                            type="text"
                            name="lastName"
                            placeholder="Last Name"
                            value={this.state.lastName}
                            onChange={(event) => this.onChangeHandle(event)}
                        />
                    </label>
                    <br />
                    <label id="profile-text">
                        Job
                        <br />
                        <input
                            id="profile-input"
                            type="text"
                            name="job"
                            placeholder="Job"
                            value={this.state.job}
                            onChange={(event) => this.onChangeHandle(event)}
                        />
                    </label>
                    <br />
                    <label id="profile-text">
                        Team
                        <br />
                        <input id="profile-input" type="text" name="team" disabled="true" style={{backgroundColor: 'lightgray'}} />
                        <button id="disabled-profile-button" disabled="true">
                            <svg class="bi bi-x" width="3em" height="3em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" d="M11.854 4.146a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708-.708l7-7a.5.5 0 0 1 .708 0z"/>
                                <path fill-rule="evenodd" d="M4.146 4.146a.5.5 0 0 0 0 .708l7 7a.5.5 0 0 0 .708-.708l-7-7a.5.5 0 0 0-.708 0z"/>
                            </svg>
                        </button>
                    </label>
                    <br />
                    <label id="profile-text">
                        Company
                        <br />
                        <input id="profile-input" type="text" name="company" disabled="true" style={{backgroundColor: 'lightgray'}} />
                        <button id="disabled-profile-button" disabled="true">
                            <svg class="bi bi-x" width="3em" height="3em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" d="M11.854 4.146a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708-.708l7-7a.5.5 0 0 1 .708 0z"/>
                                <path fill-rule="evenodd" d="M4.146 4.146a.5.5 0 0 0 0 .708l7 7a.5.5 0 0 0 .708-.708l-7-7a.5.5 0 0 0-.708 0z"/>
                            </svg>
                        </button>
                    </label>
                    <br />
                    <input id="main-stakkid-button" type="submit" value="Save" />
                </form>
            </div>
        )
    }
};