import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown';

// import stakkidLogo from '../assets/logos/logo_white.svg'
import stakkidLogo from '../assets/logos/stacklr-final-logo.png'

import fire from './firebase'
import { IndividualHttpService } from '../http_services/individual.http_service'
import { navigate, redirectTo } from '@reach/router'
import LoginDialogBox from './LoginDialogBox'
import { signInWithGoogle } from './firebase'
import PDFContent from './PDFContent'
import Selector from './Selector'
import SearchBar from './SearchBar'
import SelectSearchBar from './SelectSearchBar'
import ToolSearch from './ToolSearch'



class NavBar extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      fireErrors: '',
      showLogin: false,
      showCreateAccount: false,
      showAccountConfirmed: false,
    }

    this.signup = this.signup.bind(this)
    this.login = this.login.bind(this)
    this.logout = this.logout.bind(this)
    this.handleShowLoginModal = this.handleShowLoginModal.bind(this)
    this.handleShowCreateAccount = this.handleShowCreateAccount.bind(this)
  }

  async login(email, password) {
    try {
      await fire.auth().signInWithEmailAndPassword(email.trim(), password)
      const user = await fire.auth().currentUser
      await new IndividualHttpService().upsertByFirebaseUid({
        firebase_uid: user.uid,
        email: user.email,
      })
      if (user) {
        // window.location.href = "https://community.stakkid.com";
        window.location.href = '/individual-dashboard'
      }
      // window.location.href = "/initial";
    } catch (error) {
      console.log(error)
      alert(error.message)
    }

    this.handleShowLoginModal()
  }

  async signup(firstName, lastName, email, password) {
    try {
      await fire.auth().createUserWithEmailAndPassword(email.trim(), password)
      const user = await fire.auth().currentUser
      const response = await new IndividualHttpService().upsertByFirebaseUid({
        firebase_uid: user.uid,
        email: user.email,
        first_name: firstName,
        last_name: lastName
      })
      if (user) {
        // window.location.href = "https://community.stakkid.com";
        // window.location.href = '/login-banner'
              window.location.href = "/initial";

      }
    } catch (error) {
      console.log(error)
      alert(error.message)
    }

    this.handleShowAccountConfirmed()
  }

  async sendPasswordResetEmail(email) {
    try {
      await fire.auth().sendPasswordResetEmail(email)
      alert('Password reset link sent!')
    } catch (error) {
      console.log(error)
      alert(error.message)
    }
  }

  handleShowCreateAccount() {
    this.setState({
      showLogin: false,
    })
    this.setState({
      showCreateAccount: !this.state.showCreateAccount,
    })
  }

  handleShowAccountConfirmed() {
    this.setState({
      showCreateAccount: false,
    })
    this.setState({
      showAccountConfirmed: !this.state.showAccountConfirmed,
    })
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleShowLoginModal() {
    this.setState({
      showLogin: !this.state.showLogin,
    })
  }

  async logout() {
    await fire.auth().signOut()
    window.location = '/'
  }

  render() {
    let errorNotification = this.state.fireErrors ? (
      <div className='Error'>{this.state.fireErrors}</div>
    ) : null

    return (
      <div>
        {/* <PDFContent /> */}
        <LoginDialogBox
          fireErrors={this.state.fireErrors}
          showLogin={this.state.showLogin}
          handleShowCreateAccount={this.handleShowCreateAccount}
          handleShowLoginModal={this.handleShowLoginModal}
          login={this.login}
          showCreateAccount={this.state.showCreateAccount}
          signup={this.signup}
          handleShowAccountConfirmed={this.handleShowAccountConfirmed}
          showAccountConfirmed={this.state.showAccountConfirmed}
        />



        <Navbar expand='lg' id='main-navbar-container-23'>
          <Navbar.Brand href='/' style={{ color: 'white' }}>
            <img
              src={stakkidLogo}
              id='stakkid-nav-logo'
              onClick={() => navigate('/')}
            />
          </Navbar.Brand>

          {/* <nav></nav> */}
          <Navbar.Toggle
            aria-controls='basic-navbar-nav'
            style={{ backgroundColor: 'white' }}
          />
          <Navbar.Collapse id='basic-navbar-nav'>
            <Nav className='mr-auto' id='bold-fonte-nav'>
              <Nav>
                {!this.props.userData && (
                  <Nav.Link
                    href='#'
                    onClick={() => {
                      window.location.href = '/my-score'
                      return false
                    }}
                    id='navbar-links'
                  >
                    My Score
                  </Nav.Link>
                )}
                {this.props.userData && (
                  <Nav.Link
                    href='#'
                    onClick={() => {
                      (window.location.href = '/individual-dashboard')
                      return false
                    }}
                    id='navbar-links'
                  >
                    My Score
                  </Nav.Link>
                )}

                
<NavDropdown title="Our Solutions" id="navbar-links">
          <NavDropdown.Item href="/software-clients">Insights for Martech Software Clients</NavDropdown.Item>
          <NavDropdown.Item href="/in-market">In-market Buyers</NavDropdown.Item>
          <NavDropdown.Item href="/about">Org Scoring for Enterprise Clients</NavDropdown.Item>
        </NavDropdown>

 


                {/* <Nav.Link href='/about' id='navbar-links'>
                  Org Scoring
                </Nav.Link> */}
                {/* <Nav.Link href='/resource-partners' id='navbar-links'>
                  Resources
                </Nav.Link> */}
                <Nav.Link href='/stakkid-community' id='navbar-links'>
                  Community
                </Nav.Link>
                {/* <Nav.Link href='/software-clients' id='navbar-links'>
                Software Clients
                </Nav.Link> */}
                <Nav.Link href='/Jobs' id='navbar-links'>
                  Jobs
                </Nav.Link>
                <Nav.Link href='/about-us' id='navbar-links'>
                  About
                </Nav.Link>
                
                {/* <Nav.Link href="/billing" id="navbar-links">Billing</Nav.Link>
              <Nav.Link href="/team-profile" id="navbar-links">Team Profile</Nav.Link>
              <Nav.Link href="/team-license" id="navbar-links">Team Licenses</Nav.Link>
              <Nav.Link href="/company-profile" id="navbar-links">Company Profile</Nav.Link>
              <Nav.Link href="/company-license" id="navbar-links">Company Licenses</Nav.Link> */}
              </Nav>
               <Nav>
            <SelectSearchBar/>
            {/* <Selector /> */}
            </Nav>
            </Nav>

           
            <Nav>
              {/* <div>
                {this.props.userData && (
                  <NavDropdown title= <Link to='/individual-dashboard'>
                  {this.props.userData.email}
                </Link>> 
                    <NavDropdown.Item href='/user-rating' eventKey="4.0">Rate Tools</NavDropdown.Item>
                    <NavDropdown.Item href='/initial' eventKey="4.1">Update My Score</NavDropdown.Item>
                    <NavDropdown.Item href='/individual-dashboard' eventKey="4.2">My Dashboard</NavDropdown.Item>


                    <NavDropdown.Divider />
                    <NavDropdown.Item eventKey="4.3">Post Score on Linkedin </NavDropdown.Item>
                    <NavDropdown.Item eventKey="4.4">Post Certificate on Linkedin </NavDropdown.Item>
                  </NavDropdown>
                )}
              </div> */}

              <div
                className='user-email'
                style={{ marginTop: '2px', padding: '8px' }}
              >


                {this.props.userData && (
                  <Link to='/individual-dashboard'>
                    {this.props.userData.email}
                  </Link>



                )}
              </div>



              {!this.props.userData && (
                <button
                  type='button'
                  onClick={(event) => {
                    this.handleShowCreateAccount(event)
                    return false
                  }}
                  id='main-card-stakkid-button-2'
                >
                  Sign Up
                </button>
              )}
              {this.props.userData && (
                <button
                  href='javascript:void'

                  onClick={() => {
                    this.logout()
                    return false
                  }}
                  id='main-card-stakkid-button-2'
                >
                  Sign Out
                </button>
              )}
            </Nav>
          </Navbar.Collapse>
          
        </Navbar>
        <Navbar style={{ backgroundColor: 'rgba(247,1,1,255)', alignItems: 'center', display: 'flex', justifyContent: 'center', padding: '0' }}>
  <h3 className="text-center" style={{ color: 'white', textAlign: 'center', paddingTop: '10px' }}>
    <b>MARTECH SOFTWARE COMPANIES</b> activate your software highlights now
  </h3>
<button
 className="software-stakkid-button-rate-1"
 // onClick={() => goNext()}
 onClick={() =>
   window.open("https://stacklr.zoholandingpage.com/Activation/")}
>
 {' '}
 ACTIVATE 
</button></Navbar>

        <Modal />
      </div>
    )
  }
}

export default withRouter(NavBar)