export class RouteService {
    static params() {
        // eslint-disable-next-line no-restricted-globals
        const search = location.href.split('?')[1];
        if (search === undefined) { return {}; }
        return JSON.parse(
            '{"' + decodeURI(search).replace(/"/g, '\\"'
            ).replace(/&/g, '","')
                .replace(/=/g, '":"') + '"}');
    }
}
