import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import { signInWithGoogle } from './firebase'
import GoogleButton from 'react-google-button'
import Terms from './Terms'
import TermsOfUse from './TermsOfUse'
import { initializeApp } from 'firebase-admin'
import LinkedInLogin from './LinkedinLogin'

import '../App.css'

// import './Term.css'

const LoginDialogBox = ({
  handleShowCreateAccount,
  handleShowLoginModal,
  showLogin,
  login,
  showCreateAccount = false,
  signup,

  // handleShowAccountConfirmed,
  // showAccountConfirmed = false,
}) => {
  console.log('show login in lignDialogBox', showLogin)
  console.log('login in lgoin dialog box', login)
  return (
    <div>
      <SignInDialog
        handleShowCreateAccount={handleShowCreateAccount}
        handleShowLoginModal={handleShowLoginModal}
        showLogin={showLogin}
        login={login}
      />
      <CreateNewAccount
        handleShowCreateAccount={handleShowCreateAccount}
        showCreateAccount={showCreateAccount}
        handleShowLoginModal={handleShowLoginModal}
        signup={signup}
      />
      {/* <AccountCreatedConfirmation
        handleShowAccountConfirmed={handleShowAccountConfirmed}
        showAccountConfirmed={showAccountConfirmed}
      /> */}
    </div>
  )
}

const CreateNewAccount = ({
  showCreateAccount,
  handleShowCreateAccount,
  handleShowLoginModal,
  signup,
}) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [agree, setAgree] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  const checkboxHandler = () => {
    // if agree === true, it will be set to false
    // if agree === false, it will be set to true

    setAgree(!agree)
    // Don't miss the exclamation mark
  }

  // When the button is clicked
  const btnHandler = () => {
    alert(
      'Please indicate that you have read and agree to the Terms and Agreement'
    )
  }

  return (
    <Modal id='create-account-modal' show={showCreateAccount}>
      <Modal.Body className='modal-body row d-flex justify-content-between'>
        <div className='col-md-12 mb-2 d-flex modalTitle'>
          <h4 className='text-left mb-0 signin'>
            <b className='signi'>Create</b> Your Account
          </h4>

          <Modal.Header
            className='ml-auto'
            closeButton
            onClick={() => handleShowCreateAccount()}
          ></Modal.Header>
        </div>

        {/* <div className='social-buttonn'>
          <div className='social-button1'>
            <LinkedInLogin />
          </div>
          <div className='social-button2'>
            <GoogleButton type='dark' onClick={signInWithGoogle}></GoogleButton>
          </div>
        </div> */}

        <div className='col-md-12 mb-4 SignGoogle'></div>
        <div className='col-md-6'>
          <div className='InputDiv'>
            <label>
              First Name<sup>*</sup>
            </label>
            <div className='InputIcon'>
              <input
                id='profile-input'
                name='first_name'
                type='text'
                placeholder='First Name'
                style={{ margin: '7px' }}
                value={firstName}
                onChange={(event) => setFirstName(event.target.value)}
              />
            </div>
          </div>
        </div>
        <div className='col-md-6'>
          <div className='InputDiv'>
            <label>
              Last Name<sup>*</sup>
            </label>
            <div className='InputIcon'>
              <input
                id='profile-input'
                name='last_name'
                type='text'
                placeholder='Last Name'
                style={{ margin: '7px' }}
                value={lastName}
                onChange={(event) => setLastName(event.target.value)}
              />
            </div>
          </div>
        </div>
        {/* <div className='col-md-12'>
          <div className='InputDiv'>
            <label>
            Current Company
            </label>
            <div className='InputIcon'>
              <input
                id='profile-input'
                name='email'
                type='email'
                placeholder='Company'
                style={{ margin: '7px' }}
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
            </div>
          </div>
                  </div> */}

        <div className='col-md-12'>
          <div className='InputDiv'>
            <label>
              Username (email)<sup>*</sup>
            </label>
            <div className='InputIcon'>
              <input
                id='profile-input'
                name='email'
                type='email'
                placeholder='Email'
                style={{ margin: '7px' }}
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
            </div>
          </div>
        </div>

        <div className='col-md-12'>
          <div className='InputDiv'>
            <label>
              Password<sup>*</sup>
            </label>
            <div className='InputIcon'>
              <input
                id='profile-input'
                name='password'
                type='password'
                placeholder='Password'
                style={{ margin: '7px' }}
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              />
            </div>
          </div>
        </div>

        <div className='terms-message'>
          <input type='checkbox' id='agree' onChange={checkboxHandler} />

          <label for='agree' className='form-check-label'>
            I have read and agree to the{' '}
            <Link onClick={() => setIsOpen(true)}>Terms & Agreement</Link>
          </label>
          <Terms open={isOpen} onClose={() => setIsOpen(false)}>
            <TermsOfUse></TermsOfUse>
          </Terms>
        </div>

        {/* Don't miss the exclamation mark* */}

        <div className='col-md-12'>
          <div class='BtnWrap mt-3'>
            <button
              disabled={!agree}
              style={{
                margin: '0 auto',
                marginTop: '10px',
                marginBottom: '25px',
              }}
              className='col-md-3'
              id='main-stakkid-button'
              onClick={() => signup(firstName, lastName, email, password)}
            >
              Submit
            </button>
          </div>
        </div>
        <p className='col-md-12'>
          Already have an account?{' '}
          <Link to='' onClick={(event) => handleShowLoginModal(event)}>
            Sign in
          </Link>
        </p>
      </Modal.Body>
    </Modal>
  )
}

const SignInDialog = ({
  handleShowCreateAccount,
  handleShowLoginModal,
  showLogin,
  login,
}) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  console.log('login func in signInDialog', login)
  return (
    <Modal id='create-account-modal' show={showLogin}>
      <Modal.Body className='modal-body row d-flex justify-content-between'>
        <div className='col-md-12 mb-2 d-flex modalTitle'>
          <h4 className='text-center mb-0 signin-1'>
            {' '}
            Already Have An Account? <br /> <b className='signi'>
              Sign In
            </b>{' '}
            Here
          </h4>
          <Modal.Header
            className='ml-auto'
            closeButton
            onClick={(event) => handleShowLoginModal(event)}
          ></Modal.Header>
        </div>
        <div className='social-buttonn1'>
          <div className='social-button1'>
            <LinkedInLogin />
          </div>
          <div className='social-button2'>
            <GoogleButton type='dark' onClick={signInWithGoogle}></GoogleButton>
          </div>
        </div>

        <div className='col-md-12'>
          <div className='InputDiv'>
            <label>
              {' '}
              Username (email)<sup>*</sup>
            </label>
            <div className='InputIcon'>
              <input
                id='profile-input'
                name='email'
                type='text'
                placeholder='Email'
                style={{ margin: '7px' }}
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
            </div>
          </div>
        </div>
        <div className='col-md-12'>
          <div className='InputDiv'>
            <label>
              {' '}
              Password<sup>*</sup>
            </label>
            <div className='InputIcon'>
              <input
                id='profile-input'
                name='password'
                type='password'
                placeholder='Password'
                style={{ margin: '7px' }}
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              />
            </div>
          </div>

          <div>
            <Link to='/reset'>Forgot Password</Link>
          </div>
        </div>

        <div className='col-md-12'>
          <div className='BtnWrap mt-3'>
            <button
              type='button'
              style={{
                margin: '0 auto',
                marginTop: '30px',
                marginBottom: '25px',
              }}
              className='col-md-3'
              id='main-stakkid-button'
              onClick={() => login(email, password)}
            // onClick={(event) => this.signup(event)}
            >
              Submit
            </button>
          </div>
        </div>

        <p className='col-md-12'>
          To create your account.{' '}
          <Link to='' onClick={() => handleShowCreateAccount()}>
            Sign up
          </Link>
        </p>
      </Modal.Body>
    </Modal>
  )
}

const AccountCreatedConfirmation = ({
  handleShowAccountConfirmed,
  showAccountConfirmed,
}) => {
  return (
    <Modal
      id='account-confirmed-modal'
      centered='true'
      show={showAccountConfirmed}
    >
      <Modal.Header
        closeButton
        onClick={() => handleShowAccountConfirmed()}
      ></Modal.Header>
      <Modal.Body className='modal-body row d-flex justify-content-center'>
        <h4 style={{ textAlign: 'center' }} className='col-md-12'>
          Account Created
        </h4>
        <p>You will be redirected to your scoring modal shortly.</p>
      </Modal.Body>
    </Modal>
  )
}
// <p>Check your email to confirm your account.</p>

export default LoginDialogBox