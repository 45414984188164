import { makeStyles, useTheme } from '@material-ui/core/styles';


import React, { useState, useEffect } from 'react';
import fire from './firebase';
import stacklrIcon from '../assets/icons/stacks-1-128x128.png'
import cubesIcon from '../assets/icons/3d-cube-128x128.png'
// import adIcon from '../assets/icons/ad-promo-128x128.png'
import commerceIcon from '../assets/icons/commerce-256x256.png'
import contentIcon from '../assets/icons/content-256x256.png'
import managementIcon from '../assets/icons/management-256x256.png'
import relationshipsIcon from '../assets/icons/relationships-256x256.png'
// import salesIcon from '../assets/icons/sales-128x128.png'
import dataIcon from '../assets/icons/data-256x256.png'
// import growthIcon from '../assets/icons/stock-market-512x512.png'
import mobileShopIcon from '../assets/icons/mobile-shop-256x256.png'
import adIcon from '../assets/icons/online-advertising-256x256.png'


import '../App.css';

import CircularProgress from '@material-ui/core/CircularProgress';

import Axios from 'axios';
import { Link } from '@material-ui/core';
// import { navigate } from '@reach/router'

import TopSkills from './TopSkills'

export default function RateSkills() {
  const [showLoading, setShowLoading] = useState(true);
  const [userId, setUserId] = useState(null)
  const currentUser = fire.auth().currentUser
  const [data, setData] = useState(null)
  // if (currentUser == null) {

  // }
  useEffect(() => {   
      const controller = new AbortController();
      const signal = controller.signal;
      const cancelToken = Axios.CancelToken.source();
      const fetchData = async () => {
      const axiosUrl = global.apiBaseUrl + `/individuals?firebase_uid=${currentUser.uid}`
        let responseAxios = ''
      if (userId == null) {

         responseAxios = await Axios
        .get(axiosUrl, {
          cancelToken: cancelToken.token,

        })
        .then((res) => {
          const individualId = res.data[0].id
          setUserId(individualId)
          return individualId;       
        })
        .catch((error) => {
          if (Axios.isCancel(error)) {
            console.log("Request canceled!");
          } else if (error.response) {
            // Request made and server responded
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
        });
      }
      
      const skillUrl = global.apiBaseUrl + '/skills/new?action=individual_top_skills_by_category';

      const responseFetch = await  fetch(skillUrl,{
        method: 'POST',
        // crossDomain:true,
        redirect: 'follow', // manual, *follow, error
        headers: { 'Content-Type': 'application/json' },
        mode: 'cors',
        body: JSON.stringify({ individual_id: userId == null ? responseAxios: userId, 
          number_of_skills: 3}),
        signal: signal,
      })
        .then((resp) => resp.json())
        .then((data) => {
          console.log('skill fetch successfull in useEffect')
          return data

        }).catch(error => {
          if (typeof error.json === "function") {
              error.json().then(jsonError => {
                  console.log("Json error from API");
                  console.log(jsonError);
              }).catch(genericError => {
                  console.log("Generic error from API");
                  console.log(genericError);
                  console.log(genericError.statusText);
              });
          } else {
              console.log("Fetch error");
              console.log(error);
          }
      });
      setData(responseFetch);
    };
    setTimeout(() => {
      if (currentUser != null) {
 
      fetchData();
      setShowLoading(false);
      }
    }, 1000);
    return () => {
      cancelToken.cancel();
      controller.abort();
    }
  }, [currentUser]);

  const useStyles = makeStyles(({ breakpoints,spacing }) => ({
    loadingContainer: {
      margin: 'auto',
      width: '80%', 
      height: '80vh', 
      display: 'flex',
      flexDirection: 'column', 
      alignSelf: 'center', 
      alignItems : 'center', 
      justifyContent: 'center',
      justifySelf: 'center', 
    },
    homeLink: {
      // border: '1px solid blue',
      marginTop: '2rem',
      borderRadius: '8px', cursor: 'pointer', 
      background: '#6e4e90',
      boxShadow: '8px 10px 10px 1px rgba(0,0,0,0.5)',
      '&:hover': {
        transform: 'translateY(-5px) scale(1.005) translateZ(0)',
        // boxShadow: '0 24px 36px rgba(0,0,0,0.11) 0 24px 46px gray',
      }
    },
    flexRow: {
      display: 'flex', 
      flexDirection: 'row'
    },
    gridContainer: {
      marginBottom: '90px', 
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      // border: '2px dashed orange',
      fontFamily: 'Roboto'
    },
    headerSpan: {
      borderRadius: '8px',
      // background: '#21A3A3',
      width: '58%',
      minWidth: '300px',
      padding: '20px 10px',
      textAlign: 'center',
      margin: 'auto',
      marginTop: '1rem',
      marginBottom: '2rem',
      color: 'black',
    },
    gridHeader: {
      marginTop: '1rem',
      marginBottom: '2rem',
      placeSelf: 'center',
      width: 'auto',
      fontFamily: 'Roboto',
      fontWeight: 600,
      letterSpacing: '2px',
    },
    headerImg: {
      display: 'block',
      margin: 'auto',
      marginBottom: '3rem',
      width: '8rem',
      
    },
    grid: {
    justifyContent: 'space-evenly',
    width: '97%',
    margin: 'auto',
    // marginBottom: '2rem'
    },
    cardHeader: {
      position: 'relative',
      paddingBottom: '20px',
      // marginTop: '-20px',
      top: '-2.3rem',
      fontSize: '1.75rem',
      zIndex: '5',
      background: '#1C7BBE',
      // background: '#124D77',
      // background: '#BADFFA',


    },
    card: {
      marginTop: '2rem',
      marginBottom: '2.5rem',
      padding: '2rem',
      borderRadius: spacing(1.5),
      transition: '0.3s',
      width: '96%',
      overflow: 'initial',
      color: 'white',
      // background: '#BEABD2',
      background: '#2497ec',
      // background: '#4DADF1',
      // background: '#62B6F3',
      // background: '#847792',
      margin: 'auto',
      letterSpacing: '4px',
      minWidth: '300px',
      boxShadow: '0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25), 0 8px 16px -8px hsla(0, 0%, 0%, 0.3), 0 -6px 16px -6px hsla(0, 0%, 0%, 0.03)',
      transition: 'all ease 200ms',
      '&:hover': {
        boxShadow:' 0 0 4em 0px rgba(0, 0, 0, 0.6)',
        transform: 'scale(1.01)',
      }
    },
    box: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      // border: '2px dashed red'

    },
    skillName: {
      fontSize: '1.4rem',
      float: 'left', 
      maxWidth: '70%', 
      // border: '2px dashed purple'
    },
    avgRatingText: {
      float: 'right', 
      fontSize: '0.9rem', 
    },
    avgRatingNumber: {
      fontSize: '1.5rem',
      fontWeight: '600',
    },
      content: {
        zIndex: '2',
      alignItems: 'center',
      paddingTop: '1.5rem',
      textAlign: 'left',
      overflowX: 'auto',
      '& Fieldset': {
        '& legend': {
        fontSize: '1.4rem',
        fontWeight: '600',
        fontFamily: 'Roboto',
        
        }
      }
    },
    media: {
    display: 'block',
    zIndex: '1',
    width: '10rem',
    height: '10rem',
      margin: 'auto',
      // transform: 'scale(1.8)',
      borderRadius: spacing(2),
      [breakpoints.up('md')]: {
        marginTop: 20,
        // transform: 'translateY(28px)',
        transition: '5s'
      },
      '&:after': {
        content: '" "',
        // position: 'absolute',
        // top: 0,
        // left: 0,
        // width: '70%',
        // height: 'auto',
        borderRadius: spacing(2), // 16
        // opacity: 0.7,
      },
    },
  }));
  const classes = useStyles();


  if (data == null){
    const NoUser =() => {
       return <>
      <h3>No user logged in</h3>
      <h4>Sign up or log in to access this page</h4>
      <Link 
        to='/'
        className={classes.homeLink}
      >
          <img
            src={stacklrIcon}
            onClick={() => window.location.href='/'}
            style={{ margin: '7px 15px', }}
          />
        </Link>
        </>
    }
    return <div className={classes.loadingContainer}>
      <h4>Loading your top Skills...</h4>
      <div className={classes.flexRow}>
      <CircularProgress />
      <CircularProgress color="secondary" />
      <CircularProgress color="tertiary" />
      </div>
      {currentUser ? null:<NoUser />}
      </div>

  } else if (Object.keys(data)) {
    const icons= {
      'Ad & Promo': adIcon,
      'Content & Experience': contentIcon,
      'Commerce & Sales': mobileShopIcon,
      'Management': managementIcon,
      'Data': dataIcon,
      'Social & Relationships': relationshipsIcon,
    }
    return <div className={classes.gridContainer} >
        {/* <span className={classes.headerSpan}> */}
        {/* <h4 className={classes.gridHeader} >Top skills dashboard</h4> */}
        <h5 className={classes.gridHeader}>Rate your top skills</h5>
        <img className={classes.headerImg} style={{}} src={stacklrIcon} />
        {/* </span> */}
          <TopSkills key="skillsComponent" data={data} individual_id={userId} icons={icons}/>
      </div>
    ;
  }
}