import Select from 'react-select';
import { useHistory } from 'react-router-dom';
import { useState, useEffect } from 'react';
import debounce from 'lodash.debounce';

const SelectSearchBar = () => {
  const history = useHistory();
  const [options, setOptions] = useState([]);
  const [data, setData] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  const handleRedirect = (selectedOption) => {
    if (selectedOption) {
      const { id } = selectedOption;
      window.location.href = '/software/' + id; // Redirect to the selected URL
    }
  };

  const debouncedFilterOptions = debounce((inputValue) => {
    const filtered = data.filter(option =>
      option.tool_name.toLowerCase().includes(inputValue.toLowerCase())
    );
    if (filtered.length > 100) {
       const sliced = filtered.slice(0, 100);
       setOptions(sliced);
    } else {
      setOptions(filtered);
    }
  }, 500); // Adjust the delay as per your need

  const handleInputChange = (inputValue) => {
    setSearchValue(inputValue);
    debouncedFilterOptions(inputValue);
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const url = global.apiBaseUrl + `/tools/new?action=tools_dashboard`;
        const response = await fetch(url, {
          method: 'POST',
          redirect: 'follow', // manual, *follow, error
          headers: { 'Content-Type': 'application/json' },
          mode: 'cors',
          body: JSON.stringify({ firebase_uid: '' }),
        });
        const responseData = await response.json();
        setData(responseData);
        const filterData = responseData.slice(0, 100);
        setOptions(filterData);
      } catch (e) {
        console.log('error');
      }
    }

    fetchData();
  }, []);

  const customStyles = {
  };

  return (
    <div style={{width: '300px', marginTop: '10px', marginLeft: '10px'}}>
      <Select
        options={options}
        getOptionLabel ={(option)=>option.tool_name}
        onChange={handleRedirect}
        onInputChange={handleInputChange}
        filterOption={() => true}
        placeholder="Find a Martech software"
        menuPlacement="auto"
        menuPosition="fixed"
        inputValue={searchValue}
        styles={customStyles}
      />
    </div>
  );
};

export default SelectSearchBar