import React from 'react'
import aboutPhoto from '../assets/images/about-score_img-layers.png'
import TebImage from '../assets/images/resources_TEB-2x.png'


function About() {
  return (
    <div className='page-padding'>
      <h1 id='bold-fonte'>Align people, tools and experience</h1>
      {/* <h4 style={{ textAlign: 'center' }}>link people, tools and experience</h4> */}
      <hr />

      <br />
      <h3 id='bold-fonte'>Strategy</h3>
      <br />
      <p style={{ fontSize: '18px' }}>
        Your Organizational MarTech Stacklr Score provides insight to a key
        strategic objective of aligning people and tools. When you know where
        your MarTech experience gaps are in the organization and what tools are
        most dominant, you gain critical input for tool selection. You gain
        speed-to-market and resource optimization. First match tools that
        support your marketing strategy. Then access the visibility of the
        people on your team who possess the experience with these specific
        tools. Lastly, skill all key people in your organization with these
        digital tools. The Stacklr tools and strategy score will unleash the
        opportunity. That’s power.
      </p>
      <div>
        <img
          src={aboutPhoto}
          className='img-fluid mx-auto d-block'
          alt='...'
          style={{ margin: '15px' }}
        />
      </div>
      <p style={{ fontSize: '18px' }}>
        Stacklr strategy scoring follows the strategic breakouts from Chief
        Martec of Attraction, Engagement and Analysis & Optimization. 
      </p>
      <div className='row card-container d-flex justify-content-around'>
        <div className='col-sm-5' id='about-page-card'>
          <div className='card'>
            <div className='card-body'>
              <h4 className='card-title' id='bold-fonte'>
                Progressive
              </h4>
              <p className='card-text'>
                Bleeding edge of the industry which is creating market
                disruption with capabilities enabled within the MarTech stack.
              </p>
            </div>
          </div>
        </div>
        <div className='col-sm-5' id='about-page-card'>
          <div className='card'>
            <div className='card-body'>
              <h4 className='card-title' id='bold-fonte'>
                Optimized
              </h4>
              <p className='card-text'>
                Advanced stages of planned activities to leverage and achieve
                goals with capabilities that are enabled within the MarTech
                stack.
              </p>
            </div>
          </div>
        </div>
        <div className='col-sm-5' id='about-page-card'>
          <div className='card'>
            <div className='card-body'>
              <h4 className='card-title' id='bold-fonte'>
                Defined
              </h4>
              <p className='card-text'>
                Ability to leverage and accomplish goals with capabilities
                enabled within the MarTech stack.
              </p>
            </div>
          </div>
        </div>
        <div className='col-sm-5' id='about-page-card'>
          <div className='card'>
            <div className='card-body'>
              <h4 className='card-title' id='bold-fonte'>
                Basic
              </h4>
              <p className='card-text'>
                Consistent efforts and capabilities are enabled within the
                MarTech stack.
              </p>
            </div>
          </div>
        </div>
        <div className='col-sm-5' id='about-page-card'>
          <div className='card'>
            <div className='card-body'>
              <h4 className='card-title' id='bold-fonte'>
                Ad hoc
              </h4>
              <p className='card-text'>
                Inconsistent efforts or limited capabilities enabled within the
                martech stack.
              </p>
            </div>
          </div>
        </div>
        <div className='col-sm-5' id='about-page-card'>
          <div className='card'>
            <div className='card-body'>
              <h4 className='card-title' id='bold-fonte'>
                Non-existent
              </h4>
              <p className='card-text'>
                Little to no efforts or capabilities are enabled within the
                MarTech stack.
              </p>
            </div>
          </div>
        </div>
      </div>
      <table width='100%'>
        <tbody>
          <div id='resources-partners-header-container'>
            <tr>
              <td>
                <h1>Find a Stacklr Resource Partner</h1>{' '}
              </td>
            </tr>
          </div>
          <div id='resources-learn-more-container'>
            <tr>
              <td>
                <hr />
              </td>
            </tr>
            <div id='experts-bench-text-container'>
              <tr>
                <td className='align-middle'>
                  <img src={TebImage} width='200' paddingTop='-10px' />
                </td>
                <td width='10%'></td>
                <td width='70%'>
                  The Experts Bench (Teb) enables marketing optimization through
                  a range of consulting, professional services and staffing for
                  marketing technologies. Teb offers a competitive analysis of
                  your Stacklr company score and solutions for
                  martech&nbsp;gaps.
                  <br />
                  <br />
                  <a href='http://www.tebww.com' target='_blank'>
                    LEARN MORE
                  </a>
                </td>
              </tr>
            </div>

            <tr>
              <td colspan='2'>
                <hr />
              </td>
            </tr>
            <div id='experts-bench-text-container'>
              <tr>
                <td className='align-middle'>
                  <img
                    src='https://cdn.chiefmartec.com/wp-content/uploads/2021/09/chiefmartec-logo-165x41.png'
                    width='200'
                  />
                </td>
                <td width='0%'></td>
                <td width='10%'></td>
                <td width='70%'>
                  Stacklr partners with Chief Martec for the latest insights on
                  marketing technology changes.
                  <br />
                  <br />
                  <a href='https://chiefmartec.com/' target='_blank'>
                    LEARN MORE
                  </a>
                </td>
              </tr>
            </div>

            <tr>
              <td colspan='2'>
                <hr />
              </td>
            </tr>
            <div id='experts-bench-text-container'>
              <tr>
                <td className='align-middle'>
                  <img
                    src='https://pbs.twimg.com/profile_images/1158830616974020608/UVDZeFsY_400x400.jpg'
                    width='200'
                  />
                </td>
                <td width='10%'></td>
                <td width='70%' >
                  The CDP Institute partners with Stacklr to provide
                  vendor-neutral information about issues, methods, and
                  technology related to customer data management and educational
                  materials on CDP.
                  <br />
                  <br />
                  <a href='https://www.cdpinstitute.org/' target='_blank'>
                    LEARN MORE
                  </a>
                </td>
              </tr>
            </div>
          </div>
        </tbody>
      </table>
    </div>
  )
}

export default About
// <div style={{ textAlign: 'center', marginTop: '50px' }}>
// <form action='http://www.tebww.com/'>
//   <button id='main-card-stakkid-button-2' type='sumbit'>
//     Org STAKKID
//   </button>
// </form>
// </div>
