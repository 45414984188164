import React, { Component } from 'react'

// import Card from 'react-bootstrap/Card'
import ScottBrinkerPhoto from '../assets/images/Screen Shot 2021-10-26 at 9.08.12 PM-removebg-preview-removebg-preview.jpg'
import DavidRaabPhoto from '../assets/images/Screen Shot 2021-10-26 at 9.07.41 PM-removebg-preview-removebg-preview (2).jpg'
import DarrellFranklinPhoto from '../assets/images/Screen Shot 2021-10-26 at 9.07.22 PM-removebg-preview-removebg-preview (1)-removebg-preview.jpg'
import Chiefmartec from '../assets/images/Screenshot 2023-09-26 at 2.46.16 AM.png'
import G2 from '../assets/images/g2-logo.svg'
import Clearbit from '../assets/images/Screenshot 2023-09-07 at 3.29.46 PM.png'
import CabinetM from '../assets/images/Screenshot 2023-09-07 at 3.21.50 PM.png'
import Cognizant from '../assets/images/Screenshot 2023-09-07 at 3.21.18 PM.png'
import MarTech from '../assets/images/martech22_logo.svg'
import Gartner from '../assets/images/Screenshot 2023-09-07 at 9.52.42 AM.png'
import Martechtribe from '../assets/images/639a27ee0f67b60083c57a92_martech_midnight_blue_svg (2).svg'
import Martech360 from '../assets/images/Screenshot 2023-09-13 at 1.36.34 PM.png'
import Martechsummit from '../assets/images/Screenshot 2023-09-13 at 10.11.49 AM.png'
import ActionIQ from '../assets/images/Screenshot 2023-09-07 at 9.54.02 AM.png'
import Markletic from '../assets/images/Screenshot 2023-09-07 at 9.53.22 AM.png'


import './StakkidCommunity.css'
import DiscourseForum from './discourse-forum'
import ThreadDisplay from './ThreadDisplay'
import Card from './InfluencerCard'
// import './InfluencerCard.css'
import TribeCard from './tribeCard'
import StakkidImage from '../assets/logos/1Asset 8.png'
import Box from './CommunityBox'

class StakkidCommunity2 extends Component {
  // openInNewTab = (url) => {
  //   const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  //   if (newWindow) newWindow.opener = null
  // }
  constructor(props) {
    super(props);
    this.state = {
      scrolled: false,
    };
  }

  componentDidMount() {
    // Add scroll event listener when the component mounts
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    // Remove scroll event listener when the component unmounts
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    // Check the scroll position to determine when to trigger animations
    const scrollY = window.scrollY;
    const windowHeight = window.innerHeight;

    // Define the threshold for triggering animations (adjust as needed)
    const threshold = 100;

    if (scrollY + windowHeight > threshold) {
      // Set the state to indicate that the user has scrolled
      this.setState({ scrolled: true });
    }
  };


  getBoxClassName(id) {
    // Define the mapping of id to CSS class names
    const classMap = {
      1: 'box-one',
      2: 'box-two',
      3: 'box-three',
      4: 'box-four',
      5: 'box-five',
      6: 'box-six',
      7: 'box-seven',
      8: 'box-eight',
      // Add more id-to-class mappings as needed
    };

    // Return the CSS class name based on the id
    return classMap[id] || ''; // Default to an empty string if the id doesn't match any class
  }
  

  render() {
    const { scrolled } = this.state;
    const boxData = [
      {
        id: 1,
        logo: Chiefmartec, // Replace with the actual logo image URL
        text: 'When you need to see the ever-evolving big picture in martech, Scott Brinker shares it with the world. We love the martech map.',
        learnMoreUrl: 'https://chiefmartec.com/category/marketing-data/', // Replace with the actual URL

      },
      {
        id: 2,
        logo: G2, // Replace with the actual logo image URL
        text: 'Ubiquitious software ratings on every software, function and industry including martech. Blends well with Stacklr ratings for martech software.',
        learnMoreUrl: 'https://company.g2.com/news?_gl=1*r7s6g1*_ga*MTUxOTUwNzg2MS4xNjkwOTIwMTY5*_ga_MFZ5NDXZ5F*MTY5NTE1MjM1NS43LjAuMTY5NTE1MjM1OS41Ni4wLjA.', // Replace with the actual URL

      },
      {
        id: 3,
        logo: Martechtribe, // Replace with the actual logo image URL
        text: ( <div>Frans Riemersmas new book <a href="https://www.amazon.com/Small-book-Customer-Technology-technology/dp/B0C2RPGXD4/ref=sr_1_1?keywords=a+small+book+on+customer+technology&qid=1687537717&sr=8-1">a small book on customer technology</a> is our favorite cheat-sheet reference guide.  Everyone in martech should have this.</div>),
        learnMoreUrl: 'https://www.martechtribe.com/blog', // Replace with the actual URL

      },
     
     {
        id: 4,
        logo: Gartner, // Replace with the actual logo image URL
        text: 'Need to know a software & industry positioning? The Magic Quadrant™ is a powerful combination with Stacklr ‘s 100+ Points Of Clarity.',
        learnMoreUrl: 'https://www.gartner.com/en/newsroom', // Replace with the actual URL

      },
      {
        id: 5,
        logo: Clearbit, // Replace with the actual logo image URL
        text: 'Clearbit allows companies to create, complete, and correct their data record. Clearbit can also expose buying intent of anonymous website visitors',
        learnMoreUrl: 'https://clearbit.com/blog/tag/marketing-growth', // Replace with the actual URL

      },
      {
        id: 6,
        logo: MarTech, // Replace with the actual logo image URL
        text: 'Single best way to get a state of the union from Scott Brinker and Frans Riemersma and hear use cases from sophisticated martech groups.',
        learnMoreUrl: 'https://martech.org/newsletters/', // Replace with the actual URL

      },
      {
        id: 7,
        logo: Markletic, // Replace with the actual logo image URL
        text: 'How-tos and marketing technology tutorials by people experienced with the tools. Start with Stacklrs individual assessment and scorecard.   ',
        learnMoreUrl: 'https://www.markletic.com/blog/', // Replace with the actual URL

      },
     {
        id: 8,
        logo: CabinetM, // Replace with the actual logo image URL
        text: 'Helping enterprise identify, document and track their enterprise technology martech technology and expense. Sets the stage for Stacklr team scorecard.',
        learnMoreUrl: 'https://www.cabinetm.com/press/index', // Replace with the actual URL

      },
      // {
      //   id: 9,
      //   logo: Cognizant, // Replace with the actual logo image URL
      //   text: 'Discover the most recent Martech Blog Posts. ',
      // },
      // {
      //   id: 10,
      //   logo: Martech360, // Replace with the actual logo image URL
      //   text: 'Great interviews and articles. We especially like their Martech Content',
      // }
    
     ];
    
    return (
      <>
        <div className='page-padding'>
        <div id='resources-partners-header-container'>
            <tr>
              <td>
                <h1>Martech's Stack</h1>{' '}
              </td>
            </tr>
          </div>
          {/* <h1 id='bold-fontee'>Stacklr Community</h1>  */}
          <div className='' id='stakkid-community-qa-container'>
            <h3 id='bold-fonte'>Martech Community Q&A</h3>
            <p id='profile-text'>
              Ask questions, search for answers, connect with the Stacklr
              community from around the world.
            </p>
<div className='main-card-stakkid-buttons'>   <button
              className='main-card-stakkid-button'
              onClick={() =>
                window.open(
                  'https://stacklr.discourse.group/invites/qnakm4HZ9U'
                )
              }
            >
              {' '}
              Join the discussion
            </button></div>
         

            <br />

            <br />

            
            {/* <TribeCard /> */}
            <div id='question-widget'></div>

            <br />
            <br />
            <br />
            <br />
          </div>

          <h3 id='bold-font'>Why we like these companies that support the Martech industry</h3>

          <div className="container-box">
      {boxData.map((box) => (
        <div key={box.id} id="box-one-box" className={`${
          this.getBoxClassName(box.id)
        } ${scrolled ? 'animate-box' : ''}`}
        >
          <Box logo={box.logo} text={box.text} learnMoreUrl={box.learnMoreUrl}  />
        </div>
      ))}
    </div>
    <h3
              id='bold-fonte1'
              style={{
                textAlign: 'center',
              }}
            >
              {' '}
              Influencers
            </h3>
          <div>
           
            <br />
            <Card />
          </div>
        </div>
      </>
    )
  }
}

export default StakkidCommunity2
