import React, { useState, useEffect, useMemo } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import { tools } from '../data/toolNamesAndCategories';
import {IonItem,IonSearchbar} from '@ionic/react';
import { UiSearchService } from '../services/ui-search.service';



let j;
for (j = 0; j < tools.length; j++) {
  tools[j].averageRating = 0;
}
const allTools = tools;

const SearchBar = ({
  skill = {
    id: '',
    toolName: '',
    role: '',
    score: 1,
  },
  category
}) => {
  const dispatch = useDispatch();

  const averageRatings = useSelector((store) => store.averageRatings);

  const addAvgRatingsToTools = () => {
    let j;
    for (j = 0; j < allTools.length; j++) {
      if (
        averageRatings &&
        averageRatings.hasOwnProperty(allTools[j].toolName)
      ) {
        allTools[j].averageRating = averageRatings[allTools[j].toolName];
      }
    }
    return allTools;
  };

  const allSkills = addAvgRatingsToTools();

  const filterTools = () => {
    return [allSkills].filter((tool) => {
      return tool.category === skill.category;
    });
  };


    const [toolName, setToolName] = useState(skill.toolName);
    const [results, setResults] = useState(filterTools());
    const [searchDisabled, setSearchDisabled] = useState(true);

    


    const search = (e) => {
        const val = e.target.value;
        setToolName(val);
        setSearchDisabled(false);
        const results = UiSearchService.search(tools, val, ['toolName']);
        setResults(results);
      };
  return (
    <IonItem  id="tool-list" lines="none">
    <IonSearchbar
      debounce={500}
      id="tool-name"
      placeholder="Select a Tool"
      value={toolName}
      onIonFocus={(e) => search(e)}
      onIonChange={(e) => search(e)}
    />
  </IonItem>
  )
}

export default SearchBar