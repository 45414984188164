import React, { useEffect, useState, useRef } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import NavBar from './components/Navbar';
import LandingPage from './components/LandingPage';
import Admin from './components/dashboards/admin';
import IndividualProfile from './components/IndividualProfile';
import TeamProfile from './components/TeamProfile';
import CompanyProfile from './components/CompanyProfile';
import Billing from './components/Billing';
import CompanyLicensePage from './components/CompanyLicensePage';
import TeamLicensePage from './components/TeamLicensePage';
import About from './components/About';
import Aboutus from './components/About-us';

import MyScoring from './components/MyScoring';
import Jobs from './components/Jobs';
import TermsOfService from './components/TermsOfUse';
import PrivacyPolicy from './components/PrivacyPolicy';
import StakkidCommunity from './components/StakkidCommunity-2';
import ResourcePartners from './components/ResourcePartners';
import Software from './components/software/Software';
import Login from './components/Login';
import InitialAssessment from './components/InitialAssessment';
import SecondaryAssessment from './components/SecondaryAssessment';
// import './App.css'
import Footer from './components/Footer';
import firebase from 'firebase';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import IndividualDashboard from './components/dashboards/individual-dashboard';
import IndividualDashboardShared from './components/dashboards/individual-dashboard-shared';
import CompanyDashboard from './components/dashboards/company-dashboard';
import TeamDashboard from './components/dashboards/team-dashboard';
import ToolsDashboard from './components/dashboards/tools-dashboard';
import SurveysDashboard from './components/dashboards/surveys-dashboard';
import RatingDashboard from './components/dashboards/rating-dashboard';
import IndustryDashboard from './components/dashboards/industry-dashboard';
import ToolEdit from './components/dashboards/toolEdit';
import ToolNew from './components/dashboards/toolNew';
import AddTool from './components/add-tool';
import LoginDialogBox from './components/LoginDialogBox';
import { IndividualHttpService } from './http_services/individual.http_service';
import fire from './components/firebase';
import CommunityLogin from './components/CommunityLogin';
import Reset from './components/Reset';
import AdInfo from './components/ad-info';
import { Info } from '@material-ui/icons';
import RateSkills from './components/rate-skills';
import UserRating from './components/UserRating';
import LoginBanner from './components/LoginBanner';
import SoftwareClients from './components/SoftwareClients';
import InMarket from './components/SoftwareClients2';

import Survey from './components/Survey';
import Tools from './components/Tools';
import Individuals from './components/Individuals';

const stripePromise = loadStripe(`${process.env.PUBLISHABLE_KEY}`);
global.apiBaseUrl =
  process.env.REACT_APP_API_MODE === 'production' ||
  process.env.NODE_ENV === 'production'
    ? 'https://stakkid-be11e46e059c.herokuapp.com'
    : 'http://localhost:3000';
//:'https://stakkid-personal.herokuapp.com'

function PrivateRoute({ component: Component, authenticated, ...rest }) {
  const [showLogin, setShowLogin] = useState(false);
  const [showCreateAccount, setShowCreateAccount] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [showAccountConfirmed, setShowAccountConfirmed] = useState(false);
  const handleShowLoginModal = () => {
    setShowLogin(!showLogin);
  };

  const handleShowCreateAccount = () => {
    setShowLogin(false);
    setShowCreateAccount(!showCreateAccount);
  };

  const handleShowAccountConfirmed = () => {
    setShowCreateAccount(false);
    setShowAccountConfirmed(!showAccountConfirmed);
  };

  const login = async (email, password) => {
    try {
      await fire.auth().signInWithEmailAndPassword(email, password);
      const user = await fire.auth().currentUser;
      await new IndividualHttpService().upsertByFirebaseUid({
        firebase_uid: user.uid,
        email: user.email,
      });
      if (user) {
        // window.location.href = '/initial-assessment'
      }
      // window.location.href = "/initial";
    } catch (error) {
      console.log(error);
      // this.setState({ fireErrors: error.message })
    }
    handleShowLoginModal();
  };
  setTimeout(() => {
    if (initialLoad === true && authenticated === false) {
      console.log(
        'setTimeout initial load and authenticated',
        initialLoad,
        authenticated
      );
      setInitialLoad(false);
      setShowLogin(true);
    }
  }, 12000);
  const signup = async (email, password) => {
    try {
      await fire.auth().createUserWithEmailAndPassword(email, password);
      const user = await fire.auth().currentUser;
      const response = await new IndividualHttpService().upsertByFirebaseUid({
        firebase_uid: user.uid,
        email: user.email,
      });
      if (user) {
        window.location.href = '/initial-assessment';
      }
      // window.location.href = "/initial";
    } catch (error) {
      console.log(error);
      this.setState({ fireErrors: error.message });
    }
    handleShowAccountConfirmed();
  };
  return (
    <Route
      {...rest}
      render={(props) => {
        return authenticated ? (
          <Component {...props} />
        ) : (
          <LoginDialogBox
            showLogin={showLogin}
            handleShowCreateAccount={handleShowCreateAccount}
            handleShowLoginModal={handleShowLoginModal}
            login={login}
            showCreateAccount={showCreateAccount}
            signup={signup}
            handleShowAccountConfirmed={handleShowAccountConfirmed}
            showAccountConfirmed={showAccountConfirmed}
          />
        );
      }}
    />
  );
}

function App() {
  const [user, setUser] = useState(fire.auth().currentUser);
  console.log('app load user', user);
  const [authState, setAuthState] = useState({
    authenticated: false,
    initializing: true,
  });
  console.log('app after setstate authenticated', authState.authenticated);
  const signOutBtn = document.querySelectorAll('#signed-out');
  const signInBtn = document.querySelectorAll('#signed-in');

  const signupUI = (user) => {
    if (user) {
      signInBtn.forEach((link) => (link.style.display = 'block'));
      signOutBtn.forEach((link) => (link.style.display = 'none'));
    } else {
      signInBtn.forEach((link) => (link.style.display = 'none'));
      signOutBtn.forEach((link) => (link.style.display = 'block'));
    }
  };

  useEffect(
    () =>
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          signInBtn.forEach((link) => (link.style.display = 'block'));
          signOutBtn.forEach((link) => (link.style.display = 'none'));
          setUser(user);

          setAuthState({
            authenticated: true,
            initializing: false,
          });
        } else {
          signInBtn.forEach((link) => (link.style.display = 'none'));
          signOutBtn.forEach((link) => (link.style.display = 'block'));

          setAuthState({
            authenticated: false,
            initializing: false,
          });
        }
      }),
    [authState.authenticated]
  );
  return (
    <Router>
      <div>
        <NavBar userData={user} />
        <Elements stripe={stripePromise}>
          <Switch>
            <Route exact path="/reset" component={Reset} />
            <Route exact path="/" component={LandingPage} />
            <PrivateRoute
              exact
              path="/admin"
              component={Admin}
              authenticated={authState.authenticated}
            />
            <PrivateRoute
              exact
              path="/initial"
              component={InitialAssessment}
              authenticated={authState.authenticated}
            />
            <PrivateRoute
              exact
              path="/initial/secondary-assessment"
              component={SecondaryAssessment}
              authenticated={authState.authenticated}
            />
            <PrivateRoute
              exact
              path="/top-skills"
              component={RateSkills}
              authenticated={authState.authenticated}
            ></PrivateRoute>
            <Route exact path="/login" component={Login} />
            <PrivateRoute
              exact
              path="/profile"
              component={() => <IndividualProfile userData={user} />}
              authenticated={authState.authenticated}
            />
            <PrivateRoute
              exact
              path="/team-profile"
              component={TeamProfile}
              authenticated={authState.authenticated}
            />
            <PrivateRoute
              exact
              path="/company-profile"
              component={CompanyProfile}
              authenticated={authState.authenticated}
            />
            <PrivateRoute
              exact
              path="/billing"
              component={Billing}
              user={user}
              authenticated={authState.authenticated}
            />
            <PrivateRoute
              exact
              path="/company-license"
              component={() => <CompanyLicensePage user={user} />}
              authenticated={authState.authenticated}
            />
            <PrivateRoute
              exact
              path="/team-license"
              component={() => <TeamLicensePage user={user} />}
              authenticated={authState.authenticated}
            />
            <Route exact path="/my-score" component={MyScoring} />
            <Route exact path="/about" component={About} />
            <Route exact path="/about-us" component={Aboutus} />

            <Route exact path="/termsofservice" component={TermsOfService} />
            <Route exact path="/privacy-policy" component={PrivacyPolicy} />
            <Route
              exact
              path="/stakkid-community"
              component={StakkidCommunity}
            />
            <Route exact path="/software-clients" component={SoftwareClients} />

            <Route exact path="/in-market" component={InMarket} />

            <Route exact path="/jobs" component={Jobs} />
            <Route
              exact
              path="/resource-partners"
              component={ResourcePartners}
            />
            <Route exact path="/software/:id" component={Software} />

            <Route exact path="/survey/:id" component={Survey} />

            <PrivateRoute
              exact
              path="/individual-dashboard"
              component={IndividualDashboard}
              authenticated={authState.authenticated}
            />

            <Route exact path="/individual-dashboard/:profileId">
              <IndividualDashboardShared />
            </Route>

            <PrivateRoute
              exact
              path="/ad-info"
              component={AdInfo}
              authenticated={authState.authenticated}
            />

            <PrivateRoute
              exact
              path="/company-dashboard"
              component={CompanyDashboard}
              authenticated={authState.authenticated}
            />
            <PrivateRoute
              exact
              path="/team-dashboard"
              component={TeamDashboard}
              authenticated={authState.authenticated}
            />
            <PrivateRoute
              exact
              path="/tools-dashboard"
              component={ToolsDashboard}
              authenticated={authState.authenticated}
            />
            <PrivateRoute
              exact
              path="/surveys-dashboard"
              component={SurveysDashboard}
              authenticated={authState.authenticated}
            />
            <PrivateRoute
              exact
              path="/tools-dashboard/new"
              component={ToolNew}
              authenticated={authState.authenticated}
            />
            <PrivateRoute
              exact
              path="/tools-dashboard/edit/:id"
              component={ToolEdit}
              authenticated={authState.authenticated}
            />
            <PrivateRoute
              exact
              path="/rating-dashboard"
              component={RatingDashboard}
              authenticated={authState.authenticated}
            />
            <PrivateRoute
              exact
              path="/industry-dashboard"
              component={IndustryDashboard}
              authenticated={authState.authenticated}
            />
            <PrivateRoute
              exact
              path="/modal"
              component={AddTool}
              authenticated={authState.authenticated}
            />
            <Route
              exact
              path="/login-banner"
              component={LoginBanner}
              // authenticated={authState.authenticated}
            />

            {/* <Route
              exact
              path='/modal'
            >
              <AddTool />
            </Route>

<Route
              exact
              path='/user-rating'
            >
              <UserRating />
            </Route> */}

            <Route
              exact
              path="/user-rating"
              component={UserRating}
              // authenticated={authState.authenticated}
            />
            <PrivateRoute
              exact
              path="/community-login"
              component={CommunityLogin}
              authenticated={authState.authenticated}
            />

            <Route exact path="/tools" component={Tools} />
            <PrivateRoute
              exact
              path="/individuals"
              component={Individuals}
              authenticated={authState.authenticated}
            />
            <Redirect from="*" to="/" />
          </Switch>
        </Elements>

        <Footer />
      </div>
    </Router>
  );
}
export default App;
