import React, { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useHistory } from 'react-router';
import { auth, sendPasswordResetEmail } from './firebase';
import stakkidLogo from '../assets/logos/stacklr-final-logo.png';
import { navigate } from '@reach/router';
import './Reset.css';

function Reset() {
  const [email, setEmail] = useState('');
  const [user, loading] = useAuthState(auth);
  const history = useHistory();

  useEffect(() => {
    if (loading) return;
    if (user) history.replace('/initial');
  }, [user, loading]);

  return (
    <div className='reset-container'>
      <img
        src={stakkidLogo}
        id='stakkid-nav-logo-2'
        onClick={() => navigate('/')}
      />
      <input
        type='text'
        className='reset-textBox'
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder='E-mail Address'
      />
      <button
        className='reset-btn'
        onClick={() => sendPasswordResetEmail(email)}
      >
        Send password reset email
      </button>
    </div>
  );
}

export default Reset;
