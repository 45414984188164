import React from 'react'

import ToolSelector from './ToolSelector'

import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'
import { times } from 'lodash'
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';



function StrategyCard({
  strategy,
  category,
  skills,
  currentScore = 0,
  currentCategoriesList = null,
  type = 'initial',
}) {

  let skillsLeftToAdd = 1
  let analysisLength = 0
  let attractionLength = 0
  let engagementLength = 0

  const [count, setCount] = React.useState(9);

  if (currentCategoriesList) {
    // analysisLength = currentCategoriesList['Analysis & Optimization'].length
    // attractionLength = currentCategoriesList['Attraction'].length
    // engagementLength = currentCategoriesList['Engagement'].length
  }

  let maxCategoryItemLength = parseInt(
    getMaxNumber(attractionLength, engagementLength, analysisLength) + 1
  )
  if (type === 'initial') {
    maxCategoryItemLength < count && (maxCategoryItemLength = count);
  }
  if (type === 'initial') {
    skillsLeftToAdd = maxCategoryItemLength - skills.length
  } else {
    skillsLeftToAdd = 1
  }
  let emptySkillsArray = times(skillsLeftToAdd, () => undefined)

  if (type !== 'initial') {
    currentScore > 54 && (emptySkillsArray = times(0, () => undefined))
  }

  return (
    <Paper elevation={3} style={{ padding: '40px' }} className="ass-card">
      <h2 className="text-center mb-5">{category}</h2>
      <Grid container alignContent="center" alignItems="center">
        <Grid style={{ textAlign: 'center' }} item>
          {/* <Typography
            style={{ padding: '20px', fontSize: '20px', fontWeight: 600 }}
            variant='h4'
            component='h4'
          >
            {strategy}
          </Typography> */}

          <List style={{ flex: 'auto' }}>
            {skills.map((skill, index) => (
              <ListItem
                key={`listItem-${index}`}
                style={{ justifyContent: 'center', marginBottom: '20px' }}
              >
                
                <ToolSelector
                  key={`selector-${index}`}
                  skill={skill}
                  strategy={strategy}
                  category={category}
                />
              </ListItem>
            ))}

            {emptySkillsArray.map((item, i) => (
              <ListItem
                key={i}
                style={{ justifyContent: 'center', marginBottom: '20px' }}
              >
                <ToolSelector
                  skill={undefined}
                  strategy={strategy}
                  category={category}
                />
              </ListItem>
            ))}
            <ListItem
              key={emptySkillsArray.length + 1}
              style={{ justifyContent: 'center', marginBottom: '20px' }}
            >
              <Fab
                style={{ minWidth: '230px' }}
                variant="extended"
                size="large"
                id='add-more'
                aria-label="add"
                onClick={(e) => {
                  setCount(count + 1);
                }}
              >
                Add more
                <AddIcon />
              </Fab>
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </Paper>
  );
}

const getMaxNumber = (x, y, z) => {
  let max_val = 0
  if (x > y) {
    max_val = x
  } else {
    max_val = y
  }
  if (z > max_val) {
    max_val = z
  }
  return max_val
}

export default StrategyCard
