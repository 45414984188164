import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FlashMessage from 'react-flash-message'
import Software from '../software/Software'
import Modal from './softwaremodal'
import IconButton from '@mui/material/IconButton';

// import Modal from '@material-ui/core/Modal';



const useFetch = (url) => {
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {//Get Request
    (async () => {
      try {
        const response = await fetch(url, {
          method: 'GET',
          redirect: 'follow', // manual, *follow, error
          headers: { 'Content-Type': 'application/json' },
          mode: 'cors',
        }).then((resp) => resp.json())
          .then((data) => {
            data[0] && setData(data[0]);
          }).catch(error => {
            console.log('error', error)
          });
      } catch (eventError) {
        setError(eventError);
      }
      setLoading(false);
    })();
  }, [url]);

  return [error, data, loading];
}


const ToolEdit = (props) => {
  const tool = props.location.state;
  // const id = tool.id;
  const { id } = useParams();
  const [modalVisible, setModalVisible] = useState(false);

  // const [productData, setProductData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [removeIndex, setRemoveIndex] = useState(null);
  const [activation, setActivation] = useState(false); // Add toolActivated state
  const [ratingModalOpen, setRatingModalOpen] = useState(false);
  const [showModalOpen, setShowModalOpen] = useState(false);
  const [activeModal, setActiveModal] = useState(false); // Add toolActivated state

  const [message, setMessage] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const [messageState, setMessageState] = useState('success');
  const [errors, setErrors] = useState({});
  const fetchtoolUrl = global.apiBaseUrl + '/tools/new?action=fetch_tool&id=' + id;
  const [error, data, loading] = useFetch(fetchtoolUrl);
  const [formData, setFormData] = useState({
    short_des: '',
    long_des: '',
    url: '',
    tool_name: '',
    contact_url: '',

  });
  const [videoFormData, setVideoFormData] = useState(
    [
      {
        url: 'https://www.youtube.com/',
        description: 'Learn how to effectively use this software with comprehensive training videos.'
      },
      {
        url: 'https://www.youtube.com/',
        description: 'Training videos provide you with the skills necessary to master the tools in your Martech stack.'
      },
      {
        url: 'https://www.youtube.com/',
        description: 'Improve your skills on your own schedule with on-demand training videos.'
      }
    ]
  )
  const [productData, setProductData] = useState(
    [
      {
        name: 'plivo',
        url: ''
      },
      {
        name: 'bandwidth',
        url: ''
      }
    ]
  )

  useEffect(() => {
    data && setFormData(data)
    if (data && data.videos && data.videos.length > 0) {
      setVideoFormData(data.videos)
    }

    if (data && data.products && data.products.length > 0) {
      setProductData(data.products)
    }

    if (data) {
      setActiveModal(data.active_modal)
    }






    // Check if tool is activated
    if (data && data.companyInfo && data.companyInfo.tool_activated) {
      setActivation(true);
    } else {
      setActivation(false);
    }
  }, [data])


  const handleRatingModalOpen = () => {
    setRatingModalOpen(true);
    setShowModalOpen(false);
  };

  const handleShowModalOpen = () => {
    setShowModalOpen(true);
    setRatingModalOpen(false);
  };

  const handleModalClose = () => {
    setRatingModalOpen(false);
    setShowModalOpen(false);
  };


  const handleModalToggle = () => {
    // setModalVisible(!modalVisible);
    setShowModalOpen(!showModalOpen);

  };

  const handleActiveChange = (e) => {
    setActiveModal(e.target.checked);
  };




  const handleChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleVideoChange = (index, event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const nextShapes = videoFormData.map((data, i) => {
      if (i !== index) {
        return data;
      } else {
        if (target.type == 'textarea') {
          return {
            ...data,
            description: value,
          };
        }
        return {
          ...data,
          url: value,
        };
      }
    });
    // Re-render with the new array
    setVideoFormData(nextShapes);
  };

  const handleProductChange = (index, event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    const nextShapes = productData.map((data, i) => {
      if (i !== index) {
        return data;
      } else {
        if (name == 'product[name]') {
          return {
            ...data,
            name: value,
          };
        } else {
          return {
            ...data,
            url: value,
          };
        }
      }
    });
    // Re-render with the new array
    setProductData(nextShapes);

  };

  const addFormFields = () => {
    setProductData([...productData, { name: "", url: "" }])
  }

  const removeFormFields = (i) => {
    let newProductData = [...productData];
    newProductData.splice(i, 1);
    setProductData(newProductData)
  }



  const toggleModal = () => {
    setModalVisible(!modalVisible);
  };


  const confirmRemove = () => {
    const updatedProductData = [...productData];
    updatedProductData.splice(removeIndex, 1);
    setProductData(updatedProductData);
    setShowModal(false);
    setRemoveIndex(null);
  };

  const closeModal = () => {
    setShowModal(false);
    setRemoveIndex(null);
  };



  const validate = (formData) => {
    const errors = {};
    if (!formData.short_des) {
      errors.short_des = "Please enter short description";
    }
    // validation for other fields
    // if (!formData.long_des) {
    //   errors.long_des = "Please enter long description";
    // }
    // if (!formData.url) {
    //   errors.url = "Please enter url";
    // }
    // if (!formData.contact_url) {
    //   errors.contact_url = "Please enter contact url";
    // }
    return errors;
  }

  const handleSubmit = (event) => {
    setShowMessage(false)
    event.preventDefault();
    const e = validate(formData);
    setErrors(e)
    if (Object.entries(e).length === 0) {
      submitForm(formData)
    } else {
      setShowMessage(true)
    }
  };

  async function submitForm(formData) {
    setShowMessage(false)
    const toolUrl = global.apiBaseUrl + '/tools/new?action=update_tools';
    const response = await fetch(toolUrl, {
      method: 'POST',
      redirect: 'follow', // manual, *follow, error
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors',
      body: JSON.stringify(
        {
          id,
          short_des: formData.short_des,
          long_des: formData.long_des,
          url: formData.url,
          contact_url: formData.contact_url,
          activation: formData.activation,
          videoData: videoFormData,
          productData: productData,
          active_modal: activeModal,
        }),

    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log('success', data)
        if (data.status == 500) {
          setMessageState('error')
          setMessage('Something went wrong')
        } else {
          setMessage('Tool updated successfully')
          setMessageState('success')
        }
        setShowMessage(true)
        return data
      }).catch(error => {
        const submitMessage = {
          'message': error.message ? error.message : 'Something went wrong'
        }
        setErrors(submitMessage)
        console.log('error', error)
      });
  }
  if (loading) {
    return <div>Loading...</div>;
  }
  return (
    <div className='container-md'>

      <h2 className='mt-3'>Edit tool</h2>

      {showMessage && <FlashMessage duration={5000}><strong className={`flash-message ${messageState}-message`}>{message}</strong></FlashMessage>
      }

      {Object.entries(errors).length !== 0 && <FlashMessage duration={5000}><strong className='flash-message error-message'>{JSON.stringify(errors)}</strong></FlashMessage>
      }

      <div className='ml-auto mt-5 mb-5 d-flex'>
        <Link to={{ pathname: `/tools-dashboard/` }}>
          <ArrowBackIcon />
          <span className='ml-3'></span>Tool dashboard
        </Link>
        <div className='ml-auto'>
          <Link to={{ pathname: `/software/${id}` }} className='ml-5' target="_blank">View</Link>
        </div>
      </div>
      <div className='mt-5'>
        <form onSubmit={handleSubmit}>

          <div className='row'>

            <div className='col-12 mt-3'>
              <label htmlFor="activeModal">
                Active Modal:
                <input
                  type="checkbox"
                  checked={activeModal}
                  className='form-control'
                  name="active_modal"
                  onChange={handleActiveChange}
                />
              </label>
              <div>
                {/* Button to toggle modal visibility */}
                {/* <button onClick={handleModalToggle}>Toggle Modal</button> */}

                {/* Render the modal component based on modalVisible state */}
                {modalVisible && <Modal handleModalToggle={handleModalToggle} />}
              </div>

              {/* <label htmlFor="activation">Activation</label>
        <input
          value={activation}
          onChange={handleChange}
          className='form-control'
          type="text"
          name="activation"
          id="activation"
        /> */}
            </div>


            <div className='col-12'>
              <label htmlFor="shortDes">Tool Name</label>
              <input
                value={formData.tool_name ? formData.tool_name : ''}
                onChange={handleChange}
                placeholder={'name'}
                className='form-control'
                type="text"
                name="name"
                id="name"
                readOnly="readonly"

              />
            </div>

            <div className='col-12 mt-3'>
              <label htmlFor="shortDes">Tool short des*</label>
              <input
                value={formData.short_des ? formData.short_des : ''}
                onChange={handleChange}
                placeholder={'short des'}
                className='form-control'
                type="text"
                name="short_des"
                id="shortDes"
              />
            </div>

          </div>

          <div className='row'>
            <div className='col-12 mt-3'>
              <label htmlFor="url">Tool Url</label>
              <input
                value={formData.url ? formData.url : ''}
                onChange={handleChange}
                placeholder={'url'}
                className='form-control'
                type="text"
                name="url"
                id="url"

              />
            </div>
          </div>

          <div className='row'>
            <div className='col-12 mt-3'>
              <label htmlFor="url">Tool Contact Url</label>
              <input
                value={formData.contact_url ? formData.contact_url : ''}
                onChange={handleChange}
                placeholder={'url'}
                className='form-control'
                type="text"
                name="contact_url"
                id="contact_url"

              />
            </div>
          </div>

          <div className="form-group mt-3">
            <label htmlFor="long_Des">Tool Long Description</label>
            <textarea className="form-control" name="long_des" id="longDes" rows="3" onChange={handleChange} value={formData.long_des ? formData.long_des : ''}></textarea>
          </div>

          <fieldset>
            <legend>Videos</legend>
            {[0, 1, 2].map((element, index) => (
              <div className='row' key={index}>
                <div className='col-6 mt-3'>
                  <label htmlFor="url">Video{index} Url</label>
                  <input
                    value={videoFormData[index] ? videoFormData[index].url : ''}
                    onChange={e => handleVideoChange(index, e)}
                    placeholder={'url'}
                    className='form-control'
                    type="text"
                    name="tool[url]"

                  />
                </div>
                <div className='col-6 mt-3'>
                  <label htmlFor="long_Des">Video{index} Description</label>
                  <textarea className="form-control" name="tool[description]" rows="3" onChange={e => handleVideoChange(index, e)} value={videoFormData[index] ? videoFormData[index].description : ''}></textarea>
                </div>
              </div>
            ))}
          </fieldset>

          <fieldset>
            <legend>Products</legend>
            <div className='w-100 d-flex'>
              <button className="button add btn software-stakkid-button " type="button" onClick={() => addFormFields()}>Add a new product</button>
            </div>
            {productData.map((element, index) => (
              <div className='row' key={index}>
                <div className='col-6 mt-3'>
                  <label htmlFor="url">Prouct{index} name</label>
                  <input
                    value={productData[index] ? productData[index].name : ''}
                    onChange={e => handleProductChange(index, e)}
                    placeholder={'name'}
                    className='form-control'
                    type="text"
                    name="product[name]"

                  />
                </div>
                <div className='col-5 mt-3'>
                  <label htmlFor="url">Product{index} Url</label>
                  <input
                    value={productData[index] ? productData[index].url : ''}
                    onChange={e => handleProductChange(index, e)}
                    placeholder={'url'}
                    className='form-control'
                    type="text"
                    name="product[url]"

                  />
                </div>
                <div className='col-1 mt-5'>
                  {
                    index ?
                      <button type="button" className="btn software-stakkid-button remove-btn" onClick={() => removeFormFields(index)}>Remove</button>
                      : null
                  }
                </div>
              </div>
            ))}
          </fieldset>




          <fieldset>


            {/* Modal */}
            {showModal && (
              <div className="modal">
                <div className="modal-content">
                  <h3>Confirmation</h3>
                  <p>Are you sure you want to remove this product?</p>
                  <div className="modal-actions">
                    <button type="button" onClick={confirmRemove}>Yes</button>
                    <button type="button" onClick={closeModal}>No</button>
                  </div>
                </div>
              </div>
            )}
          </fieldset>

          <div className='row mt-3'>
            <button type="submit" className='btn software-stakkid-button mb-5'>Submit</button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default ToolEdit