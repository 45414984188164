import { CrudHttpService } from './crud.http_service'

export class SkillHttpService extends CrudHttpService {
  constructor() {
    super('skills')
  }

  async averages(payload) {
    let results = {}
    fetch(global.apiBaseUrl + '/skills/new?action=averages', {
      method: 'POST',
      redirect: 'follow', // manual, *follow, error

      headers: { 'Content-Type': 'application/json' },
      mode: 'cors',
      body: JSON.stringify(payload),
    })
      .then((resp) => resp.json())
      .then((data) => (results = data))
    return results
  }

  async toolNames(payload) {
    return await this.newPost('tool_names', payload)
  }

  async totalScore(payload) {
    return await this.newPost('total_score', payload)
  }
}
