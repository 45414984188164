import React from 'react'

// import './SoftwareClients2.css'
import './About-us.css'
import inmarket from '../assets/images/adobehandshack.png'
import inmarket2 from '../assets/images/image 17.jpg'
import jodiimage from '../assets/images/Jodi Passport Picture 2.png'

import numberof from '../assets/images/numberof.png'
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ellipse from '../assets/images/Ellipse 978.png'
import CircleStack from './CircleStack';



const Aboutus = () => {

    return (
        <div className='abtPage1'>
             {/* <div className='darkscreen'>
                    
                    </div> */}
            <div className='abtheader-one'>
           
                <img
                    className='abtheader-img'
                    src={inmarket2}

                />
                
                {/* <h2 className='header-text'>How Can Stacklr Help
                    You Capture New Clients
                    In-Market?</h2> */}
            </div>
            <div className='body-one1'>

                <div className='body-para'>
                    
                    <h2 className='para-title-body1'>Who we are</h2>

                    <ul className='para-group-body1'>
                        <li className='para-one-body1'> 
                        Stacklr is a digital Martech platform for Insights and In-market solutions beginning with the User. 
                       </li>
                       <li className='para-one-body1'>
                       Individual Martech User needs often are missed in strategic Martech alignment. Stacklr answers the needs of millions of Martech users. Professionals complete a comprehensive assessment of their skills with a patent pending User Assessment and rate Martech tools. The results are displayed in a visual summary of their Martech stack experience in a dashboard (called a Stacklr), available to post, drop into certificates on LinkedIn, and attach to career connections.
                       </li>
                    </ul>
                </div>
          
                <div className="circle-container">
        <CircleStack />
      </div>

                {/* <div className='para-body-img'>
                    <img
                        className='body-img2'
                        src={numberof}

                    />
                </div> */}
            </div>

            <div className='body-two1'>
                <div className='body-para-one1'>
                    <h2 className='body-h2-one'>Who we serve</h2>
                    <p className='body-p-one1'>Stacklr empowers Software Companies, Individual Users, and Enterprise Clients with unparalleled insights to leverage the full potential of the ever-evolving Martech landscape. Individual users complete a full Martech tool and skill assessment to obtain their Stacklr score and unlock a free Dashboard that can be shared on LinkedIn. </p>
                    </div>

                    <div className='body-para-two1'>
                    {/* <h2 className='body-h2-two'>Are your leads for buyer, influencers and decision maker who are in-market?</h2> */}
                    <p className='body-p-two'>Each Software Company can activate a software highlights page  to connect with In-Market Users and Decision-Makers for targeted lead generation. With access to Stacklr’s rating analytics, your insights are richer than ever.  Combine this with other sources of rating data, like G2, for a 360 view of users and potential buyers. </p>
                </div>

                <div className='rectangle-button-o1'><p className='rectangle-button-two1'>Enterprise clients optimize their existing stack and talent with Stacklr’s marketing enterprise assessment.</p></div>
            </div>

            <div className='rectangle-one1'>
                <div className='rectangle-title1'>Advisory Board</div>
                <div className='rectangle-para1'>The Stacklr Advisory Board consists of industry leaders who bring decades of experience to the table and provide expert guidance.</div>
               
                <div className='rectangle-title2'>Contact Info</div>
                <div className='rectangle-para2'>Email: contactstacklr@stacklr.com</div>
                <div className='rectangle-para3'>Phone: 602-820-7879</div>

            </div>

            <div className='rectangle-two1'>
                <div className='rectangle-title4'>Leadership</div>

                <img
                        className='body-img1'
                        src={jodiimage}

                    />

                <div className='rectangle-title5'>Jodi Schneider</div>
                <div className='rectangle-para4'>Founder of Stacklr, The Experts Bench</div>
                <div className='rectangle-para5'>Founder and CEO of Oskcar, CPRi</div>

                <div className='rectangle-para6'>“Martech User insight is <br></br>critical to Martech optimization. <br></br>Stacklr is the missing link”</div>
               
                

            </div>


        </div>








    )
}


export default Aboutus