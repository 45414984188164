import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import StrategyCard from "./StrategyCard";
import Grid from "@material-ui/core/Grid";
import Modal from "@material-ui/core/Modal";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import StepIcon from "@material-ui/core/StepIcon";
import ClearIcon from "@material-ui/icons/Clear";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { loadScoreAction, loadSkillsAction } from "../actions";
import { CATEGORY_OBJECTS } from "../constants";

const useStylesStepIcon = makeStyles(
  {
    root: {
      color: "rgba(0, 139, 192, 0.2)",
      "&$completed": {
        color: "#e84521",
      },
    },
    completed: {},
  },
  { name: "MuiStepIcon" }
);

const useStylesInitialAssessment = makeStyles({
  root: {
    color: "white",
    backgroundColor: "rgba(0, 139, 192, 1.0)",
    "&:hover": {
      backgroundColor: "rgba(0, 139, 192, 1.0)",
    },
  },
  modal: {
    margin: "auto",
    width: "55%",
  },
  unorderedList: {
    padding: "40px",
    "& li": {
      padding: "20px",
    },
  },
});

function GreenStepIcon(props) {
  const classes = useStylesStepIcon();
  return <StepIcon {...props} classes={{ root: classes.root }} />;
}

function SecondaryAssessment() {
  const classes = useStylesInitialAssessment();
  const currentScore = useSelector((store) => store.currentScore);
  const sortedSkills = useSelector((store) => store.sortedSkills);

  const dispatch = useDispatch();

  const [shouldShowModal, setShouldShowModal] = useState(true);
  const [currentCategoryObject, setCurrentCategoryObject] = useState(
    CATEGORY_OBJECTS[1]
  );
  const [expandedCategories, setExpandedCategories] = useState(null);
  const [allExpanded, setAllExpanded] = useState(null);
  const [categoriesList, setCategoriesList] = useState(CATEGORY_OBJECTS);

  useEffect(() => {
    dispatch(loadSkillsAction);
    dispatch(loadScoreAction);
  }, []);

  let justifyContent = "space-between";
  if (currentCategoryObject.step == 2) {
    justifyContent = "flex-end";
  }

  const backButtonOnClick = (e) => {
    setCurrentCategoryObject(CATEGORY_OBJECTS[currentCategoryObject.step - 2]);
  };
  const nextButtonOnClick = (e) => {
    setCurrentCategoryObject(CATEGORY_OBJECTS[currentCategoryObject.step]);
  };
  const doneButtonOnClick = (e) => {
    window.location.href = "/individual-dashboard";
  };

  return (
    <>
      <ion-list>
        <ion-item lines="none">
          <ion-title style={{ textAlign: "center", fontSize: "18px" }}>
            Your STAKKID Score
          </ion-title>
        </ion-item>
        <ion-item lines="full">
          <ion-title style={{ textAlign: "center", fontSize: "40px" }}>
            {currentScore}
          </ion-title>
        </ion-item>
      </ion-list>
      <div className="page-padding secondary-assesment-bx secondary-assesment-cs">
        <div
          className="d-flex align-items-center"
          style={{ paddingRight: "16px" }}
        >
          <span
            className="ml-auto"
            onClick={() => setAllExpanded(!allExpanded)}
          >
            {allExpanded ? "Close All" : "Open All"}{" "}
            {allExpanded ? (
              <ExpandLessIcon style={{ fontSize: "40px" }} />
            ) : (
                <ExpandMoreIcon style={{ fontSize: "40px" }} />
              )}
          </span>
        </div>

        {categoriesList.map(
          (categoryObject, i) =>
            categoryObject.step > 1 && (
              <Accordion
                key={i}
                className={i === categoriesList.length - 1 ? "last-child" : ""}
                expanded={
                  allExpanded !== null
                    ? allExpanded === true
                      ? true
                      : false
                    : categoryObject.categoryName === expandedCategories
                      ? true
                      : false
                }
                onChange={(e, expanded) => {
                  setAllExpanded(null);
                  expanded
                    ? setExpandedCategories(categoryObject.categoryName)
                    : setExpandedCategories(null);
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon style={{ fontSize: "40px" }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  style={{ paddingLeft: 0 }}
                >
                  <h2>{categoryObject.categoryTitle}</h2>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography component="div">
                    <Grid
                      container
                      justify="center"
                      spacing={2}
                      style={{ marginTop: "40px" }}
                    >
                      {sortedSkills[categoryObject.categoryName] &&
                        Object.keys(
                          sortedSkills[categoryObject.categoryName]
                        ).map((strategy, index) => (
                          <Grid key={`grid-${index}`} xd={4} item>
                            <StrategyCard
                              key={`card-${index}`}
                              strategy={strategy}
                              category={categoryObject.categoryName}
                              skills={
                                sortedSkills[categoryObject.categoryName][
                                strategy
                                ]
                              }
                              currentCategoriesList={
                                sortedSkills[categoryObject.categoryName]
                              }
                              currentScore={currentScore}
                              type="secondary"
                            />
                          </Grid>
                        ))}
                    </Grid>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            )
        )}

        <div style={{ marginTop: "40px", display: "flex", justifyContent }}>
          <Button classes={{ root: classes.root }} onClick={doneButtonOnClick}>
            Done
          </Button>
        </div>

        <Modal
          style={{ top: "25%" }}
          className={`custom-modal ${classes.modal}`}
          size="lg"
          open={shouldShowModal}
        >
          <Paper style={{ padding: "40px" }}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                onClick={(e) => {
                  setShouldShowModal(false);
                }}
              >
                <ClearIcon />
              </Button>
            </div>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <Typography
                style={{ fontWeight: "500", fontSize: "2.95rem" }}
                variant="h2"
                component="h2"
              >
                Improve Your STAKKID Score
              </Typography>
            </div>

            <ul className={classes.unorderedList}>
              <li>
                <Typography variant="h5" component="h5">
                  Add skills to any MarTech category and strategy up to 54 tools
                  overall.
                </Typography>
              </li>
              <li>
                <Typography variant="h5" component="h5">
                  Improve in a certain skill? Update your score on that Skill.
                </Typography>
              </li>
            </ul>
          </Paper>
        </Modal>
      </div>
    </>
  );
}

export default SecondaryAssessment;
