import React from "react";

const CommunityLogin = () => {
  return (
    <div>
      <h1>Community Login Page Success</h1>
    </div>
  );
};

export default CommunityLogin;
