import React from 'react';

const CommunityBox = ({ logo, text, learnMoreUrl }) => {
  return (
    <div className="box">
      <div className="content">
        <img src={logo} alt="Logo" className="logo" />
        <p>{text}</p>
      </div>
      <div>
      <a href={learnMoreUrl} target="_blank" rel="noopener noreferrer" className="learn-more">
        Learn More
      </a>      </div>
    </div>
  );
};

export default CommunityBox;