import React from 'react';
import './CircleStack.css'; // Create a CSS file for styling

const CircleStack = () => {
    const data = [
      {
        label: "40k+",
        value: "Number of Ratings"
      },
      {
        label: "8k+",
        value: "Number of Tools"
      },
      {
        label: "8",
        value: "Average Tools per User"
      },
      {
        label: "3.6m",
        value: "Number of Variables"
      },
      {
        label: "100+",
        value: "Points of Insight"
      },
      {
        label: "12k+",
        value: "Number of Super Users"
      }
    ]; // Data to display
  
    return (
      <div className="circle-stack">
        {data.map((item, index) => (
          <div key={index} className="circle">
            <div className="circle-content">
              <div className="circle-label">{item.label}</div>
              <div className="circle-value">{item.value}</div>
            </div>
          </div>
        ))}
      </div>
    );
  };

export default CircleStack;
