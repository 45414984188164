import React, { PureComponent } from 'react'
import { PieChart, Pie, Cell, Label } from 'recharts'
import './piechart.css'
import { PlatformService } from '../../../services/platform.service'
import stakkidLogo1 from '../../../assets/logos/logo_color.svg'
import { black } from 'material-ui/styles/colors'
import { IonCard } from '@ionic/react'

const RADIAN = Math.PI / 180
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5
  const x = cx + radius * Math.cos(-midAngle * RADIAN)
  const y = cy + radius * Math.sin(-midAngle * RADIAN)

  return (
    <text
      x={x}
      y={y}
      fill='white'
      textAnchor='middle'
      dominantBaseline='central'
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  )
}

export default class RadialChart extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      data: this.formatRadialData(),
    }
  }

  formatRadialData() {
    const categories = this.props.data
    const data = [
      {
        name: 'Ad-Promo',
        value: parseFloat(
          this.makeSkillScoreOneIfZero(categories['Ad & Promo'])
        ),
        color: 'red',
      },
      {
        name: 'Data',
        value: parseFloat(this.makeSkillScoreOneIfZero(categories.Data)),
        color: '#4EC251',
      },
      {
        name: 'Management',
        value: parseFloat(this.makeSkillScoreOneIfZero(categories.Management)),
        color: '#2A428C',
      },
      {
        name: 'Exp-Content',
        value: parseFloat(
          this.makeSkillScoreOneIfZero(categories['Content & Experience'])
        ),
        color: '#ECBF2F',
      },
      {
        name: 'Social',
        value: parseFloat(
          this.makeSkillScoreOneIfZero(categories['Social & Relationship'])
        ),
        color: '#75AADB',
      },
      {
        name: 'Commerce-Sales',
        value: parseFloat(
          this.makeSkillScoreOneIfZero(categories['Sales & Commerce'])
        ),
        color: '#6d4d95',
      },
    ]
    return data
  }

  makeSkillScoreOneIfZero(skill) {
    if ([0, undefined].includes(skill)) {
      return 0.8
    }
    return skill
  }

  renderCells() {
    return this.state.data.map((entry, index) => {
      return [<Cell key={`cell-${index}`} fill={entry.color}></Cell>]
    })
  }

  setDimensions() {
    if (PlatformService.mobile()) {
      return 200
    }
    return 400
  }

  renderMobile() {
    return [
      <PieChart
        width={this.setDimensions()}
        height={this.setDimensions()}
        style={{
          overflow: 'visible',
          marginBottom: '125px',
        }}
      >
        <Pie
          isAnimationActive={true}
          data={this.state.data}
          dataKey='value'
          cx={200}
          cy={200}
          innerRadius={60}
          outerRadius={120}
          fill='#82ca9d'
          label={(entry) => entry.name}
        >
          {/*<Label value='STACKLR' position='center' />*/}
          {this.renderCells()}
        </Pie>
      </PieChart>,
    ]
  }

  render() {
    if (PlatformService.mobile()) {
      return this.renderMobile()
    }
    return (
      <PieChart
        width={this.setDimensions()}
        height={this.setDimensions()}
        style={{ overflow: 'visible' }}
      >
        <Pie
          isAnimationActive={true}
          data={this.state.data}
          dataKey='value'
          cx={240}
          cy={160}
          innerRadius={50}
          outerRadius={100}
          fill='#82ca9d'
          label={(entry) => entry.name}
        >
          {/*<Label value='STACKLR' position='center' />*/}
          {this.renderCells()}
        </Pie>
      </PieChart>
    )
  }
}
