import {getPlatforms} from '@ionic/core';
export class PlatformService {
  static current() {
    if (PlatformService.mobile()) {
      return 'mobile';
    } else {
      return 'desktop';
    }
  }

  static mobile() {
    return PlatformService.list().includes('mobile');
  }

   static tablet() {
    return PlatformService.list().includes('tablet');
  }

   static desktop() {
    return PlatformService.list().includes('desktop');
  }

  static list() {
    return getPlatforms(window);
  }


}
