import React, { useState } from 'react'
import Badge from '@material-ui/core/Badge'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import { makeStyles } from '@material-ui/core/styles'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import { useSelector, useDispatch } from 'react-redux'

const useStyles = makeStyles({
  root: {
    minWidth: '230px',
  },
  badge: {
    padding: '10px',
    backgroundColor: '#e84521',
    height: '35px',
    width: '35px',
    borderRadius: '50%',
    fontSize: '16px',
  },
  modal: {
    margin: 'auto',
    width: '50%',
  },
})

function ToolUnselected({ category, strategy }) {
  const classes = useStyles()
  let [shouldShowModalForUnselectedTool, setShouldShowModalForUnselectedTool] =
    useState(false)
  const currentScore = useSelector((store) => store.currentScore)
  const dispatch = useDispatch();

  const defaultTool = {
    id: '',
    toolName: '',
    role: '',
    score: 1,
  };

  return (
    <>
      <Fab
                  id="add-tool"

        classes={{ root: classes.root }}
        variant='extended'
        size='large'
        aria-label='add'
        onClick={(e) => {
          dispatch({
            type: 'SET_TOOL',
            value: 'add',
            tool: defaultTool,
          });
          setShouldShowModalForUnselectedTool(true)
        }}
      >
        Add Tool
        <AddIcon />
      </Fab>
    </>
  )
}

function ToolSelected({ skill, category, strategy }) {
  const classes = useStyles()
  let [shouldShowModalForToolSelected, setShouldShowModalForToolSelected] =
    useState(false)
  const dispatch = useDispatch();

  return (
    <>
      <Badge
        classes={{ badge: classes.badge }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        badgeContent={`${skill.role ? skill.role.toUpperCase() : ''}${skill.score}`}
        color="primary"
      >
        <Fab
          classes={{ root: classes.root }}
          variant='extended'
          size='large'
          aria-label='add'
          onClick={(e) => {
            dispatch({
              type: 'SET_TOOL',
              value: 'update',
              tool: skill
            })
          }}
        >
          {skill.toolName}
          <MoreHorizIcon />
        </Fab>
      </Badge>
    </>
  )
}

function ToolSelector({ category, strategy, skill }) {
  return (
    <>
      {skill ? (
        <ToolSelected skill={skill} />
      ) : (
        <ToolUnselected category={category} strategy={strategy} />
      )}
    </>
  )
}
export default ToolSelector
