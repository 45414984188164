import React from 'react'
import './Term.css'

const MODAL_STYLES = {
  position: 'fixed',
  top: '30%',
  left: '50%',
  width: '685px',
  height: '600px',
  transform: 'translate(-50%, -50%)',
  backgroundColor: '#FFF',
  padding: '50px',
  zIndex: 1000,
  overflow: 'auto',
}

const OVERLAY_STYLES = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, .7)',
  zIndex: 1000,
}

export default function Terms({ open, children, onClose }) {
  if (!open) return null

  return (
    <>
      <div style={OVERLAY_STYLES} />
      <div style={MODAL_STYLES}>
        {children}
        <button className='terms-button' onClick={onClose}>
          Done
        </button>
      </div>
    </>
  )
}

// const Terms = () => {
//   const [agree, setAgree] = useState(false)

//   const checkboxHandler = () => {
//     // if agree === true, it will be set to false
//     // if agree === false, it will be set to true
//     setAgree(!agree)
//     // Don't miss the exclamation mark
//   }

//   // When the button is clicked
//   const btnHandler = () => {
//     alert('The button is clickable!')
//   }

//   return (
//     <div className='App'>
//       <div className='container'>
//         <div>
//           <input type='checkbox' id='agree' onChange={checkboxHandler} />
//           <label htmlFor='agree' className='form-check-label'>
//             I have read and agree to the{' '}
//             <Link to='/termsofservice'>Terms & Agreement</Link>
//           </label>
//         </div>

//         {/* Don't miss the exclamation mark* */}
//         <button disabled={!agree} className='btn' onClick={btnHandler}>
//           Continue
//         </button>

//         <button
//           type='button'
//           disabled={!agree}
//           style={{
//             margin: '0 auto',
//             marginTop: '30px',
//             marginBottom: '25px',
//           }}
//           className='col-md-3'
//           id='main-stakkid-button'
//           onClick={() => signup(email, password)}
//         >
//           Submit
//         </button>

//         onClick={
//           disabled ? { btnHandler } : (event) => handleShowLoginModal(event)

//       </div>
//     </div>

//     // sign up
//     <Modal id='create-account-modal' show={this.state.showCreateAccount}>
//     <Modal.Body className='modal-body row d-flex justify-content-between'>
//       <div className='col-md-12 mb-2 d-flex modalTitle'>
//         <h4 className='text-left mb-0'>Create Account</h4>
//         <Modal.Header
//           className='ml-auto'
//           closeButton
//           onClick={() => this.handleShowCreateAccount()}
//           // onClick={() => this.handleShowLoginModal()}
//         ></Modal.Header>
//       </div>
//       {/* <div className="col-md-12 mb-4 SignGoogle">
//   <button id="linkedin-login-button">
//     <i className="fab fa-linkedin"></i>Sign in with LinkedIn
//   </button>
// </div> */}
//       <div className='col-md-12'>
//         <div className='InputDiv'>
//           <label>
//             Username(email)<sup>*</sup>
//           </label>
//           <div className='InputIcon'>
//             <input
//               id='profile-input'
//               name='email'
//               type='text'
//               placeholder='Email'
//               style={{ margin: '7px' }}
//               value={this.state.email}
//               onChange={(event) => this.handleChange(event)}
//             />
//           </div>
//         </div>
//       </div>
//       <div className='col-md-12'>
//         <div className='InputDiv'>
//           <label>
//             Password<sup>*</sup>
//           </label>
//           <div className='InputIcon'>
//             <input
//               id='profile-input'
//               name='password'
//               type='password'
//               placeholder='Password'
//               style={{ margin: '7px' }}
//               value={this.state.password}
//               onChange={(event) => this.handleChange(event)}
//             />
//           </div>
//         </div>
//       </div>

//       <div className='col-md-12'>
//         <div class='BtnWrap'>
//           <button
//             type='button'
//             style={{
//               margin: '0 auto',
//               marginTop: '30px',
//               marginBottom: '25px',
//             }}
//             id='main-stakkid-button'
//             onClick={(event) => this.signup(event)}
//           >
//             Submit
//           </button>
//         </div>
//       </div>

//       <p className='col-md-12'>
//         Do you have an account?{' '}
//         <Link to='' onClick={() => this.handleShowLoginModal()}>
//           Sign in
//         </Link>
//       </p>
//     </Modal.Body>
//   </Modal>

//   )
// }

// export default Terms
