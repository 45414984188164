import React from 'react'
import FeedOne from '../assets/images/Screen Shot 2022-05-09 at 2.53.06 PM.png'
import FeedTwo from '../assets/images/Screen Shot 2022-05-09 at 2.54.08 PM.png'
import FeedThree from '../assets/images/Screen Shot 2022-05-09 at 2.54.29 PM.png'
import FeedFour from '../assets/images/Screen Shot 2022-05-09 at 2.54.54 PM.png'
import FeedFive from '../assets/images/posteight.png'
import FeedSix from '../assets/images/postsix.png'
import FeedSeven from '../assets/images/postseven.png'
import '../App.css'

function tribeCard() {
  return (
    <div>
      <div className='upper-containerr'>
        <br />
        <div className='image-containerr'>
          <img
            className='image'
            src={FeedOne}
            alt=''
            width='680px'
            // onClick={() =>
            //   window.open(
            //     'https://stakkid.tribeplatform.com/questions-answers-kp63n6v7/post/do-you-have-all-software-on-the-ostakkid-platform-or-just-martech-rBqw0WYO33YMJYJ',
            //     '_blank'
            //   )
            // }
          />
        </div>
        <br />
        <div className='image-containerr'>
          <img
            className='image'
            src={FeedTwo}
            alt=''
            width='680px'
            // onClick={() =>
            //   window.open(
            //     'https://stakkid.tribeplatform.com/questions-answers-kp63n6v7/post/is-cdp-on-stakkid-S0ad5Qt46lcr0d4',
            //     '_blank'
            //   )
            // }
          />
        </div>
        <br />
        <div className='image-containerr'>
          <img
            className='image'
            src={FeedThree}
            alt=''
            width='680px'
            // onClick={() =>
            //   window.open(
            //     'https://stakkid.tribeplatform.com/questions-answers-kp63n6v7/post/how-can-i-add-our-new-software-release-to-the-platform-R2NqEWNgDysH0XA',
            //     '_blank'
            //   )
            // }
          />
        </div>
      </div>
    </div>
  )
}

export default tribeCard
