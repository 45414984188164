import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import VideoCard from './components/VideoCard';
import RateCard from './components/RateCard';
import SurveyCard from './components/SurveyCard';

import RefCompany from './components/RefCompany';
import Rating from '@mui/material/Rating';

import Software4 from '../../assets/images/software4.png';
import Ad1 from '../../assets/images/Slide1.jpg';
import Video1 from '../../assets/images/video-1.png';
import Video2 from '../../assets/images/video-2.png';
import Video3 from '../../assets/images/video-3.png';
import './Software.css';
import Modal from '@material-ui/core/Modal';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Select from 'react-select';
import FlashMessage from 'react-flash-message';

const Software = (props, { handleModalToggle }) => {
  const storedIndex = localStorage.getItem('currentIndex');
  const [currentIndex, setCurrentIndex] = useState(storedIndex || null);
  const { id } = props.match.params;
  const rateModal = props.match.params.id === currentIndex;
  const [initialLoad, setInitialLoad] = useState(true);

  const [ratingModal, setRatingModal] = useState(false);
  const [showModal, setShowModal] = useState(true);
  const [industryModal, setIndustryModal] = useState(false);
  const [videoLists, setVideoLists] = useState([
    {
      id: 1,
      image: Video1,
      url: 'https://www.youtube.com/',
      description:
        'Learn how to effectively use this software with comprehensive training videos.',
    },
    {
      id: 2,
      image: Video2,
      url: 'https://www.youtube.com/',
      description:
        'Training videos provide you with the skills necessary to master the tools in your Martech stack.',
    },
    {
      id: 3,
      image: Video3,
      url: 'https://www.youtube.com/',
      description:
        'Improve your skills on your own schedule with on-demand training videos.',
    },
  ]);

  const [productLists, setProductLists] = useState([
    {
      id: 1,
      name: Video1,
      url: 'https://www.youtube.com/',
    },
    {
      id: 2,
      name: Video1,
      url: 'https://www.youtube.com/',
    },
  ]);

  const [companyInfo, setCompanyInfo] = useState({
    id: 1,
    tool_name: '',
    short_des: '',
    long_des: '',
    url: '',
    super_users: 0,
    users: 0,
    dominant_role: '',
    dominant_strategy: '',
    dominant_category: '',
  });

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const [rating, setRating] = useState(0);
  const [industry, setIndustry] = useState('');
  const [fullName, setFullName] = useState('');
  const [industries, setIndustries] = useState([]);
  const [message, setMessage] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const [messageState, setMessageState] = useState('success');
  const [toolActivated, setToolActivated] = useState(false); // Add toolActivated state
  const [activeModal, setActiveModal] = useState(false);

  const fetchtoolUrl =
    global.apiBaseUrl + '/tools/new?action=fetch_tool&id=' + id;
  useEffect(() => {
    //Get Request
    (async () => {
      try {
        const response = await fetch(fetchtoolUrl, {
          method: 'GET',
          redirect: 'follow', // manual, *follow, error
          headers: { 'Content-Type': 'application/json' },
          mode: 'cors',
        })
          .then((resp) => resp.json())
          .then((data) => {
            data[0].logo = Software4;
            data[0] && setCompanyInfo(data[0]);
            data[0] && setActiveModal(data[0].active_modal);
            setRatingModal(!data[0].active_modal);
            if (data[0].videos && data[0].videos.length > 0) {
              setVideoLists(data[0].videos);
            }
            if (data[0].products && data[0].products.length > 0) {
              setProductLists(data[0].products);
            }
          })
          .catch((error) => {
            console.log('error', error);
          });
      } catch (eventError) {
        setError(eventError);
      }
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    // Update currentIndex when the component mounts or when props.match.params.id changes
    setCurrentIndex(props.match.params.id);
    localStorage.setItem('currentIndex', props.match.params.id);
  }, [props.match.params.id]);

  // const onButtonClose = () => {
  //   setButtonModal(!buttonModal);
  // }

  const handleModalOpen = () => {
    setShowModal(true);
  };

  useEffect(() => {
    if (initialLoad) {
      setInitialLoad(false);
    }
  }, [initialLoad]);

  const handleModalClose = () => {
    setCurrentIndex(null);
    localStorage.removeItem('currentIndex');
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setRatingModal(true);
    // handleModalToggle(); // Toggle modal visibility using the callback function
  };

  const onClose = () => {
    setRatingModal(!ratingModal);
  };

  const goNext = () => {
    console.log('goNext');
    // setRatingModal(!ratingModal);
    setIndustryModal(!industryModal);
    const industryToolUrl = global.apiBaseUrl + '/industries';
    //Get Request
    (async () => {
      try {
        const response = await fetch(industryToolUrl, {
          method: 'GET',
          redirect: 'follow', // manual, *follow, error
          headers: { 'Content-Type': 'application/json' },
          mode: 'cors',
        })
          .then((resp) => resp.json())
          .then((data) => {
            console.log('response', data);
            data.length > 0 && setIndustries(data);
          })
          .catch((error) => {
            console.log('error', error);
          });
      } catch (eventError) {
        setError(eventError);
      }
      setLoading(false);
    })();
  };

  const customStyles = {
    option: (provided) => ({
      ...provided,
      color: 'black',
      padding: 5,
    }),
  };

  const handleChange = (e) => {
    setIndustry(e);
  };

  const handleFullNameChange = (e) => {
    setFullName(e.target.value);
  };

  const submitRating = () => {
    console.log('submitRating', rating, industry, fullName);
    if (!industry || !fullName) {
      setShowMessage(true);
      setMessageState('error');
      setMessage('Please fill all fields!');
      setTimeout(() => {
        setShowMessage(false);
      }, 3000);
    } else {
      const ratingUrl = global.apiBaseUrl + '/ratings/new?action=update_rating';
      (async () => {
        const response = await fetch(ratingUrl, {
          method: 'POST',
          redirect: 'follow', // manual, *follow, error
          headers: { 'Content-Type': 'application/json' },
          mode: 'cors',
          body: JSON.stringify({
            tool_id: id,
            score: rating,
            industry_id: industry.id,
            full_name: fullName,
          }),
        })
          .then((resp) => resp.json())
          .then((data) => {
            console.log('success', data);
            setShowMessage(true);
            setMessageState('success');
            setMessage('Thank you for your rating!');
            setTimeout(() => {
              onClose();
            }, 3000);
            return data;
          })
          .catch((error) => {
            console.log('error', error);
          });
      })();
    }
  };

  return (
    <div className="software-container">
      {/* {!initialLoad && currentIndex && (
                    <div className="text-center">
                    <h1>{companyInfo.toolActivated}</h1>

                                          <h1>{companyInfo.tool_name} has not activated their content yet</h1>   
                                          <button
                  className="software-stakkid-button-rate"
                  // onClick={() => goNext()}
                  onClick={() =>
                    window.open("https://stacklr.zoholandingpage.com/Activation/")}
                >
                  {' '}
                  Request Activation 
                </button>
                <IconButton
          aria-label="Close"
          // className="MuiButtonBase-root MuiIconButton-root Component-closeButton-14"
          onClick={handleModalClose}
          style ={{ fontSize: '1px', color: 'white'}}
        >
          off
        </IconButton>
                </div>
                )} */}

      <div className="row">
        <div className="col-md-12 pl-md-0 col-lg-9">
          <div className="row">
            <div className="col-lg-6 col-xl-7 flex-column">
              <div className="company-header d-flex align-items-center">
                {/* <div className="company-logo">
                  <img
                    src={companyInfo.logo}
                    width="62"
                    alt={companyInfo.tool_name}
                  />
                </div> */}

                <div className="company-title">
                  <a href={companyInfo.url} target="_blank">
                    <h1>{companyInfo.tool_name}</h1>
                  </a>
                </div>
              </div>
              <h5 className="mb-3 mt-2 title_color company-short-des">
                {companyInfo.short_des}
              </h5>
              <p className="company-des pr-5">{companyInfo.long_des}</p>

              <div className="company-info mt-4 row">
                <div className="col-md-12">
                  <h6
                    className={`font-weight-bold title_color ${companyInfo.tool_name}`}
                  >
                    Have a question for {companyInfo.tool_name}?
                    <button
                      className={`software-stakkid-button ml-2 py-2 ${companyInfo.tool_name}`}
                      onClick={() =>
                        window.open(companyInfo.contact_url, '_blank')
                      }
                      // onClick={handleModalOpen}
                    >
                      {' '}
                      Contact
                    </button>
                  </h6>
                  <p className="mt-3 font-weight-bold">
                    Products like {companyInfo.tool_name}:
                  </p>
                  <div
                    className={`product-button d-flex align-items-center ${companyInfo.tool_name}`}
                  >
                    {productLists.map((product, index) => (
                      <a
                        href={product.url}
                        // className="video-link"
                        // className={`product-img mr-2${companyInfo.tool_name}`}
                        target="_blank"
                      >
                        <div
                          className={`product-img mr-2 ${companyInfo.tool_name}`}
                        >
                          {/* <img src={Software2} alt="" /> */}
                          <span>{product.name}</span>
                        </div>
                      </a>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6 mb-4 col-xl-5">
              <RefCompany info={companyInfo} />
              <div className="mt-3">
                <SurveyCard companyId={id} />
              </div>
            </div>
          </div>
          <div
            className={`row ad-placeholder-2  ml-md-0 ${companyInfo.tool_name}`}
          >
            Advertising/Promotion
          </div>
          <div
            className={`row ad-placeholder  d-flex justify-content-center align-items-center mb-4 ml-md-0 ${companyInfo.tool_name}`}
          >
            <img
              className={`stacklr-ad ${companyInfo.tool_name}`}
              src={Ad1}
              onClick={() =>
                window.open(
                  'https://tebww.zoholandingpage.com/speakyourcandidateslanguage/'
                )
              }
            />
          </div>
        </div>

        <div className={`col-md-12 pl-4 col-lg-3 ${companyInfo.tool_name}`}>
          {videoLists.map((video, index) => (
            <VideoCard
              className={`col-md-12 pl-4 col-lg-3 ${companyInfo.tool_name}`}
              key={index}
              card={video}
              index={index}
            />
          ))}
        </div>
      </div>

      <Modal
        style={{ top: '20%' }}
        className={`custom-modal software-modal`}
        size="lg"
        open={ratingModal}
      >
        <Box
          sx={{ width: '330px' }}
          style={{
            background: '#fff',
            margin: '0px auto',
            padding: '10px 20px',
            borderRadius: '20px',
          }}
        >
          <IconButton
            aria-label="Close"
            className="MuiButtonBase-root MuiIconButton-root Component-closeButton-14"
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
          {!industryModal ? (
            <>
              <p id="rate-description">
                Click the stars below to rate
                <br />
                {companyInfo.tool_name}
              </p>
              <div className="text-center">
                <Rating
                  style={{ textAlign: 'center' }}
                  value={rating}
                  size="large"
                  // onChangeActive={(event, newHover) => {event.preventDefault()}}
                  onChange={(event, newValue) => {
                    setRating(newValue);
                  }}
                />
              </div>
              <div className="text-center">
                <button
                  className="software-stakkid-button-rate"
                  onClick={() => goNext()}
                >
                  {' '}
                  Next
                </button>
              </div>
            </>
          ) : (
            <>
              {showMessage && (
                <FlashMessage duration={3000}>
                  <strong
                    className={`flash-message text-center ${messageState}-message`}
                  >
                    {message}
                  </strong>
                </FlashMessage>
              )}
              <div className="form-block">
                <label>FullName</label>
                <input
                  value={fullName}
                  onChange={(e) => handleFullNameChange(e)}
                  placeholder={'Full Name'}
                  className="form-control"
                  type="text"
                  name="fullname"
                />
              </div>

              <div className="form-block mt-3">
                <label>Industry</label>
                <Select
                  placeholder="Choose industry"
                  onChange={handleChange}
                  getOptionLabel={(option) => {
                    return option.name;
                  }}
                  getOptionValue={(option) => {
                    return option.id;
                  }}
                  options={industries}
                  styles={customStyles}
                />
              </div>

              <div className="text-center">
                <button
                  className="software-stakkid-button-rate"
                  onClick={() => submitRating()}
                >
                  {' '}
                  Done
                </button>
              </div>
            </>
          )}
        </Box>
      </Modal>

      {!initialLoad && currentIndex && (
        <Modal
          style={{ top: '20.5%' }}
          className={`custom-modal software-modal`}
          size="xl"
          open={activeModal && showModal}
        >
          <Box
            sx={{ width: '350px' }}
            style={{
              background: '#fff',
              margin: '0px auto',
              padding: '10px 20px',
              borderRadius: '20px',
            }}
          >
            <IconButton
              aria-label="Close"
              className="MuiButtonBase-root MuiIconButton-root Component-closeButton-14"
              // onClick={onClose}
              // onClick={handleCloseModal} // Call handleCloseModal when the close button is clicked

              onClick={() => {
                (window.location.href = '/')
                return false
              }}
            >
              <CloseIcon />
            </IconButton>

            <>
              <p id="rate-description" style={{ textAlign: 'center' }}>
                {companyInfo.tool_name} has not activated their content yet
              </p>

              <div className="text-center">
                <button
                  className="software-stakkid-button-rate"
                  // onClick={() => goNext()}
                  onClick={() =>
                    window.open(
                      'https://stacklr.zoholandingpage.com/Activation/'
                    )
                  }
                >
                  {' '}
                  Request Activation
                </button>
                <IconButton
                  aria-label="Close"
                  // className="MuiButtonBase-root MuiIconButton-root Component-closeButton-14"
                  onClick={handleModalClose}
                  style={{ fontSize: '1px', color: 'white' }}
                >
                  off
                </IconButton>
              </div>
            </>
          </Box>
        </Modal>
      )}
    </div>
  );
};

export default Software;
