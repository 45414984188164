import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import { signInWithGoogle } from './firebase'
import GoogleButton from 'react-google-button'
import Terms from './Terms'
import TermsOfUse from './TermsOfUse'
import { initializeApp } from 'firebase-admin'
import LinkedInLogin from './LinkedinLogin'
import FlashMessage from 'react-flash-message';
import Select from 'react-select';



import '../App.css'


// import './Term.css'



const LoginDialogBox2 = ({
  handleShowCreateAccount,
  handleShowLoginModal,
  showLogin,
  login,
  showCreateAccount = false,
  signup,

  // handleShowAccountConfirmed,
  // showAccountConfirmed = false,
}) => {
  console.log('show login in lignDialogBox', showLogin)
  console.log('login in lgoin dialog box', login)
  return (
    <div>

      <CreateNewAccount
        handleShowCreateAccount={handleShowCreateAccount}
        showCreateAccount={showCreateAccount}
        handleShowLoginModal={handleShowLoginModal}
        signup={signup}
      />
      {/* <AccountCreatedConfirmation
        handleShowAccountConfirmed={handleShowAccountConfirmed}
        showAccountConfirmed={showAccountConfirmed}
      /> */}
    </div>
  )
}

const CreateNewAccount = ({
  showCreateAccount,
  handleShowCreateAccount,
  handleShowLoginModal,
  signup,
}) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [agree, setAgree] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  const checkboxHandler = () => {
    // if agree === true, it will be set to false
    // if agree === false, it will be set to true

    setAgree(!agree)
    // Don't miss the exclamation mark
  }

  // When the button is clicked
  const btnHandler = () => {
    alert(
      'Please indicate that you have read and agree to the Terms and Agreement'
    )
  }


  const [rating, setRating] = useState(5);
  const [ratingModal, setRatingModal] = useState(true);


  const [industry, setIndustry] = useState('');
  const [fullName, setFullName] = useState('');
  const [industries, setIndustries] = useState([]);
  const [message, setMessage] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const [messageState, setMessageState] = useState('success');
  const [industryModal, setIndustryModal] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showFullNameInput, setShowFullNameInput] = useState(false);
  const [showCreateAccountInputs, setShowCreateAccountInputs] = useState(false);


  const handleChange = (e) => {
    setIndustry(e);
  }

  const onClose = () => {
    setRatingModal(!ratingModal);
  }

  const handleFullNameChange = (e) => {
    setFullName(e.target.value);
  }

  const handleClickNameOnly = () => {
    setShowFullNameInput(true);
    setShowCreateAccountInputs(false);
  };

  const handleClickCreateAccount = () => {
    setShowFullNameInput(false);
    setShowCreateAccountInputs(true);
  };




  const goNext = () => {
    console.log('goNext');
    // setRatingModal(!ratingModal);
    setIndustryModal(!industryModal);
    const industryToolUrl = global.apiBaseUrl + '/industries';
    //Get Request
    (async () => {
      try {
        const response = await fetch(industryToolUrl, {
          method: 'GET',
          redirect: 'follow', // manual, *follow, error
          headers: { 'Content-Type': 'application/json' },
          mode: 'cors',
        })
          .then((resp) => resp.json())
          .then((data) => {
            console.log('response', data)
            data.length > 0 && setIndustries(data);
          })
          .catch((error) => {
            console.log('error', error);
          });
      } catch (eventError) {
        setError(eventError);
      }
      setLoading(false);
    })();
  }

  const submitRating = (props) => {
    // const { id } = props.match.params;

    console.log('submitRating', rating, industry, fullName)
    if(!industry || !fullName) {
      setShowMessage(true);
      setMessageState('error');
      setMessage('Please fill all fields!');
      setTimeout(() => {
        setShowMessage(false);
      }, 3000);
    } else {
      const ratingUrl =
        global.apiBaseUrl + '/ratings/new?action=update_rating';
      (async () => {
        const response = await fetch(ratingUrl, {
          method: 'POST',
          redirect: 'follow', // manual, *follow, error
          headers: { 'Content-Type': 'application/json' },
          mode: 'cors',
          body: JSON.stringify({
            // tool_id: id,
            // score: rating,
            // industry_id: industry.id,
            full_name: fullName,
          }),
        })
          .then((resp) => resp.json())
          .then((data) => {
            console.log('success', data);
            setShowMessage(true);
            setMessageState('success');
            setMessage('Thank you for your rating!');
            setTimeout(() => {
              onClose();
            }, 3000);
            return data;
          })
          .catch((error) => {
            console.log('error', error);
          });
      })();
    }
  }


  return (
    <Modal id='create-account-modal-2' show={showCreateAccount}>
      <div className="titleCloseBtn2">
        <button
          onClick={() => handleShowCreateAccount()}

        >
          X
        </button>
      </div>

      <Modal.Body className='modal-body row d-flex justify-content-between'>

<h4 style={{ display: 'flex', justifyContent: 'center', paddingLeft: '25%', }}>
        <b className='col-md-12' style={{
          display: 'flex', flexDirection: 'column', marginTop: '10px'

        }}>
          
          Have an account?{' '}
          <div className='col-md-12'>
            <div class='BtnWrappp mt-3'>
              <button
                // disabled={!agree}
                style={{
                  margin: '0 auto',
                  marginTop: '10px',
                  marginBottom: '30px',
                }}
                // className='col-md-3'
                id='main-stakkid-button-log'
                onClick={() => {
                  (window.location.href = '/individual-dashboard')
                  return false
                }}
              >
                Click Here            </button>
            </div>
          </div>
        </b>
        </h4>
        <div className='col-md-12 mb-2 d-flex modalTitle'>
          <h4 className='text-right mb-0' style={{ display: 'flex', justifyContent: 'center', paddingLeft: '10px', }}>

            <b className='signi'>If not, we would like to get to know you
            </b>
          </h4>




        </div>

        <div className="modalContainer2">

          <div className="footer2"
          >
            {/* <button
              onClick={handleClickNameOnly}
              // onClick={() => {
              //   setOpenModal(false);
              // }}
              id="cancelBtn2"
            >
              Name Only
            </button> */}
            <button type='button'
              onClick={handleClickCreateAccount} id="cancelBtn2"
            >Create Account
            </button>
          </div>
        </div>


        {/* <div className='social-buttonn'>
          <div className='social-button1'>
            <LinkedInLogin />
          </div>
          <div className='social-button2'>
            <GoogleButton type='dark' onClick={signInWithGoogle}></GoogleButton>
          </div>
        </div> */}

        <div className='col-md-12 mb-4 SignGoogle'></div>

        <div className='col-md-12'>
          {showFullNameInput &&
            <div className='InputDiv'>

              <label>
                Full Name<sup>*</sup>
              </label>
              {/* <div className='InputIcon'> */}
              <input
                value={fullName}
                label="Full Name"
                onChange={(e) => handleFullNameChange(e)}
                placeholder={'Full Name'}
                className="form-control"
                type="text"
                name="fullname"
              />
              {/* </div> */}

              {/* <div className="form-block mt-3">
                <label>Industry</label>
                <Select
                  placeholder="Choose industry"
                  onChange={handleChange}
                  getOptionLabel={(option) => {
                    return option.name;
                  }}
                  getOptionValue={(option) => {
                    return option.id;
                  }}
                  options={industries}
                  styles={customStyles}
                />
              </div> */}
              <div className='col-md-12'>
                <div class='BtnWrappp mt-3'>
                  <button
                    // disabled={!agree}
                    style={{
                      margin: '0 auto',
                      marginTop: '10px',
                      marginBottom: '25px',
                    }}
                    // className='col-md-3'
                    id='main-stakkid-button-log'
                    onClick={() => submitRating()
                      (window.location.href = '/')}
                  >
                    {' '}

                    Submit
                  </button>
                </div>
              </div>
            </div>}
        </div>


        {/* <div className='col-md-12'>
          <div className='InputDiv'>
            <label>
              Industry<sup>*</sup>
            </label>
            <div className='InputIcon'>
              <Select
                placeholder="Choose industry"
                onChange={handleChange}
                getOptionLabel={(option) => {
                  return option.name;
                }}
                getOptionValue={(option) => {
                  return option.id;
                }}
                options={industries}
                styles={customStyles}
              />
            </div>
          </div>
        </div> */}




        <div className='col-md-12 mb-4 SignGoogle'></div>
        <div className='col-md-6'>
          {showCreateAccountInputs && (
            <div className='InputDiv'>
              <label>
                First Name<sup>*</sup>
              </label>
              {/* <div className='InputIcon'> */}
              <input
                id='profile-input'
                name='first_name'
                type='text'
                placeholder='First Name'
                style={{ margin: '7px' }}
                value={firstName}
                onChange={(event) => setFirstName(event.target.value)}
              />
              {/* </div> */}
            </div>)}
        </div>
        <div className='col-md-6'>
          {showCreateAccountInputs && (

            <div className='InputDiv'>
              <label>
                Last Name<sup>*</sup>
              </label>
              {/* <div className='InputIcon'> */}
              <input
                id='profile-input'
                name='last_name'
                type='text'
                placeholder='Last Name'
                style={{ margin: '7px' }}
                value={lastName}
                onChange={(event) => setLastName(event.target.value)}
              />
              {/* </div> */}
            </div>)}
        </div>
        <div className='col-md-12'>
          {showCreateAccountInputs && (

            <div className='InputDiv'>
              <label>
                Username (email)<sup>*</sup>
              </label>
              {/* <div className='InputIcon'> */}
              <input
                id='profile-input'
                name='email'
                type='email'
                placeholder='Email'
                style={{ margin: '7px' }}
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
              {/* </div> */}
            </div>)}
        </div>

        <div className='col-md-12'>
          {showCreateAccountInputs && (

            <div className='InputDiv'>
              <label>
                Password<sup>*</sup>
              </label>
              {/* <div className='InputIcon'> */}
              <input
                id='profile-input'
                name='password'
                type='password'
                placeholder='Password'
                style={{ margin: '7px' }}
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              />
              {/* </div> */}
            </div>)}
        </div>

        {showCreateAccountInputs && (

          <div className='terms-message'>



            <input type='checkbox' id='agree' onChange={checkboxHandler} />

            <label for='agree' className='form-check-label'>
              I have read and agree to the{' '}
              <Link onClick={() => setIsOpen(true)}>Terms & Agreement</Link>
            </label>
            <Terms open={isOpen} onClose={() => setIsOpen(false)}>
              <TermsOfUse></TermsOfUse>
            </Terms>
          </div>)}

        {/* Don't miss the exclamation mark* */}

        <div className='col-md-12'>

          {showCreateAccountInputs && (

            <div class='BtnWrappp mt-3'>
              <button
                disabled={!agree}
                style={{
                  margin: '0 auto',
                  marginTop: '10px',
                  marginBottom: '25px',
                }}
                // className='col-md-3'
                id='main-stakkid-button-log'
                onClick={() => signup(firstName, lastName, email, password)}
              >
                Submit
              </button>
            </div>)}
        </div>


      </Modal.Body>
    </Modal>
  )
}

const customStyles = {
  option: (provided) => ({
    ...provided,
    color: 'black',
    padding: 5,
  }),
};






const AccountCreatedConfirmation = ({
  handleShowAccountConfirmed,
  showAccountConfirmed,
}) => {
  return (
    <Modal
      id='account-confirmed-modal'
      centered='true'
      show={showAccountConfirmed}
    >
      <Modal.Header
        closeButton
        onClick={() => handleShowAccountConfirmed()}
      ></Modal.Header>
      <Modal.Body className='modal-body row d-flex justify-content-center'>
        <h4 style={{ textAlign: 'center' }} className='col-md-12'>
          Account Created
        </h4>
        <p>You will be redirected to your scoring modal shortly.</p>
      </Modal.Body>
    </Modal>
  )
}
// <p>Check your email to confirm your account.</p>

export default LoginDialogBox2