import React, { Component } from 'react'
import { connect } from 'react-redux'
import firebase from 'firebase'
import { FixedSizeList as List } from 'react-window'
import {
  IonButton,
  IonPopover,
  IonRange,
  IonCard,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonCardContent,
  IonText,
  IonItem,
  IonCardHeader,
  IonLabel,
  IonList,
  IonListHeader,
  IonSelect,
  IonSelectOption,
  IonPage,
  IonItemDivider,
  IonSearchbar,
} from '@ionic/react'
import { tools } from '../data/toolNamesAndCategories'
import { UiSearchService } from '../services/ui-search.service'
import ClearIcon from '@material-ui/icons/Clear'
import DeleteIcon from '@material-ui/icons/Delete'
import InfoIcon from '@material-ui/icons/Info'
import Tooltip from '@material-ui/core/Tooltip'

import {
  addSkillAction,
  loadScoreAction,
  loadSkillsAction,
  loadAllAvgRatingsAction,
  removeSkillAction,
  updateSkillAction,
  loadTopSkillsAction,
} from '../actions'
import {
  UPDATE_INITIAL_ASSESSMENT_SCORE,
  UPDATE_INITIAL_ASSESSMENT_SKILLS,
} from '../constants'
import { SkillHttpService } from '../http_services/skill.http_service'
import { CrudHttpService, update } from '../http_services/crud.http_service'
import '../App.css'
import './add-tool.css'
import RatingStar from './Rating'
import {SkillRating} from './Rating'


let j; 
for (j=0;j< tools.length; j++) {
  tools[j].averageRating = 0
}
const allTools = tools;

class AddTool extends Component {
  constructor(props) {
    super(props)

    this.tools = this.filterTools()
    this.state = {
      toolName: this.props.skill.toolName,
      rating: this.props.skill.rating,
      role: this.props.skill.role,
      category: this.props.skill.category,
      score: this.props.skill.score,
      isTopSkill: this.props.skill.isTopSkill,
      results: this.tools,
      searchDisabled: true,
      showRolePopover: false,
      showScorePopover: false,
      showRatePopover: false,
    }
    console.log(this.filterTools)

  }

  filterTools() {
    return [...this.props.tools].filter((tool) => {
      return tool.category === this.props.category
    })
  }

  async submit() {
    this.props.addSkill({
      category: this.props.category,
      strategy: this.props.strategy,
      tool_name: this.state.toolName,
      role: this.state.role,
      score: this.state.score,
      rating: this.state.rating
    })
    this.props.loadSkills()
    this.props.loadScore()
    this.props.exitModal()
  }

  async submitUpdate() {
    var skill = {
      category: this.props.category,
      strategy: this.props.strategy,
      tool_name: this.state.toolName,
      role: this.state.role,
      rating: this.state.rating,
      score: this.state.score,
      id: this.props.skill.id,
    }
    this.props.updateSkill(skill)
    var props = this.props
    setTimeout(
      function() {
        props.loadSkills()
        props.loadScore()
        // props.loadRating()
        props.exitModal()
      },
      2000 //quick fix to avoid race condition
    )
  }

  updateRole(e) {
    this.state.role = e.target.value
  }
  updateScore(e) {
    this.state.score = e.target.value
  }
  updateRating(e) {
    this.state.rating = e.target.value
  }
  updateToolName(e) {
    this.state.toolName = e.target.value
  }

  setToolName(name) {
    this.setState({
      searchDisabled: true,
      toolName: name,
    })
  }

  search(e) {
    const val = e.target.value

    this.setState(
      {
        toolName: val,
        searchDisabled: false,
      },
      () => {

        const results = UiSearchService.search(tools, val, ['toolName'])
        this.setState({ results: results })

      }
    )
  }

  renderSearch() {
    if (this.state.searchDisabled === true) {
      return
    }

    return this.state.results.slice(0, 883).map((result, index) => {
      // if ( this.stat[result.toolName] ) {
      //   result.averageRating = 
      // }
      return [
        <IonItem
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
          className='addToolPopover'
          button={true}
          key={`${result - index}`}
          onClick={(e) => {
            this.setToolName(result.toolName)
          }}
        >
          {result.toolName}&nbsp;
          <SkillRating className='search-result-rating' rating={result.averageRating} />
        </IonItem>,
      ]
    })
  }

  renderToolIfSelected() {
    if (this.state.toolName === '') {
      return
    }
    return [<IonItem key={this.state.toolName}>Selected Tool: {this.state.toolName}</IonItem>]
  }

  remove() {
    this.props.removeSkill(this.props.skill)
    this.props.loadSkills()
    this.props.loadAllAvgRatings()
    this.props.loadScore()
    this.props.exitModal()
  }
  renderRateSkillIfTopSkill() {
    if (this.state.isTopSkill == false) {
      return
    }
   return <><IonItem key={this.state.toolName}lines='none'>
   <InfoIcon
     onMouseOver={(e) => {
       this.setState((state) => ({
         ...state,
         showRatingPopover: true,
       }))
     }}
   />
   <IonPopover
     style={{ padding: '20px' }}
     cssClass='addTooltipPopover'
     isOpen={this.state.showRatingPopover}
     onDidDismiss={(e) => {
       this.setState((state) => ({
         ...state,
         showRatingPopover: false,
       }))
     }}
   >
     Rate your overall experience with this tool.
     <RatingStar  name='rate' />= Poor
     <RatingStar  name='rating' />= Excellent
   </IonPopover>
   <IonLabel>Rate your experience with this tool</IonLabel>
 </IonItem>
 <IonItem style={{overflow: 'visible'}}key={`skill${this.state.toolName}`}lines='none'>
   <IonRange
     onIonChange={(e) => this.updateRating(e)}
     id='rating'
     value={this.state.rating}
     onClick={(e) => {
       this.updateRating(e)
     }}
     // value={this.remove()}
     pin={true}
     min={1}
     max={5}
     ticks={true}
     snaps={true}
   >
     <IonLabel slot='start'>1</IonLabel>
     <IonLabel slot='end'>5</IonLabel>
   </IonRange>
 </IonItem>
 <IonItem key={`skills${this.state.toolName}`}lines='none'>
   <div
     style={{
       display: 'flex',
       justifyContent: 'space-between',
       width: '100%',
     }}
   >
    <RatingStar  name='rate' />
    <RatingStar  name='rating' />
     
   </div>
 </IonItem>
 </>
  }
  render() {
    console.log('showRolePopover: ', this.state.showRolePopover)

    return [
      <IonCard className='IonCardWrap'>
        <IonCardHeader>
          <IonToolbar>
            <IonButtons slot='end'>
              {this.props.skill.id && (
                <IonButton
                  onClick={(e) => {
                    this.remove()
                    this.props.removeSkill(this.props.skill)
                    this.props.loadSkills()
                    this.props.loadScore()
                  }}
                >
                  <DeleteIcon />
                </IonButton>
              )}{' '}
              <IonButton
                onClick={(e) => {
                  this.props.exitModal()
                }}
              >
                <ClearIcon />
              </IonButton>
            </IonButtons>

            <IonTitle style={{ padding: '20px' }}> Add Tool</IonTitle>
          </IonToolbar>
        </IonCardHeader>
        <IonCardContent className='IonCardStuff'>
          <IonList>
            <IonItem lines='none'>
              <IonSearchbar
                debounce={500}
                className='toolName'
                placeholder='Select a Tool'
                value={this.state.toolName}
                onIonFocus={(e) => this.search(e)}
                onIonChange={(e) => this.search(e)}
              />
            </IonItem>
            <div style={{ maxHeight: '300px', overflowY: 'scroll' }}>
              {this.renderSearch()}
            </div>
            {this.renderToolIfSelected()}
            <IonItem lines='none'>
              <InfoIcon
                onMouseOver={(e) => {
                  this.setState((state) => ({
                    ...state,
                    showRolePopover: true,
                  }))
                }}
              />
              <IonPopover
                style={{ padding: '20px' }}
                cssClass='addTooltipPopover'
                isOpen={this.state.showRolePopover}
                onDidDismiss={(e) => {
                  this.setState((state) => ({
                    ...state,
                    showRolePopover: false,
                  }))
                }}
              >
                This is the role you play in using the tool. It could be the
                role in your current position or how you have used it in the
                past.
              </IonPopover>
              <IonLabel>My role with this tool</IonLabel>
            </IonItem>
            <IonItem>
              <IonSelect
                interface='popover'
                id='role'
                value={this.state.role}
                placeholder='Select Role'
                onIonChange={(e) => this.updateRole(e)}
                className='custom-role'
              >
                <IonSelectOption value='p'>Planner</IonSelectOption>
                <IonSelectOption value='a'>Analyst</IonSelectOption>
                <IonSelectOption value='u'>User</IonSelectOption>
                <IonSelectOption value='l'>Leader</IonSelectOption>
                <IonSelectOption value='i'>Technologist</IonSelectOption>
              </IonSelect>
            </IonItem>

            {/* <IonItem lines='none'>
              <InfoIcon
                onMouseOver={(e) => {
                  this.setState((state) => ({
                    ...state,
                    showRolePopover: true,
                  }))
                }}
              />
              <IonPopover
                style={{ padding: '20px' }}
                cssClass='addTooltipPopover'
                isOpen={this.state.showRolePopover}
                onDidDismiss={(e) => {
                  this.setState((state) => ({
                    ...state,
                    showRolePopover: false,
                  }))
                }}
              >
                This is the role you play in using the tool. It could be the
                role in your current position or how you have used it in the
                past.
              </IonPopover>
              <IonLabel>The strategy that this tool supported</IonLabel>
            </IonItem>
            <IonItem>
              <IonSelect
                interface='popover'
                id='role'
                value={this.state.role}
                placeholder='Select Strategy'
                onIonChange={(e) => this.updateRole(e)}
                className='custom-role'
              >
                <IonSelectOption value='p'>Attraction</IonSelectOption>
                <IonSelectOption value='a'>Engagement</IonSelectOption>
                <IonSelectOption value='u'>Analysis & Optimization
</IonSelectOption>
                
              </IonSelect>
            </IonItem> */}

            <IonItem lines='none'>
              <InfoIcon
                onMouseOver={(e) => {
                  this.setState((state) => ({
                    ...state,
                    showScorePopover: true,
                  }))
                }}
              />
              <IonPopover
                style={{ padding: '20px' }}
                cssClass='addTooltipPopover'
                isOpen={this.state.showScorePopover}
                onDidDismiss={(e) => {
                  this.setState((state) => ({
                    ...state,
                    showScorePopover: false,
                  }))
                }}
              >
                Rank your level of competency with this tool with 1 being a
                novice, 3 being intermediate, and 5 being an expert.
              </IonPopover>
              <IonLabel>My expertise level with this tool</IonLabel>
            </IonItem>
            <IonItem style={{overflow: 'visible'}}lines='none'>
              <IonRange
                onIonChange={(e) => this.updateScore(e)}
                id='score'
                value={this.state.score}
                onClick={(e) => {
                  this.updateScore(e)
                }}
                // value={this.remove()}
                pin={true}
                min={1}
                max={5}
                ticks={true}
                snaps={true}
              >
                <IonLabel slot='start'>1</IonLabel>
                <IonLabel slot='end'>5</IonLabel>
              </IonRange>
            </IonItem>
            <IonItem>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
              justifyContent: 'space-between',
                }}
              >
                <div>Novice</div>
                <div>Expert</div>
              </div>
            </IonItem>
          
            { this.renderRateSkillIfTopSkill()}
          </IonList>

          {this.props.skill.id == '' && (
            <IonButton
              onIonChange={(e) => this.updateScore(e)}
              expand='half'
              // shape='round'
              size='large'
              style={{ width: '30%', marginLeft: '35%', marginTop: '5%' }}
              onClick={() => this.submit()}
            >
              SAVE
            </IonButton>
          )}

          {this.props.skill.id != '' && (
            <IonButton
              onIonChange={(e) => this.updateScore(e)}
              expand='half'
              // shape='round'
              size='large'
              style={{ width: '30%', marginLeft: '35%', marginTop: '5%' }}
              onClick={() => this.submitUpdate()}
            >
              UPDATE
            </IonButton>
          )}
        </IonCardContent>
      </IonCard>,
    ]
  }
}

AddTool.defaultProps = {
  skill: {
    id: '',
    toolName: '',
    role: '',
    score: 1,
  },
}

const mapStateToProps = (state, ownProps) => {
  const {skill, strategy, category, exitModal} = ownProps
  const addAvgRatingsToTools = () => {

    let j;
    for (j=0; j< allTools.length; j++) {
     
      if (state.averageRatings.hasOwnProperty(allTools[j].toolName)){
        allTools[j].averageRating = state.averageRatings[allTools[j].toolName]
        
      }
    }
    return allTools
  }
  const allSkills = addAvgRatingsToTools()

  return {stats: state, tools: allSkills }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadSkills: () => {
      dispatch(loadSkillsAction)
    },
    loadTopSkills: () => {
      dispatch(loadTopSkillsAction)
    },
    loadAllAvgRatings: () => {
      dispatch(loadAllAvgRatingsAction)
    },
    addSkill: (skill) => {
      dispatch(addSkillAction(skill))
    },
    updateSkill: (skill) => {
      dispatch(updateSkillAction(skill))
    },
    removeSkill: (skill) => {
      dispatch(removeSkillAction(skill))
    },
    loadScore: () => {
      dispatch(loadScoreAction)
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddTool)