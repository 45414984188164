import { signUpWithLinkedIn } from './firebase'
import React from 'react'

const LinkedInLogin = () => {
  return (
    <div onClick={() => signUpWithLinkedIn()}>
      <div type='submit' style={{ height: '50px', width: '215px' }}>
        <img
          style={{ height: '100%', width: '100%', textAlign: 'center' }}
          src={
            'https://taggbox.com/blog/wp-content/uploads/2018/09/Signin-with-LinkedIn.png'
          }
          alt={'LinkedIn authentification'}
        />
      </div>
    </div>
  )
}

export default LinkedInLogin
