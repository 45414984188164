import React, { Component } from 'react'
import fire from '../firebase'
import { loadingController } from '@ionic/core'
import './dashboard.css'
import '../../App.css'
import { IndividualHttpService } from '../../http_services/individual.http_service'
import { DataGrid } from '@material-ui/data-grid'
import { rest } from 'lodash'
import Protect from 'react-app-protect'
import 'react-app-protect/dist/index.css'

const columns = [
  { field: 'id', headerName: 'ID', width: 300 },
  {
    field: 'firstName',
    headerName: 'First name',
    width: 150,
    editable: true,
  },
  {
    field: 'lastName',
    headerName: 'Last name',
    width: 150,
    editable: true,
  },
  {
    field: 'email',
    headerName: 'Email',
    width: 250,
    editable: true,
  },
  {
    field: 'lastLoginAt',
    headerName: 'Last Login At',
    width: 250,
    editable: true,
  },
  {
    field: 'totalScore',
    headerName: 'Total Score',
    width: 150,
    editable: true,
  },
  {
    field: 'topSkills',
    headerName: 'Top Tools',
    width: 400,
    editable: true,
  },
]

export default class Admin extends Component {
  constructor(props) {
    super(props)

    this.state = {
      user: {},
      data: [],
      top_skills: {},
    }
  }

  async componentDidMount() {
    const spinner = await loadingController.create({
      spinner: 'crescent',
      message: 'Loading Dashboard Capture',
    })

    await spinner.present()
    try {
      const user = await fire.auth()
      const { currentUser } = user
      if (currentUser) {
        const railsUser = await new IndividualHttpService().where({
          firebase_uid: currentUser.uid,
        })
        this.setState({ user: railsUser[0] })
        await this.fetchData(currentUser.uid)
      }
    } catch (e) {
      console.log('error')
      console.log(e)
      await spinner.dismiss()
      return
    }
    await spinner.dismiss()
  }
  addSkillsToUsersObject(usersSkills, usersObject) {
    let i
    let newUsersObject = usersObject
    let newUsersSkills = usersSkills
    let userId
    let userSkills = ''
    for (i = 0; i < newUsersObject.length; i++) {
      userId = newUsersObject[i].id
      const userSkills = newUsersSkills[userId]
      newUsersObject[i].topSkills = userSkills
    }
    return newUsersObject
  }

  getTop3Skills = async (individual_id) => {
    const urlSkills =
      global.apiBaseUrl + '/skills/new?action=individual_dashboard'

    let loopData = ''
    await fetch(urlSkills, {
      method: 'POST',
      redirect: 'follow',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors',
      body: JSON.stringify({ individual_id: individual_id }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        data = data.tools.slice(0, 3)
        let i
        for (i = 0; i < data.length; i++) {
          loopData += `${data[i].tool_name} : ${data[i].score} \n `
        }
      })
    return loopData
  }
  async fetchData(uid) {
    const url = global.apiBaseUrl + `/individuals/new?action=all_individuals`
    fetch(url, {
      method: 'POST',
      redirect: 'follow', // manual, *follow, error
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors',
      body: JSON.stringify({ firebase_uid: uid }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        const rows = data.map((individual) => {
          let dateTimeString = individual.last_login_at
          function formatDateTime(str) {
            const dateString = str.toLocaleString().split('T')[0]
            const timeString = str.toLocaleString().split('T')[1]
            const dateArr = dateString.split('-')
            const finalDateString = `${dateArr[1]}/${dateArr[2]}/${dateArr[0]}`

            const longTimeArr = timeString.split('.')[0]
            const shortTimeArr = longTimeArr.split(':')
            const hour = shortTimeArr[0]
            const minute = shortTimeArr[1]

            const finalTimeString = `${
              hour > 12 ? hour - 12 : hour > 1 ? parseInt(hour) : '12'
            }:${minute} ${hour >= 12 ? 'PM' : 'AM'}`

            const fullDateString =
              'At ' + finalTimeString + ' on ' + finalDateString

            return fullDateString
          }
          const fullLoginDateString = formatDateTime(dateTimeString)
          this.getTop3Skills(individual.id)
            .then((result) => {
              let newObj = this.state.top_skills
              newObj[individual.id] = result
              this.setState({
                top_skills: newObj,
              })
              return result
            })
            .catch((error) => {
              console.log('error')
              console.log(error)
            })

          const newObj = {
            id: individual.id,
            firstName: individual.first_name,
            lastName: individual.last_name,
            email: individual.email,
            lastLoginAt: fullLoginDateString,
            totalScore: individual.total_score,
            topSkills: 'skills not loaded yet..',
          }
          return newObj
        })
        this.setState({ data: rows })
      })
  }
  render() {
    const { data, top_skills } = this.state
    const dataWithSkills = this.addSkillsToUsersObject(top_skills, data)
    return [
      <Protect sha512='707339bd291f9aae93e137fc2bbe8dbece059dac65a948396cac0f319c0e0a035dec00d6a42083148fac29fc972806ab4f928d661d471c86b74df76f2c1e74ee'>
        <ion-grid>
          <div style={{ height: 95 * 9, width: '100%' }}>
            <DataGrid
              rowHeight={90}
              rows={dataWithSkills}
              columns={columns}
              pageSize={20}
              rowsPerPageOptions={[20]}
              checkboxSelection
              disableSelectionOnClick
              style={{ whiteSpace: 'pre-wrap' }}
            />
          </div>
        </ion-grid>
      </Protect>,
    ]
  }
}
