const UPDATE_INITIAL_ASSESSMENT_SCORE = 'UPDATE_INITIAL_ASSESSMENT_SCORE'
const UPDATE_INITIAL_ASSESSMENT_SKILLS = 'UPDATE_INITIAL_ASSESSMENT_SKILLS'
const SET_SCORE = 'SET_SCORE'
const SET_RUNNING_SCORE = 'SET_RUNNING_SCORE'
const SET_SKILLS = 'SET_SKILLS'
const SET_TOP_SKILLS = 'SET_TOP_SKILLS'
const SET_ALL_AVERAGES = 'SET_ALL_AVERAGES'

const DEFAULT_STORE = {
  currentScore: 0,
  sortedSkills: {},
  topSkills: {},
  allSkillAverages: {},
  toolAction: 'add',
  tool: {},
  averageRatings: {},
}

const CATEGORY_OBJECTS = [
  {
    step: 1,
    categoryTitle: undefined,
    categoryName: undefined,
  },
  {
    step: 2,
    categoryTitle: 'Advertising & Promotion',
    categoryName: 'Ad & Promo',
  },
  {
    step: 3,
    categoryTitle: 'Experience & Content',
    categoryName: 'Content & Experience',
  },
  {
    step: 4,
    categoryTitle: 'Social & Relationships',
    categoryName: 'Social & Relationships',
  },
  {
    step: 5,
    categoryTitle: 'Commerce & Sales',
    categoryName: 'Commerce & Sales',
  },
  {
    step: 6,
    categoryTitle: 'Data',
    categoryName: 'Data',
  },
  {
    step: 7,
    categoryTitle: 'Management',
    categoryName: 'Management',
  },
]

export {
  UPDATE_INITIAL_ASSESSMENT_SKILLS,
  UPDATE_INITIAL_ASSESSMENT_SCORE,
  DEFAULT_STORE,
  CATEGORY_OBJECTS,
  SET_SCORE,
  SET_RUNNING_SCORE,
  SET_SKILLS,
  SET_TOP_SKILLS,
  SET_ALL_AVERAGES
}
