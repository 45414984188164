import React, { useState, useEffect } from 'react'
import dashPhoto2 from '../assets/images/Screen Shot 2022-08-26 at 9.30.42 AM.png'
import dashPhoto3 from '../assets/images/Screenshot 2023-04-11 at 4.25.27 PM.png'
import dashPhoto4 from '../assets/images/My project.jpg'
import dashPhoto5 from '../assets/images/Screenshot 2023-07-28 at 4.41.49 PM.png'



import { Link } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import fire from './firebase'
import Billing from './Billing'
import { IndividualHttpService } from '../http_services/individual.http_service'
import { render } from '@testing-library/react'
import './YoutubeEmbed.css'
import YoutubeEmbed from './YoutubeEmbed'
import LoginDialogBox from './LoginDialogBox'
import handleShowLoginModal from './LoginDialogBox'
import { navigate, redirectTo } from '@reach/router'
import './MyScoring.css'

export default class MyScoring extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
      password: '',
      showBundle: false,
      showCreateAccount: false,
      showAccountConfirmed: false,
      shouldShowModal: true,
      ShowLoginModal: false,
      companySize: null,
      showLogin: false,
      showCreateAccount: false,
      showAccountConfirmed: false,
      bundle: '',
    }

    this.signup = this.signup.bind(this)
    this.login = this.login.bind(this)
    this.handleShowLoginModal = this.handleShowLoginModal.bind(this)
    this.handleShowCreateAccount = this.handleShowCreateAccount.bind(this)
    this.handleShouldShowModal = this.handleShouldShowModal.bind(this)
  }

  async login() {
    try {
      await fire
        .auth()
        .signInWithEmailAndPassword(this.state.email, this.state.password)
      const user = await fire.auth().currentUser
      await new IndividualHttpService().upsertByFirebaseUid({
        firebase_uid: user.uid,
        email: user.email,
      })
      if (user) {
        window.location.href = '/initial'
      }
      // window.location.href = '/initial'
    } catch (error) {
      console.log(error)
      alert(error.message)
    }

    this.handleShowLoginModal()
  }

  showBilling() {
    return <Billing />
  }

  async signup() {
    try {
      await fire
        .auth()
        .createUserWithEmailAndPassword(this.state.email, this.state.password)
      const user = await fire.auth().currentUser
      const response = await new IndividualHttpService().upsertByFirebaseUid({
        firebase_uid: user.uid,
        email: user.email,
      })
      window.location.href = '/initial'
    } catch (error) {
      console.log(error)
      alert(error.message)
    }

    this.handleShowAccountConfirmed()
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleShowBundle() {
    this.setState({
      showBundle: !this.state.showBundle,
    })
  }

  handleShowCreateAccount() {
    this.setState({
      showCreateAccount: !this.state.showCreateAccount,
    })
  }

  handleShouldShowModal() {
    this.setState({
      shouldShowModal: !this.state.shouldShowModal,
    })
  }

  handleShowLoginModal() {
    this.setState({
      showLogin: !this.state.showLogin,
    })
  }

  handleShowAccountConfirmed() {
    this.setState({
      showCreateAccount: false,
    })
    this.setState({
      showAccountConfirmed: !this.state.showAccountConfirmed,
    })
  }

  updateBundle(e) {
    this.setState({
      bundle: e.target.id,
    })
  }

  render() {
    return (
      <div>
        {/* <LoginDialogBox
          showLogin={this.state.showLogin}
          handleShowCreateAccount={this.handleShowCreateAccount}
          handleShowLoginModal={this.handleShowLoginModal}
          login={this.login}
          showCreateAccount={this.state.showCreateAccount}
          signup={this.signup}
          handleShowAccountConfirmed={this.handleShowAccountConfirmed}
          showAccountConfirmed={this.state.showAccountConfirmed}
        />

        <Modal
          id='account-confirmed-modal'
          centered='true'
          show={this.state.showAccountConfirmed}
        >
          <Modal.Header
            closeButton
            onClick={() => this.handleShowAccountConfirmed()}
          ></Modal.Header>
          <Modal.Body className='modal-body row d-flex justify-content-center'>
            <h4 style={{ textAlign: 'center' }} className='col-md-12'>
              Account Created
            </h4>
            <p>You will be redirected to your scoring modal shortly.</p>
          </Modal.Body>
        </Modal> */}

        <div className='page-padding'>
          <div className='my-scoringone'>
            <br />
            <br />
            <h1 id='bold-fonte'>
              {/* <h1 className='ms-title'>
                Does your company know how to leverage your Martech achievement?
              </h1> */}
              <h1 className='ms-title-2'>
                Get the Recognition You Deserve With Stacklr
                in 5 minutes
              </h1>
            </h1>
          
          </div>



          <div
              className='appp-2'
            >
              {/* <h2>Unlock the Power of Your Martech Stack in 5 minutes</h2> */}
              <p className='appp-2-p'>
                Stacklr measures your Martech stack by scoring your abilities
                and skills. The first three digits reflect your Martech level
                and the two letters reflect how you use the technology. Your
                score is uniquely yours, take pride in it!” to the right of it.


              </p>
            </div>

          <div className='apppp' >
            <div className='appp' >
              {/* <YoutubeEmbed embedId='rokGy0huYEA' /> */}


              <div className='img-row'>

<img
  src={dashPhoto3}
  className='img-fluid2 mx-auto d-block'
  alt='...'

/>

</div>

              <div className='img-row'>
              <div
              className='img-row-title'
            >
              <h2>What my score means</h2>
             
            </div>
                <img
                  src={dashPhoto4}
                  className='img-fluid3 mx-auto d-block'
                  alt='...'

                />

              </div>
            </div>

            <hr />

            
          </div>


          <div>
            <p className='appp-2-p-1'>
              Stacklr scoring follows the strategic breakouts from Chief
              Martec of Attraction, Engagement and Analysis & Optimization.
            </p>
          </div>
        
          <hr />


      
  


          <div className="rec-wrapper"
>
          <img
                  src={dashPhoto5}
                  className="wrapper"
                  alt='...'

                />

            <p className='myscore-para'>
              <span style={{ fontSize: '39px' }}>Next step for you</span>
              <br />
              <span style={{ fontSize: '24px' }}>1.</span> When you look at your
              pie chart with your category strengths and your tool grid, what
              mastery jumps out? Is there a weaker area that you need to advance
              in?
              <br />
              <span style={{ fontSize: '24px' }}>2.</span> What are your career
              goals? Do you want to go deep in one technology or category (see
              Beast and Crusher definitions below)? Do you want to go broad across
              categories? Do you want to some of each? Your answer drives how you
              plan your future technology.
              <br />
              <span style={{ fontSize: '24px' }}>3.</span> If your company were to
              do a Stacklr with tool inventory, how does your score overlay
              theirs? Do you have strengths in their tool choices for digital
              transformation? Do they know all of your strengths? Are they
              leveraging yours?
              <br />
              <span style={{ fontSize: '24px' }}>4.</span> Share your score with
              the world on LinkedIn.
            </p>


          </div>





          

        </div>

      </div>
    )
  }
}
