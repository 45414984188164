import React, { Component, Button } from 'react'
import fire from '../firebase'
import { loadingController } from '@ionic/core'
import './dashboard.css'
import '../../App.css'
import { IndividualHttpService } from '../../http_services/individual.http_service'
import { DataGrid } from '@material-ui/data-grid'
import { rest } from 'lodash'
import Protect from 'react-app-protect'
import 'react-app-protect/dist/index.css'
import { Link } from 'react-router-dom'

const columns = [
  { field: 'id', headerName: 'ID', width: 100 },
  {
    field: 'toolName',
    headerName: 'Tool Name',
    width: 150,
    editable: true,
  },
  {
    field: 'innovation',
    headerName: 'Innovation',
    width: 150,
    editable: true,
  },
  {
    field: 'support',
    headerName: 'Support',
    width: 150,
    editable: true,
  },
  {
    field: 'customer_experience',
    headerName: 'Customer experience',
    width: 250,
    editable: true,
  },
  {
    field: 'pricing_high',
    headerName: 'Pricing too high',
    width: 150,
    editable: true,
  },
  {
    field: 'previous_software',
    headerName: 'from another software',
    width: 250,
    editable: true,
  },
  {
    field: 'what_software',
    headerName: 'What software do you integrate',
    width: 200,
    editable: true,
  },
  {
    field: 'email',
    headerName: 'Email',
    width: 200,
    editable: true,
  }
]

export default class SurveysDashboard extends Component {
  constructor(props) {
    super(props)

    this.state = {
      user: {},
      data: [],
      top_skills: {},
    }
  }

  async componentDidMount() {
    const spinner = await loadingController.create({
      spinner: 'crescent',
      message: 'Loading Dashboard Capture',
    })

    await spinner.present()
    try {
      const user = await fire.auth()
      const { currentUser } = user
      if (currentUser) {
        const railsUser = await new IndividualHttpService().where({
          firebase_uid: currentUser.uid,
        })
        this.setState({ user: railsUser[0] })
        await this.fetchData(currentUser.uid)
      }
    } catch (e) {
      console.log(e)
      await spinner.dismiss()
      return
    }
    await spinner.dismiss()
  }


  async fetchData(uid) {
    const url = global.apiBaseUrl + `/surveys/new?action=surveys_dashboard`
    fetch(url, {
      method: 'POST',
      redirect: 'follow', // manual, *follow, error
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors',
      body: JSON.stringify({ firebase_uid: uid }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        const rows = data.map((survey) => {
          const newObj = {
            id: survey.id,
            innovation: survey.innovation,
            support: survey.support,
            customer_experience: survey.customer_experience,
            pricing_high: survey.pricing_high ? "Yes" : "No",
            previous_software: survey.previous_software,
            what_software: survey.what_software,
            email: survey.email,
            toolName: survey.tool_name
          }
          return newObj
        })
        this.setState({ data: rows })
      })
  }

  render() {
    const { data } = this.state

    return [
      <Protect sha512='707339bd291f9aae93e137fc2bbe8dbece059dac65a948396cac0f319c0e0a035dec00d6a42083148fac29fc972806ab4f928d661d471c86b74df76f2c1e74ee'>
        <ion-grid>
          <div style={{ height: 95 * 9, width: '100%' }}>
            <DataGrid
              rowHeight={90}
              rows={data}
              columns={columns}
              pageSize={20}
              rowsPerPageOptions={[20]}
              checkboxSelection
              disableSelectionOnClick
              style={{ whiteSpace: 'pre-wrap' }}
            />
          </div>
        </ion-grid>
      </Protect>,
    ]
  }
}
