

import React, { useState, useEffect } from 'react';

import cx from 'clsx';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader'
import { useContainedCardHeaderStyles } from '@mui-treasury/styles/cardHeader/contained';
import { useSoftRiseShadowStyles } from '@mui-treasury/styles/shadow/softRise';
import { useFadedShadowStyles } from '@mui-treasury/styles/shadow/faded';

import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Rating from '@material-ui/lab/Rating';



  export default function TopSkills({data, individual_id, icons}) {
    let skillsInfo = data;
    const cardHeaderStyles = useContainedCardHeaderStyles();
    const cardShadowStyles = useSoftRiseShadowStyles({ inactive: true });
    const cardHeaderShadowStyles = useFadedShadowStyles();

    const useStyles = makeStyles(({ breakpoints,spacing }) => ({
      loadingContainer: {
        margin: 'auto',
        width: '80%', 
        height: '80vh', 
        display: 'flex',
        flexDirection: 'column', 
        alignSelf: 'center', 
        alignItems : 'center', 
        justifyContent: 'center',
        justifySelf: 'center', 
      },
      homeLink: {
        // border: '1px solid blue',
        marginTop: '2rem',
        borderRadius: '8px', cursor: 'pointer', 
        background: '#6e4e90',
        boxShadow: '8px 10px 10px 1px rgba(0,0,0,0.5)',
        '&:hover': {
          transform: 'translateY(-5px) scale(1.005) translateZ(0)',
          // boxShadow: '0 24px 36px rgba(0,0,0,0.11) 0 24px 46px gray',
        }
      },
      flexRow: {
        display: 'flex', 
        flexDirection: 'row'
      },
      gridContainer: {
        marginBottom: '90px', 
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        // border: '2px dashed orange',
        fontFamily: 'Roboto'
      },
      headerSpan: {
        borderRadius: '8px',
        // background: '#21A3A3',
        width: '58%',
        minWidth: '300px',
        padding: '20px 10px',
        textAlign: 'center',
        margin: 'auto',
        marginTop: '1rem',
        marginBottom: '2rem',
        color: 'black',
      },
      gridHeader: {
        marginTop: '1rem',
        marginBottom: '2rem',
        placeSelf: 'center',
        width: 'auto',
        fontFamily: 'Roboto',
        fontWeight: 600,
        letterSpacing: '2px',
      },
      headerImg: {
        display: 'block',
        margin: 'auto',
        marginBottom: '3rem',
        width: '8rem',
        
      },
      grid: {
      justifyContent: 'space-evenly',
      width: '97%',
      margin: 'auto',
      // marginBottom: '2rem'
      },
      cardHeader: {
        position: 'relative',
        paddingBottom: '20px',
        // marginTop: '-20px',
        top: '-2.3rem',
        fontSize: '1.75rem',
        zIndex: '5',
        background: '#1C7BBE',
        // background: '#124D77',
        // background: '#BADFFA',
  
  
      },
      card: {
        marginTop: '2rem',
        marginBottom: '2.5rem',
        padding: '1rem',
        borderRadius: spacing(1.5),
        transition: '0.3s',
        width: '96%',
        overflow: 'initial',
        color: 'white',
        // background: '#BEABD2',
        background: '#2497ec',
        // background: '#4DADF1',
        // background: '#62B6F3',
        // background: '#847792',
        margin: 'auto',
        letterSpacing: '4px',
        minWidth: '300px',
        boxShadow: '0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25), 0 8px 16px -8px hsla(0, 0%, 0%, 0.3), 0 -6px 16px -6px hsla(0, 0%, 0%, 0.03)',
        transition: 'all ease 200ms',
        '&:hover': {
          boxShadow:' 0 0 4em 0px rgba(0, 0, 0, 0.6)',
          transform: 'scale(1.01)',
        }
      },
      box: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        // border: '2px dashed red'
  
      },
      skillName: {
        fontSize: '1rem',
        float: 'left', 
        maxWidth: '70%', 
        // border: '2px dashed purple'
      },
      avgRatingText: {
        float: 'right', 
        fontSize: '0.8rem', 
      },
      avgRatingNumber: {
        fontSize: '1.3rem',
        fontWeight: '600',
      },
        content: {
          zIndex: '2',
        alignItems: 'center',
        paddingTop: '1rem',
        textAlign: 'left',
        overflowX: 'auto',
        '& Fieldset': {
          '& legend': {
          fontSize: '1.4rem',
          fontWeight: '600',
          fontFamily: 'Roboto',
          
          }
        }
      },
      media: {
      display: 'block',
      zIndex: '1',
      width: '10rem',
      height: '10rem',
        margin: 'auto',
        // transform: 'scale(1.8)',
        borderRadius: spacing(2),
        [breakpoints.up('md')]: {
          marginTop: 10,
          // transform: 'translateY(28px)',
          transition: '5s'
        },
        '&:after': {
          content: '" "',
          // position: 'absolute',
          // top: 0,
          // left: 0,
          // width: '70%',
          // height: 'auto',
          borderRadius: spacing(2), // 16
          // opacity: 0.7,
        },
      },
    }));
    const classes = useStyles();

    function SimpleRating({individualId, rating, name, category, score, skill_avg, key}) {
      const labels = {
        0: 'Unrated',
        1: 'Lower',
        2: 'Low',
        3: 'Medium',
        4: 'High',
        5: 'Essential',
      };
      const [value, setValue] = React.useState(rating);
      const [hover, setHover] = React.useState(-1);
      async function submitSkillRating(individualId, skillName, skillCategory, skillRating) {
    
      const skillUrl = global.apiBaseUrl + '/skills/new?action=update_skill_rating';
      const response = await  fetch(skillUrl,{
        method: 'POST',
        redirect: 'follow', // manual, *follow, error
        headers: { 'Content-Type': 'application/json' },
        mode: 'cors',
        body: JSON.stringify({ individual_id: individualId, tool_name: skillName, category: skillCategory, rating: skillRating}),
    
      })
        .then((resp) => resp.json())
        .then((data) => {
          return data
        }).catch(error => {
          if (typeof error.json === "function") {
              error.json().then(jsonError => {
                  console.log("Json error from API");
                  console.log(jsonError);
              }).catch(genericError => {
                  console.log("Generic error from API");
                  console.log(genericError)
                  console.log(genericError.statusText);
              });
          } else {
              console.log("Fetch error");
              console.log(error);
          }
      });
      }
      const changeRating = ( rating ) => {
        submitSkillRating(individualId, name, category, rating);
      }
      return (
        
          <Box component="fieldset" mb={3} borderColor="transparent" key={key} style={{zIndex: '10'}} >
            <div className={classes.box}>
            <Typography component="legend" className={classes.skillName}>{name}</Typography>
            <Typography className={classes.avgRatingText}>avg : <span className={classes.avgRatingNumber}>{skill_avg}
            </span>
            </Typography>
            </div>
            <div className={classes.box}>
    
            <Rating
              size='large'
              name={name + 'rating'}
              value={value}
              onChangeActive={(event, newHover) => {
                event.preventDefault()
        setHover(newHover);
      }}
              onChange={(event, newValue) => {
                event.preventDefault()
                if (newValue == value || newValue == null) {
                  return 
                }
                setValue(newValue);
                changeRating(newValue);
              }}
            />
             {value !== null && <Box ml={2} style={{fontSize: '1.3rem'}}>{labels[hover !== -1 ? hover : value]}</Box>}
            </div>
          </Box>)
     }
    return <Grid container spacing={1} className={classes.grid}> 
      {Object.keys(skillsInfo).map((category, index) => {
        return <Grid item xs={12} sm={6} md={4} lg={3} key={index} >
          <Card item  className={cx(classes.card, cardShadowStyles.root)}
          raised={true}
          >

          <CardHeader
        className={[classes.cardHeader,cardHeaderShadowStyles.root]}
        classes={cardHeaderStyles}
        title={category}
        // avatar={icons[category]}
        // subheader={''}
        />
        
         <CardMedia
            className={classes.media}
            image={icons[category]}/>

          <CardContent className={classes.content}>        
         
          {skillsInfo[category].map((skill,idx) => {
              return <SimpleRating key={idx} rating={skill[2]} name={skill[0]} score={skill[1]} category={category} individualId={individual_id} skill_avg={skill[5]? skill[5]:'unrated'}/>
            })}
          </CardContent>
          </Card>
        </Grid>
      })}
      </Grid>
  }