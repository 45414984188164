import React, { useState, useEffect, useMemo } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import {
    IonPopover,
    IonRange,
    IonItem,

    IonLabel,

    IonSearchbar,
} from '@ionic/react';
import { tools } from '../data/toolNamesAndCategories';
import { UiSearchService } from '../services/ui-search.service';
import ClearIcon from '@material-ui/icons/Clear';
import InfoIcon from '@material-ui/icons/Info';
import {
    loadScoreAction,
    loadSkillsAction,
    updateSkillAction,
} from '../actions';
import '../App.css';
import './add-tool.css';
import RatingStar from './Rating';
import 'intro.js/introjs.css';




let j;
for (j = 0; j < tools.length; j++) {
    tools[j].averageRating = 0;
}
const allTools = tools;

const ToolSearch = ({
    skill = {
        id: '',
        toolName: '',
        role: '',
        score: 1,
    },
    category
}) => {
    const dispatch = useDispatch();

    const averageRatings = useSelector((store) => store.averageRatings);

    const addAvgRatingsToTools = () => {
        let j;
        for (j = 0; j < allTools.length; j++) {
            if (
                averageRatings &&
                averageRatings.hasOwnProperty(allTools[j].toolName)
            ) {
                allTools[j].averageRating = averageRatings[allTools[j].toolName];
            }
        }
        return allTools;
    };

    const allSkills = addAvgRatingsToTools();

    const filterTools = () => {
        return [allSkills].filter((tool) => {
            return tool.category === skill.category;
        });
    };





    const [toolId, setToolId] = useState(null);
    const [toolName, setToolName] = useState(skill.toolName);
    const [rating, setRating] = useState(skill.rating);
    const [role, setRole] = useState(skill.role);
    const [strategy, setStrategy] = useState(skill.strategy);
    const [score, setScore] = useState(skill.score);
    const [isTopSkill, setIsTopSkill] = useState(skill.isTopSkill);
    const [results, setResults] = useState(filterTools());
    const [searchDisabled, setSearchDisabled] = useState(true);

    const [showRatingPopover, setShowRatingPopover] = useState(false);

    useEffect(() => {
        if (skill.toolName != undefined) {
            setToolId(skill.id);
            setToolName(skill.toolName);
            setRating(skill.rating);
            setRole(skill.role);
            setScore(skill.score);
            setIsTopSkill(skill.isTopSkill);
            setStrategy(skill.strategy);
        }
    }, [skill]);

    const init = () => {
        setToolName('');
        setRating(0);
        setRole('');
        setToolId(null);
        setScore(0);
        setStrategy('');
    }

    const search = (e) => {
        const val = e.target.value;
        setToolName(val);
        setSearchDisabled(false);
        const results = UiSearchService.search(tools, val, ['toolName']);
        setResults(results);

        console.log(setResults(results))

    };

    const renderSearch = () => {
        if (searchDisabled === true) {
            return;
        }

        // console.log('renderSearch', skill);

        return results.slice(0, 100).map((result, index) => {
            // if ( this.stat[result.toolName] ) {
            //   result.averageRating =
            // }
            return [
                <IonItem
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                    className="addToolPopover"
                    button={true}
                    key={`${result - index}`}
                    onClick={(e) => {
                        setToolName(result.toolName);
                    }}
                >
                    {result.toolName}&nbsp;

                </IonItem>,
            ];
        });
    };

    const renderToolIfSelected = () => {
        if (toolName !== '') {
            return (
                <IonItem lines="none">
                    <IonLabel>
                        <h2>{toolName}</h2>
                    </IonLabel>
                    <ClearIcon
                        onClick={(e) => {
                            setToolName('');
                            setRole('');
                            setRating(0);
                            setScore(0);
                        }}
                    />
                </IonItem>
            );
        }
    };

    const renderRateSkillIfTopSkill = () => {
        if (isTopSkill == false) {
            return;
        }
        return (
            <>
                <IonItem key={toolName} lines="none">
                    <InfoIcon
                        onMouseOver={(e) => {
                            setShowRatingPopover(true);
                        }}
                    />
                    <IonPopover
                        style={{ padding: '20px' }}
                        cssClass="addTooltipPopover"
                        isOpen={showRatingPopover}
                        onDidDismiss={(e) => {
                            setShowRatingPopover(false);
                        }}
                    >
                        Rate your overall experience with this tool.
                        <RatingStar name="rate" />= Poor
                        <RatingStar name="rating" />= Excellent
                    </IonPopover>
                    <IonLabel>Rate your experience with this tool</IonLabel>
                </IonItem>
                <IonItem
                    style={{ overflow: 'visible' }}
                    key={`skill${toolName}`}
                    lines="none"
                >
                    <IonRange
                        onIonChange={(e) => updateRating(e)}
                        id="rating"
                        value={rating}
                        onClick={(e) => {
                            updateRating(e);
                        }}
                        // value={this.remove()}
                        pin={true}
                        min={1}
                        max={5}
                        ticks={true}
                        snaps={true}
                    >
                        <IonLabel slot="start">1</IonLabel>
                        <IonLabel slot="end">5</IonLabel>
                    </IonRange>
                </IonItem>
                <IonItem key={`skills${toolName}`} lines="none">
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%',
                        }}
                    >
                        <RatingStar name="rate" />
                        <RatingStar name="rating" />
                    </div>
                </IonItem>
            </>
        );
    };






    const updateRating = (e) => {
        setRating(e.target.value);
    };

    async function submitUpdate() {
        const updatedSkill = {
            category: category,
            strategy: strategy,
            tool_name: toolName,
            role: role,
            rating: rating,
            score: score,
            id: skill.id,
        };
        dispatch(updateSkillAction(updatedSkill));
        setTimeout(
            function () {
                dispatch(loadSkillsAction);
                dispatch(loadScoreAction);
            },
            2000 //quick fix to avoid race condition
        );
    }

    return (
        <>

            <div>
                <IonItem id="tool-list" lines="none">

                    <IonSearchbar
                        debounce={500}
                        id="tool-name"
                        placeholder="Select a Software"
                        value={toolName}
                        onIonFocus={(e) => search(e)}
                        onIonChange={(e) => search(e)}
                    />
                </IonItem>

                <div style={{ maxHeight: '300px', overflowY: 'scroll' }}>
                    {renderSearch()}
                </div>
                {renderToolIfSelected()}

            </div>



        </>

    );
};


export default ToolSearch;