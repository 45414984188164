import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown';

// import stakkidLogo from '../assets/logos/logo_white.svg'
import stakkidLogo from '../assets/logos/stacklr-final-logo.png'

import fire from './firebase'
import { IndividualHttpService } from '../http_services/individual.http_service'
import { navigate, redirectTo } from '@reach/router'
import LoginDialogBox from './LoginDialogBox2'

import { signInWithGoogle } from './firebase'
import './Rating-modal.css';

class Ratingmodal extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
          fullName: "",
          industry: "",
          fireErrors: '',
          step: 1,
      input1: '',
      input2: '',
          showLogin: false,
          showCreateAccount: false,
          showAccountConfirmed: false,
        }
    
        this.signup = this.signup.bind(this)
        this.login = this.login.bind(this)
        this.logout = this.logout.bind(this)
        this.handleShowLoginModal = this.handleShowLoginModal.bind(this)
        this.handleShowCreateAccount = this.handleShowCreateAccount.bind(this)
        this.handleContinueRating = this.handleContinueRating.bind(this);
        this.handleFinishRating = this.handleFinishRating.bind(this);
        this.handleFullNameChange = this.handleFullNameChange.bind(this);
        this.handleIndustryChange = this.handleIndustryChange.bind(this);
      }


    async login(email, password) {
        try {
          await fire.auth().signInWithEmailAndPassword(email.trim(), password)
          const user = await fire.auth().currentUser
          await new IndividualHttpService().upsertByFirebaseUid({
            firebase_uid: user.uid,
            email: user.email,
          })
          if (user) {
            // window.location.href = "https://community.stakkid.com";
            window.location.href = '/individual-dashboard'
          }
          // window.location.href = "/initial";
        } catch (error) {
          console.log(error)
          alert(error.message)
        }
    
        this.handleShowLoginModal()
      }
    
      async signup(firstName, lastName, email, password) {
        try {
          await fire.auth().createUserWithEmailAndPassword(email.trim(), password)
          const user = await fire.auth().currentUser
          const response = await new IndividualHttpService().upsertByFirebaseUid({
            firebase_uid: user.uid,
            email: user.email,
            first_name: firstName,
            last_name: lastName
          })
          if (user) {
            // window.location.href = "https://community.stakkid.com";
            // window.location.href = '/login-banner'
                  window.location.href = "/initial";
    
          }
        } catch (error) {
          console.log(error)
          alert(error.message)
        }
    
        this.handleShowAccountConfirmed()
      }
    
      async sendPasswordResetEmail(email) {
        try {
          await fire.auth().sendPasswordResetEmail(email)
          alert('Password reset link sent!')
        } catch (error) {
          console.log(error)
          alert(error.message)
        }
      }

      handleContinueRating() {
        this.setState({ step: 2 });
      }
    
      handleFinishRating() {
        this.props.setOpenModal(false);
      }
    
      handleFullNameChange(event) {
        this.setState({ fullName: event.target.value });
      }
    
      handleIndustryChange(event) {
        this.setState({ industry: event.target.value });
      }
    
     

    
      handleShowCreateAccount() {
        this.setState({
          showLogin: false,

        })
        this.setState({
          showCreateAccount: !this.state.showCreateAccount,
        })
      }
    
      handleShowAccountConfirmed() {
        this.setState({
          showCreateAccount: false,
        })
        this.setState({
          showAccountConfirmed: !this.state.showAccountConfirmed,
        })
      }
    
      handleChange(event) {
        this.setState({
          [event.target.name]: event.target.value,
        })
      }
    
      handleShowLoginModal() {
        this.setState({
          showLogin: !this.state.showLogin,
        })
      }

      handleStep1Submit = (event) => {
        event.preventDefault();
        this.setState({ step: 2 });
      };
    
      handleStep2Submit = (event) => {
        event.preventDefault();
        // Do something with input1 and input2
        // Close modal
      };
    
      handleInputChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
      };
    
      async logout() {
        await fire.auth().signOut()
        window.location = '/'
      }
      

      render() {
        const { setOpenModal } = this.props;
        const { step, input1, input2 } = this.state;

    
    //   render() {
      if (!setOpenModal) {
        return null;
      }
        let errorNotification = this.state.fireErrors ? (
          <div className='Error'>{this.state.fireErrors}</div>
        ) : null
    

    return (
      <div className="modalBackground">
        <LoginDialogBox
          fireErrors={this.state.fireErrors}
          showLogin={this.state.showLogin}
          handleShowCreateAccount={this.handleShowCreateAccount}
          handleShowLoginModal={this.handleShowLoginModal}
          login={this.login}
          showCreateAccount={this.state.showCreateAccount}
          signup={this.signup}
          handleShowAccountConfirmed={this.handleShowAccountConfirmed}
          showAccountConfirmed={this.state.showAccountConfirmed}
        />

        
        
        <div className="modalContainer">
          
          <div className="titleCloseBtn">
            <button
              onClick={() => {
                setOpenModal(false);
              }}
            >
              X
            </button>
          </div>
          
          <div className="title">
            <h1>Would you like to rate another tool?</h1>
          </div>
          {/* <div className="body">
            <p>The next page looks amazing. Hope you want to go there!</p>
          </div> */}
          <div className="footer">
            <button
              onClick={() => {
                setOpenModal(false);
              }}
              id="cancelBtn"
            >
              Rate More
            </button>
            <button  type='button'
                  onClick={(event) => {
                    this.handleShowCreateAccount(event)
                    return false
                    
                  }}              id="cancelBtn"
                  >I'm Finished</button>
          </div>
        </div>
      </div>
    );
    
  }
}

export default Ratingmodal;

