import React from 'react'

function Jobs() {
  return (
    <div>
      <br />
      <div className='page-padding'>
        <h1 id='bold-fonte'>MarTech Job Openings</h1>
        <hr />

        <div
          style={{
            paddingLeft: '50px',
            paddingRight: '50px',
            textAlign: 'center',
          }}
        >
          {/* <div id='iraJobsList'></div> */}

          // Create a separate JavaScript file, e.g., main.js

// Wait for the page to fully load
document.addEventListener("DOMContentLoaded", function () {
    // Your code here to interact with elements or perform actions
});


          


          
        </div>
      </div>
      
    </div>
    
  )
}

export default Jobs
