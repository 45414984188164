export class UiSearchService {
  constructor() {}

  static search(haystack, needle, filterTerms) {
    if (filterTerms === undefined) {
      filterTerms = Object.keys(haystack[0])
    }
    if ([undefined, null].includes(needle)) {
      needle = ''
    }
    if ('' === needle.trim()) {
      return [...haystack]
    }
    return [...haystack].filter((item) => {
      const matches = filterTerms.map((key) => {
        const [key1, key2, key3] = key.split('.')
        let term = ''
        if (key3 !== undefined) {
          term = item[key1][key2][key3]
        } else if (key2 !== undefined) {
          term = item[key1][key2]
        } else {
          term = item[key1]
        }
        return UiSearchService.match(needle, term)
      })

      return matches.includes(true)
    })
  }

  static match(searchTerm, term) {
    // tslint:disable-next-line:no-shadowed-variable
    const matches = searchTerm
      .split(/\s+/)
      .filter((searchTerm) => {
        return searchTerm.trim() !== ''
      })
      .map((searchTerm) => {
        return term.toLowerCase().includes(searchTerm)
      })
    return !matches.includes(false)
  }
}
