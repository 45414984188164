import { CrudHttpService } from './crud.http_service';

export class IndividualHttpService extends CrudHttpService {
  constructor() {
    super('individuals');
  }

  async upsertByFirebaseUid(payload) {
    return await this.newPost('upsert_by_firebase_uid', payload);
  }
}
