import { Link } from 'react-router-dom';
import Video1 from '../../../assets/images/video-1.png';
import Video2 from '../../../assets/images/video-2.png';
import Video3 from '../../../assets/images/video-3.png';

const VideoCard = ({ card, index }) => {

  const images = [
    Video1,
    Video2,
    Video3
  ];

  return (
    <div className="video-card mb-3">
      <a href={card.url} className="video-link" target='_blank'>
        <div className="video-img-bg">
          <img src={card.img} className="video-img" alt="No Image" />
        </div>
      </a>
      <p className="video-des mt-2">{card.description}</p>
    </div>
  );
};

export default VideoCard;
