import React, { Component } from 'react';
import { connect } from 'react-redux'
import {
  IonButton,
  IonPopover,
  IonRange,
  IonItem,
  IonLabel,
  IonSearchbar,
} from '@ionic/react'
import { tools } from '../data/toolNamesAndCategories'
import { UiSearchService } from '../services/ui-search.service'
import InfoIcon from '@material-ui/icons/Info'
import {
  addSkillAction,
  loadScoreAction,
  loadSkillsAction,
  loadAllAvgRatingsAction,
  removeSkillAction,
  updateSkillAction,
  loadTopSkillsAction,
} from '../actions'
import '../App.css'
import './add-tool.css'
import RatingStar from './Rating'
import { SkillRating } from './Rating'
import mainStakkidPhoto3 from '../assets/images/AdobeStock_285756278.jpg'
import './UserRating.css'
import Rating from '@material-ui/lab/Rating';
import Modal from '@material-ui/core/Modal';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Select from 'react-select';
import FlashMessage from 'react-flash-message';
import Ratingmodal from './Rating-modal';

let j;
for (j = 0; j < tools.length; j++) {
  tools[j].averageRating = 0
}
const allTools = tools;
var toolLists = [];

const customStyles = {
  option: (provided) => ({
    ...provided,
    color: 'black',
    padding: 5,
  }),
};
class UserRating extends Component {

  constructor(props) {
    super(props)
    // this.props.loadAllAvgRatings()

    console.log("Constructor")
    this.tools = allTools

    this.state = {
      results: this.tools,
      searchDisabled: true,
      showRolePopover: false,
      showScorePopover: false,
      showRatePopover: false,
      query: "",
      rating: 0,
      modalOpen: true,
      likeModalOpen: false,
      showIndustryModal: false,
      toolData: [],
      fullName: '',
      showMessage: false,
      industries: [],
      industry: '',
      toolId: '',
      showMessage: false,
      messageState: 'success',
      message: '',
    }
  }

  componentDidMount() {
    const url = global.apiBaseUrl + `/tools/new?action=tools_dashboard`;
    const fetchData = async () => {
      const response = await fetch(url);
      const jsonData = await response.json();
      toolLists = jsonData;
      this.setState({ toolData: jsonData });
    };

    fetchData();
    this.getIndustries()
    this.props.loadAllAvgRatingsAction()
  }

  getIndustries() {
    const industryToolUrl = global.apiBaseUrl + '/industries';
    //Get Request
    (async () => {
      try {
        const response = await fetch(industryToolUrl, {
          method: 'GET',
          redirect: 'follow', // manual, *follow, error
          headers: { 'Content-Type': 'application/json' },
          mode: 'cors',
        })
          .then((resp) => resp.json())
          .then((data) => {

            data.length > 0 && this.setState({ industries: data });
          })
          .catch((error) => {
            console.log('error', error);
          });
      } catch (eventError) {
        console.log('error', eventError);
      }
    })();
  }




  async submit() {
    if (!this.state.industry || !this.state.fullName) {
      this.setState({
        showMessage: true,
        messageState: 'error',
        message: 'Please fill in all fields',
      });
      return;
    } else {
      const ratingUrl =
        global.apiBaseUrl + '/ratings/new?action=update_rating';
      (async () => {
        const response = await fetch(ratingUrl, {
          method: 'POST',
          redirect: 'follow', // manual, *follow, error
          headers: { 'Content-Type': 'application/json' },
          mode: 'cors',
          body: JSON.stringify({
            tool_id: this.state.toolId,
            score: this.state.rating,
            industry_id: this.state.industry.id,
            full_name: this.state.fullName,
          }),
        })
          .then((resp) => resp.json())
          .then((data) => {
            this.setState({
              showMessage: true,
              messageState: 'success',
              message: 'Thank you for your rating!',
            });
            setTimeout(() => {
              this.onClose();
            }, 3000);
            return data;
          })
          .catch((error) => {
            console.log('error', error);
          });
      })();
    }
    this.props.loadSkills()
    this.props.loadScore()
    // this.props.loadAllAvgRatings()

  }



  setToolName(name) {
    this.setState({
      searchDisabled: true,
      toolName: name,
    })
  }

  setQuery(name) {
    this.setState({
      query: name,
    })
  }

  setRating(rating) {
    this.setState({
      rating: rating,
    })
  }

  handleFullNameChange(e) {
    this.setState({ fullName: e.target.value });
  }

  handleChange(e) {
    this.setState({ industry: e });
  }

  onClose() {
    this.setState({ modalOpen: false });
  }

  updateRating(e) {
    this.state.rating = e.target.value
  }

  search(e) {
    const val = e.target.value
    this.setState(
      {
        query: val,
        searchDisabled: false,
      },
      () => {
        const results = UiSearchService.search(this.state.toolData, val, ['tool_name'])
        this.setState({ results: results })
      }
    )
  }



  renderSearch() {
    if (this.state.searchDisabled === true || this.state.query === "") {
      return
    }

    return this.state.results.slice(0, 20).map((result, index) => {

      return [
        <IonItem
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
          className='addToolPopover'
          button={true}
          key={`${result - index}`}
          onClick={(e) => {
            this.setToolName(result.tool_name)
            this.setState({ toolId: result.id })
            this.setQuery("")
          }}
        >
          {result.tool_name}&nbsp;
          <SkillRating className='search-result-rating' rating={result.averageRating} />
        </IonItem>,
      ]
    })
  }


  remove() {
    this.props.removeSkill(this.props.skill)
    this.props.loadSkills()
    this.props.loadAllAvgRatings()
    this.props.loadScore()
    this.props.exitModal()
  }
  renderRateSkillIfTopSkill() {
    if (this.state.isTopSkill === false) {
      return
    }
    return <><IonItem key={this.state.toolName} lines='none'>
      <InfoIcon
        onMouseOver={(e) => {
          this.setState((state) => ({
            ...state,
            showRatingPopover: true,
          }))
        }}
      />
      <IonPopover
        style={{ padding: '20px' }}
        cssClass='addTooltipPopover'
        isOpen={this.state.showRatingPopover}
        onDidDismiss={(e) => {
          this.setState((state) => ({
            ...state,
            showRatingPopover: false,
          }))
        }}
      >
        Rate your overall experience with this tool.
        <RatingStar name='rate' />= Poor
        <RatingStar name='rating' />= Excellent
      </IonPopover>
      <IonLabel>Rate your experience with this tool</IonLabel>
    </IonItem>
      <IonItem style={{ overflow: 'visible' }} key={`skill${this.state.toolName}`} lines='none'>
        <IonRange
          onIonChange={(e) => this.updateRating(e)}
          id='rating'
          value={this.state.rating}
          onClick={(e) => {
            this.updateRating(e)
          }}
          pin={true}
          min={1}
          max={5}
          ticks={true}
          snaps={true}
        >
          <IonLabel slot='start'>1</IonLabel>
          <IonLabel slot='end'>5</IonLabel>
        </IonRange>
      </IonItem>
      <IonItem key={`skills${this.state.toolName}`} lines='none'>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <RatingStar name='rate' />
          <RatingStar name='rating' />

        </div>
      </IonItem>
    </>
  }

  render() {
    const { modalOpen } = this.state;

    return (
      <div className='slide-writing'>
        <img
          src={mainStakkidPhoto3}
          alt='happy-garl'
          width='100%'
          height='100%'
        />

        <div className='slide-rating'>
          <p className='slide-t'>Your Rating Matters</p>
          <h3 className='slide-t-2'>Choose 1-5 stars (5=high)</h3>

          <div>
            <IonSearchbar
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '50%',
              }}
              debounce={500}
              id='toolName'
              placeholder='Enter software name'
              value={this.state.query}
              onIonFocus={(e) => this.search(e)}
              onIonChange={(e) => this.search(e)}
            />

            <div style={{ maxHeight: '300px', overflowY: 'scroll' }}>
              {this.renderSearch()}
            </div>
          </div>
          <h1>{this.state.toolName}</h1>
          <div>
            <Rating
              style={{ textAlign: 'center' }}
              size='large'
              onChange={(event, newValue) => { this.setRating(newValue) }}
            />

            <IonButton
              className="rateBtn"
              color="tertiary"
              expand='half'
              shape='round'
              size='large'
              style={{ width: '25%', height: '44px', marginLeft: '2%', marginTop: '-4%' }}
              onClick={() => {
                this.setState({ likeModalOpen: true });
                this.submit();
              }}>
              Rate
            </IonButton>
            {/* <Ratingmodal /> */}
            {this.state.likeModalOpen && <Ratingmodal setOpenModal={() => this.setState({ likeModalOpen: false })} />}
            <Modal
              style={{ top: '20%' }}
              className={`custom-modal software-modal`}
              size="lg"
              open={this.state.modalOpen}
            >
              <Box
                sx={{ width: '420px' }}
                style={{
                  background: '#fff',
                  margin: '0px auto',
                  padding: '10px 20px',
                  borderRadius: '20px',
                }}
              >
                <IconButton
                  aria-label="Close"
                  className="MuiButtonBase-root MuiIconButton-root Component-closeButton-14"
                  onClick={() => this.onClose()}
                >
                  {/* <CloseIcon /> */}
                </IconButton>
                <h4 className=' mb-0' style={{ display: 'flex', justifyContent: 'center', }}>

            <b className='signi'>Enter your name to begin rating
            </b>
          </h4>
          <br></br>
                <>
                  {this.state.showMessage && (
                    <FlashMessage duration={3000}>
                      <strong
                        className={`flash-message text-center ${this.state.messageState}-message`}
                      >
                        {this.state.message}
                      </strong>
                    </FlashMessage>
                  )}
                  
                  <div className="form-block">
                    <label>FullName</label>
                    <input
                      value={this.state.fullName}
                      onChange={(e) => this.handleFullNameChange(e)}
                      placeholder={'Full Name'}
                      className="form-control"
                      type="text"
                      name="fullname"
                    />
                  </div>

                  {/* <div className="form-block mt-3">
                    <label>Industry</label>
                    <Select
                      placeholder="Choose industry"
                      onChange={(e) => this.handleChange(e)}
                      getOptionLabel={(option) => {
                        return option.name;
                      }}
                      getOptionValue={(option) => {
                        return option.id;
                      }}
                      options={this.state.industries}
                      styles={customStyles}
                    />
                  </div> */}

                  <div className="text-center">
                    <button
                      className="software-stakkid-button-rate"
                      onClick={() => this.onClose()}
                    >
                      {' '}
                      Start Rating
                    </button>
                  </div>
                </>
              </Box>
            </Modal>

          </div>
        </div>
      </div>
    );
  }
}


const mapStateToProps = (state, ownProps) => {
  const addAvgRatingsToTools = () => {
    if (state.averageRatings !== undefined) {
      let j;
      for (j = 0; j < toolLists.length; j++) {
        // console.log('toolLists[j].tool_name', toolLists[j].tool_name)
        if (state.averageRatings.hasOwnProperty(toolLists[j].tool_name)) {
          toolLists[j].averageRating = state.averageRatings[toolLists[j].tool_name]
        }
      }
    }
    return toolLists
  }
  const allSkills = addAvgRatingsToTools()
  return { state: state, results: allSkills }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadSkills: () => {
      dispatch(loadSkillsAction)
    },
    loadTopSkills: () => {
      dispatch(loadTopSkillsAction)
    },
    loadAllAvgRatingsAction: () => {
      dispatch(loadAllAvgRatingsAction)
    },
    addSkill: (skill) => {
      dispatch(addSkillAction(skill))
    },
    updateSkill: (skill) => {
      dispatch(updateSkillAction(skill))
    },
    removeSkill: (skill) => {
      dispatch(removeSkillAction(skill))
    },
    loadScore: () => {
      dispatch(loadScoreAction)
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserRating)

