import React, { useState } from 'react'

import Modal from '@material-ui/core/Modal'
import Typography from '@material-ui/core/Typography'
import ClearIcon from '@material-ui/icons/Clear'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import ScoreImg from '../assets/images/IMG_0072.jpg'

const useStylesInitialAssessment = makeStyles({
  root: {
    color: 'white',
    backgroundColor: 'rgba(0, 139, 192, 1.0)',
    '&:hover': {
      backgroundColor: 'rgba(0, 139, 192, 1.0)',
    },
  },
  modal: {
    margin: 'auto',
    maxWidth: '650px',
  },
  unorderedList: {
    padding: '40px',
    '& li': {
      padding: '20px',
    },
  },
})

function Popups() {
  const [shouldShowModal, setShouldShowModal] = useState(true)
  const classes = useStylesInitialAssessment()

  return (
    <div className='first-modal'>
      <Modal
        style={{ top: '20%' }}
        className={`custom-modal ${classes.modal}`}
        size='lg'
        open={shouldShowModal}
      >
        <Paper style={{ padding: '40px' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              onClick={(e) => {
                setShouldShowModal(false)
              }}
            >
              <ClearIcon />
            </Button>
          </div>
          <div className='flex-child magenta'>
            <ion-title
              style={{
                fontSize: '25px',
                fontWeight: 'bolder',
                marginTop: '10%',
                alignItems: 'center',
              }}
            >
              What my score means
            </ion-title>
            <img
              src={ScoreImg}
              alt='...'
              style={{
                width: '80%',
              }}
            />
          </div>
        </Paper>
      </Modal>
    </div>
  )
}

export default Popups
