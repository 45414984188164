import React, { useState, useEffect } from 'react';
import fire from '../firebase'
import { loadingController } from '@ionic/core'
import FlashMessage from 'react-flash-message'
import { Link } from 'react-router-dom'

const ToolNew = () => {
  const [tools, setTools] = useState([]);
  const [toolName, setToolName] = useState('');
  const [categoryId, setCategoryId] = useState('');
  const [categories, setCategories] = useState([]);
  const [message, setMessage] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const [messageState, setMessageState] = useState('success');
  const [errors, setErrors] = useState({});
  // const categories = [
  //   { id: 1, name: 'Category 1' },
  //   { id: 2, name: 'Category 2' },
  //   // ... other categories ...
  // ];

  useEffect(() => {
    async function fetchData() {
      try {
        const spinner = await loadingController.create({
          spinner: 'crescent',
          message: 'Loading Dashboard Capture',
        })
        await spinner.present()

        const currentUser = fire.auth().currentUser;
        if (currentUser) {

          const url = global.apiBaseUrl + `/tools/new?action=fetch_categories`;
          const response = await fetch(url, {
            method: 'POST',
            redirect: 'follow', // manual, *follow, error
            headers: { 'Content-Type': 'application/json' },
            mode: 'cors',
            body: JSON.stringify({ firebase_uid: currentUser.uid }),
          });
          const responseData = await response.json();
          setCategories(responseData);
          console.log('responseData', responseData);

        }
        await spinner.dismiss();
      } catch (e) {
        console.log('error');
        console.log(e);
      }
    }

    fetchData();
  }, []);

  const handleToolNameChange = (event) => {
    setToolName(event.target.value);
  };

  const handleCategoryIdChange = (event) => {
    setCategoryId(event.target.value);
  };

  async function handleSubmit(event) {
    setShowMessage(false)
    event.preventDefault();
    const toolUrl = global.apiBaseUrl + '/tools/new?action=create_tool';
    const response = await fetch(toolUrl, {
      method: 'POST',
      redirect: 'follow', // manual, *follow, error
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors',
      body: JSON.stringify(
        {
          name: toolName,
          category_id: categoryId,
        }),

    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log('success', data)
        if (data.status == 500) {
          setMessageState('error')
          setMessage('Something went wrong')
        } else {
          setMessage('Tool added successfully')
          setMessageState('success')
          setTools([...tools, data]);
        }
        setShowMessage(true)
      }).catch(error => {
        const submitMessage = {
          'message': error.message ? error.message : 'Something went wrong'
        }
        setErrors(submitMessage)
        console.log('error', error)
      });
  }

  return (
    <div className='container-md'>
      <h2 className='mt-3'>Add a New Tool</h2>
      {showMessage && <FlashMessage duration={5000}><strong className={`flash-message ${messageState}-message`}>{message}</strong></FlashMessage>
      }
      <form onSubmit={handleSubmit}>
        <div className='col-12'>
          <label htmlFor="toolName">Tool Name:</label>
          <input
            type="text"
            id="toolName"
            value={toolName}
            onChange={handleToolNameChange}
            required
            className='form-control'
          />
        </div>
        <div className='col-12 mt-3'>
          <label htmlFor="categoryId">Category:</label>
          <div>
            <select
              id="categoryId"
              value={categoryId}
              onChange={handleCategoryIdChange}
              required
            >
              <option value="">Select a category</option>
              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className='col-12 mt-3'>
          <button type="submit" className='btn software-stakkid-button mb-5'>Submit</button>
        </div>
      </form>

      {/* Display the list of added tools */}
      <h4>Added Tools:</h4>
      <ul>
        {tools.map((tool, index) => (
          <li key={index}>
            Id: {tool.id}, Tool Name: {tool.name}, Category ID: {tool.category_id}
            <Link to={{
              pathname: `/software/${tool.id}`
            }} className='ml-2' >View</Link>
            <Link to={{
              pathname: `/tools-dashboard/edit/${tool.id}`
            }} className='ml-2'>Edit</Link>
          </li>
        ))}
      </ul>

    </div>
  );
};

export default ToolNew;