export const tools = [
  {
    toolName: '[24]7',
    category: 'Advertising & Promotion',
  },
  {
    toolName: '4INFO',
    category: 'Advertising & Promotion',
  },
  {
    toolName: '33Across',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Abyssale',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'ACA Network',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Acuity',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Ad Reform',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Adacado',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Adadyn',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Adaptly',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Adara',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Adback',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'AdBank',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Adbeat',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'adClarity',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Adcombi',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Adcrowd',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'AdDaptive Intelligence',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Addefend',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'AdElement',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'AdEx',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'AdForm',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'AdIant',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'AdLabs',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Adline',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Adludio',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'AdMantX',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Admedo',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Adobe Experience Manager',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Adobe Analytics',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Adobe Customer Journey',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Adobe Real-time CDP ',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Adobe Audience Manager',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Adobe Target',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Adobe Campaign',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Adobe Advertising',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Adobe Workfront',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Adobe Photoshop',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Adobe Illustrator',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Adobe After Effects',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Adobe Premiere',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Adobe InDesign',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Adobe Substance',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Adobe Spark',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Adobe Fresco',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Adobe Rush',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Adobe Dimension',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Adobe Dreamweaver',
    category: 'Advertising & Promotion',
  },

  {
    toolName: 'Adperium',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'adplexity',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'AdQuick',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Adrecover',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'AdRoll',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'AdShares',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'AdSupply',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'AdsWizz',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Adtaxi',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Advanse',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Adventive',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Advertise.com',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Adwerx',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Adzooma',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Adzymic',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'AffinityAnswers',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Alphonso',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Amazon',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Amillionads',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Amino',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Amobee',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'AOL',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Appier',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Apple',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Audience Serv',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Audience2Media',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'AudienceScience',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Aumago',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Audience Manager',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Salesforce CRM',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Adobe Cloud',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'BannerFlow',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'BannerSnack',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Bannerwise',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'BeesWax',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Bidswitch',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Bigbom',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Bionic',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Blackwood Seven',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Blip',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'BlockClick',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Blockthrough',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'BlueCava',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Bucksense',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'BuySellAds',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Cablato',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Cardlytics',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Cadreon',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Celtra',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Centro',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Chartboost',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Choozle',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'circus offers',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Clinch',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Clipcentric',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Connexity',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Conversant',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Converto',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Criteo',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'CtrlShift',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'DAD',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'DataXu',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Dax',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Delta Projects',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Demandbase',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Dentsu Aegis Network',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Digilant',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Digital Remedy',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'District M',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Doohlabs',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'DoorBoost',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'DoubleVerify',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Dstillery',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Eletype',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Emerse',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'EnvisionX',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Epom',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Epsilon',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'ExactDrive',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Exit Bee',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Exoclick',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Eyereturn Marketing',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Facebook Business',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Flashtalking',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Fluidads',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Funnel',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'FusionSeven',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Galdly',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Genius Monkey',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Getintent',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Google Marketing Platform',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'GumGum',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Headway',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Imonomy',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Improve Digital',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'IndexExchange',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Infolinks',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'InSkin Media',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Integral Ad Science',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Inuvo',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'iNvolved Media',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'IPONWEB',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Jelli',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Jun Group',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Kind Ads',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Kiosked',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Kwanzoo',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'LiveIntent',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Lucidity',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'MAD Network',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'MadHive',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Madison Logic',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Mamaya',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Marchex',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Match2One',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Matchcraft',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'MaxxRTB',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Mediadonuts',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'MediaMath',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'MediaOcean',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'MediaWide',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Meetrics',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Meredith',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'MetaMarkets',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Midroll',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Mixpo',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Moat',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'My6senese',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Myntelligence',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Nanigans',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Netmining',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Newsroom AI',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Nielsen',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Nominal Techno',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'NYIAX',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'OmniVirt',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'OpenX',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Operative',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Ortec',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Outbrain',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Perfect Audience',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Platform.Io',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'PLYmedia',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'PowerLinks Media',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Propeller Ads',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Pulsepoint',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Quantcast',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Quantic Mind',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Quividi',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Rakuten Advertising',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Reactx',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Realytics',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Rebel AI',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Reklam Store',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Resonate',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'RetargetLinks',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'RevJet',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'RhythmOne',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'RockerBox',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'ROKT',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Roq.Ad',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'RTB House',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'RTB System',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Rubicon Project',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Run',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'SAP',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Scoota',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Semasio',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Semcasting',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Simpli.fi',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Simpli5d',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Sizmek',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'SkyLads',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Smart AdServer',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'SmartyAds',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Sociomantic Labs',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Sojern',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'SolveMedia',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Sonobi',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Sortable',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Sovrn',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Specless',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Spoutable',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'SRAX',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'SteelHouse',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'StickerRide',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Streamlyn',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Strossle',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Stuck AI',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Sublimeskinz',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'SuperAwesome',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Tap',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Targetoo',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Teemo',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Telmar',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Terminus',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Thalamus',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'The Trade Desk',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Thrive',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Thunder',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Tradedoubler',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'TrafficAvenue',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'TrafficJunky',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'tribeOS',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'TrueX',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Tvty',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Ubex',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'UnderdogMedia',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Unidesq',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Valassis',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Ve Global',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Vertoz',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Weborama',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Wigetmedia',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Xandr',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Xaxis',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'XCHNG',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Xertive',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'YellowHammer',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Yieldlab',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Yoc',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'YP',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Zebestof',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Zeta Global',
    category: 'Advertising & Promotion',
  },
  {
    toolName: '3CInteractive',
    category: 'Advertising & Promotion',
  },
  {
    toolName: '140 Proof',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Aarki',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Accengage',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'AdAction Interactive',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'AdAdapted',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Adcenix',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'AdColony',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Adello',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Adelphic',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'AdFalcon by Noqoush',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Adgoji',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Adikteev',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Adjust',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'ADLIB',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'AdLocus',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'adPoPcorn',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'AdSpruce',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'adsquare',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Adtile Technologies',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Adzouk',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Affle',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'AirKast',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Airpush',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Airship',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'AltBeacon',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Altrooz',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'App Samurai',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Applause',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'AppLift',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'AppLovin',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Appnext',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Appreciate',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'AppTweak',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Aquto',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Arena Interactive Oy',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Attentive',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Avazu',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'AvidMobile',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Axonix',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Batch',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Bidsopt',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Branch',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Brandwatch',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'BulkSMS',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Burst SMS',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Button',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'byyd',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Call Loop',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'CallHub',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Celltick',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Chalk Digital',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Cidewalk',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Cisco DNA Spaces',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'CleverTap',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Clickatell',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Clickky',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Cordial',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Coupontools',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'CytechMobile',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Datami',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Deeplink',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Digital Turbine',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Ez Texting',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Factual',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Fastory',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Fiksu',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'FollowAnalytics',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Foursquare',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Fyber',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Gadmobe Interactive',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'GeoMoby',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'GeoPlugin',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'GetSocial',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Gimbal',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Glipsa',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Go2mobi',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Google',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'GroundTruth',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'GrowthPush',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'GupShup',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'HelloWorld',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'HeyStaks',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'HyprMX',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'IGAWorks',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Infobip',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'InMarket',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'InMobi',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Inspired Mobile',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Interceptd',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Intercom',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'iPinYou',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'iZooto',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Jampp',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'JUICE Mobile',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Kargo',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Kochava',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Kontakt.io',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Kwanko',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Landmrk',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'LevelUp',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'LifeStreet Media',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'LIFTOFF',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Linkedin',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Linkmobility',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'LiveRamp',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'IBM SilverPop',
    category: 'Advertising & Promotion',
  },

  {
    toolName: 'Madvertise',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Manage',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Marfeel',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Meatti',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'MediaOptions',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Mediasmart',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Mediaspike',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'mGage',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'MindMe',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Minimob',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'MobFox',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Mobidea',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Mobilda',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Mobile Traffic',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'MobileAction',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'MobileDigital',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'MobileFuse',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'mobileStorm',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Mobilewalla',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Mobilozophy',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Mobiniti',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'MOBIT',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Mobivity',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'MobStac',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Mobusi',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Mobvista',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'MOCA',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'MoEngage Inc.',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Moovweb',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'MoPub',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Mozeo',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Near',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Nend',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'NinthDecimal',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Notificare',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'NotifyFox',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Nuance',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Nuviad',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'OneSignal',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'OnLiquid',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'OpenMarket',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Oplytic',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Opt It',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'ovo',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Oxygen8 Group',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Papaya',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'PayTunes',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Pendo',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Perform[cb]',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Perion',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Permodo',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'PlaceIQ',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'PocketHive',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Pocketmath',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'POKKT',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Prioridata',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Privy',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'ProTexting',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Proximity Kit',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Publicators',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'PubNative',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Pulsate',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Pushed',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'PushMonkey',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Pushnami',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'PushPrime',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Pushpro',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'PushSpring',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'PushWizard',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'PushWoosh',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Radar',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Radius Networks',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Rain',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Relatia',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Remerge',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Repro',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Revx',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Rover',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Rungopher',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'S4M',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Sabio Mobile',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Sailthru',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Sapho',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Scanova',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'SessionM',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'ShallWeAd',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Shelfbucks',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'shopkick',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'signal360',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'SimpleTexting',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Sinch',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Sito Mobile',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Skyhook',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'SlickText',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Smaato',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Smadex',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'SMS Workflow',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Splicky',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'StartApp',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'StreetHawk',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'SUMOTEXT',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Swrve',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Syniverse',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Tabatoo',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Tamoco',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Tapad',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Tapjoy',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Taplytics',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Tappx',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Taptica',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Tatango',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Teckst',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Tenjin',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Textburst',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Texting Base',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Textlocal',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'TextMagic',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'TextMarks',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'ThinkGaming',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Thumbvista',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'TNK',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'TreSensa',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'TrialPay',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Trumpia',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Tune',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Twilio',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'TXT180',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Txtimpact',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'TxtSync',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'UberAds',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'UberMedia',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Ubimo',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Upland Software',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Upstream',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Velti',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Verve',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Vibes',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Vistar Media',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Weq',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Widespace',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'WompMobile',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'WonderPush',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Xtremepush',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Yahoo!',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Yieldmo',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'YOOSE',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'YouAppi',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Zaius',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Adblade',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Adnow',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Advault',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'AdYouLike',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Adzerk',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Anstrex',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Bidmotion',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Bidtellect',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Brax',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'BuzzFeed',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Buzzoola',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Chameleon Ad',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Cision',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Connatix',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Content.Ad',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'dianomi',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Digg',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Disqus',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'DistroScale',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Dpgmedia.be',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Earnify',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'illuma',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Instinctive',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'LockerDome',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Makemereach',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'MGID',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Mirriad',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'MobSoc',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Native Ad Buzz',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Native Ads',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'NativeX',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Nativo',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Nudge',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'OneSpot',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Opt Intelligence',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'plista',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Polar',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'PowerInbox',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Powerreviews',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Powerspace',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Pressboard',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Pub Ocean',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'PubMatic',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'QChain',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'RapidFire',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Reactor Media',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'ReadPeak',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Real Content Network',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'reddit',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'redirect.com',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Revcontent',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Shareaholic',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Sharethrough',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Stack Overflow',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'StackAdapt',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Storygize',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'StreamSpread',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'StumbleUpon',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Tabmo',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Taboola',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Tapstone',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'TripleLift',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Vertebrae',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Vibrant Media',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'XXLContent',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'YeahMobi',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Zemanta',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'ZergNet',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Zumobi',
    category: 'Advertising & Promotion',
  },
  {
    toolName: '3BL Media',
    category: 'Advertising & Promotion',
  },
  {
    toolName: '24-7 Press Release',
    category: 'Advertising & Promotion',
  },
  {
    toolName: '1888 Press Release',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Ace Media',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'AgilityPR',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Answer the Public',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Burrellesluce',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Business Wire',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'BuzzStream',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Coverage Books',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Critical Mention',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'CustomScoop',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'eReleases',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'GlobeNewswire',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'GroupHigh',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'iPRsoftware',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'iQ Media',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'ISEBOX',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'JustReachOut',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Lianapress.de',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Meltwater',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Metro Monitor',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Muck Rack',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Mustr',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'MyMediaRoom',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'MyNewsdesk',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Nasdaq',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'NewsBox',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Notified',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Onclusive',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'OnePitch',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'OpenPR',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Pitchbox',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'PitchEngine',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'PR Log',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'PR Newswire',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'pr.co',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'PR.com',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Pressat',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Pressfriendly',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Pressrush',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Prezly',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'PRFire',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'PRgloo',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'PRmax',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'PRnews.IO',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Prowly',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'PRunderground',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Release Wire',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Seedepth',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Signal',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Smart Pr',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'supernewsroom',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'The Media Vantage',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'TrendKite',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Ubermetrics',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Vuelio',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Wiztopic',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Zignal Labs',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Adway',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Alyce',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'AmazingMail',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'AXOMO',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Blurb',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Bond',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Cafe Press',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'DirectMailManager',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'eLynxx Solutions',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Enthusem',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Formax',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Gelato',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Gooten',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'GotPrint',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Grow Mail',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Hello Market',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'HH Global',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Inkit',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'KingsLine',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'LKCS',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Lob',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Lucidpress',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'MarcomCentral',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Mimeo',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'MOO',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Next Day Flyers',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Optilyz',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Ordant',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Poplar',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Postalytics',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Postie',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'PostPilot',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Priint',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Printfection',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'PrintingForLess',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Printix',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'PrintSF',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'PrintUI',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'PsPrint',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Reachdesk',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Scribeless',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Sculpteo',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Taradel',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Thankster',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Thnks',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Tinkercad',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'UPrinting',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Vbs',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'VeraCore',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'VistaPrint',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'vpress',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'XMPie',
    category: 'Advertising & Promotion',
  },
  {
    toolName: '4C',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Acquisio',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Adboozter',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'AdChieve',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'AdCore',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'AdEspresso',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'AdGooroo',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'adHawk',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Adhook.io',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Adloox',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Adlucent',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'AdNabu',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'AdParlor',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Adscale',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Adsmurai',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Adspert',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'AdStage',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Adthena',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Adtuo',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Advocado',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'AdWords Robot',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Ahalogy',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Algomizer',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Alytag',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Ampush',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Aori',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Apex Pacific',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Appbi',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Baidu',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Balihoo',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Bidalgo',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Bing Ads',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Brand Networks',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'BrandPlug',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Brandverity',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Catvertiser',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'cClearly',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Click Cease',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Click Guardian',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Cobiro',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Cyberclick.Es',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Danads',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'DeltaX',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Dolead',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Driftrock',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Evocalize',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Experian',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Facebook',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Facelift',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'FastTony.es',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Finch',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Flightly',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'FROGO AD',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Goa',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Google Ads',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'GPlugin',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Hootsuite',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Instagram',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Instaon',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Intelliad',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'iSpionage',
    category: 'Advertising & Promotion',
  },
  {
    toolName: "Kamp'N",
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Kenshoo',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Keywee',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Kliken',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Kongalytics',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Kontentino',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Lightning AI',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'LookSmart',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Marin Software',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'MomentFeed',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Multiplier Solutions',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Nanos',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'needls.',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'NetElixir',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Never',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'NXAD',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Opteo',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Pagemodo',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Pattern89',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Peadler',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Percept',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Pinterest',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Polygraph',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Popular Pays',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'PPC Protect',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Prettysocial Media',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'ProTop',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Quarizmi',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Quora',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Qwaya',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Recommendme',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'ReFUEL4',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Reputation.com',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Reveal Bot',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Rhombus',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Rikibo',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'RocketROI',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'ROIHunter',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Sapien',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Search Experiences',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Search Force',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Sether',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Shape.io',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Sidecar',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Smarter eCommerce',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Smartly.io',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Snapchat',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Social-Clicks',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'SocialCode',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Socialdealer',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Socialease',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'SocialFlow',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Soko.ai',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'SpeedPPC',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Sprinklr',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Squared.io',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Steerfox',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'StitcherAds',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'StrikeSocial',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'SunnyReports',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Swoop',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Swydo',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Targeto',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Tenscores',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'The Next Ad',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Tiger Pistol',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Topvisor',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'TrueClicks',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'TrustAds',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Twitter',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Uptmz',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Verizon Media',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'WordStream',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Xamine',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Yandex',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Yelp',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Zalster',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'AdGreetz',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Adlaunch',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'AdPoint Video',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'adRise',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'advids.co',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Answer Media',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Beachfront Media',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Bidtheatre',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Bluebillywig',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Brandbee',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Brandzooka',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'brid.tv',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Cadent Network',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Chocolate',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'clypd',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Eyeview',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'FreeWheel',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'FuelX',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Good-Loop',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Gruuv Interactive',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Inform',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Innovid',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'iPowow',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Jivox',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'JW Player',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Liquidus',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'LoopMe',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Panopto',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Pixability',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Roku',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Savvy',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Seenthis',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Sightly',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Simulmedia',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Smartclip',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Smartzer',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'SnapStudioPlus',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Somoto',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Spirable',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'SpotX',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Stream RailŒåäóæ',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Streamads',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Teads',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Telaria',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Thirdpresence',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Touchstorm',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'TVSquared',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Undertone',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Unruly',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Verasity',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Viddyad',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'VidsyHQ',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Viewbix',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'ViralGains',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Virool',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Visible World',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Vungle',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'We Adapt',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'WideOrbit',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'YouTube',
    category: 'Advertising & Promotion',
  },
  {
    toolName: 'Zlyde',
    category: 'Advertising & Promotion',
  },
  {
    toolName: '2checkout',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Adledge',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Adtraction',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Salesforce CRM',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Adobe Cloud',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Adobe Commerce',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Affi.io',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Affilae',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'AffiliateBuzz',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Affiliated.io',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Attrace',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Avantlink',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'awin',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Beyond Affiliate',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'boaelite',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Cake',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'CJ Affiliate',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Clickbank',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Clickinc',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'clixGalore',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Cloudfind',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Cydec',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Daisycon',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Digidip',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'digitalriver',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'eBay',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Everflow',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'EvoLeads',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'FMTC',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'FuseClick',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Google',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Hitpath',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'HOQU',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'iDevAffiliate',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Impact',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Jrox',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Kwanko',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Leaddyno',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Linkconnector',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Linktrust',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Linkwise',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Martechtracker',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'MaxBounty',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Mobidea',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'MyLead',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Netrefer',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Offerit',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'OmniSTAR',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Orangear',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'PayDotCom',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'PayKickstart',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Perform[cb]',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'persuasionworks',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Postaffiliatepro',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'PubExcahange',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Publisher Discovery',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Quality Unit',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'RedTrack.io',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'RefereumŒåäóæ',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'RefToken',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'RevenueWire',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Rewardful',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Scaleo',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Shareasale',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'SkimLinks',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'SocialBug',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Strackr',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Tapfiliate',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Target Circle',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Terminus',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Thrivemarket',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'thrivetracker',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Trackier',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Trackingdesk',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Tradedoubler',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Tradepoint',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'TradeTracker.com',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Trust Metrics',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'TUNE',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Webgains',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Wecantrack',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'WP Affiliate Manager',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Yieldkit',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Adam Continuity',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Adobe',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Affise',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Agiliron',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Allbound',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Ansira',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Aprimo',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Averetek',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Balihoo',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Blackhawk Network',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Bonial',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Brand Machine',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Brandify',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'BrandMaker',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Brandmuscle',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Bridgeline Digital',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Broadridge',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'CallidusCloud',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'CampaignDrive',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Chainsync',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Channel Konnect',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Channel Partners Online',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'ChannelAdvisor',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Channelassist',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Channeleyes',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'ChannelNet',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'ChannelPilot',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Channelsight',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Channeltivity',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Channext',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Chatmeter',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Cognizant',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Computer Market Research',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Datasphere',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Distribion',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'E2open',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Earthlink',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Edenred',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Eggzack',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Eloqua B2C Marketing',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Eloqua B2B Marketing',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Eloqua Fusion Marketing',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Eloqua Marketing Automation',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Eloqua Responsys Campaign Management',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Eloqua CrowdTwist Loyalty and Engagement',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Eloqua Bluekai Data Management Platform',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Eloqua Audience Segmentation',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Eloqua Unity Customer Data Platform',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Eloqua Infinity Behavioral Intelligence',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Eloqua Maxymiser Testing and Optimization',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'EPAM',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Expandly',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'FetchRev',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Fision',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Focused Impressions',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'FreeWheel',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'GeoRanker',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Gorilla Corporation',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Gorilla Toolz',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Heraldlogic',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'HP',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'IBM',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'ignitetech',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Impartner',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Insequent',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Itagroup',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Leadmaximizer',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'LeadMethod',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Localize',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Logicbay',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Magentrix',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Main Street Hub',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'MarcomCentral',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Mindmatrix',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'MRP',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Naranga',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Netsertive',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'NetSuite',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Nitromojo',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'NowApps Technologies',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Numerator',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Ods2',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Openprisetech',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'OwnLocal',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Pageflex',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Partnermarketing.com',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'PartnerPath',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'PartnerStack',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Prmdeals',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Promio',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Reach',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Reputation.com',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Ringcentral',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Salesforce Email Studio',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Salesforce Journey Builder',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Salesforce Interaction Studio',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Salesforce CDP',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Salesforce Commerce B2B',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Salesforce Pardot',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Salesforce Datorama',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Salesforce Advertising Studio',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Salesforce Mobile Studio',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Salesforce Content Management',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Salesforce Google Marketing Platform',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Salesforce Loyalty Management',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Salesforce Datorama Reports',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Seaters',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'SeedLogix',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Socialrep',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'SocioDigital Analytics Company',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'SproutLoud',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Strata company',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Structuredweb',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Surefire Local',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'SweetIQ',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'SYNQY',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Synup',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'TasteFinder',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Taylor Communications',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'TIEKinetix',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Uberall',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Visiperf',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Vya Systems',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Web.com',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Webinfinity',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Zift Solutions',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Zinfi',
    category: 'Commerce & Sales',
  },
  {
    toolName: '4-Tell',
    category: 'Commerce & Sales',
  },
  {
    toolName: '8Select',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'acobot',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Adabra',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Adaplo',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Addshoppers',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'AdRoll',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'AdvantageCS',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Agility multichannel',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Aitoc',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'AiTrillion',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Akeneo',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Algolia',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Alkemics',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Altitude Labs',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Amasty',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Antavo',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Apptus',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Apruve',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Aria Systems',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'askuity',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Attraqt',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Augment',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Barilliance',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Bask.yt',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Beeketing',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'BGenius',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Blue Yonder',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'blueknow',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'BoostMySale',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Brainsins',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Brightpearl',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Bronto',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Build WooFunnels',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'CartStack',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Channable',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Channel Pilot Solutions',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Channelengine',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'channelsale',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Chatchamp',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'ciValue',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Clarity eCommerce',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Clerk.io',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Clic2Buy',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'clicksco',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Cloud Commerce',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Cloudconversion',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'cloudIQ',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'CloudSuite',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'clutch',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'CM Commerce',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Coffeecup',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Coherent Path',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Connectif',
    category: 'Commerce & Sales',
  },
  // {
  //   toolName: 'Constant',
  //   category: 'Commerce & Sales',
  // },
  {
    toolName: 'Crealytics',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Crobox',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Darwin Pricing',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'datacrushers',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'DataFeedWatch',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Datafeedwatch.nl',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'DCatalog',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'DemoUp',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Dezdy',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Divvit',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Downstream',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Drupal Commerce',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Early Birds',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Easyads.Eu',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'EasyAsk eCommerce',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Edge by Ascential',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Elastic',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Enalito',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'eShopsGalore',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Etailinsights',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Etsy Marketing Tool',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Fact-Finder',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Fanplayr',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Feedonomics',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Feedvisor',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'findify',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Firecart',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Firepush',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Flintanalytics',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'fluid',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Fomo',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Fresh Relevance',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Glew',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'GoDataFeed',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Gradient.io',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Granify',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Groupby',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Hatch',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Hawk Search',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Heraldbee',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'HikaShop',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Ideoclick',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Inbenta',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'inferlytics',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Intent',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Interspire',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'jumper',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Kartra',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'kelkoogroup',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Kibo',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Klaviyo',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Klevu Oy',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Knetik',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'LeadHub',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Lengow',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Liftoff E-Commerce',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Linguastat',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Listrak',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'loop54',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'loyaltybuilders',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'MageMail',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Mercatus Digital Solutions for Grocery',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Metrilo',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'mi9retail',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Mindbody',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Mmuze',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Mokini',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'MorphL AI',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Namogoo',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Needle',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Netivity',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Nextopia',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'olobolo',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Ometria',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'OnlineSales.ai',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'OrderGroove',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'OrderMetrics',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Partial.ly',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Pathfinder',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'PaySimple',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Perfion',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Photoslurp',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Pinpoint Predictive',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Pleisty',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Prediggo',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Prisync',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Productcaster',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Productimize',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'ProductsUp',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Proof Factor',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'PRZM',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'PureClarity',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Qixol',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'QuarticON',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'QuickeSelling',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Rakuten Intelligence',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Recapture',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Recart',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Recheckit',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Recurly',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Rees46',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Reflektion',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Rejoiner',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Remarkety',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Retailops',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'RetailRocket',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'ReTargeter',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Retention Rocket',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Retentiongrid',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'RichRelevance',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Rubix3',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'SaleCycle',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Salsify',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'SAP',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Scalefast',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'SearchSpring',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Segments',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Sellable',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Sellbrite',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'selleractive',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'SellerApp',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Sellercloud',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'sellerlabs',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Sello',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Shoelace',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Shogun',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'shopgate',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Shoppad',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Sidecar',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Sitelucent',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'skuiq',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Smarterclick',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Smoolis',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'SMSBump',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Snap & buy!',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'SnapRetail',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'SocialShopWave',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Socital',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Sokrati',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Solid Commerce',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Sparkfly',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Spently',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Springbot',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Springengage',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Staqu',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Stitch Fix',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'storenvy',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'StoreYa',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Strands',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Styla',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Subbly',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Swifterm',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Syndigo',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'taggstar',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'TargetClose',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Tgn Solutions International Gmbh',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Tinyclues',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Tobi',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Toonimo',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'TouchCR',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Trbo Gmbh',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'TrustedSite',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Twenga Solutions',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'TxtCart',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Unbxd',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Uncoil',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Upodi',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'upsellit',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Vendevor',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Vendiro',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Vextras',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Visualsoft eCommerce',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Vleks',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Voucherify',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'W3bstore.com',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'webgility',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Webinterpret',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'WebJaguar ECommerce',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'WedgeCommerce',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Wemark',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Whoop!',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Wisepops',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Wiser',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Wix',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'xsellco',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'YFret',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Yotpo',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Yusp',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Zangerine',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'zentail',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Zepo ecommerce',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'ZINation',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Zonos',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Zoovu',
    category: 'Commerce & Sales',
  },
  {
    toolName: '1ShoppingCart',
    category: 'Commerce & Sales',
  },
  {
    toolName: '3dcart',
    category: 'Commerce & Sales',
  },
  {
    toolName: '24nettbutikk',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'AbanteCart',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Ability Commerce',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'AbleCommerce',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Acadaca',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Acquia',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Advansys',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Agenne',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Alibaba',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Amazon',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'AmeriCommerce',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'AppJetty',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Apptuse',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Aptean',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Aptos',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Aptusshop',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Arastta',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Askas',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'AspDotNetStorefront',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Atlas',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Atomstore',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Attane',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'BigCommerce',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Bluecore',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'bluesnap',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Bolt',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Brand Retailers',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'BrandedOnline',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'broadleafcommerce',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'buildabazaar',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Capillary',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'CardinalCommerce',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Cart2Cart',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Cartface',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Cartfunnel',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'CartHook Checkout',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Cartin',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Cartloom',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Carts Guru',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Celery',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'ChannelApe',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Chargify',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Cleverbridge',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Click2Sync',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Clover Shop',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'CommerceHub',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'commercetools',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'CommerceV3',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Configio',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Connected Business',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Contalog',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'ContentShelf',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'CoreCommerce',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'coresense',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'CS-Cart',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Cstore',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Customer Focus',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Cygneto Apps For Ordering',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Digital River',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'E-merchant',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'EasyStore',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Ecmanager',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'ECOM 2.0',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'eComchain',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Ecwid',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Effectconnect',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'EKM',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Elastic Path',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Elcodi',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Elementh',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'enVista',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'ePages',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Episerver',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Erply',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Estoreify',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Etsy',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'fastspring',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'FinestShops',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Finqu',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Flog mall',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Fortune3',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Four51',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Foxy',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Fujitsu',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Genesis Digital',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Global e',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'GoECart',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'HotWax Commerce Platform',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'I-Systems',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Icatalogue Gmbh',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'IceShop',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'IdoSell',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'iMarketVend',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Insite Software',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Intershop',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Izberg',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Jazva',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Jimdo',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Jumpseller',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'k-eCommerce',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'KalioCommerce',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Kentico',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Kooomo',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'LemonStand',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Lianacommerce.de',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'LightSpeed',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Litextension',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Litium',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'LockTrip',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'LSNetx',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Mad Mimi',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Magento',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Merchium',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Mirakl Marketplace Platform',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Miva',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Mobify',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'MobilityeCommerce',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'moltin',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Monsoon Inc',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Moovweb',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Mycashflow',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'MyCommerce',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'MyStore',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Name Bazaar',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Narvar',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'nChannel',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Neto',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Nextchapter',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Nexway',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'NimbleCommerce',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'nopcommerce',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'NuggetWeb',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'NuORDER',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Oorjit',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'OpenBazaar',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Openbravo',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'OpenCart',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Optilead',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'optimizely',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Oracle',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Orckestra',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Orderingpages',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Origami',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Origin',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'OroCommerce',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Oscar Software',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'osCommerce',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Oxatis',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Oxid',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Paddle',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Payever',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'PayPro Global',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Pepperi',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Pinnacle Cart',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'PlentyMarkets',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'plug&paid',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Poqcommerce',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Postcart',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'PrestaShop',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'ProductCart',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Proximis',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Purse',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Reaction Commerce',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Redcart',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'retail.global',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'ROC Commerce',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Rocketr',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Sales & Orders',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Sana',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Scan & Shop',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'SearchFit',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Second Phase',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Sellfy',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Selly',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'SendOwl',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Sharetribe',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Shiprocket 360',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'shopaccino',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'shopcloud',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Shopery',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'ShopFactory',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Shopify',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Shopio',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Shopline',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Shoplo',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Shopnix',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Shopping Cart Elite',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'shoprocket',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'ShopSite',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'ShopSocially',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Shoptet',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Shopware',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'SHUUP',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Skava',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Skubana',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Sky-Shop',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Slatwall Commerce',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Smart Trip Platform',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'SmartWeb',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Snipcart',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Soapbox Engage',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'SoftwareProjects',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Solid Cactus',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Solidus',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Speed Commerce',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Spryker',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Squarespace',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Storbie',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'STORM',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Storm Commerce',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Supr',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'SureDone',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Sylius',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Targetbay',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'ThriveCart',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Tipser',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Transactionale',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'TrueCommerce',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'uCommerce',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'UltraCart',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Unilog',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Upclick',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Vendio',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Virid',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Virto Commerce',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Volusion',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'VTEX',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'weblinc',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Webnexs',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Weebly',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Whirlwind eCommerce',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'WooCommerce',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Workarea',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Workit-Software',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'WP EasyCart',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'X-Cart',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'x2x eCommerce',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'xt:Commerce',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Zentail Commerce',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Ziqy',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Znode',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Zoey',
    category: 'Commerce & Sales',
  },
  {
    toolName: '11antsanalytics',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Accurat',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Admove',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Adszy',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Agosto',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Airspace',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Aisle411',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Aislelabs',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Analyse²',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Arduino',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Arreya',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Aura Vision',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'AutoPOS',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Beaconic',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Beaconstac',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Blis',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'bluedot',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Celerant',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Channelplay',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Cisco',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Cloud4wi',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Collectec',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Commerce Tools',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Convious',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'CrowdOptic',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Custocentrix',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Custora',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Daisyintel',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Dor',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Ecrs',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'emnos',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Empyr',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Enplug',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Epicor',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'eSellerHub',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Estimote',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Eversight',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'EVRYTHNG',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Flame Analytics',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Fysical',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Geospock',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Getpin',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Gimbal',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'GoNow',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Hivestack',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'HTK',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Huq',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Hygh Tech',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Idealspot',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'InContext Solutions',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Indexhr',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Infor',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Inmar',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'InMarket',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Insideretail',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Intel',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Intelligence Node',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'intenseye',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Label Insight',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Leeroy',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Lightspeedhq',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Localistico',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Location Sciences',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Locomizer',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Magic Add',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Manhattan Associates',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Manthan',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Market6',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Marketingdonut',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Meltspot',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Mi9 retail',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Miipharos',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Mira',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Mobee',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Mobilozophy',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'NewStore',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Nucleus.vision',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Nurama',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'NXTclick',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Offrie',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Omnia',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Onyx Beacon',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Openbravo Commerce Suite',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Pangearetail',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Percolata',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Phy',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Pinmeto',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Placebag',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Placer.ai',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Plot Projects',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Point Inside',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Popdeem',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'PoweredLocal',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Qr Code Creator',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Quicon',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Radius Networks',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Retail Next',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Retailpro',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Retailsonar',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'retailwire',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Revionics',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'rewango',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'SalesWarp',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Scala',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Sensape',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Sensorberg',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Sentiance',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Shazam',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Shelf bucks',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'ShopperTrack',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Sigfox',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Smartwhere',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Social retail',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Solink',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Sparkfun',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'SPS Commerce Analytics',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Stentle',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'StoreHippo',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Strategic Pricing Solutions',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Streamretail',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Streetbees',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Tamoco',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Trax',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Tulip',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Unacast',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Upper Quandrant',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Upstream Commerce',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Vend',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Verve',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Vision',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'VL OMNI',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Walkbase',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Weblib',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Wirespring',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Yelp WiFi',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Yext',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Yodiwo',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Yuzu',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Zoniz',
    category: 'Commerce & Sales',
  },
  {
    toolName: '1sales',
    category: 'Commerce & Sales',
  },
  {
    toolName: '2VizCon Sales',
    category: 'Commerce & Sales',
  },
  {
    toolName: '3D City',
    category: 'Commerce & Sales',
  },
  {
    toolName: '19th Mile',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'AbsoluteData',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Accent',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Accompany',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Acuity3D',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'ADMIRAL',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Adtelligence',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'ADvendio',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Agent3',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Altify',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'amoCRM',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Amplemarket',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'AnalysisPlace',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Apparound',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Apttus',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Aquafadas',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Arpedio',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Art of Emails',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Asset Sonar',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'atEvent',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Atlatl software',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Attach',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Attentive.us',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Autoklose',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Avention',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'AVISO',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'aWorkbook',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Azurepath',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'B-Reputation',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Badger Maps',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Balto',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Bambu',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Bananatag',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Better Proposals',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Bigtincan',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'BizKonnect',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Black Ink',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Bloobirds',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Bloomfire',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'BlueprintCPQ',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'BombBomb',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Bombora',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'BoothCrawler',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'BounceHelp',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Boxxstep BRM',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Brainshark',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Brillio',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'bugle',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Bureau van Dijk',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Buyerdeck',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'BuzzBoard',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'BuzzBuilder',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'CamCard',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Channel Rocket',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Charlie',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Chili Piper',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Chorus',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Cien',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Cincom Systems',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'CircleBack',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Cirrus Insight',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Cision',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Clari',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Clarin',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Clearbit',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'ClearSlide',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Click2Contract',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'ClickBack',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Cliently',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'ClientPoint',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Cliffox',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'ClinchPad',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'close.com',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Closeplan Suite',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'CloudApps',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Cloze',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Cognism',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Colabo',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Collective[i]',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Commerce.AI',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Commercial Tribe',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Commissionly',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Conclusive Analytics',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Conga',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'ConnectAndSell',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Connecteam',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Connector',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'ConnectWise',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Consensus',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Contactually',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Contify',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Contiq',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Converfit',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Conversica',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Costello',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'CrankWheel',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'CrazyCall',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Crunchbase',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'CrystalKnows',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'CustomShow',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Cybermiles',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'd!NK',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Data Genie',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Datadwell',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Datahug',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Datalovers',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Datananas',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Datanyze',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'DealCloud',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'DealCoachPro',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Dealhub',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'DealPoint.io',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'DealSignal',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Decisionlink',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'DemandFarm',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Detective',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'DialSource',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'DiscoverOrg',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'DocSend',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Docsify',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'DocuSign',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Dooly',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Drift',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'DueDil',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Dun & Bradstreet',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Duuers',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'eatNgage',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Ebsta',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'eCairn',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Echobot',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Ecos',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'eGrabber',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Email Hunter',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'emaze',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'encaptiv',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'EndeavorCPQ',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'engage',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Enhatch',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'eSpatial',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Evercontact',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'EverString',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'ExecVision',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'eyecall',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'FieldSalesPro',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'FindEmails',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Five9',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Follow Up Pal',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Folloze',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'ForceManager',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Found',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'FoxBound',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'FPX',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Frontline Selling',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Fullcast',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'FullContact',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'FunnelCake',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'FunnelSource, Inc',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Fusemachines',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'GetAccept',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'GetProspect',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Gong.io',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Groove Labs',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Growbots',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Gryphon',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Guru',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Handshake',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Hellosign (a Dropbox Company)',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'HG Insights',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Highspot',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'HireVue',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Hoopla',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Hubsell',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'HubSpot Marketing Hub',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'HubSpot Sales Hub',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'HubSpot Marketing Hub',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'HubSpot Marketing Hub',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'HubSpot Marketing Hub',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Hushly',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'iDetail',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'If No Reply',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'IKO System',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Illumineto',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Implisense',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Inboundli',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Infofree',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Infogamy',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Infolio',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Infonyze',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'InsightSquared',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Insite',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Integrate',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Intelisale',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Intelliquip',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Intelliverse',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Interseller',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Intricately',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Introhive',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Inzant',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'iPresent',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'IQ',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Jifflenow',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'jLAN Mobile',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Joomag',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Journey Sales',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'KBMax',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Kinetizine',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Kixie',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Klenty',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Klozers',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Klue',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Knowland',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Lead Assign',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Lead Forensics',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'LeadBoxer',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Leadexpress',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'LeadFuze',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'LeadGnome',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Leadinfo',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'LeadIQ',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Leadspace',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'LeadWorx',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'LeanData',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Lemlist',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Lessonly',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'LevelEleven',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'LevelJump Software',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Liid',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Lime Go',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'LinkedIn',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Loopio',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Lucep',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Lucidpress',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Lusha',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Lynkos',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'MagneticOne (by MagneticOne Mobile Works)',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Mailparser.io',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'MailShake',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'MapAnything',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Adobe Marketo Engage',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Matrix Solutions',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Mediafly',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'MediaRadar',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Membrain',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Microsoft',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'MindTickle',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'MixRank',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'MobileForce',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Model N',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Modus',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'MonsterConnect',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'MyAlerts',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Myphoner',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'N2Hive',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'NCSquared',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'NetCommissions',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'netFactor',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Nimble',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'NOMALYS',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Nomination',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Nova.ai',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Oceanos',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'OneMob',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Onit',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Onsight',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Ontrack Workflow',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Opphound',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'OppScience',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'OptifiNow',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Optymyze',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'OpusViz',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'OrgChartHub (by CabinetM)',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Outreach',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Owler',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'OxyLeads',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'PandaDoc',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Paperflite',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'People.ai',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'PerformYard',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'PersistIQ',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Personali',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'PhoneBurner',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'PipeCandy',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Pipedrive',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Pipeliner CRM',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Pitch.Link',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'PitchBook',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Pitcher',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Pobuca',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Point N Time',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Polis',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Populr',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Postwire',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Pounce',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'PredictiveOps',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'PredictLeads',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Prezentor',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Prezentt',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Prezi',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Pricefx',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'PriceGrid',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Prolifiq',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Proposable',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Proposify',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Pros',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Prospect.io',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Prospectin',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Prospecting.io',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Qollabi',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Qstream',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Quark',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'QuarterOne',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'QuickMail',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Quoteonsite',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'QuoteWerks',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Qwilr',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Qymatix',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Radium CRM',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'RampedUp.io',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Reachable',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'RealZips',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Refract',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Regalix',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'ReIntent',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'RelPro',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Render',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Repignite',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Reply',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Repsly',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'ResponsePoint',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Revegy',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Revenue Grid',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'RFPIO',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'RingDNA',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'RingLead',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Rock Content',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Rocketreach',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'S&P Global',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Sage',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'SAP Commerce Cloud',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'SAP Data Hug',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Sales Joe',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Sales Rabbit',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Sales-i',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Sales-Push',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Sales.Rocks',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Salesbox',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'SalesChoice',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'SaleScout',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'SalesEdge',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Salesfeed',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Salesframe',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'SalesFuel',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Salesfully',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Salesgenie',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Saleshandy',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Saleshood',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Salesify',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'SalesLoft',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'SALESmanago',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Salesmsg',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Salesninja',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'SalesOptimize',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'SalesPad',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'SalesScreen',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'SalesTing',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'SalesVisual',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Salesvue',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Saleswhale',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Salezeo',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'SCEPOS',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Scribe',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Seidat',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Seismic',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Sellf',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'SellHack',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Selling To Zebras',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'SendYourMedia',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Sentrana',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'servicePath',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Showcase',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Showell - Sales Enablement Platform',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Showpad',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'SimilarTech',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Sisense',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'SKURA',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'SmarterMe',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'SmartReach',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'SMSZap (by LyntonWeb)',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'SoAmpli',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Sofon',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Spiro',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Spyglaz',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Stirista',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'StoryPad',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Sudo',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'SuMo',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Sweet Show',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Swipe.to',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Switchit',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Synthio',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Tact.ai',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Tacton',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Talkdesk',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Talloo',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Teamgate',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Teamgram',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'TellWise',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Tenfold',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Terapeak',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'ThatsNice',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'The ROI Shop',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'The Sales Developers',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Threads',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Tilkee',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Toolyt',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'TopOPPS',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Tracks',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Trade Force',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'TrepScore',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Troops',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'TrustSphere',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Tubular',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Tubular.io',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Unomy',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Upland',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'UpLead',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Vablet',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Vainu',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Valgen',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Validity',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Valkre',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'VanillaSoft',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Veact',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Veelo',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Velocify',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Veloxy',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'VeriShow',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Vidyard',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Vingle',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'VipeCloud',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'VisibleThread',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Visione Scan',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'VisitBasis',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Visitor Queue',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Vistex',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'VisualVisitor',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'VoiceOps',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Voogy',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Vouchercart',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Vsnap',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Vymo',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Wem',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Whoisvisiting',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'WideAngle',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Winmo',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Wiredminds',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Woodpecker',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'WorkRamp',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Xactly',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'XANT',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Xeerpa',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Xinn',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Yesware',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'ZaapIT',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Zensed',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Zero Keyboard',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'zilliant',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Zoho',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Zoomforth',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'Zoomifier',
    category: 'Commerce & Sales',
  },
  {
    toolName: 'ZoomInfo',
    category: 'Commerce & Sales',
  },
  {
    toolName: '8-Point Arc',
    category: 'Content & Experience',
  },
  {
    toolName: 'Acrolinx',
    category: 'Content & Experience',
  },
  {
    toolName: 'AddThis',
    category: 'Content & Experience',
  },
  {
    toolName: 'Adioma',
    category: 'Content & Experience',
  },
  {
    toolName: 'Adobe',
    category: 'Content & Experience',
  },
  {
    toolName: 'Salesforce CRM',
    category: 'Content & Experience',
  },
  {
    toolName: 'Adobe Cloud',
    category: 'Content & Experience',
  },
  {
    toolName: 'Adobe Campaign',
    category: 'Content & Experience',
  },
  {
    toolName: 'Adobe Experience Manager',
    category: 'Content & Experience',
  },
  {
    toolName: 'Adobe InDesign',
    category: 'Content & Experience',
  },
  {
    toolName: 'Adobe Illustrator',
    category: 'Content & Experience',
  },
  {
    toolName: 'Adobe Photoshop',
    category: 'Content & Experience',
  },
  {
    toolName: 'Advantage CSP',
    category: 'Content & Experience',
  },
  {
    toolName: 'Adzis',
    category: 'Content & Experience',
  },
  {
    toolName: 'Airim',
    category: 'Content & Experience',
  },
  {
    toolName: 'Amplience',
    category: 'Content & Experience',
  },
  {
    toolName: 'Answerbase',
    category: 'Content & Experience',
  },
  {
    toolName: 'Arkadium',
    category: 'Content & Experience',
  },
  {
    toolName: 'Atomic Reach',
    category: 'Content & Experience',
  },

  {
    toolName: 'Attane',
    category: 'Content & Experience',
  },

  {
    toolName: 'Auphonic',
    category: 'Content & Experience',
  },
  {
    toolName: 'AutomatedInsights',
    category: 'Content & Experience',
  },
  {
    toolName: 'Backly',
    category: 'Content & Experience',
  },
  {
    toolName: 'Banners Mall',
    category: 'Content & Experience',
  },
  {
    toolName: 'Beacon',
    category: 'Content & Experience',
  },
  {
    toolName: 'Blaze',
    category: 'Content & Experience',
  },
  {
    toolName: 'Blog.Filestage.io',
    category: 'Content & Experience',
  },
  {
    toolName: 'Brandpoint',
    category: 'Content & Experience',
  },
  {
    toolName: 'Brojure',
    category: 'Content & Experience',
  },
  {
    toolName: 'Buzzsprout',
    category: 'Content & Experience',
  },
  {
    toolName: 'BuzzSumo',
    category: 'Content & Experience',
  },
  {
    toolName: 'Canva',
    category: 'Content & Experience',
  },
  {
    toolName: 'Categorical Technology',
    category: 'Content & Experience',
  },
  {
    toolName: 'Celum',
    category: 'Content & Experience',
  },
  {
    toolName: 'Ceralytics',
    category: 'Content & Experience',
  },
  {
    toolName: 'Clearscope',
    category: 'Content & Experience',
  },
  {
    toolName: 'ClearVoice',
    category: 'Content & Experience',
  },
  {
    toolName: 'CliClap',
    category: 'Content & Experience',
  },
  {
    toolName: 'Cloudwords',
    category: 'Content & Experience',
  },
  {
    toolName: 'Communiqu??',
    category: 'Content & Experience',
  },

  {
    toolName: 'CONCURED',
    category: 'Content & Experience',
  },
  {
    toolName: 'Conductor',
    category: 'Content & Experience',
  },
  {
    toolName: 'Content Launch',
    category: 'Content & Experience',
  },
  {
    toolName: 'Content Snare',
    category: 'Content & Experience',
  },
  {
    toolName: 'contentbird',
    category: 'Content & Experience',
  },
  {
    toolName: 'ContentFleet',
    category: 'Content & Experience',
  },
  {
    toolName: 'ContentFly',
    category: 'Content & Experience',
  },
  {
    toolName: 'ContentGems',
    category: 'Content & Experience',
  },
  {
    toolName: 'ContentInsight',
    category: 'Content & Experience',
  },
  {
    toolName: 'Contently',
    category: 'Content & Experience',
  },
  {
    toolName: 'ContentMX',
    category: 'Content & Experience',
  },
  {
    toolName: 'ContentNet',
    category: 'Content & Experience',
  },
  {
    toolName: 'Contento',
    category: 'Content & Experience',
  },
  {
    toolName: 'Contentools',
    category: 'Content & Experience',
  },
  {
    toolName: 'ContentRaven',
    category: 'Content & Experience',
  },
  {
    toolName: 'ContentRunner',
    category: 'Content & Experience',
  },
  {
    toolName: 'Contentstack',
    category: 'Content & Experience',
  },
  {
    toolName: 'Contentstadium',
    category: 'Content & Experience',
  },
  {
    toolName: 'ContentStudio',
    category: 'Content & Experience',
  },
  {
    toolName: 'Contfeed',
    category: 'Content & Experience',
  },
  {
    toolName: 'Cooperatize',
    category: 'Content & Experience',
  },
  {
    toolName: 'Copify',
    category: 'Content & Experience',
  },
  {
    toolName: 'Copytesting',
    category: 'Content & Experience',
  },
  {
    toolName: 'Cortex',
    category: 'Content & Experience',
  },
  {
    toolName: 'Coschedule',
    category: 'Content & Experience',
  },
  {
    toolName: 'CrowdSource',
    category: 'Content & Experience',
  },
  {
    toolName: 'Crowdynews',
    category: 'Content & Experience',
  },
  {
    toolName: 'Curata',
    category: 'Content & Experience',
  },
  {
    toolName: 'Curated',
    category: 'Content & Experience',
  },
  {
    toolName: 'Curation Suite',
    category: 'Content & Experience',
  },
  {
    toolName: 'CustomerStories',
    category: 'Content & Experience',
  },
  {
    toolName: 'Cutnut',
    category: 'Content & Experience',
  },
  {
    toolName: 'Datasine',
    category: 'Content & Experience',
  },
  {
    toolName: 'DaVinci Workgroups',
    category: 'Content & Experience',
  },
  {
    toolName: 'Designcrowd',
    category: 'Content & Experience',
  },
  {
    toolName: 'Desygner',
    category: 'Content & Experience',
  },
  {
    toolName: 'Digital Impulse',
    category: 'Content & Experience',
  },
  {
    toolName: 'Dilogr',
    category: 'Content & Experience',
  },
  {
    toolName: 'DivvyHQ',
    category: 'Content & Experience',
  },
  {
    toolName: 'Doz',
    category: 'Content & Experience',
  },
  {
    toolName: 'DrumUp',
    category: 'Content & Experience',
  },
  {
    toolName: 'Easelly',
    category: 'Content & Experience',
  },
  {
    toolName: 'Ebyline',
    category: 'Content & Experience',
  },
  {
    toolName: 'EditorEye',
    category: 'Content & Experience',
  },
  {
    toolName: 'eLink',
    category: 'Content & Experience',
  },
  {
    toolName: 'Embedly',
    category: 'Content & Experience',
  },
  {
    toolName: 'Engageya',
    category: 'Content & Experience',
  },
  {
    toolName: 'Epictions',
    category: 'Content & Experience',
  },
  {
    toolName: 'ExpertFile',
    category: 'Content & Experience',
  },
  {
    toolName: 'Ezdia',
    category: 'Content & Experience',
  },
  {
    toolName: 'Fabl',
    category: 'Content & Experience',
  },
  {
    toolName: 'Factr',
    category: 'Content & Experience',
  },
  {
    toolName: 'Falcon.io',
    category: 'Content & Experience',
  },
  {
    toolName: 'Faveeo',
    category: 'Content & Experience',
  },
  {
    toolName: 'Finity',
    category: 'Content & Experience',
  },
  {
    toolName: 'Fiverr',
    category: 'Content & Experience',
  },
  {
    toolName: 'Flickr',
    category: 'Content & Experience',
  },
  {
    toolName: 'Flockler',
    category: 'Content & Experience',
  },
  {
    toolName: 'Flowbox',
    category: 'Content & Experience',
  },
  {
    toolName: 'Fluid Topics',
    category: 'Content & Experience',
  },
  {
    toolName: 'FMG Suite',
    category: 'Content & Experience',
  },
  {
    toolName: 'Foleon',
    category: 'Content & Experience',
  },
  {
    toolName: 'GatherContent',
    category: 'Content & Experience',
  },
  {
    toolName: 'Ghostwriter',
    category: 'Content & Experience',
  },
  {
    toolName: 'Ginger Software',
    category: 'Content & Experience',
  },
  {
    toolName: 'Grammarly',
    category: 'Content & Experience',
  },
  {
    toolName: 'Hipcast',
    category: 'Content & Experience',
  },
  {
    toolName: 'Hyscore',
    category: 'Content & Experience',
  },
  {
    toolName: 'Ignite',
    category: 'Content & Experience',
  },
  {
    toolName: 'InboundRocket',
    category: 'Content & Experience',
  },
  {
    toolName: 'Inboxinsight',
    category: 'Content & Experience',
  },
  {
    toolName: 'Infogram',
    category: 'Content & Experience',
  },
  {
    toolName: 'INK',
    category: 'Content & Experience',
  },
  {
    toolName: 'Inkling',
    category: 'Content & Experience',
  },
  {
    toolName: 'InPowered',
    category: 'Content & Experience',
  },
  {
    toolName: 'Intellyo',
    category: 'Content & Experience',
  },
  {
    toolName: 'Isebox',
    category: 'Content & Experience',
  },
  {
    toolName: 'Issuu',
    category: 'Content & Experience',
  },
  {
    toolName: 'IBM SilverPop',
    category: 'Content & Experience',
  },

  {
    toolName: 'Kapost',
    category: 'Content & Experience',
  },
  {
    toolName: 'Keytiles',
    category: 'Content & Experience',
  },
  {
    toolName: 'Keywee',
    category: 'Content & Experience',
  },
  {
    toolName: 'Knotch',
    category: 'Content & Experience',
  },
  {
    toolName: 'Kudani',
    category: 'Content & Experience',
  },
  {
    toolName: 'Libsyn',
    category: 'Content & Experience',
  },
  {
    toolName: 'Limber',
    category: 'Content & Experience',
  },
  {
    toolName: 'Limk',
    category: 'Content & Experience',
  },
  {
    toolName: 'Lingohub',
    category: 'Content & Experience',
  },
  {
    toolName: 'Lino',
    category: 'Content & Experience',
  },
  {
    toolName: 'Listly',
    category: 'Content & Experience',
  },
  {
    toolName: 'Lucidpress',
    category: 'Content & Experience',
  },
  {
    toolName: 'Lumina Datamatics',
    category: 'Content & Experience',
  },
  {
    toolName: 'Magisto',
    category: 'Content & Experience',
  },
  {
    toolName: 'MarketMuse',
    category: 'Content & Experience',
  },
  {
    toolName: 'Medium',
    category: 'Content & Experience',
  },
  {
    toolName: 'Memsource',
    category: 'Content & Experience',
  },
  {
    toolName: 'MessagePath',
    category: 'Content & Experience',
  },
  {
    toolName: 'MGID',
    category: 'Content & Experience',
  },
  {
    toolName: 'Miappi',
    category: 'Content & Experience',
  },
  {
    toolName: 'Mintent',
    category: 'Content & Experience',
  },
  {
    toolName: 'MotionPoint',
    category: 'Content & Experience',
  },
  {
    toolName: 'Movable Media',
    category: 'Content & Experience',
  },
  {
    toolName: 'Narrativ',
    category: 'Content & Experience',
  },
  {
    toolName: 'Narrative Science',
    category: 'Content & Experience',
  },
  {
    toolName: 'Nativo',
    category: 'Content & Experience',
  },
  {
    toolName: 'Naviga',
    category: 'Content & Experience',
  },
  {
    toolName: 'nDash',
    category: 'Content & Experience',
  },
  {
    toolName: 'NewsCred',
    category: 'Content & Experience',
  },
  {
    toolName: 'Niche',
    category: 'Content & Experience',
  },
  {
    toolName: 'Odyssiant',
    category: 'Content & Experience',
  },
  {
    toolName: 'Olapic',
    category: 'Content & Experience',
  },
  {
    toolName: 'OnBase',
    category: 'Content & Experience',
  },
  {
    toolName: 'OneSpot',
    category: 'Content & Experience',
  },
  {
    toolName: 'Oracle',
    category: 'Content & Experience',
  },
  {
    toolName: 'Outbrain',
    category: 'Content & Experience',
  },
  {
    toolName: 'Oz Content',
    category: 'Content & Experience',
  },
  {
    toolName: 'Paper.li',
    category: 'Content & Experience',
  },
  {
    toolName: 'Parllay',
    category: 'Content & Experience',
  },
  {
    toolName: 'Parse.ly',
    category: 'Content & Experience',
  },
  {
    toolName: 'Passle',
    category: 'Content & Experience',
  },
  {
    toolName: 'PathFactory',
    category: 'Content & Experience',
  },
  {
    toolName: 'Percolate',
    category: 'Content & Experience',
  },
  {
    toolName: 'Perkuto',
    category: 'Content & Experience',
  },
  {
    toolName: 'Persado',
    category: 'Content & Experience',
  },
  {
    toolName: 'Phraseapp',
    category: 'Content & Experience',
  },
  {
    toolName: 'Phrasee',
    category: 'Content & Experience',
  },
  {
    toolName: 'Piktochart',
    category: 'Content & Experience',
  },
  {
    toolName: 'Pixlee',
    category: 'Content & Experience',
  },
  {
    toolName: 'PixTeller',
    category: 'Content & Experience',
  },
  {
    toolName: 'Pixxfly',
    category: 'Content & Experience',
  },
  {
    toolName: 'Po.et',
    category: 'Content & Experience',
  },
  {
    toolName: 'PostPlanner',
    category: 'Content & Experience',
  },
  {
    toolName: 'PowerPost',
    category: 'Content & Experience',
  },
  {
    toolName: 'Pressly',
    category: 'Content & Experience',
  },
  {
    toolName: 'Priceonomics',
    category: 'Content & Experience',
  },
  {
    toolName: 'Primas',
    category: 'Content & Experience',
  },
  {
    toolName: 'Publicate',
    category: 'Content & Experience',
  },
  {
    toolName: 'PublishThis',
    category: 'Content & Experience',
  },
  {
    toolName: 'PulsePoint',
    category: 'Content & Experience',
  },
  {
    toolName: 'PureFocus',
    category: 'Content & Experience',
  },
  {
    toolName: 'Qordoba',
    category: 'Content & Experience',
  },
  {
    toolName: 'Quadrigram',
    category: 'Content & Experience',
  },
  {
    toolName: 'Quark',
    category: 'Content & Experience',
  },
  {
    toolName: 'Rallyverse',
    category: 'Content & Experience',
  },
  {
    toolName: 'React & Share',
    category: 'Content & Experience',
  },
  {
    toolName: 'RebelMouse',
    category: 'Content & Experience',
  },
  {
    toolName: 'Revcontent',
    category: 'Content & Experience',
  },
  {
    toolName: 'Revue',
    category: 'Content & Experience',
  },
  {
    toolName: 'Rock Content',
    category: 'Content & Experience',
  },
  {
    toolName: 'Salesforce Experience Cloud',
    category: 'Content & Experience',
  },
  {
    toolName: 'Salesforce CMS',
    category: 'Content & Experience',
  },
  {
    toolName: 'Salesforce Account Engagement',
    category: 'Content & Experience',
  },
  {
    toolName: 'Salesforce Marketing Cloud',
    category: 'Content & Experience',
  },
  {
    toolName: 'Salesforce Personalization',
    category: 'Content & Experience',
  },
  {
    toolName: 'Salesforce App Cloud',
    category: 'Content & Experience',
  },
  {
    toolName: 'Salesforce Engagement',
    category: 'Content & Experience',
  },
  {
    toolName: 'Salsify',
    category: 'Content & Experience',
  },
  {
    toolName: 'Scoop.It',
    category: 'Content & Experience',
  },
  {
    toolName: 'Scripted',
    category: 'Content & Experience',
  },
  {
    toolName: 'Shareist',
    category: 'Content & Experience',
  },
  {
    toolName: 'Shareroot',
    category: 'Content & Experience',
  },
  {
    toolName: 'ShowHows',
    category: 'Content & Experience',
  },
  {
    toolName: 'Shuttlerock',
    category: 'Content & Experience',
  },
  {
    toolName: 'Skyword',
    category: 'Content & Experience',
  },
  {
    toolName: 'Smartling',
    category: 'Content & Experience',
  },
  {
    toolName: 'Social Animal',
    category: 'Content & Experience',
  },
  {
    toolName: 'Social Board',
    category: 'Content & Experience',
  },
  {
    toolName: 'SocialBoost',
    category: 'Content & Experience',
  },
  {
    toolName: 'SourcePoint',
    category: 'Content & Experience',
  },
  {
    toolName: 'Sprinklr',
    category: 'Content & Experience',
  },
  {
    toolName: 'SqueezeCMM',
    category: 'Content & Experience',
  },
  {
    toolName: 'StartAFire',
    category: 'Content & Experience',
  },
  {
    toolName: 'Storychief',
    category: 'Content & Experience',
  },
  {
    toolName: 'storyliner',
    category: 'Content & Experience',
  },
  {
    toolName: 'Storyports',
    category: 'Content & Experience',
  },
  {
    toolName: 'Storyshaker',
    category: 'Content & Experience',
  },
  {
    toolName: 'StoryStream',
    category: 'Content & Experience',
  },
  {
    toolName: 'SurveyMonkey',
    category: 'Content & Experience',
  },
  {
    toolName: 'Taboola',
    category: 'Content & Experience',
  },
  {
    toolName: 'TacticRealtime',
    category: 'Content & Experience',
  },
  {
    toolName: 'tchop',
    category: 'Content & Experience',
  },
  {
    toolName: 'TechSmith',
    category: 'Content & Experience',
  },
  {
    toolName: 'Textmetrics',
    category: 'Content & Experience',
  },
  {
    toolName: 'ThisMoment',
    category: 'Content & Experience',
  },
  {
    toolName: 'Topic',
    category: 'Content & Experience',
  },
  {
    toolName: 'TrackMaven',
    category: 'Content & Experience',
  },
  {
    toolName: 'Translations.com',
    category: 'Content & Experience',
  },
  {
    toolName: 'Trendemon',
    category: 'Content & Experience',
  },
  {
    toolName: 'Triberr',
    category: 'Content & Experience',
  },
  {
    toolName: 'TruEdit',
    category: 'Content & Experience',
  },
  {
    toolName: 'Turtl',
    category: 'Content & Experience',
  },
  {
    toolName: 'Uberflip',
    category: 'Content & Experience',
  },
  {
    toolName: 'Upcontent',
    category: 'Content & Experience',
  },
  {
    toolName: 'Upscribe',
    category: 'Content & Experience',
  },
  {
    toolName: 'Upwork',
    category: 'Content & Experience',
  },
  {
    toolName: 'Venngage',
    category: 'Content & Experience',
  },
  {
    toolName: 'Verance Corp',
    category: 'Content & Experience',
  },
  {
    toolName: 'Verblio',
    category: 'Content & Experience',
  },
  {
    toolName: 'Vibrant Media',
    category: 'Content & Experience',
  },
  {
    toolName: 'Videoblocks',
    category: 'Content & Experience',
  },
  {
    toolName: 'ViralContentBee',
    category: 'Content & Experience',
  },
  {
    toolName: 'VisibleThread',
    category: 'Content & Experience',
  },
  {
    toolName: 'Visme',
    category: 'Content & Experience',
  },
  {
    toolName: 'Visually',
    category: 'Content & Experience',
  },
  {
    toolName: 'Vobile',
    category: 'Content & Experience',
  },
  {
    toolName: 'vWriter.com',
    category: 'Content & Experience',
  },
  {
    toolName: 'WeLocalize',
    category: 'Content & Experience',
  },
  {
    toolName: 'Wochit',
    category: 'Content & Experience',
  },
  {
    toolName: 'Workfront',
    category: 'Content & Experience',
  },
  {
    toolName: 'Wriber',
    category: 'Content & Experience',
  },
  {
    toolName: 'WriterAccess',
    category: 'Content & Experience',
  },
  {
    toolName: 'Wyzowl',
    category: 'Content & Experience',
  },
  {
    toolName: 'Xrintelligence',
    category: 'Content & Experience',
  },
  {
    toolName: 'Youzign',
    category: 'Content & Experience',
  },
  {
    toolName: 'Yseop',
    category: 'Content & Experience',
  },
  {
    toolName: 'Zemanta',
    category: 'Content & Experience',
  },
  {
    toolName: 'Zencastr',
    category: 'Content & Experience',
  },
  {
    toolName: 'Zencontent',
    category: 'Content & Experience',
  },
  {
    toolName: 'Zerys',
    category: 'Content & Experience',
  },
  {
    toolName: 'Zest.is',
    category: 'Content & Experience',
  },
  {
    toolName: 'Zoomin',
    category: 'Content & Experience',
  },
  {
    toolName: '1Brand',
    category: 'Content & Experience',
  },
  {
    toolName: '10,000ft',
    category: 'Content & Experience',
  },
  {
    toolName: 'Acquia',
    category: 'Content & Experience',
  },
  {
    toolName: 'Adgistics',
    category: 'Content & Experience',
  },
  {
    toolName: 'Aetopia',
    category: 'Content & Experience',
  },
  {
    toolName: 'Akaneo',
    category: 'Content & Experience',
  },
  {
    toolName: 'amplifi.io',
    category: 'Content & Experience',
  },
  {
    toolName: 'Aprimo',
    category: 'Content & Experience',
  },
  {
    toolName: 'Argosy',
    category: 'Content & Experience',
  },
  {
    toolName: 'Asset Bank',
    category: 'Content & Experience',
  },
  {
    toolName: 'AssetServ',
    category: 'Content & Experience',
  },
  {
    toolName: 'Avid',
    category: 'Content & Experience',
  },
  {
    toolName: 'Axiell',
    category: 'Content & Experience',
  },
  {
    toolName: 'BarberStock',
    category: 'Content & Experience',
  },
  {
    toolName: 'Beeyond',
    category: 'Content & Experience',
  },
  {
    toolName: 'BeTheBrand',
    category: 'Content & Experience',
  },
  {
    toolName: 'Bluestone PIM',
    category: 'Content & Experience',
  },
  {
    toolName: 'BoonDrive',
    category: 'Content & Experience',
  },
  {
    toolName: 'Brand Ensemble',
    category: 'Content & Experience',
  },
  {
    toolName: 'BRANDAD Systems AG',
    category: 'Content & Experience',
  },
  {
    toolName: 'Brandfolder',
    category: 'Content & Experience',
  },
  {
    toolName: 'Brandkit',
    category: 'Content & Experience',
  },
  {
    toolName: 'BrandMaker',
    category: 'Content & Experience',
  },
  {
    toolName: 'Brandmaster',
    category: 'Content & Experience',
  },
  {
    toolName: 'Brandox',
    category: 'Content & Experience',
  },
  {
    toolName: 'Brandsystems',
    category: 'Content & Experience',
  },
  {
    toolName: 'Brandware',
    category: 'Content & Experience',
  },
  {
    toolName: 'Brandworkz',
    category: 'Content & Experience',
  },
  {
    toolName: 'Brightspot',
    category: 'Content & Experience',
  },
  {
    toolName: 'Bynder',
    category: 'Content & Experience',
  },
  {
    toolName: 'Canter',
    category: 'Content & Experience',
  },
  {
    toolName: 'Canto',
    category: 'Content & Experience',
  },
  {
    toolName: 'Capital ID',
    category: 'Content & Experience',
  },
  {
    toolName: 'Capture',
    category: 'Content & Experience',
  },
  {
    toolName: 'CatDV',
    category: 'Content & Experience',
  },
  {
    toolName: 'Chili-Publish',
    category: 'Content & Experience',
  },
  {
    toolName: 'CI HUB GmbH',
    category: 'Content & Experience',
  },
  {
    toolName: 'Citdigital',
    category: 'Content & Experience',
  },
  {
    toolName: 'Cleanpix',
    category: 'Content & Experience',
  },
  {
    toolName: 'Cloudinary',
    category: 'Content & Experience',
  },
  {
    toolName: 'Cocoon',
    category: 'Content & Experience',
  },
  {
    toolName: 'Compano',
    category: 'Content & Experience',
  },
  {
    toolName: 'Comrads',
    category: 'Content & Experience',
  },
  {
    toolName: 'Connectingthedots',
    category: 'Content & Experience',
  },
  {
    toolName: 'Cooperate',
    category: 'Content & Experience',
  },
  {
    toolName: 'Cordeo',
    category: 'Content & Experience',
  },
  {
    toolName: 'Crossbase.be',
    category: 'Content & Experience',
  },
  {
    toolName: 'CrossCap',
    category: 'Content & Experience',
  },
  {
    toolName: 'Dalim Software',
    category: 'Content & Experience',
  },
  {
    toolName: 'Daminion',
    category: 'Content & Experience',
  },
  {
    toolName: 'Dampdocs',
    category: 'Content & Experience',
  },
  {
    toolName: 'Data Dwell',
    category: 'Content & Experience',
  },
  {
    toolName: 'Deltek',
    category: 'Content & Experience',
  },
  {
    toolName: 'DigiZuite',
    category: 'Content & Experience',
  },
  {
    toolName: 'DSMN8',
    category: 'Content & Experience',
  },
  {
    toolName: 'DynamicWeb',
    category: 'Content & Experience',
  },
  {
    toolName: 'Dynamo Software',
    category: 'Content & Experience',
  },
  {
    toolName: 'Elateral',
    category: 'Content & Experience',
  },
  {
    toolName: 'Empress',
    category: 'Content & Experience',
  },
  {
    toolName: 'Encode',
    category: 'Content & Experience',
  },
  {
    toolName: 'Evolphin Software',
    category: 'Content & Experience',
  },
  {
    toolName: 'Extensis',
    category: 'Content & Experience',
  },
  {
    toolName: 'Eyebase',
    category: 'Content & Experience',
  },
  {
    toolName: 'FADEL',
    category: 'Content & Experience',
  },
  {
    toolName: 'Facebook',
    category: 'Content & Experience',
  },
  {
    toolName: 'Filecamp',
    category: 'Content & Experience',
  },
  {
    toolName: 'Filespin.io',
    category: 'Content & Experience',
  },
  {
    toolName: 'Fotoware',
    category: 'Content & Experience',
  },
  {
    toolName: 'Gredi',
    category: 'Content & Experience',
  },
  {
    toolName: 'Hive9',
    category: 'Content & Experience',
  },
  {
    toolName: 'Honeycomb Archive',
    category: 'Content & Experience',
  },
  {
    toolName: 'IBM',
    category: 'Content & Experience',
  },
  {
    toolName: 'Icecatpim',
    category: 'Content & Experience',
  },
  {
    toolName: 'iDAM',
    category: 'Content & Experience',
  },
  {
    toolName: 'Imagen',
    category: 'Content & Experience',
  },
  {
    toolName: 'ImageRelay',
    category: 'Content & Experience',
  },
  {
    toolName: 'Imageshop',
    category: 'Content & Experience',
  },
  {
    toolName: 'ImageVault',
    category: 'Content & Experience',
  },
  {
    toolName: 'In Qmanager',
    category: 'Content & Experience',
  },
  {
    toolName: 'InfoDeli',
    category: 'Content & Experience',
  },
  {
    toolName: 'Infor',
    category: 'Content & Experience',
  },
  {
    toolName: 'Inriver',
    category: 'Content & Experience',
  },
  {
    toolName: 'IntelligenceBank',
    category: 'Content & Experience',
  },
  {
    toolName: 'Interact',
    category: 'Content & Experience',
  },
  {
    toolName: 'Instagram',
    category: 'Content & Experience',
  },
  {
    toolName: 'Twitter',
    category: 'Content & Experience',
  },
  {
    toolName: 'Pinterest',
    category: 'Content & Experience',
  },
  {
    toolName: 'Snapchat',
    category: 'Content & Experience',
  },
  {
    toolName: 'Youtube',
    category: 'Content & Experience',
  },
  {
    toolName: 'Keen Strategy',
    category: 'Content & Experience',
  },
  {
    toolName: 'Keepeek',
    category: 'Content & Experience',
  },
  {
    toolName: 'Kodak',
    category: 'Content & Experience',
  },
  {
    toolName: 'LightRocket Media',
    category: 'Content & Experience',
  },
  {
    toolName: 'Lingo',
    category: 'Content & Experience',
  },
  {
    toolName: 'Lucidea',
    category: 'Content & Experience',
  },
  {
    toolName: 'Lytho',
    category: 'Content & Experience',
  },
  {
    toolName: 'm-savvy',
    category: 'Content & Experience',
  },
  {
    toolName: 'Mainstream Data',
    category: 'Content & Experience',
  },
  {
    toolName: 'MajorTom',
    category: 'Content & Experience',
  },
  {
    toolName: 'MaPS System',
    category: 'Content & Experience',
  },
  {
    toolName: 'MarcomCentral',
    category: 'Content & Experience',
  },
  {
    toolName: 'Marvia',
    category: 'Content & Experience',
  },
  {
    toolName: 'Mediabank',
    category: 'Content & Experience',
  },
  {
    toolName: 'MediaBeacon',
    category: 'Content & Experience',
  },
  {
    toolName: 'MediaFiler',
    category: 'Content & Experience',
  },
  {
    toolName: 'MediaHaven',
    category: 'Content & Experience',
  },
  {
    toolName: 'MediaSilo',
    category: 'Content & Experience',
  },
  {
    toolName: 'Mediavalet',
    category: 'Content & Experience',
  },
  {
    toolName: 'MerlinOne',
    category: 'Content & Experience',
  },
  {
    toolName: 'Mosaic',
    category: 'Content & Experience',
  },
  {
    toolName: 'msights',
    category: 'Content & Experience',
  },
  {
    toolName: 'Mtivity',
    category: 'Content & Experience',
  },
  {
    toolName: 'Musqot',
    category: 'Content & Experience',
  },
  {
    toolName: 'NetX',
    category: 'Content & Experience',
  },
  {
    toolName: 'North plains',
    category: 'Content & Experience',
  },
  {
    toolName: 'Nuxeo',
    category: 'Content & Experience',
  },
  {
    toolName: 'OCLC',
    category: 'Content & Experience',
  },
  {
    toolName: 'Onedot',
    category: 'Content & Experience',
  },
  {
    toolName: 'OpenText',
    category: 'Content & Experience',
  },
  {
    toolName: 'Orphea',
    category: 'Content & Experience',
  },
  {
    toolName: 'Outfit',
    category: 'Content & Experience',
  },
  {
    toolName: 'Overcast HQ',
    category: 'Content & Experience',
  },
  {
    toolName: 'Papirfly',
    category: 'Content & Experience',
  },
  {
    toolName: 'Photoshelter',
    category: 'Content & Experience',
  },
  {
    toolName: 'Phototank',
    category: 'Content & Experience',
  },
  {
    toolName: 'Pickit',
    category: 'Content & Experience',
  },
  {
    toolName: 'Pics.io',
    category: 'Content & Experience',
  },
  {
    toolName: 'Piction',
    category: 'Content & Experience',
  },
  {
    toolName: 'Picturepark',
    category: 'Content & Experience',
  },
  {
    toolName: 'Pixelboxx',
    category: 'Content & Experience',
  },
  {
    toolName: 'Pixx.io',
    category: 'Content & Experience',
  },
  {
    toolName: 'Plytix Pim',
    category: 'Content & Experience',
  },
  {
    toolName: 'Productflow',
    category: 'Content & Experience',
  },
  {
    toolName: 'Propago',
    category: 'Content & Experience',
  },
  {
    toolName: 'Proplanet',
    category: 'Content & Experience',
  },
  {
    toolName: 'Qbank',
    category: 'Content & Experience',
  },
  {
    toolName: 'Razuna',
    category: 'Content & Experience',
  },
  {
    toolName: 'Relayter',
    category: 'Content & Experience',
  },
  {
    toolName: 'Resolut Marketing Systems',
    category: 'Content & Experience',
  },
  {
    toolName: 'ResourceBase',
    category: 'Content & Experience',
  },
  {
    toolName: 'ResourceSpace',
    category: 'Content & Experience',
  },
  {
    toolName: 'SAP',
    category: 'Content & Experience',
  },
  {
    toolName: 'SAS',
    category: 'Content & Experience',
  },
  {
    toolName: 'Scalia',
    category: 'Content & Experience',
  },
  {
    toolName: 'Sharedien',
    category: 'Content & Experience',
  },
  {
    toolName: 'Skypim',
    category: 'Content & Experience',
  },
  {
    toolName: 'Southpaw Techology',
    category: 'Content & Experience',
  },
  {
    toolName: 'Src',
    category: 'Content & Experience',
  },
  {
    toolName: 'Stibo Step',
    category: 'Content & Experience',
  },
  {
    toolName: 'Strata Company',
    category: 'Content & Experience',
  },
  {
    toolName: 'Structpim',
    category: 'Content & Experience',
  },
  {
    toolName: 'SuttleStraus',
    category: 'Content & Experience',
  },
  {
    toolName: 'Syncforce',
    category: 'Content & Experience',
  },
  {
    toolName: 'Syndigo',
    category: 'Content & Experience',
  },
  {
    toolName: 'TandemVault',
    category: 'Content & Experience',
  },
  {
    toolName: 'Templafy',
    category: 'Content & Experience',
  },
  {
    toolName: 'Third Light',
    category: 'Content & Experience',
  },
  {
    toolName: 'THRON',
    category: 'Content & Experience',
  },
  {
    toolName: 'Transcend360',
    category: 'Content & Experience',
  },
  {
    toolName: 'Utomo',
    category: 'Content & Experience',
  },
  {
    toolName: 'Virtusales',
    category: 'Content & Experience',
  },
  {
    toolName: 'Vya',
    category: 'Content & Experience',
  },
  {
    toolName: 'WebDAM',
    category: 'Content & Experience',
  },
  {
    toolName: 'Wedia',
    category: 'Content & Experience',
  },
  {
    toolName: 'Widen',
    category: 'Content & Experience',
  },
  {
    toolName: 'Wiredrive',
    category: 'Content & Experience',
  },
  {
    toolName: 'Woodwing Software',
    category: 'Content & Experience',
  },
  {
    toolName: 'WorkSpan',
    category: 'Content & Experience',
  },
  {
    toolName: 'Xara',
    category: 'Content & Experience',
  },
  {
    toolName: 'xCircular',
    category: 'Content & Experience',
  },
  {
    toolName: 'XDAM',
    category: 'Content & Experience',
  },
  {
    toolName: 'Yangaroo',
    category: 'Content & Experience',
  },
  {
    toolName: '1PointMail',
    category: 'Content & Experience',
  },
  {
    toolName: '4Dem',
    category: 'Content & Experience',
  },
  {
    toolName: '250ok',
    category: 'Content & Experience',
  },
  {
    toolName: 'ActiveTrail',
    category: 'Content & Experience',
  },
  {
    toolName: 'acyba',
    category: 'Content & Experience',
  },
  {
    toolName: 'AGNITAS',
    category: 'Content & Experience',
  },
  {
    toolName: 'Amazon SES',
    category: 'Content & Experience',
  },
  {
    toolName: 'Apex Pacific',
    category: 'Content & Experience',
  },
  {
    toolName: 'Apsis',
    category: 'Content & Experience',
  },
  {
    toolName: 'Astonish Email',
    category: 'Content & Experience',
  },
  {
    toolName: 'AudiencePoint',
    category: 'Content & Experience',
  },
  {
    toolName: 'Aurea',
    category: 'Content & Experience',
  },
  {
    toolName: 'AWeber',
    category: 'Content & Experience',
  },
  {
    toolName: 'BaseDriver',
    category: 'Content & Experience',
  },
  {
    toolName: 'Benchmark',
    category: 'Content & Experience',
  },
  {
    toolName: 'Blocks Edit',
    category: 'Content & Experience',
  },
  {
    toolName: 'Botletter',
    category: 'Content & Experience',
  },
  {
    toolName: 'Cakemail',
    category: 'Content & Experience',
  },
  {
    toolName: 'Campaign Monitor',
    category: 'Content & Experience',
  },
  {
    toolName: 'Campaign Workhub',
    category: 'Content & Experience',
  },
  {
    toolName: 'Campaigner',
    category: 'Content & Experience',
  },
  {
    toolName: 'Campayn',
    category: 'Content & Experience',
  },
  {
    toolName: 'Charley',
    category: 'Content & Experience',
  },
  {
    toolName: 'Clever Elements',
    category: 'Content & Experience',
  },
  {
    toolName: 'CleverReach',
    category: 'Content & Experience',
  },
  {
    toolName: 'Closealert',
    category: 'Content & Experience',
  },
  {
    toolName: 'CO-SENDER',
    category: 'Content & Experience',
  },
  {
    toolName: 'Comm100',
    category: 'Content & Experience',
  },
  {
    toolName: 'Communicator',
    category: 'Content & Experience',
  },
  {
    toolName: 'Constant Contact',
    category: 'Content & Experience',
  },
  {
    toolName: 'Copernica',
    category: 'Content & Experience',
  },
  {
    toolName: 'Cordial',
    category: 'Content & Experience',
  },
  {
    toolName: 'Covideo',
    category: 'Content & Experience',
  },
  {
    toolName: 'Creamailer',
    category: 'Content & Experience',
  },
  {
    toolName: 'Customer.io',
    category: 'Content & Experience',
  },
  {
    toolName: 'DeBounce',
    category: 'Content & Experience',
  },
  {
    toolName: 'Delivra',
    category: 'Content & Experience',
  },
  {
    toolName: 'Desiger Free Solutions',
    category: 'Content & Experience',
  },
  {
    toolName: 'Dialog Insight',
    category: 'Content & Experience',
  },
  {
    toolName: 'DirectIQ',
    category: 'Content & Experience',
  },
  {
    toolName: 'Doppler',
    category: 'Content & Experience',
  },
  {
    toolName: 'Dyspatch',
    category: 'Content & Experience',
  },
  {
    toolName: 'eFlyerMaker',
    category: 'Content & Experience',
  },
  {
    toolName: 'Elastic Email',
    category: 'Content & Experience',
  },
  {
    toolName: 'EliteEmail',
    category: 'Content & Experience',
  },
  {
    toolName: 'Email Hippo',
    category: 'Content & Experience',
  },
  {
    toolName: 'eMailChef',
    category: 'Content & Experience',
  },
  {
    toolName: 'Emaileri',
    category: 'Content & Experience',
  },
  {
    toolName: 'emailicious',
    category: 'Content & Experience',
  },
  {
    toolName: 'Emailmovers',
    category: 'Content & Experience',
  },
  {
    toolName: 'EmailOctopus',
    category: 'Content & Experience',
  },
  {
    toolName: 'EmailOnAcid',
    category: 'Content & Experience',
  },
  {
    toolName: 'emBlue',
    category: 'Content & Experience',
  },
  {
    toolName: 'eMercury',
    category: 'Content & Experience',
  },
  {
    toolName: 'Emma',
    category: 'Content & Experience',
  },
  {
    toolName: 'Ennect',
    category: 'Content & Experience',
  },
  {
    toolName: 'Eloqua B2C Marketing',
    category: 'Content & Experience',
  },
  {
    toolName: 'Eloqua B2B Marketing',
    category: 'Content & Experience',
  },
  {
    toolName: 'Eloqua Fusion Marketing',
    category: 'Content & Experience',
  },
  {
    toolName: 'Eloqua Marketing Automation',
    category: 'Content & Experience',
  },
  {
    toolName: 'Eloqua Responsys Campaign Management',
    category: 'Content & Experience',
  },
  {
    toolName: 'Eloqua CrowdTwist Loyalty and Engagement',
    category: 'Content & Experience',
  },
  {
    toolName: 'Eloqua Bluekai Data Management Platform',
    category: 'Content & Experience',
  },
  {
    toolName: 'Eloqua Audience Segmentation',
    category: 'Content & Experience',
  },
  {
    toolName: 'Eloqua Unity Customer Data Platform',
    category: 'Content & Experience',
  },
  {
    toolName: 'Eloqua Infinity Behavioral Intelligence',
    category: 'Content & Experience',
  },
  {
    toolName: 'Eloqua Maxymiser Testing and Optimization',
    category: 'Content & Experience',
  },
  {
    toolName: 'Epsilon',
    category: 'Content & Experience',
  },
  {
    toolName: 'ExpertSender',
    category: 'Content & Experience',
  },
  {
    toolName: 'ExpressPigeon',
    category: 'Content & Experience',
  },
  {
    toolName: 'Ezepo',
    category: 'Content & Experience',
  },
  {
    toolName: 'FireDrum Email Marketing',
    category: 'Content & Experience',
  },
  {
    toolName: 'Flexmail',
    category: 'Content & Experience',
  },
  {
    toolName: 'Freshmail',
    category: 'Content & Experience',
  },
  {
    toolName: 'Gamalon',
    category: 'Content & Experience',
  },
  {
    toolName: 'GetResponse',
    category: 'Content & Experience',
  },
  {
    toolName: 'Gimmio',
    category: 'Content & Experience',
  },
  {
    toolName: 'Gist',
    category: 'Content & Experience',
  },
  {
    toolName: 'Godaddy',
    category: 'Content & Experience',
  },
  {
    toolName: 'Goodbits',
    category: 'Content & Experience',
  },
  {
    toolName: 'Goolara',
    category: 'Content & Experience',
  },
  {
    toolName: 'Hellodialog',
    category: 'Content & Experience',
  },
  {
    toolName: 'Instiller',
    category: 'Content & Experience',
  },
  {
    toolName: 'Inwise',
    category: 'Content & Experience',
  },
  {
    toolName: 'inxmail',
    category: 'Content & Experience',
  },
  {
    toolName: 'Jangomail',
    category: 'Content & Experience',
  },
  {
    toolName: 'Jilt',
    category: 'Content & Experience',
  },
  {
    toolName: 'Kajomi',
    category: 'Content & Experience',
  },
  {
    toolName: 'Kickbox',
    category: 'Content & Experience',
  },
  {
    toolName: 'Kickdynamic',
    category: 'Content & Experience',
  },
  {
    toolName: 'Kind Ads',
    category: 'Content & Experience',
  },
  {
    toolName: 'Knak',
    category: 'Content & Experience',
  },
  {
    toolName: 'KulaHub',
    category: 'Content & Experience',
  },
  {
    toolName: 'Lead Demon',
    category: 'Content & Experience',
  },
  {
    toolName: 'LeadGnome',
    category: 'Content & Experience',
  },
  {
    toolName: 'LeadSparrow',
    category: 'Content & Experience',
  },
  {
    toolName: 'Lianamailer.de',
    category: 'Content & Experience',
  },
  {
    toolName: 'ListMarketer',
    category: 'Content & Experience',
  },
  {
    toolName: 'Litmus',
    category: 'Content & Experience',
  },
  {
    toolName: 'LiveClicker',
    category: 'Content & Experience',
  },
  {
    toolName: 'LiveIntent',
    category: 'Content & Experience',
  },
  {
    toolName: 'Mad Mimi',
    category: 'Content & Experience',
  },
  {
    toolName: 'MagNews',
    category: 'Content & Experience',
  },
  {
    toolName: 'Mail250',
    category: 'Content & Experience',
  },
  {
    toolName: 'Mailblast',
    category: 'Content & Experience',
  },
  {
    toolName: 'Mailbutler Business',
    category: 'Content & Experience',
  },
  {
    toolName: 'MailChimp',
    category: 'Content & Experience',
  },
  {
    toolName: 'Mailee.me',
    category: 'Content & Experience',
  },
  {
    toolName: 'Maileon',
    category: 'Content & Experience',
  },
  {
    toolName: 'Mailerlite',
    category: 'Content & Experience',
  },
  {
    toolName: 'MailGet',
    category: 'Content & Experience',
  },
  {
    toolName: 'Mailgun',
    category: 'Content & Experience',
  },
  {
    toolName: 'Mailify',
    category: 'Content & Experience',
  },
  {
    toolName: 'Mailigen',
    category: 'Content & Experience',
  },
  {
    toolName: 'Mailing Manager',
    category: 'Content & Experience',
  },
  {
    toolName: 'mailingwork',
    category: 'Content & Experience',
  },
  {
    toolName: 'Mailjet',
    category: 'Content & Experience',
  },
  {
    toolName: 'MailKitchen',
    category: 'Content & Experience',
  },
  {
    toolName: 'MailMigo',
    category: 'Content & Experience',
  },
  {
    toolName: 'MailMojo',
    category: 'Content & Experience',
  },
  {
    toolName: 'MailMunch',
    category: 'Content & Experience',
  },
  {
    toolName: 'Mailody',
    category: 'Content & Experience',
  },
  {
    toolName: 'MailPoet',
    category: 'Content & Experience',
  },
  {
    toolName: 'MailRelay',
    category: 'Content & Experience',
  },
  {
    toolName: 'Mailtastic',
    category: 'Content & Experience',
  },
  {
    toolName: 'MailUp',
    category: 'Content & Experience',
  },
  {
    toolName: 'MailWizz',
    category: 'Content & Experience',
  },
  {
    toolName: 'MailZak',
    category: 'Content & Experience',
  },
  {
    toolName: 'Make',
    category: 'Content & Experience',
  },
  {
    toolName: 'Mapp',
    category: 'Content & Experience',
  },
  {
    toolName: 'MassMailer',
    category: 'Content & Experience',
  },
  {
    toolName: 'Measuremail',
    category: 'Content & Experience',
  },
  {
    toolName: 'MediaProwler',
    category: 'Content & Experience',
  },
  {
    toolName: 'Mercanto',
    category: 'Content & Experience',
  },
  {
    toolName: 'MessageGears',
    category: 'Content & Experience',
  },
  {
    toolName: 'Minutemailer',
    category: 'Content & Experience',
  },
  {
    toolName: 'Moosend',
    category: 'Content & Experience',
  },
  {
    toolName: 'MovableInk',
    category: 'Content & Experience',
  },
  {
    toolName: 'MPZMail',
    category: 'Content & Experience',
  },
  {
    toolName: 'Mustache',
    category: 'Content & Experience',
  },
  {
    toolName: 'mVizz',
    category: 'Content & Experience',
  },
  {
    toolName: 'MyBizMailer',
    category: 'Content & Experience',
  },
  {
    toolName: 'Newoldstamp',
    category: 'Content & Experience',
  },
  {
    toolName: 'Newsletter2Go',
    category: 'Content & Experience',
  },
  {
    toolName: 'NewZapp',
    category: 'Content & Experience',
  },
  {
    toolName: 'NiftyImages',
    category: 'Content & Experience',
  },
  {
    toolName: 'Notablist',
    category: 'Content & Experience',
  },
  {
    toolName: 'Omnisend',
    category: 'Content & Experience',
  },
  {
    toolName: 'Ongage',
    category: 'Content & Experience',
  },
  {
    toolName: 'Orinax',
    category: 'Content & Experience',
  },
  {
    toolName: 'Outfunnel',
    category: 'Content & Experience',
  },
  {
    toolName: 'Oxygenta',
    category: 'Content & Experience',
  },
  {
    toolName: 'Padiact',
    category: 'Content & Experience',
  },
  {
    toolName: 'Pepo Campaigns',
    category: 'Content & Experience',
  },
  {
    toolName: 'Pinpointe',
    category: 'Content & Experience',
  },
  {
    toolName: 'Popcorn',
    category: 'Content & Experience',
  },
  {
    toolName: 'PostageApp',
    category: 'Content & Experience',
  },
  {
    toolName: 'Postmark',
    category: 'Content & Experience',
  },
  {
    toolName: 'Predictive Response',
    category: 'Content & Experience',
  },
  {
    toolName: 'Pure360',
    category: 'Content & Experience',
  },
  {
    toolName: 'Quickmail.io',
    category: 'Content & Experience',
  },
  {
    toolName: 'Quokka',
    category: 'Content & Experience',
  },
  {
    toolName: 'RapidMail',
    category: 'Content & Experience',
  },
  {
    toolName: 'RareLogic',
    category: 'Content & Experience',
  },
  {
    toolName: 'rasa.io',
    category: 'Content & Experience',
  },
  {
    toolName: 'ReachMail',
    category: 'Content & Experience',
  },
  {
    toolName: 'RedCappi',
    category: 'Content & Experience',
  },
  {
    toolName: 'Remailme',
    category: 'Content & Experience',
  },
  {
    toolName: 'Replybutton',
    category: 'Content & Experience',
  },
  {
    toolName: 'Robly',
    category: 'Content & Experience',
  },

  {
    toolName: 'Salesforce Email Studio',
    category: 'Content & Experience',
  },
  {
    toolName: 'Salesforce Journey Builder',
    category: 'Content & Experience',
  },
  {
    toolName: 'Salesforce Interaction Studio',
    category: 'Content & Experience',
  },
  {
    toolName: 'Salesforce CDP',
    category: 'Content & Experience',
  },
  {
    toolName: 'Salesforce Pardot',
    category: 'Content & Experience',
  },
  {
    toolName: 'Salesforce Datorama',
    category: 'Content & Experience',
  },
  {
    toolName: 'Salesforce Advertising Studio',
    category: 'Content & Experience',
  },
  {
    toolName: 'Salesforce Mobile Studio',
    category: 'Content & Experience',
  },
  {
    toolName: 'Salesforce Content Management',
    category: 'Content & Experience',
  },
  {
    toolName: 'Salesforce Google Marketing Platform',
    category: 'Content & Experience',
  },
  {
    toolName: 'Salesforce Loyalty Management',
    category: 'Content & Experience',
  },
  {
    toolName: 'Salesforce Datorama Reports',
    category: 'Content & Experience',
  },
  {
    toolName: 'Sarv.com',
    category: 'Content & Experience',
  },
  {
    toolName: 'Savicom',
    category: 'Content & Experience',
  },
  {
    toolName: 'sendeffect',
    category: 'Content & Experience',
  },
  {
    toolName: 'SenderGen',
    category: 'Content & Experience',
  },
  {
    toolName: 'SendForensics',
    category: 'Content & Experience',
  },
  {
    toolName: 'SendGrid',
    category: 'Content & Experience',
  },
  {
    toolName: 'Sendicate',
    category: 'Content & Experience',
  },
  {
    toolName: 'SendOut',
    category: 'Content & Experience',
  },
  {
    toolName: 'SendPulse',
    category: 'Content & Experience',
  },
  {
    toolName: 'SendSmith',
    category: 'Content & Experience',
  },
  {
    toolName: 'Sensorpro',
    category: 'Content & Experience',
  },
  {
    toolName: 'Sentopia',
    category: 'Content & Experience',
  },
  {
    toolName: 'Seventh Sense',
    category: 'Content & Experience',
  },
  {
    toolName: 'Shopmagic',
    category: 'Content & Experience',
  },
  {
    toolName: 'Siftrock',
    category: 'Content & Experience',
  },
  {
    toolName: 'Signaturia',
    category: 'Content & Experience',
  },
  {
    toolName: 'Sigstr',
    category: 'Content & Experience',
  },
  {
    toolName: 'Slashlead',
    category: 'Content & Experience',
  },
  {
    toolName: 'Smaily',
    category: 'Content & Experience',
  },
  {
    toolName: 'SmartrMail',
    category: 'Content & Experience',
  },
  {
    toolName: 'SMTP',
    category: 'Content & Experience',
  },
  {
    toolName: 'SocketLabs',
    category: 'Content & Experience',
  },
  {
    toolName: 'SparkPost',
    category: 'Content & Experience',
  },
  {
    toolName: 'Spotler',
    category: 'Content & Experience',
  },
  {
    toolName: 'SpreadeMail',
    category: 'Content & Experience',
  },
  {
    toolName: 'Stensul',
    category: 'Content & Experience',
  },
  {
    toolName: 'StreamSend',
    category: 'Content & Experience',
  },
  {
    toolName: 'Stripo',
    category: 'Content & Experience',
  },
  {
    toolName: 'Subscriber Voice',
    category: 'Content & Experience',
  },
  {
    toolName: 'Targeteveryone',
    category: 'Content & Experience',
  },
  {
    toolName: 'Taxi',
    category: 'Content & Experience',
  },
  {
    toolName: 'TotalSend',
    category: 'Content & Experience',
  },
  {
    toolName: 'ToucBase',
    category: 'Content & Experience',
  },
  {
    toolName: 'Tripolis',
    category: 'Content & Experience',
  },
  {
    toolName: 'Ungapped',
    category: 'Content & Experience',
  },
  {
    toolName: 'Unisender',
    category: 'Content & Experience',
  },
  {
    toolName: 'Upaknee',
    category: 'Content & Experience',
  },
  {
    toolName: 'Upland Software',
    category: 'Content & Experience',
  },
  {
    toolName: 'Validity',
    category: 'Content & Experience',
  },
  {
    toolName: 'Velocity Software',
    category: 'Content & Experience',
  },
  {
    toolName: 'Vero',
    category: 'Content & Experience',
  },
  {
    toolName: 'VerticalResponse',
    category: 'Content & Experience',
  },
  {
    toolName: 'Vestorly',
    category: 'Content & Experience',
  },
  {
    toolName: 'Vinmail',
    category: 'Content & Experience',
  },
  {
    toolName: 'Vision6',
    category: 'Content & Experience',
  },
  {
    toolName: 'Voxmail',
    category: 'Content & Experience',
  },
  {
    toolName: 'Webbula',
    category: 'Content & Experience',
  },
  {
    toolName: 'WhatCounts',
    category: 'Content & Experience',
  },
  {
    toolName: 'Wilken',
    category: 'Content & Experience',
  },
  {
    toolName: 'WiseStamp',
    category: 'Content & Experience',
  },
  {
    toolName: 'WordFly',
    category: 'Content & Experience',
  },
  {
    toolName: 'Xink',
    category: 'Content & Experience',
  },
  {
    toolName: 'Xtremepush',
    category: 'Content & Experience',
  },
  {
    toolName: 'Yesmail',
    category: 'Content & Experience',
  },
  {
    toolName: 'zebNet',
    category: 'Content & Experience',
  },
  {
    toolName: 'Zendesk',
    category: 'Content & Experience',
  },
  {
    toolName: 'Zero Bounce',
    category: 'Content & Experience',
  },
  {
    toolName: 'Zivver',
    category: 'Content & Experience',
  },
  {
    toolName: 'Zoho',
    category: 'Content & Experience',
  },
  {
    toolName: '1World Online',
    category: 'Content & Experience',
  },
  {
    toolName: '3Radical',
    category: 'Content & Experience',
  },
  {
    toolName: '4Screens',
    category: 'Content & Experience',
  },
  {
    toolName: '123FormBuilder',
    category: 'Content & Experience',
  },
  {
    toolName: 'Addsearch',
    category: 'Content & Experience',
  },
  {
    toolName: 'Adictiz',
    category: 'Content & Experience',
  },
  {
    toolName: 'Adsy',
    category: 'Content & Experience',
  },
  {
    toolName: 'agilezone.com',
    category: 'Content & Experience',
  },
  {
    toolName: 'Aimtell',
    category: 'Content & Experience',
  },
  {
    toolName: 'AlphaNetworks',
    category: 'Content & Experience',
  },
  {
    toolName: 'Apester',
    category: 'Content & Experience',
  },
  {
    toolName: 'Arilyn',
    category: 'Content & Experience',
  },
  {
    toolName: 'Atipso',
    category: 'Content & Experience',
  },
  {
    toolName: 'Badgeville',
    category: 'Content & Experience',
  },
  {
    toolName: 'Bers Gmbh',
    category: 'Content & Experience',
  },
  {
    toolName: 'Blippar',
    category: 'Content & Experience',
  },
  {
    toolName: 'Blitzen',
    category: 'Content & Experience',
  },
  {
    toolName: 'BooleanApp',
    category: 'Content & Experience',
  },
  {
    toolName: 'Bouncezap',
    category: 'Content & Experience',
  },
  {
    toolName: 'brandquiz',
    category: 'Content & Experience',
  },
  {
    toolName: 'Breaker',
    category: 'Content & Experience',
  },
  {
    toolName: 'Calcapp',
    category: 'Content & Experience',
  },
  {
    toolName: 'Camera IQ',
    category: 'Content & Experience',
  },
  {
    toolName: 'Ceros',
    category: 'Content & Experience',
  },
  {
    toolName: 'Choicely',
    category: 'Content & Experience',
  },
  {
    toolName: 'Citia',
    category: 'Content & Experience',
  },
  {
    toolName: 'Cognito Forms',
    category: 'Content & Experience',
  },
  {
    toolName: 'ContestDomination',
    category: 'Content & Experience',
  },
  {
    toolName: 'ContestFactory',
    category: 'Content & Experience',
  },
  {
    toolName: 'Convertful',
    category: 'Content & Experience',
  },
  {
    toolName: 'Conveyour',
    category: 'Content & Experience',
  },
  {
    toolName: 'Cool Tabs',
    category: 'Content & Experience',
  },
  {
    toolName: 'CopperPix',
    category: 'Content & Experience',
  },
  {
    toolName: 'Crowdsignal',
    category: 'Content & Experience',
  },
  {
    toolName: 'Decisionaire',
    category: 'Content & Experience',
  },
  {
    toolName: 'Digioh',
    category: 'Content & Experience',
  },
  {
    toolName: 'Doopoll',
    category: 'Content & Experience',
  },
  {
    toolName: 'Dot',
    category: 'Content & Experience',
  },
  {
    toolName: 'Easypromos',
    category: 'Content & Experience',
  },
  {
    toolName: 'Edition Digital',
    category: 'Content & Experience',
  },
  {
    toolName: 'Eko',
    category: 'Content & Experience',
  },
  {
    toolName: 'Enalyzer',
    category: 'Content & Experience',
  },
  {
    toolName: 'eSurveysPro',
    category: 'Content & Experience',
  },
  {
    toolName: 'Feedify',
    category: 'Content & Experience',
  },
  {
    toolName: 'FormDesk',
    category: 'Content & Experience',
  },
  {
    toolName: 'Formidable Forms',
    category: 'Content & Experience',
  },
  {
    toolName: 'forms.app',
    category: 'Content & Experience',
  },
  {
    toolName: 'Formsite',
    category: 'Content & Experience',
  },
  {
    toolName: 'Formstack',
    category: 'Content & Experience',
  },
  {
    toolName: 'FormTitan',
    category: 'Content & Experience',
  },
  {
    toolName: 'Formwize',
    category: 'Content & Experience',
  },
  {
    toolName: 'Free Online Surveys',
    category: 'Content & Experience',
  },
  {
    toolName: 'Fyrebox',
    category: 'Content & Experience',
  },
  {
    toolName: 'GameSalad',
    category: 'Content & Experience',
  },
  {
    toolName: 'Gametize',
    category: 'Content & Experience',
  },
  {
    toolName: 'Gamewheel',
    category: 'Content & Experience',
  },
  {
    toolName: 'Gamiphy',
    category: 'Content & Experience',
  },
  {
    toolName: 'Genially',
    category: 'Content & Experience',
  },
  {
    toolName: 'Getsitecontrol',
    category: 'Content & Experience',
  },
  {
    toolName: 'Gleam',
    category: 'Content & Experience',
  },
  {
    toolName: 'Google',
    category: 'Content & Experience',
  },
  {
    toolName: 'Gravity Forms',
    category: 'Content & Experience',
  },
  {
    toolName: 'Guides',
    category: 'Content & Experience',
  },
  {
    toolName: 'Happydemics',
    category: 'Content & Experience',
  },
  {
    toolName: 'Hapyak',
    category: 'Content & Experience',
  },
  {
    toolName: 'HelloWorld',
    category: 'Content & Experience',
  },
  {
    toolName: 'Heyo',
    category: 'Content & Experience',
  },
  {
    toolName: 'INDE',
    category: 'Content & Experience',
  },
  {
    toolName: 'Ingage',
    category: 'Content & Experience',
  },
  {
    toolName: 'Intuiface',
    category: 'Content & Experience',
  },
  {
    toolName: 'ion interactive',
    category: 'Content & Experience',
  },
  {
    toolName: 'iSpring',
    category: 'Content & Experience',
  },
  {
    toolName: 'Jebbit',
    category: 'Content & Experience',
  },
  {
    toolName: 'Joomag',
    category: 'Content & Experience',
  },
  {
    toolName: 'Jotform',
    category: 'Content & Experience',
  },
  {
    toolName: 'Judgify',
    category: 'Content & Experience',
  },
  {
    toolName: 'Kaon Interactive',
    category: 'Content & Experience',
  },
  {
    toolName: 'Kickoff Labs',
    category: 'Content & Experience',
  },
  {
    toolName: 'Kontest',
    category: 'Content & Experience',
  },
  {
    toolName: 'Kuia',
    category: 'Content & Experience',
  },
  {
    toolName: 'Leadfamly',
    category: 'Content & Experience',
  },
  {
    toolName: 'Leadgen',
    category: 'Content & Experience',
  },
  {
    toolName: 'LeadQuizzes',
    category: 'Content & Experience',
  },
  {
    toolName: 'LimeSurvey',
    category: 'Content & Experience',
  },
  {
    toolName: 'Mackevision',
    category: 'Content & Experience',
  },
  {
    toolName: 'Maglr',
    category: 'Content & Experience',
  },
  {
    toolName: 'MapMe',
    category: 'Content & Experience',
  },
  {
    toolName: 'Mindstamp',
    category: 'Content & Experience',
  },
  {
    toolName: 'Monterosa',
    category: 'Content & Experience',
  },
  {
    toolName: 'Nl.Checkmarket',
    category: 'Content & Experience',
  },
  {
    toolName: 'Notifia',
    category: 'Content & Experience',
  },
  {
    toolName: 'Novi Survey',
    category: 'Content & Experience',
  },
  {
    toolName: 'Obsurvey',
    category: 'Content & Experience',
  },
  {
    toolName: 'Offercraft',
    category: 'Content & Experience',
  },
  {
    toolName: 'OpenWater',
    category: 'Content & Experience',
  },
  {
    toolName: 'Opinary',
    category: 'Content & Experience',
  },
  {
    toolName: 'Opinion Stage',
    category: 'Content & Experience',
  },
  {
    toolName: 'Outgrow',
    category: 'Content & Experience',
  },
  {
    toolName: 'Paperform',
    category: 'Content & Experience',
  },
  {
    toolName: 'Perq',
    category: 'Content & Experience',
  },
  {
    toolName: 'Phase2',
    category: 'Content & Experience',
  },
  {
    toolName: 'Pinpoll',
    category: 'Content & Experience',
  },
  {
    toolName: 'PlayBasis',
    category: 'Content & Experience',
  },
  {
    toolName: 'Playbuzz',
    category: 'Content & Experience',
  },
  {
    toolName: 'Playfilm',
    category: 'Content & Experience',
  },
  {
    toolName: 'Plyfe',
    category: 'Content & Experience',
  },
  {
    toolName: 'Pollfish',
    category: 'Content & Experience',
  },
  {
    toolName: 'Popup Maker',
    category: 'Content & Experience',
  },
  {
    toolName: 'Prontoforms',
    category: 'Content & Experience',
  },
  {
    toolName: 'ProProfs',
    category: 'Content & Experience',
  },
  {
    toolName: 'Publitas',
    category: 'Content & Experience',
  },
  {
    toolName: 'PUG Interactive',
    category: 'Content & Experience',
  },
  {
    toolName: 'PushOne',
    category: 'Content & Experience',
  },
  {
    toolName: 'Qualifio',
    category: 'Content & Experience',
  },
  {
    toolName: 'QuestionPro',
    category: 'Content & Experience',
  },
  {
    toolName: 'QuizMaker',
    category: 'Content & Experience',
  },
  {
    toolName: 'Quizworks',
    category: 'Content & Experience',
  },
  {
    toolName: 'Qzzr',
    category: 'Content & Experience',
  },
  {
    toolName: 'Rafflecopter',
    category: 'Content & Experience',
  },
  {
    toolName: 'Rapt Media',
    category: 'Content & Experience',
  },
  {
    toolName: 'REDCap',
    category: 'Content & Experience',
  },
  {
    toolName: 'Riddle',
    category: 'Content & Experience',
  },
  {
    toolName: 'Sawtooth Software',
    category: 'Content & Experience',
  },
  {
    toolName: 'SecondStreet',
    category: 'Content & Experience',
  },
  {
    toolName: 'Shortstack',
    category: 'Content & Experience',
  },
  {
    toolName: 'Silk',
    category: 'Content & Experience',
  },
  {
    toolName: 'SmartSurvey',
    category: 'Content & Experience',
  },
  {
    toolName: 'SnapApp',
    category: 'Content & Experience',
  },
  {
    toolName: 'Snipp',
    category: 'Content & Experience',
  },
  {
    toolName: 'SoGoSurvey',
    category: 'Content & Experience',
  },
  {
    toolName: 'Spott',
    category: 'Content & Experience',
  },
  {
    toolName: 'Stereoscape',
    category: 'Content & Experience',
  },
  {
    toolName: 'Strutta',
    category: 'Content & Experience',
  },
  {
    toolName: 'Submittable',
    category: 'Content & Experience',
  },
  {
    toolName: 'Super Simple Survey',
    category: 'Content & Experience',
  },
  {
    toolName: 'Survey Anyplace',
    category: 'Content & Experience',
  },
  {
    toolName: 'SurveyGizmo',
    category: 'Content & Experience',
  },
  {
    toolName: 'SurveyLegend',
    category: 'Content & Experience',
  },
  {
    toolName: 'SurveySparrow',
    category: 'Content & Experience',
  },
  {
    toolName: 'Survs',
    category: 'Content & Experience',
  },
  {
    toolName: 'Syndeca',
    category: 'Content & Experience',
  },
  {
    toolName: 'Tabfoundry',
    category: 'Content & Experience',
  },
  {
    toolName: 'TallySpace',
    category: 'Content & Experience',
  },
  {
    toolName: 'TheROIShop',
    category: 'Content & Experience',
  },
  {
    toolName: 'ThingLink',
    category: 'Content & Experience',
  },
  {
    toolName: 'Tiled',
    category: 'Content & Experience',
  },
  {
    toolName: 'Toonimo',
    category: 'Content & Experience',
  },
  {
    toolName: 'Tumult',
    category: 'Content & Experience',
  },
  {
    toolName: 'Twixl',
    category: 'Content & Experience',
  },
  {
    toolName: 'Typeform',
    category: 'Content & Experience',
  },
  {
    toolName: 'uQuiz',
    category: 'Content & Experience',
  },
  {
    toolName: 'Verbate',
    category: 'Content & Experience',
  },
  {
    toolName: 'Versal',
    category: 'Content & Experience',
  },
  {
    toolName: 'Vinja',
    category: 'Content & Experience',
  },
  {
    toolName: 'Viralsweep',
    category: 'Content & Experience',
  },
  {
    toolName: 'Visual Antidote',
    category: 'Content & Experience',
  },
  {
    toolName: 'Visualizer',
    category: 'Content & Experience',
  },
  {
    toolName: 'Vizia',
    category: 'Content & Experience',
  },
  {
    toolName: 'Voise',
    category: 'Content & Experience',
  },
  {
    toolName: 'Vollwinkel',
    category: 'Content & Experience',
  },
  {
    toolName: 'Votigo',
    category: 'Content & Experience',
  },
  {
    toolName: 'Votion',
    category: 'Content & Experience',
  },
  {
    toolName: 'Voxco',
    category: 'Content & Experience',
  },
  {
    toolName: 'VoxVote',
    category: 'Content & Experience',
  },
  {
    toolName: 'VYPER',
    category: 'Content & Experience',
  },
  {
    toolName: 'WalkMe',
    category: 'Content & Experience',
  },
  {
    toolName: 'Webflow',
    category: 'Content & Experience',
  },
  {
    toolName: 'weForms',
    category: 'Content & Experience',
  },
  {
    toolName: 'Whatfix',
    category: 'Content & Experience',
  },
  {
    toolName: 'Widgetic',
    category: 'Content & Experience',
  },
  {
    toolName: 'Wishpond',
    category: 'Content & Experience',
  },
  {
    toolName: 'Wizehive',
    category: 'Content & Experience',
  },
  {
    toolName: 'Wizer',
    category: 'Content & Experience',
  },
  {
    toolName: 'Woobox',
    category: 'Content & Experience',
  },
  {
    toolName: 'Woorise',
    category: 'Content & Experience',
  },
  {
    toolName: 'WPForms',
    category: 'Content & Experience',
  },
  {
    toolName: 'Wrap',
    category: 'Content & Experience',
  },
  {
    toolName: 'Wufoo',
    category: 'Content & Experience',
  },
  {
    toolName: 'Wyng',
    category: 'Content & Experience',
  },
  {
    toolName: 'Zembula',
    category: 'Content & Experience',
  },
  {
    toolName: 'Zerolight',
    category: 'Content & Experience',
  },
  {
    toolName: 'Zeroqode',
    category: 'Content & Experience',
  },
  {
    toolName: 'Zmags',
    category: 'Content & Experience',
  },
  {
    toolName: '8Digits',
    category: 'Content & Experience',
  },
  {
    toolName: '366 Degrees',
    category: 'Content & Experience',
  },
  {
    toolName: 'Acoustic',
    category: 'Content & Experience',
  },
  {
    toolName: 'Act-On',
    category: 'Content & Experience',
  },
  {
    toolName: 'ACT!',
    category: 'Content & Experience',
  },
  {
    toolName: 'Actito',
    category: 'Content & Experience',
  },
  {
    toolName: 'ActiveCampaign',
    category: 'Content & Experience',
  },
  {
    toolName: 'ActiveDemand',
    category: 'Content & Experience',
  },
  {
    toolName: 'ActiveProspect',
    category: 'Content & Experience',
  },
  {
    toolName: 'Acxiom',
    category: 'Content & Experience',
  },
  {
    toolName: 'Adabra',
    category: 'Content & Experience',
  },
  {
    toolName: 'Adluge',
    category: 'Content & Experience',
  },
  {
    toolName: 'Advermind',
    category: 'Content & Experience',
  },
  {
    toolName: 'Agillic',
    category: 'Content & Experience',
  },
  {
    toolName: 'AgilOne',
    category: 'Content & Experience',
  },
  {
    toolName: 'Aioma',
    category: 'Content & Experience',
  },
  {
    toolName: 'Airship',
    category: 'Content & Experience',
  },
  {
    toolName: 'Akero',
    category: 'Content & Experience',
  },
  {
    toolName: 'Alterian',
    category: 'Content & Experience',
  },
  {
    toolName: 'ALTKRAFT',
    category: 'Content & Experience',
  },
  {
    toolName: 'Antics DMS',
    category: 'Content & Experience',
  },
  {
    toolName: 'Antvoice',
    category: 'Content & Experience',
  },
  {
    toolName: 'Applicata',
    category: 'Content & Experience',
  },
  {
    toolName: 'Aptean',
    category: 'Content & Experience',
  },
  {
    toolName: 'Aritic',
    category: 'Content & Experience',
  },
  {
    toolName: 'Artegic',
    category: 'Content & Experience',
  },
  {
    toolName: 'Artsai',
    category: 'Content & Experience',
  },
  {
    toolName: 'Audienti',
    category: 'Content & Experience',
  },
  {
    toolName: 'Automational',
    category: 'Content & Experience',
  },
  {
    toolName: 'Automatr',
    category: 'Content & Experience',
  },
  {
    toolName: 'Automizy',
    category: 'Content & Experience',
  },
  {
    toolName: 'Autopilot',
    category: 'Content & Experience',
  },
  {
    toolName: 'Axtria',
    category: 'Content & Experience',
  },
  {
    toolName: 'Beagle',
    category: 'Content & Experience',
  },
  {
    toolName: 'Bisnode',
    category: 'Content & Experience',
  },
  {
    toolName: 'Blitz',
    category: 'Content & Experience',
  },
  {
    toolName: 'Bluecore',
    category: 'Content & Experience',
  },
  {
    toolName: 'Blueshift',
    category: 'Content & Experience',
  },
  {
    toolName: 'BNS AiO',
    category: 'Content & Experience',
  },
  {
    toolName: 'Boberdoo',
    category: 'Content & Experience',
  },
  {
    toolName: 'Boingnet',
    category: 'Content & Experience',
  },
  {
    toolName: 'Booker',
    category: 'Content & Experience',
  },
  {
    toolName: 'BostonLogic',
    category: 'Content & Experience',
  },
  {
    toolName: 'Braze',
    category: 'Content & Experience',
  },
  {
    toolName: 'Bridg',
    category: 'Content & Experience',
  },
  {
    toolName: 'BSI',
    category: 'Content & Experience',
  },
  {
    toolName: 'BuyerGenomics',
    category: 'Content & Experience',
  },
  {
    toolName: 'Buzz360',
    category: 'Content & Experience',
  },
  {
    toolName: 'BuzzBoard',
    category: 'Content & Experience',
  },
  {
    toolName: 'Bycape.io',
    category: 'Content & Experience',
  },
  {
    toolName: 'Callbox',
    category: 'Content & Experience',
  },
  {
    toolName: 'CallidusCloud',
    category: 'Content & Experience',
  },
  {
    toolName: 'CampaignRunner',
    category: 'Content & Experience',
  },
  {
    toolName: 'Canddi',
    category: 'Content & Experience',
  },
  {
    toolName: 'Captavi',
    category: 'Content & Experience',
  },
  {
    toolName: 'Cato customer cloud',
    category: 'Content & Experience',
  },
  {
    toolName: 'Chainlink',
    category: 'Content & Experience',
  },
  {
    toolName: 'Cheetah Digital',
    category: 'Content & Experience',
  },
  {
    toolName: 'Chimpify',
    category: 'Content & Experience',
  },
  {
    toolName: 'chocoBRAIN',
    category: 'Content & Experience',
  },
  {
    toolName: 'Clastic',
    category: 'Content & Experience',
  },
  {
    toolName: 'Cleverworks',
    category: 'Content & Experience',
  },
  {
    toolName: 'ClickDimensions',
    category: 'Content & Experience',
  },
  {
    toolName: 'Clodura',
    category: 'Content & Experience',
  },
  {
    toolName: 'Cogia Intelligence',
    category: 'Content & Experience',
  },
  {
    toolName: 'Communigator',
    category: 'Content & Experience',
  },
  {
    toolName: 'Concep',
    category: 'Content & Experience',
  },
  {
    toolName: 'ConnectedWare',
    category: 'Content & Experience',
  },
  {
    toolName: 'ContactPigeon',
    category: 'Content & Experience',
  },
  {
    toolName: 'ConvertKit',
    category: 'Content & Experience',
  },
  {
    toolName: 'ConvertLab',
    category: 'Content & Experience',
  },
  {
    toolName: 'CrossEngage',
    category: 'Content & Experience',
  },
  {
    toolName: 'DailyStory',
    category: 'Content & Experience',
  },
  {
    toolName: 'DANAConnect',
    category: 'Content & Experience',
  },
  {
    toolName: 'Dashcord',
    category: 'Content & Experience',
  },
  {
    toolName: 'Databowl',
    category: 'Content & Experience',
  },
  {
    toolName: 'Datacrush',
    category: 'Content & Experience',
  },
  {
    toolName: 'Datatrics',
    category: 'Content & Experience',
  },
  {
    toolName: 'Daxko',
    category: 'Content & Experience',
  },
  {
    toolName: 'Delio',
    category: 'Content & Experience',
  },
  {
    toolName: 'Demandforce',
    category: 'Content & Experience',
  },
  {
    toolName: 'DemandShore',
    category: 'Content & Experience',
  },
  {
    toolName: 'DialogInsight',
    category: 'Content & Experience',
  },
  {
    toolName: 'dotdigital',
    category: 'Content & Experience',
  },
  {
    toolName: 'Drip',
    category: 'Content & Experience',
  },
  {
    toolName: 'Dux Soup',
    category: 'Content & Experience',
  },
  {
    toolName: 'DYMATRIX',
    category: 'Content & Experience',
  },
  {
    toolName: 'Dynamic Leads',
    category: 'Content & Experience',
  },
  {
    toolName: 'Edatis',
    category: 'Content & Experience',
  },
  {
    toolName: 'emarketeer',
    category: 'Content & Experience',
  },
  {
    toolName: 'Emarsys',
    category: 'Content & Experience',
  },
  {
    toolName: 'eMaximation',
    category: 'Content & Experience',
  },
  {
    toolName: 'emfluence',
    category: 'Content & Experience',
  },
  {
    toolName: 'Encharge',
    category: 'Content & Experience',
  },
  {
    toolName: 'engagebay',
    category: 'Content & Experience',
  },
  {
    toolName: 'Envoke',
    category: 'Content & Experience',
  },
  {
    toolName: 'eSputnik',
    category: 'Content & Experience',
  },
  {
    toolName: 'Everlytic',
    category: 'Content & Experience',
  },
  {
    toolName: 'Experian',
    category: 'Content & Experience',
  },
  {
    toolName: 'Experiture',
    category: 'Content & Experience',
  },
  {
    toolName: 'Exponea',
    category: 'Content & Experience',
  },
  {
    toolName: 'FanBridge',
    category: 'Content & Experience',
  },
  {
    toolName: 'Firecart.io',
    category: 'Content & Experience',
  },
  {
    toolName: 'FirstHive',
    category: 'Content & Experience',
  },
  {
    toolName: 'Flytext',
    category: 'Content & Experience',
  },
  {
    toolName: 'Force24',
    category: 'Content & Experience',
  },
  {
    toolName: 'Funnelmaker',
    category: 'Content & Experience',
  },
  {
    toolName: 'Fuse Machine',
    category: 'Content & Experience',
  },
  {
    toolName: 'FuzeIQ',
    category: 'Content & Experience',
  },
  {
    toolName: 'Gamooga',
    category: 'Content & Experience',
  },
  {
    toolName: 'Genoo',
    category: 'Content & Experience',
  },
  {
    toolName: 'GleanView',
    category: 'Content & Experience',
  },
  {
    toolName: 'GreenRope',
    category: 'Content & Experience',
  },
  {
    toolName: 'Groundhogg',
    category: 'Content & Experience',
  },
  {
    toolName: 'Growlabs',
    category: 'Content & Experience',
  },
  {
    toolName: 'Hannon Hill',
    category: 'Content & Experience',
  },
  {
    toolName: 'Hatchbuck',
    category: 'Content & Experience',
  },
  {
    toolName: 'Hey Oliver',
    category: 'Content & Experience',
  },
  {
    toolName: 'Higher Logic',
    category: 'Content & Experience',
  },
  {
    toolName: 'HubSpot Marketing Hub',
    category: 'Content & Experience',
  },
  {
    toolName: 'HubSpot Sales Hub',
    category: 'Content & Experience',
  },
  {
    toolName: 'HubSpot CMS Hub',
    category: 'Content & Experience',
  },
  {
    toolName: 'HubSpot Operations Hub',
    category: 'Content & Experience',
  },
  {
    toolName: 'HubSpot Service Hub',
    category: 'Content & Experience',
  },
  {
    toolName: 'Hull',
    category: 'Content & Experience',
  },
  {
    toolName: 'Iceberg Digital',
    category: 'Content & Experience',
  },
  {
    toolName: 'iContact',
    category: 'Content & Experience',
  },
  {
    toolName: 'IgnitionOne',
    category: 'Content & Experience',
  },
  {
    toolName: 'IKOSystem',
    category: 'Content & Experience',
  },
  {
    toolName: 'Inbound Now',
    category: 'Content & Experience',
  },
  {
    toolName: 'Inboundio',
    category: 'Content & Experience',
  },
  {
    toolName: 'Inbox25',
    category: 'Content & Experience',
  },
  {
    toolName: 'Indition',
    category: 'Content & Experience',
  },
  {
    toolName: 'INgageHub',
    category: 'Content & Experience',
  },
  {
    toolName: 'Ingenius Technologies',
    category: 'Content & Experience',
  },
  {
    toolName: 'Inspired',
    category: 'Content & Experience',
  },
  {
    toolName: 'Integrate',
    category: 'Content & Experience',
  },
  {
    toolName: 'IntellaSphere',
    category: 'Content & Experience',
  },
  {
    toolName: 'Interakt',
    category: 'Content & Experience',
  },
  {
    toolName: 'Intercom',
    category: 'Content & Experience',
  },
  {
    toolName: 'InterlinkOne',
    category: 'Content & Experience',
  },
  {
    toolName: 'iPresso',
    category: 'Content & Experience',
  },
  {
    toolName: 'Iterable',
    category: 'Content & Experience',
  },
  {
    toolName: 'itracMarketer',
    category: 'Content & Experience',
  },
  {
    toolName: 'Jeenga',
    category: 'Content & Experience',
  },
  {
    toolName: 'Jeto',
    category: 'Content & Experience',
  },
  {
    toolName: 'JINGdigital',
    category: 'Content & Experience',
  },
  {
    toolName: 'Jumplead',
    category: 'Content & Experience',
  },
  {
    toolName: 'Kitewheel',
    category: 'Content & Experience',
  },
  {
    toolName: 'Kulea',
    category: 'Content & Experience',
  },
  {
    toolName: 'Lead Champion',
    category: 'Content & Experience',
  },
  {
    toolName: 'Lead Guerrilla',
    category: 'Content & Experience',
  },
  {
    toolName: 'LeadBarrel',
    category: 'Content & Experience',
  },
  {
    toolName: 'Leadberry',
    category: 'Content & Experience',
  },
  {
    toolName: 'LeadBI',
    category: 'Content & Experience',
  },
  {
    toolName: 'Leadbyte',
    category: 'Content & Experience',
  },
  {
    toolName: 'Leadenhancer',
    category: 'Content & Experience',
  },
  {
    toolName: 'LeadFox',
    category: 'Content & Experience',
  },
  {
    toolName: 'Leadgenerator.io',
    category: 'Content & Experience',
  },
  {
    toolName: 'Leadify',
    category: 'Content & Experience',
  },
  {
    toolName: 'LeadLiaison',
    category: 'Content & Experience',
  },
  {
    toolName: 'LeadMailbox',
    category: 'Content & Experience',
  },
  {
    toolName: 'LeadManager',
    category: 'Content & Experience',
  },
  {
    toolName: 'Leadmaster',
    category: 'Content & Experience',
  },
  {
    toolName: 'Leadonance',
    category: 'Content & Experience',
  },
  {
    toolName: 'LeadPath',
    category: 'Content & Experience',
  },
  {
    toolName: 'LeaDroid',
    category: 'Content & Experience',
  },
  {
    toolName: 'LeadSift',
    category: 'Content & Experience',
  },
  {
    toolName: 'LeadSquared',
    category: 'Content & Experience',
  },
  {
    toolName: 'Leadux Gmbh',
    category: 'Content & Experience',
  },
  {
    toolName: 'Lemnisk',
    category: 'Content & Experience',
  },
  {
    toolName: 'Liana Technologies',
    category: 'Content & Experience',
  },
  {
    toolName: 'Lianacem.de',
    category: 'Content & Experience',
  },
  {
    toolName: 'Limelight',
    category: 'Content & Experience',
  },
  {
    toolName: 'Listing To Leads',
    category: 'Content & Experience',
  },
  {
    toolName: 'Loopify',
    category: 'Content & Experience',
  },
  {
    toolName: 'Loyalistic',
    category: 'Content & Experience',
  },
  {
    toolName: 'Maatoo',
    category: 'Content & Experience',
  },
  {
    toolName: 'MaaxMarket',
    category: 'Content & Experience',
  },
  {
    toolName: 'MakeContact',
    category: 'Content & Experience',
  },
  {
    toolName: 'Makesbridge',
    category: 'Content & Experience',
  },
  {
    toolName: 'MarketFox',
    category: 'Content & Experience',
  },
  {
    toolName: 'Marketing 360',
    category: 'Content & Experience',
  },
  {
    toolName: 'MarketingLeo',
    category: 'Content & Experience',
  },
  {
    toolName: 'Adobe Marketo Engage',
    category: 'Content & Experience',
  },
  {
    toolName: 'MarketPower',
    category: 'Content & Experience',
  },
  {
    toolName: 'Markitude',
    category: 'Content & Experience',
  },
  {
    toolName: 'Markovation',
    category: 'Content & Experience',
  },
  {
    toolName: 'Maropost',
    category: 'Content & Experience',
  },
  {
    toolName: 'Mautic',
    category: 'Content & Experience',
  },
  {
    toolName: 'MDC Dot',
    category: 'Content & Experience',
  },
  {
    toolName: 'Merkle',
    category: 'Content & Experience',
  },
  {
    toolName: 'Metadata',
    category: 'Content & Experience',
  },
  {
    toolName: 'Mi8 Marketing Cloud',
    category: 'Content & Experience',
  },
  {
    toolName: 'Micronotes',
    category: 'Content & Experience',
  },
  {
    toolName: 'Microsoft',
    category: 'Content & Experience',
  },
  {
    toolName: 'Mindfire',
    category: 'Content & Experience',
  },
  {
    toolName: 'MLeads',
    category: 'Content & Experience',
  },
  {
    toolName: 'MoEngage',
    category: 'Content & Experience',
  },
  {
    toolName: 'Motiva AI',
    category: 'Content & Experience',
  },
  {
    toolName: 'MOVology',
    category: 'Content & Experience',
  },
  {
    toolName: 'Movylo',
    category: 'Content & Experience',
  },
  {
    toolName: 'Mumara',
    category: 'Content & Experience',
  },
  {
    toolName: 'Net-Results',
    category: 'Content & Experience',
  },
  {
    toolName: 'Netcore',
    category: 'Content & Experience',
  },
  {
    toolName: 'Notchitup',
    category: 'Content & Experience',
  },
  {
    toolName: 'NotifyVisitors',
    category: 'Content & Experience',
  },
  {
    toolName: 'Nurture',
    category: 'Content & Experience',
  },
  {
    toolName: 'OCR7',
    category: 'Content & Experience',
  },
  {
    toolName: 'Oncord',
    category: 'Content & Experience',
  },
  {
    toolName: 'Ontraport',
    category: 'Content & Experience',
  },
  {
    toolName: 'Optingun',
    category: 'Content & Experience',
  },
  {
    toolName: 'OutboundEngine',
    category: 'Content & Experience',
  },
  {
    toolName: 'Pardot',
    category: 'Content & Experience',
  },
  {
    toolName: 'Pega',
    category: 'Content & Experience',
  },
  {
    toolName: 'PeopleVine',
    category: 'Content & Experience',
  },
  {
    toolName: 'Pipz',
    category: 'Content & Experience',
  },
  {
    toolName: 'Placester',
    category: 'Content & Experience',
  },
  {
    toolName: 'Pleasepoint',
    category: 'Content & Experience',
  },
  {
    toolName: 'Plezi',
    category: 'Content & Experience',
  },
  {
    toolName: 'Plumb5',
    category: 'Content & Experience',
  },
  {
    toolName: 'Poptin',
    category: 'Content & Experience',
  },
  {
    toolName: 'Popwallet',
    category: 'Content & Experience',
  },
  {
    toolName: 'ProCampaign',
    category: 'Content & Experience',
  },
  {
    toolName: 'Promio.net',
    category: 'Content & Experience',
  },
  {
    toolName: 'PUBLITECH',
    category: 'Content & Experience',
  },
  {
    toolName: 'PX',
    category: 'Content & Experience',
  },
  {
    toolName: 'QGraph',
    category: 'Content & Experience',
  },
  {
    toolName: 'Qrious',
    category: 'Content & Experience',
  },
  {
    toolName: 'QuickPivot',
    category: 'Content & Experience',
  },
  {
    toolName: 'RD Station',
    category: 'Content & Experience',
  },
  {
    toolName: 'Redeye',
    category: 'Content & Experience',
  },
  {
    toolName: 'RedPoint Global',
    category: 'Content & Experience',
  },
  {
    toolName: 'Related',
    category: 'Content & Experience',
  },
  {
    toolName: 'Resulticks',
    category: 'Content & Experience',
  },
  {
    toolName: 'Rezora',
    category: 'Content & Experience',
  },
  {
    toolName: 'RIghtOn Interactive',
    category: 'Content & Experience',
  },
  {
    toolName: 'RightWave',
    category: 'Content & Experience',
  },
  {
    toolName: 'Rollick',
    category: 'Content & Experience',
  },
  {
    toolName: 'Route',
    category: 'Content & Experience',
  },
  {
    toolName: 'Rulemailer',
    category: 'Content & Experience',
  },
  {
    toolName: 'SalesFusion',
    category: 'Content & Experience',
  },
  {
    toolName: 'SALESmanago',
    category: 'Content & Experience',
  },
  {
    toolName: 'SalesPanda',
    category: 'Content & Experience',
  },
  {
    toolName: 'SalesSeek',
    category: 'Content & Experience',
  },
  {
    toolName: 'Salestraction',
    category: 'Content & Experience',
  },
  {
    toolName: 'SalesWings',
    category: 'Content & Experience',
  },
  {
    toolName: 'SAM.ai',
    category: 'Content & Experience',
  },
  {
    toolName: 'SC-Networks',
    category: 'Content & Experience',
  },
  {
    toolName: 'SeedLogix',
    category: 'Content & Experience',
  },
  {
    toolName: 'Sell.Do',
    category: 'Content & Experience',
  },
  {
    toolName: 'Selligent',
    category: 'Content & Experience',
  },
  {
    toolName: 'SendInBlue',
    category: 'Content & Experience',
  },
  {
    toolName: 'SendX',
    category: 'Content & Experience',
  },
  {
    toolName: 'Sentic Technologies',
    category: 'Content & Experience',
  },
  {
    toolName: 'Sentori',
    category: 'Content & Experience',
  },
  {
    toolName: 'Sharpspring',
    category: 'Content & Experience',
  },
  {
    toolName: 'Signpost',
    category: 'Content & Experience',
  },
  {
    toolName: 'SimplyCast',
    category: 'Content & Experience',
  },
  {
    toolName: 'Sitecore',
    category: 'Content & Experience',
  },
  {
    toolName: 'Skyler360',
    category: 'Content & Experience',
  },
  {
    toolName: 'Smarketing Cloud',
    category: 'Content & Experience',
  },
  {
    toolName: 'SmarterHQ',
    category: 'Content & Experience',
  },
  {
    toolName: 'SmartMessage',
    category: 'Content & Experience',
  },
  {
    toolName: 'smoove',
    category: 'Content & Experience',
  },
  {
    toolName: 'Solomoto',
    category: 'Content & Experience',
  },
  {
    toolName: 'Sparklane',
    category: 'Content & Experience',
  },
  {
    toolName: 'Sparkroom',
    category: 'Content & Experience',
  },
  {
    toolName: 'Spike',
    category: 'Content & Experience',
  },
  {
    toolName: 'Spotmore',
    category: 'Content & Experience',
  },
  {
    toolName: 'SproutLoud',
    category: 'Content & Experience',
  },
  {
    toolName: 'Swift Digital',
    category: 'Content & Experience',
  },
  {
    toolName: 'Swrve',
    category: 'Content & Experience',
  },
  {
    toolName: 'Symplify',
    category: 'Content & Experience',
  },
  {
    toolName: 'Synerise',
    category: 'Content & Experience',
  },
  {
    toolName: 'Teradata',
    category: 'Content & Experience',
  },
  {
    toolName: 'Ternair',
    category: 'Content & Experience',
  },
  {
    toolName: 'The Orbiter Project',
    category: 'Content & Experience',
  },
  {
    toolName: 'Thorit',
    category: 'Content & Experience',
  },
  {
    toolName: 'ThriveHive',
    category: 'Content & Experience',
  },
  {
    toolName: 'TPNI',
    category: 'Content & Experience',
  },
  {
    toolName: 'Triggerbee',
    category: 'Content & Experience',
  },
  {
    toolName: 'Ukit',
    category: 'Content & Experience',
  },
  {
    toolName: 'Upshot.ai',
    category: 'Content & Experience',
  },
  {
    toolName: 'User.com',
    category: 'Content & Experience',
  },
  {
    toolName: 'V12 Data',
    category: 'Content & Experience',
  },
  {
    toolName: 'vbout',
    category: 'Content & Experience',
  },
  {
    toolName: 'Vendasta',
    category: 'Content & Experience',
  },
  {
    toolName: 'Venntive',
    category: 'Content & Experience',
  },
  {
    toolName: 'VetMatrix',
    category: 'Content & Experience',
  },
  {
    toolName: 'Visiblee',
    category: 'Content & Experience',
  },
  {
    toolName: 'Vuture',
    category: 'Content & Experience',
  },
  {
    toolName: 'WebEngage',
    category: 'Content & Experience',
  },
  {
    toolName: 'Webmecanik',
    category: 'Content & Experience',
  },
  {
    toolName: 'Webpower',
    category: 'Content & Experience',
  },
  {
    toolName: 'White Rabbit',
    category: 'Content & Experience',
  },
  {
    toolName: 'Wired Plus',
    category: 'Content & Experience',
  },
  {
    toolName: 'Womplify',
    category: 'Content & Experience',
  },
  {
    toolName: 'Xert',
    category: 'Content & Experience',
  },
  {
    toolName: 'Xzazu',
    category: 'Content & Experience',
  },
  {
    toolName: 'Yieldr',
    category: 'Content & Experience',
  },
  {
    toolName: 'Yodle',
    category: 'Content & Experience',
  },
  {
    toolName: 'Yulsn',
    category: 'Content & Experience',
  },
  {
    toolName: 'Zaius',
    category: 'Content & Experience',
  },
  {
    toolName: 'ZeetaPro',
    category: 'Content & Experience',
  },
  {
    toolName: 'Zeta',
    category: 'Content & Experience',
  },
  {
    toolName: 'Zumvu',
    category: 'Content & Experience',
  },
  {
    toolName: 'Zymplify',
    category: 'Content & Experience',
  },
  {
    toolName: 'Adalo',
    category: 'Content & Experience',
  },
  {
    toolName: 'Alpha Software',
    category: 'Content & Experience',
  },
  {
    toolName: 'App Press',
    category: 'Content & Experience',
  },
  {
    toolName: 'Appbaker',
    category: 'Content & Experience',
  },
  {
    toolName: 'Appblade',
    category: 'Content & Experience',
  },
  {
    toolName: 'Appcelerator',
    category: 'Content & Experience',
  },
  {
    toolName: 'Appery.io',
    category: 'Content & Experience',
  },
  {
    toolName: 'Appfigures',
    category: 'Content & Experience',
  },
  {
    toolName: 'AppGyver',
    category: 'Content & Experience',
  },
  {
    toolName: 'Appinstitute',
    category: 'Content & Experience',
  },
  {
    toolName: 'Appivo',
    category: 'Content & Experience',
  },
  {
    toolName: 'Apple',
    category: 'Content & Experience',
  },
  {
    toolName: 'Appmachine',
    category: 'Content & Experience',
  },
  {
    toolName: 'AppPresser',
    category: 'Content & Experience',
  },
  {
    toolName: 'Appsbar',
    category: 'Content & Experience',
  },
  {
    toolName: 'AppsBuilder',
    category: 'Content & Experience',
  },
  {
    toolName: 'appScatter',
    category: 'Content & Experience',
  },
  {
    toolName: 'Appscend',
    category: 'Content & Experience',
  },
  {
    toolName: 'AppsFlyer',
    category: 'Content & Experience',
  },
  {
    toolName: 'AppSheet',
    category: 'Content & Experience',
  },
  {
    toolName: 'Apptimize',
    category: 'Content & Experience',
  },
  {
    toolName: 'Apptopia',
    category: 'Content & Experience',
  },
  {
    toolName: 'AppTweak',
    category: 'Content & Experience',
  },
  {
    toolName: 'Appy Pie',
    category: 'Content & Experience',
  },
  {
    toolName: 'Appyourself',
    category: 'Content & Experience',
  },
  {
    toolName: 'Apteligent',
    category: 'Content & Experience',
  },
  {
    toolName: 'Arctouch',
    category: 'Content & Experience',
  },
  {
    toolName: 'Aruba Networks',
    category: 'Content & Experience',
  },
  {
    toolName: 'Attendify',
    category: 'Content & Experience',
  },
  {
    toolName: 'Augur',
    category: 'Content & Experience',
  },
  {
    toolName: 'Azetone',
    category: 'Content & Experience',
  },
  {
    toolName: 'Backinapp',
    category: 'Content & Experience',
  },
  {
    toolName: 'Betty Blocks',
    category: 'Content & Experience',
  },
  {
    toolName: 'Bitbar',
    category: 'Content & Experience',
  },
  {
    toolName: 'Bizness Apps',
    category: 'Content & Experience',
  },
  {
    toolName: 'Branding Brand',
    category: 'Content & Experience',
  },
  {
    toolName: 'Brightxpress',
    category: 'Content & Experience',
  },
  {
    toolName: 'Bubble',
    category: 'Content & Experience',
  },
  {
    toolName: 'Buildfire',
    category: 'Content & Experience',
  },
  {
    toolName: 'Built.io',
    category: 'Content & Experience',
  },
  {
    toolName: 'CafeX',
    category: 'Content & Experience',
  },
  {
    toolName: 'Catalog Bar',
    category: 'Content & Experience',
  },
  {
    toolName: 'CleverTap',
    category: 'Content & Experience',
  },
  {
    toolName: 'Como',
    category: 'Content & Experience',
  },
  {
    toolName: 'Copilot',
    category: 'Content & Experience',
  },
  {
    toolName: 'CreateMyFreeApp',
    category: 'Content & Experience',
  },
  {
    toolName: 'Deeplink',
    category: 'Content & Experience',
  },
  {
    toolName: 'Dropsource',
    category: 'Content & Experience',
  },
  {
    toolName: 'Dynatrace',
    category: 'Content & Experience',
  },
  {
    toolName: 'Eachscape',
    category: 'Content & Experience',
  },
  {
    toolName: 'EASY SOFTWARE ApiOmat',
    category: 'Content & Experience',
  },
  {
    toolName: 'Famous',
    category: 'Content & Experience',
  },
  {
    toolName: 'Feedhenry',
    category: 'Content & Experience',
  },
  {
    toolName: 'Flowfinity',
    category: 'Content & Experience',
  },
  {
    toolName: 'FollowAnalytics',
    category: 'Content & Experience',
  },
  {
    toolName: 'Glide',
    category: 'Content & Experience',
  },
  {
    toolName: 'GoodBarber',
    category: 'Content & Experience',
  },
  {
    toolName: 'Guidebook',
    category: 'Content & Experience',
  },
  {
    toolName: 'Halosys',
    category: 'Content & Experience',
  },
  {
    toolName: 'iBuildApp',
    category: 'Content & Experience',
  },
  {
    toolName: 'Inglobe Technologies',
    category: 'Content & Experience',
  },
  {
    toolName: 'Instappy',
    category: 'Content & Experience',
  },
  {
    toolName: 'ionic',
    category: 'Content & Experience',
  },
  {
    toolName: 'ironSource',
    category: 'Content & Experience',
  },
  {
    toolName: 'Kidozen',
    category: 'Content & Experience',
  },
  {
    toolName: 'King of App',
    category: 'Content & Experience',
  },
  {
    toolName: 'Kony',
    category: 'Content & Experience',
  },
  {
    toolName: 'Lineupr',
    category: 'Content & Experience',
  },
  {
    toolName: 'Liquid',
    category: 'Content & Experience',
  },
  {
    toolName: 'Lumavate',
    category: 'Content & Experience',
  },
  {
    toolName: 'MobApp Creator',
    category: 'Content & Experience',
  },
  {
    toolName: 'Mobidonia',
    category: 'Content & Experience',
  },
  {
    toolName: 'Mobile Roadie',
    category: 'Content & Experience',
  },
  {
    toolName: 'MobileBridge',
    category: 'Content & Experience',
  },
  {
    toolName: 'Mobileframe',
    category: 'Content & Experience',
  },
  {
    toolName: 'Mobincube',
    category: 'Content & Experience',
  },
  {
    toolName: 'Modo',
    category: 'Content & Experience',
  },
  {
    toolName: 'Monaca',
    category: 'Content & Experience',
  },
  {
    toolName: 'Nutshell Apps',
    category: 'Content & Experience',
  },
  {
    toolName: 'Open As App',
    category: 'Content & Experience',
  },
  {
    toolName: 'OpenBack',
    category: 'Content & Experience',
  },
  {
    toolName: 'OutSystems',
    category: 'Content & Experience',
  },
  {
    toolName: 'Passion.io',
    category: 'Content & Experience',
  },
  {
    toolName: 'Phunware',
    category: 'Content & Experience',
  },
  {
    toolName: 'Progress',
    category: 'Content & Experience',
  },
  {
    toolName: 'Rollout.io',
    category: 'Content & Experience',
  },
  {
    toolName: 'SauceLabs',
    category: 'Content & Experience',
  },
  {
    toolName: 'Sencha',
    category: 'Content & Experience',
  },
  {
    toolName: 'Shots',
    category: 'Content & Experience',
  },
  {
    toolName: 'Shoutem',
    category: 'Content & Experience',
  },
  {
    toolName: 'Siberian',
    category: 'Content & Experience',
  },
  {
    toolName: 'Swiftic',
    category: 'Content & Experience',
  },
  {
    toolName: 'Taplytics',
    category: 'Content & Experience',
  },
  {
    toolName: 'TapRight',
    category: 'Content & Experience',
  },
  {
    toolName: 'Tapstream',
    category: 'Content & Experience',
  },
  {
    toolName: 'TextPuff',
    category: 'Content & Experience',
  },
  {
    toolName: 'Thetool',
    category: 'Content & Experience',
  },
  {
    toolName: 'Thunkable',
    category: 'Content & Experience',
  },
  {
    toolName: 'Tune',
    category: 'Content & Experience',
  },
  {
    toolName: 'Twilio',
    category: 'Content & Experience',
  },
  {
    toolName: 'UXCam',
    category: 'Content & Experience',
  },
  {
    toolName: 'ViziApps',
    category: 'Content & Experience',
  },
  {
    toolName: 'Wem',
    category: 'Content & Experience',
  },
  {
    toolName: 'Xamarin',
    category: 'Content & Experience',
  },
  {
    toolName: 'Yapp',
    category: 'Content & Experience',
  },
  {
    toolName: 'Ytel',
    category: 'Content & Experience',
  },
  {
    toolName: '[24]7',
    category: 'Content & Experience',
  },
  {
    toolName: '8Seconds',
    category: 'Content & Experience',
  },
  {
    toolName: '40Nuggets',
    category: 'Content & Experience',
  },
  {
    toolName: 'A/B Tasty',
    category: 'Content & Experience',
  },
  {
    toolName: 'Accenture',
    category: 'Content & Experience',
  },
  {
    toolName: 'Adalysis',
    category: 'Content & Experience',
  },
  {
    toolName: 'Adapti',
    category: 'Content & Experience',
  },
  {
    toolName: 'Adaptive Campaigns',
    category: 'Content & Experience',
  },
  {
    toolName: 'AdBasis',
    category: 'Content & Experience',
  },
  {
    toolName: 'Adpushup',
    category: 'Content & Experience',
  },
  {
    toolName: 'Albert',
    category: 'Content & Experience',
  },
  {
    toolName: 'Amplero',
    category: 'Content & Experience',
  },
  {
    toolName: 'Appocalypsis',
    category: 'Content & Experience',
  },
  {
    toolName: 'Apptus',
    category: 'Content & Experience',
  },
  {
    toolName: 'Beyable',
    category: 'Content & Experience',
  },
  {
    toolName: 'BloomReach',
    category: 'Content & Experience',
  },
  {
    toolName: 'Bouncex',
    category: 'Content & Experience',
  },
  {
    toolName: 'Bound',
    category: 'Content & Experience',
  },
  {
    toolName: 'BrightInfo',
    category: 'Content & Experience',
  },
  {
    toolName: 'CaliberMind',
    category: 'Content & Experience',
  },
  {
    toolName: 'Canopy Labs',
    category: 'Content & Experience',
  },
  {
    toolName: 'Captain Growth',
    category: 'Content & Experience',
  },
  {
    toolName: 'Certona',
    category: 'Content & Experience',
  },
  {
    toolName: 'ChangeAgain',
    category: 'Content & Experience',
  },
  {
    toolName: 'ClickFunnels',
    category: 'Content & Experience',
  },
  {
    toolName: 'Clickthroo',
    category: 'Content & Experience',
  },
  {
    toolName: 'Cloud.IQ',
    category: 'Content & Experience',
  },
  {
    toolName: 'Clutch',
    category: 'Content & Experience',
  },
  {
    toolName: 'Coherent Path',
    category: 'Content & Experience',
  },
  {
    toolName: 'CompeteShark',
    category: 'Content & Experience',
  },
  {
    toolName: 'Concentric',
    category: 'Content & Experience',
  },
  {
    toolName: 'Conductrics',
    category: 'Content & Experience',
  },
  {
    toolName: 'Contactlab',
    category: 'Content & Experience',
  },
  {
    toolName: 'ContentSquare',
    category: 'Content & Experience',
  },
  {
    toolName: 'Converdiant',
    category: 'Content & Experience',
  },
  {
    toolName: 'Converly',
    category: 'Content & Experience',
  },
  {
    toolName: 'Convert',
    category: 'Content & Experience',
  },
  {
    toolName: 'Convertize',
    category: 'Content & Experience',
  },
  {
    toolName: 'CoolTool',
    category: 'Content & Experience',
  },
  {
    toolName: 'Cxense',
    category: 'Content & Experience',
  },
  {
    toolName: 'Demandbase',
    category: 'Content & Experience',
  },
  {
    toolName: 'Driveback',
    category: 'Content & Experience',
  },
  {
    toolName: 'DynamicYield',
    category: 'Content & Experience',
  },
  {
    toolName: 'e-Spirit, Inc.',
    category: 'Content & Experience',
  },
  {
    toolName: 'Effective Experiments',
    category: 'Content & Experience',
  },
  {
    toolName: 'Episerver',
    category: 'Content & Experience',
  },
  {
    toolName: 'Ethnio',
    category: 'Content & Experience',
  },
  {
    toolName: 'etracker Targeting Suite',
    category: 'Content & Experience',
  },
  {
    toolName: 'Evergage',
    category: 'Content & Experience',
  },
  {
    toolName: 'Evolv',
    category: 'Content & Experience',
  },
  {
    toolName: 'Exchange Solutions',
    category: 'Content & Experience',
  },
  {
    toolName: 'ExitIntel',
    category: 'Content & Experience',
  },
  {
    toolName: 'ExitMonitor',
    category: 'Content & Experience',
  },
  {
    toolName: 'Eyequant',
    category: 'Content & Experience',
  },
  {
    toolName: 'EyesDecide',
    category: 'Content & Experience',
  },
  {
    toolName: 'ezoic',
    category: 'Content & Experience',
  },
  {
    toolName: 'Fanomena',
    category: 'Content & Experience',
  },
  {
    toolName: 'FengGUI',
    category: 'Content & Experience',
  },
  {
    toolName: 'Foundit',
    category: 'Content & Experience',
  },
  {
    toolName: 'Freespee',
    category: 'Content & Experience',
  },
  {
    toolName: 'Fresh Relevance',
    category: 'Content & Experience',
  },
  {
    toolName: 'FreshWorks',
    category: 'Content & Experience',
  },
  {
    toolName: 'Frosmo',
    category: 'Content & Experience',
  },
  {
    toolName: 'FunnelEnvy',
    category: 'Content & Experience',
  },
  {
    toolName: 'Gated Content',
    category: 'Content & Experience',
  },
  {
    toolName: 'GeoFli',
    category: 'Content & Experience',
  },
  {
    toolName: 'Ghostery',
    category: 'Content & Experience',
  },
  {
    toolName: 'Giftd',
    category: 'Content & Experience',
  },
  {
    toolName: 'Google Ad Scripts',
    category: 'Content & Experience',
  },
  {
    toolName: 'GrooveJar',
    category: 'Content & Experience',
  },
  {
    toolName: 'GrowthGiant',
    category: 'Content & Experience',
  },
  {
    toolName: 'HiConversion',
    category: 'Content & Experience',
  },
  {
    toolName: 'Hushly',
    category: 'Content & Experience',
  },
  {
    toolName: 'Idio',
    category: 'Content & Experience',
  },
  {
    toolName: 'Improvely',
    category: 'Content & Experience',
  },
  {
    toolName: 'Insider',
    category: 'Content & Experience',
  },
  {
    toolName: 'Instapage',
    category: 'Content & Experience',
  },
  {
    toolName: 'Intellimize',
    category: 'Content & Experience',
  },
  {
    toolName: 'Intempt Technologies',
    category: 'Content & Experience',
  },
  {
    toolName: 'Jahia',
    category: 'Content & Experience',
  },
  {
    toolName: 'Justuno',
    category: 'Content & Experience',
  },
  {
    toolName: 'Kameleoon',
    category: 'Content & Experience',
  },
  {
    toolName: 'Karooya',
    category: 'Content & Experience',
  },
  {
    toolName: 'Kibo',
    category: 'Content & Experience',
  },
  {
    toolName: 'Knexus',
    category: 'Content & Experience',
  },
  {
    toolName: 'Lander',
    category: 'Content & Experience',
  },
  {
    toolName: 'Landingi',
    category: 'Content & Experience',
  },
  {
    toolName: 'Launchrock',
    category: 'Content & Experience',
  },
  {
    toolName: 'Leadpages',
    category: 'Content & Experience',
  },
  {
    toolName: 'LeadsRX',
    category: 'Content & Experience',
  },
  {
    toolName: 'Leanplum',
    category: 'Content & Experience',
  },
  {
    toolName: 'LetReach',
    category: 'Content & Experience',
  },
  {
    toolName: 'LeveragePoint',
    category: 'Content & Experience',
  },
  {
    toolName: 'Lifecycle',
    category: 'Content & Experience',
  },
  {
    toolName: 'LiftIgniter',
    category: 'Content & Experience',
  },
  {
    toolName: 'Liftmap',
    category: 'Content & Experience',
  },
  {
    toolName: 'Load Focus',
    category: 'Content & Experience',
  },
  {
    toolName: 'LoadImpact',
    category: 'Content & Experience',
  },
  {
    toolName: 'Logic Hop',
    category: 'Content & Experience',
  },
  {
    toolName: 'Loop11',
    category: 'Content & Experience',
  },
  {
    toolName: 'MarketingOptimizer',
    category: 'Content & Experience',
  },
  {
    toolName: 'Maxymizely',
    category: 'Content & Experience',
  },
  {
    toolName: 'Monetate',
    category: 'Content & Experience',
  },
  {
    toolName: 'Monoloop',
    category: 'Content & Experience',
  },
  {
    toolName: 'Mutiny',
    category: 'Content & Experience',
  },
  {
    toolName: 'Nelio',
    category: 'Content & Experience',
  },
  {
    toolName: 'Netmining',
    category: 'Content & Experience',
  },
  {
    toolName: 'NextUser',
    category: 'Content & Experience',
  },
  {
    toolName: 'Northpage',
    category: 'Content & Experience',
  },
  {
    toolName: 'Nosto',
    category: 'Content & Experience',
  },
  {
    toolName: 'Now Interact',
    category: 'Content & Experience',
  },
  {
    toolName: 'Ometrics',
    category: 'Content & Experience',
  },
  {
    toolName: 'OmniConvert',
    category: 'Content & Experience',
  },
  {
    toolName: 'Opteo',
    category: 'Content & Experience',
  },
  {
    toolName: 'Optimizely',
    category: 'Content & Experience',
  },
  {
    toolName: 'Optimizepress',
    category: 'Content & Experience',
  },
  {
    toolName: 'Optimonk',
    category: 'Content & Experience',
  },
  {
    toolName: 'Optimove',
    category: 'Content & Experience',
  },
  {
    toolName: 'OptInMonster',
    category: 'Content & Experience',
  },
  {
    toolName: 'Optkit',
    category: 'Content & Experience',
  },
  {
    toolName: 'Optmyzr',
    category: 'Content & Experience',
  },
  {
    toolName: 'Padicode',
    category: 'Content & Experience',
  },
  {
    toolName: 'PageMutant',
    category: 'Content & Experience',
  },
  {
    toolName: 'PageWiz',
    category: 'Content & Experience',
  },
  {
    toolName: 'Personify XP',
    category: 'Content & Experience',
  },
  {
    toolName: 'Personyze',
    category: 'Content & Experience',
  },
  {
    toolName: 'PFL',
    category: 'Content & Experience',
  },
  {
    toolName: 'Piano',
    category: 'Content & Experience',
  },
  {
    toolName: 'Pippity',
    category: 'Content & Experience',
  },
  {
    toolName: 'Populr',
    category: 'Content & Experience',
  },
  {
    toolName: 'PossibleNow',
    category: 'Content & Experience',
  },
  {
    toolName: 'Postclick',
    category: 'Content & Experience',
  },
  {
    toolName: 'Privyr',
    category: 'Content & Experience',
  },
  {
    toolName: 'ProAdly',
    category: 'Content & Experience',
  },
  {
    toolName: 'PROS',
    category: 'Content & Experience',
  },
  {
    toolName: 'Prudsys',
    category: 'Content & Experience',
  },
  {
    toolName: 'Purlem',
    category: 'Content & Experience',
  },
  {
    toolName: 'Pyze',
    category: 'Content & Experience',
  },
  {
    toolName: 'Qubit',
    category: 'Content & Experience',
  },
  {
    toolName: 'Quicksprout',
    category: 'Content & Experience',
  },
  {
    toolName: 'Qwardo',
    category: 'Content & Experience',
  },
  {
    toolName: 'Reactful',
    category: 'Content & Experience',
  },
  {
    toolName: 'REES46',
    category: 'Content & Experience',
  },
  {
    toolName: 'Reflektion',
    category: 'Content & Experience',
  },
  {
    toolName: 'Retention Science',
    category: 'Content & Experience',
  },
  {
    toolName: 'Revtrax',
    category: 'Content & Experience',
  },
  {
    toolName: 'RichRelevance',
    category: 'Content & Experience',
  },
  {
    toolName: 'RocketPop',
    category: 'Content & Experience',
  },
  {
    toolName: 'Roojoom',
    category: 'Content & Experience',
  },
  {
    toolName: 'Sailthru',
    category: 'Content & Experience',
  },
  {
    toolName: 'Segmatic',
    category: 'Content & Experience',
  },
  {
    toolName: 'Segmint',
    category: 'Content & Experience',
  },
  {
    toolName: 'SessionM',
    category: 'Content & Experience',
  },
  {
    toolName: 'SiteGainer',
    category: 'Content & Experience',
  },
  {
    toolName: 'Siteimprove',
    category: 'Content & Experience',
  },
  {
    toolName: 'SiteSpect',
    category: 'Content & Experience',
  },
  {
    toolName: 'Skymosity',
    category: 'Content & Experience',
  },
  {
    toolName: 'Sleeknote',
    category: 'Content & Experience',
  },
  {
    toolName: 'SmartBear',
    category: 'Content & Experience',
  },
  {
    toolName: 'SmartFocus',
    category: 'Content & Experience',
  },
  {
    toolName: 'SmoothConversion',
    category: 'Content & Experience',
  },
  {
    toolName: 'SoloSegment',
    category: 'Content & Experience',
  },
  {
    toolName: 'Spaceboost',
    category: 'Content & Experience',
  },
  {
    toolName: 'SparkPage',
    category: 'Content & Experience',
  },
  {
    toolName: 'Spinnakr',
    category: 'Content & Experience',
  },
  {
    toolName: 'Spotright',
    category: 'Content & Experience',
  },
  {
    toolName: 'Sticky',
    category: 'Content & Experience',
  },
  {
    toolName: 'Sumo',
    category: 'Content & Experience',
  },
  {
    toolName: 'Target2Sell',
    category: 'Content & Experience',
  },
  {
    toolName: 'Teston',
    category: 'Content & Experience',
  },
  {
    toolName: 'TrafficTruffle',
    category: 'Content & Experience',
  },
  {
    toolName: 'TravelTime Platform',
    category: 'Content & Experience',
  },
  {
    toolName: 'True Fit',
    category: 'Content & Experience',
  },
  {
    toolName: 'Tweakwise',
    category: 'Content & Experience',
  },
  {
    toolName: 'Twik',
    category: 'Content & Experience',
  },
  {
    toolName: 'Unbounce',
    category: 'Content & Experience',
  },
  {
    toolName: 'Unless',
    category: 'Content & Experience',
  },
  {
    toolName: 'UpliftROI',
    category: 'Content & Experience',
  },
  {
    toolName: 'UpRank',
    category: 'Content & Experience',
  },
  {
    toolName: 'UsabilityHub',
    category: 'Content & Experience',
  },
  {
    toolName: 'UseItBetter',
    category: 'Content & Experience',
  },
  {
    toolName: 'Userbrain',
    category: 'Content & Experience',
  },
  {
    toolName: 'Userlytics',
    category: 'Content & Experience',
  },
  {
    toolName: 'UserTesting',
    category: 'Content & Experience',
  },
  {
    toolName: 'UserZoom',
    category: 'Content & Experience',
  },
  {
    toolName: 'Validately',
    category: 'Content & Experience',
  },
  {
    toolName: 'Vibetrace',
    category: 'Content & Experience',
  },
  {
    toolName: 'Volkside',
    category: 'Content & Experience',
  },
  {
    toolName: 'VWO',
    category: 'Content & Experience',
  },
  {
    toolName: 'Webeo',
    category: 'Content & Experience',
  },
  {
    toolName: 'Webtrekk',
    category: 'Content & Experience',
  },
  {
    toolName: 'Webtrends',
    category: 'Content & Experience',
  },
  {
    toolName: 'WEVO',
    category: 'Content & Experience',
  },
  {
    toolName: 'WhatUsersDo',
    category: 'Content & Experience',
  },
  {
    toolName: 'Wigzo',
    category: 'Content & Experience',
  },
  {
    toolName: 'Winbounce',
    category: 'Content & Experience',
  },
  {
    toolName: 'Wingify',
    category: 'Content & Experience',
  },
  {
    toolName: 'Wylei',
    category: 'Content & Experience',
  },
  {
    toolName: 'Yext',
    category: 'Content & Experience',
  },
  {
    toolName: 'Yieldify',
    category: 'Content & Experience',
  },
  {
    toolName: 'Yottaa',
    category: 'Content & Experience',
  },
  {
    toolName: 'Advanced Web Ranking',
    category: 'Content & Experience',
  },
  {
    toolName: 'ahrefs',
    category: 'Content & Experience',
  },
  {
    toolName: 'Altezza',
    category: 'Content & Experience',
  },
  {
    toolName: 'Attracta',
    category: 'Content & Experience',
  },
  {
    toolName: 'Audisto GmbH',
    category: 'Content & Experience',
  },
  {
    toolName: 'Authoritas',
    category: 'Content & Experience',
  },
  {
    toolName: 'AuthorityLabs',
    category: 'Content & Experience',
  },
  {
    toolName: 'BeamUsUp',
    category: 'Content & Experience',
  },
  {
    toolName: 'Bing',
    category: 'Content & Experience',
  },
  {
    toolName: 'Botify',
    category: 'Content & Experience',
  },
  {
    toolName: 'BrightEdge',
    category: 'Content & Experience',
  },
  {
    toolName: 'BrightLocal',
    category: 'Content & Experience',
  },
  {
    toolName: 'Bruce Clay',
    category: 'Content & Experience',
  },
  {
    toolName: 'CanIRank',
    category: 'Content & Experience',
  },
  {
    toolName: 'Caphyon',
    category: 'Content & Experience',
  },
  {
    toolName: 'Clickx',
    category: 'Content & Experience',
  },
  {
    toolName: 'Cocolyze',
    category: 'Content & Experience',
  },
  {
    toolName: 'Cognitive SEO',
    category: 'Content & Experience',
  },
  {
    toolName: 'Contentking',
    category: 'Content & Experience',
  },
  {
    toolName: 'Curv.io',
    category: 'Content & Experience',
  },
  {
    toolName: 'Cuutio',
    category: 'Content & Experience',
  },
  {
    toolName: 'Dareboost',
    category: 'Content & Experience',
  },
  {
    toolName: 'DeepCrawl',
    category: 'Content & Experience',
  },
  {
    toolName: 'DemandSphere',
    category: 'Content & Experience',
  },
  {
    toolName: 'Dragon Metrics',
    category: 'Content & Experience',
  },
  {
    toolName: 'EasyRedir',
    category: 'Content & Experience',
  },
  {
    toolName: 'Empowerkit',
    category: 'Content & Experience',
  },
  {
    toolName: 'FatJoe',
    category: 'Content & Experience',
  },
  {
    toolName: 'Glasshat',
    category: 'Content & Experience',
  },
  {
    toolName: 'GoingUp',
    category: 'Content & Experience',
  },
  {
    toolName: 'Google Trends',
    category: 'Content & Experience',
  },
  {
    toolName: 'gShift Labs',
    category: 'Content & Experience',
  },
  {
    toolName: 'HitTail',
    category: 'Content & Experience',
  },
  {
    toolName: 'Irayo',
    category: 'Content & Experience',
  },
  {
    toolName: 'Jaaxy',
    category: 'Content & Experience',
  },
  {
    toolName: 'Jumpshot',
    category: 'Content & Experience',
  },
  {
    toolName: 'K-Meta',
    category: 'Content & Experience',
  },
  {
    toolName: 'Kerboo',
    category: 'Content & Experience',
  },
  {
    toolName: 'Keyword Machine',
    category: 'Content & Experience',
  },
  {
    toolName: 'Keyword Studio',
    category: 'Content & Experience',
  },
  {
    toolName: 'Keyword Tool',
    category: 'Content & Experience',
  },
  {
    toolName: 'KeywordEye',
    category: 'Content & Experience',
  },
  {
    toolName: 'KWFinder',
    category: 'Content & Experience',
  },
  {
    toolName: 'Linkdex',
    category: 'Content & Experience',
  },
  {
    toolName: 'Linkio',
    category: 'Content & Experience',
  },
  {
    toolName: 'LinkPatrol',
    category: 'Content & Experience',
  },
  {
    toolName: 'LinkResearchTools (LRT)',
    category: 'Content & Experience',
  },
  {
    toolName: 'Lipperhey',
    category: 'Content & Experience',
  },
  {
    toolName: 'LotusJump',
    category: 'Content & Experience',
  },
  {
    toolName: 'Majestic',
    category: 'Content & Experience',
  },
  {
    toolName: 'Mangools',
    category: 'Content & Experience',
  },
  {
    toolName: 'MarketGoo',
    category: 'Content & Experience',
  },
  {
    toolName: 'Marketing Miner',
    category: 'Content & Experience',
  },
  {
    toolName: 'Meta Glossary',
    category: 'Content & Experience',
  },
  {
    toolName: 'Mondovo',
    category: 'Content & Experience',
  },
  {
    toolName: 'MonitorBacklinks',
    category: 'Content & Experience',
  },
  {
    toolName: 'Moonsearch',
    category: 'Content & Experience',
  },
  {
    toolName: 'Morningscore',
    category: 'Content & Experience',
  },
  {
    toolName: 'Moz',
    category: 'Content & Experience',
  },
  {
    toolName: 'Neil Patel',
    category: 'Content & Experience',
  },
  {
    toolName: 'Netpeak Software',
    category: 'Content & Experience',
  },
  {
    toolName: 'NinjaCat',
    category: 'Content & Experience',
  },
  {
    toolName: 'nTopic',
    category: 'Content & Experience',
  },
  {
    toolName: 'OnCrawl',
    category: 'Content & Experience',
  },
  {
    toolName: 'OneproSEO',
    category: 'Content & Experience',
  },
  {
    toolName: 'Pi Datametrics',
    category: 'Content & Experience',
  },
  {
    toolName: 'ProRankTracker',
    category: 'Content & Experience',
  },
  {
    toolName: 'ProTop',
    category: 'Content & Experience',
  },
  {
    toolName: 'PureOxygenLabs',
    category: 'Content & Experience',
  },
  {
    toolName: 'Rank Ranger',
    category: 'Content & Experience',
  },
  {
    toolName: 'Rank Science',
    category: 'Content & Experience',
  },
  {
    toolName: 'RankActive',
    category: 'Content & Experience',
  },
  {
    toolName: 'rankingCoach GmbH',
    category: 'Content & Experience',
  },
  {
    toolName: 'Rankinity',
    category: 'Content & Experience',
  },
  {
    toolName: 'RankTracker',
    category: 'Content & Experience',
  },
  {
    toolName: 'RankTrackr',
    category: 'Content & Experience',
  },
  {
    toolName: 'Rankwatch',
    category: 'Content & Experience',
  },
  {
    toolName: 'Raven',
    category: 'Content & Experience',
  },
  {
    toolName: 'Rio SEO',
    category: 'Content & Experience',
  },
  {
    toolName: 'rmoov',
    category: 'Content & Experience',
  },
  {
    toolName: 'Ryte',
    category: 'Content & Experience',
  },
  {
    toolName: 'Screaming Frog',
    category: 'Content & Experience',
  },
  {
    toolName: 'SE Ranking',
    category: 'Content & Experience',
  },
  {
    toolName: 'SearchMan',
    category: 'Content & Experience',
  },
  {
    toolName: 'Searchmetrics',
    category: 'Content & Experience',
  },
  {
    toolName: 'Searchramen',
    category: 'Content & Experience',
  },
  {
    toolName: 'SearchTrackr',
    category: 'Content & Experience',
  },
  {
    toolName: 'SECockpit',
    category: 'Content & Experience',
  },
  {
    toolName: 'Semji',
    category: 'Content & Experience',
  },
  {
    toolName: 'SEMRush',
    category: 'Content & Experience',
  },
  {
    toolName: 'SEO Crawler',
    category: 'Content & Experience',
  },
  {
    toolName: 'SEO Panel',
    category: 'Content & Experience',
  },
  {
    toolName: 'SEO PowerSuite',
    category: 'Content & Experience',
  },
  {
    toolName: 'Seo Samba',
    category: 'Content & Experience',
  },
  {
    toolName: 'SEO4Ajax',
    category: 'Content & Experience',
  },
  {
    toolName: 'SEObility',
    category: 'Content & Experience',
  },
  {
    toolName: 'SeoClarity',
    category: 'Content & Experience',
  },
  {
    toolName: 'Seodity',
    category: 'Content & Experience',
  },
  {
    toolName: 'seoGEEK',
    category: 'Content & Experience',
  },
  {
    toolName: 'SEOJet',
    category: 'Content & Experience',
  },
  {
    toolName: 'SEOlytics',
    category: 'Content & Experience',
  },
  {
    toolName: 'SEOlyzer',
    category: 'Content & Experience',
  },
  {
    toolName: 'SEOmator',
    category: 'Content & Experience',
  },
  {
    toolName: 'SEOmonitor',
    category: 'Content & Experience',
  },
  {
    toolName: 'SEOpageoptimizer',
    category: 'Content & Experience',
  },
  {
    toolName: 'SEOprofiler',
    category: 'Content & Experience',
  },
  {
    toolName: 'SEOQuake',
    category: 'Content & Experience',
  },
  {
    toolName: 'SEORadar',
    category: 'Content & Experience',
  },
  {
    toolName: 'SEOzoom',
    category: 'Content & Experience',
  },
  {
    toolName: 'SerpScan',
    category: 'Content & Experience',
  },
  {
    toolName: 'Serpstat',
    category: 'Content & Experience',
  },
  {
    toolName: 'SheerSEO',
    category: 'Content & Experience',
  },
  {
    toolName: 'Sitebulb',
    category: 'Content & Experience',
  },
  {
    toolName: 'Sitechecker',
    category: 'Content & Experience',
  },
  {
    toolName: 'Siteguru',
    category: 'Content & Experience',
  },
  {
    toolName: 'Siteoscope',
    category: 'Content & Experience',
  },
  {
    toolName: 'SoloSEO',
    category: 'Content & Experience',
  },
  {
    toolName: 'Soovle',
    category: 'Content & Experience',
  },
  {
    toolName: 'SpyFu',
    category: 'Content & Experience',
  },
  {
    toolName: 'SwissMadeMarketing',
    category: 'Content & Experience',
  },
  {
    toolName: 'Synup',
    category: 'Content & Experience',
  },
  {
    toolName: 'The Search Monitor',
    category: 'Content & Experience',
  },
  {
    toolName: 'Thruuu',
    category: 'Content & Experience',
  },
  {
    toolName: 'Topvisor',
    category: 'Content & Experience',
  },
  {
    toolName: 'Traffic Travis',
    category: 'Content & Experience',
  },
  {
    toolName: 'TribeLocal',
    category: 'Content & Experience',
  },
  {
    toolName: 'Tylio',
    category: 'Content & Experience',
  },
  {
    toolName: 'Uberall',
    category: 'Content & Experience',
  },
  {
    toolName: 'Unamo',
    category: 'Content & Experience',
  },
  {
    toolName: 'UpCity',
    category: 'Content & Experience',
  },
  {
    toolName: 'URLprofiler',
    category: 'Content & Experience',
  },
  {
    toolName: 'Varvy',
    category: 'Content & Experience',
  },
  {
    toolName: 'Visably',
    category: 'Content & Experience',
  },
  {
    toolName: 'Web CEO',
    category: 'Content & Experience',
  },
  {
    toolName: 'WhiteSpark',
    category: 'Content & Experience',
  },
  {
    toolName: 'Woorank',
    category: 'Content & Experience',
  },
  {
    toolName: 'Wordtracker',
    category: 'Content & Experience',
  },
  {
    toolName: 'WP SEO Hub',
    category: 'Content & Experience',
  },
  {
    toolName: 'Xovi',
    category: 'Content & Experience',
  },
  {
    toolName: 'Yoast',
    category: 'Content & Experience',
  },
  {
    toolName: 'Zigstat',
    category: 'Content & Experience',
  },
  {
    toolName: 'Accordium',
    category: 'Content & Experience',
  },
  {
    toolName: 'Animoto',
    category: 'Content & Experience',
  },
  {
    toolName: 'AudiencePlayer',
    category: 'Content & Experience',
  },
  {
    toolName: 'Autheos',
    category: 'Content & Experience',
  },
  {
    toolName: 'Bebanjo',
    category: 'Content & Experience',
  },
  {
    toolName: 'BentPixels',
    category: 'Content & Experience',
  },
  {
    toolName: 'Bidio',
    category: 'Content & Experience',
  },
  {
    toolName: 'BIGVU',
    category: 'Content & Experience',
  },
  {
    toolName: 'Bonjoro',
    category: 'Content & Experience',
  },
  {
    toolName: 'Brandlive',
    category: 'Content & Experience',
  },
  {
    toolName: 'Brightcove',
    category: 'Content & Experience',
  },
  {
    toolName: 'Buzzshow',
    category: 'Content & Experience',
  },
  {
    toolName: 'Canned.me',
    category: 'Content & Experience',
  },
  {
    toolName: 'Cincopa',
    category: 'Content & Experience',
  },
  {
    toolName: 'CorporateTube',
    category: 'Content & Experience',
  },
  {
    toolName: 'Dacast',
    category: 'Content & Experience',
  },
  {
    toolName: 'Dubb',
    category: 'Content & Experience',
  },
  {
    toolName: 'Dynamic-Video',
    category: 'Content & Experience',
  },
  {
    toolName: 'Grabyo',
    category: 'Content & Experience',
  },
  {
    toolName: 'Hippo Video',
    category: 'Content & Experience',
  },
  {
    toolName: 'ICX Media',
    category: 'Content & Experience',
  },
  {
    toolName: 'Idomoo',
    category: 'Content & Experience',
  },
  {
    toolName: 'Kaltura',
    category: 'Content & Experience',
  },
  {
    toolName: 'LBRY',
    category: 'Content & Experience',
  },
  {
    toolName: 'Livinglens',
    category: 'Content & Experience',
  },
  {
    toolName: 'Magnfi',
    category: 'Content & Experience',
  },
  {
    toolName: 'MediaFusion',
    category: 'Content & Experience',
  },
  {
    toolName: 'Minute',
    category: 'Content & Experience',
  },
  {
    toolName: 'Moovly',
    category: 'Content & Experience',
  },
  {
    toolName: 'Mysimpleshow',
    category: 'Content & Experience',
  },
  {
    toolName: 'Piksel',
    category: 'Content & Experience',
  },
  {
    toolName: 'Playable',
    category: 'Content & Experience',
  },
  {
    toolName: 'Playwire',
    category: 'Content & Experience',
  },
  {
    toolName: 'Plotto',
    category: 'Content & Experience',
  },
  {
    toolName: 'PowToon',
    category: 'Content & Experience',
  },
  {
    toolName: 'Pulpix',
    category: 'Content & Experience',
  },
  {
    toolName: 'Ramp',
    category: 'Content & Experience',
  },
  {
    toolName: 'ReachEngine',
    category: 'Content & Experience',
  },
  {
    toolName: 'Rocketium',
    category: 'Content & Experience',
  },
  {
    toolName: 'Saysw',
    category: 'Content & Experience',
  },
  {
    toolName: 'Shakr',
    category: 'Content & Experience',
  },
  {
    toolName: 'Slidely',
    category: 'Content & Experience',
  },
  {
    toolName: 'Snapparazzi',
    category: 'Content & Experience',
  },
  {
    toolName: 'SociVidz',
    category: 'Content & Experience',
  },
  {
    toolName: 'Sproutvideo',
    category: 'Content & Experience',
  },
  {
    toolName: 'Storyteq',
    category: 'Content & Experience',
  },
  {
    toolName: 'SundaySky',
    category: 'Content & Experience',
  },
  {
    toolName: 'Talk Fusion',
    category: 'Content & Experience',
  },
  {
    toolName: 'Tellyo',
    category: 'Content & Experience',
  },
  {
    toolName: 'TubeTrackr',
    category: 'Content & Experience',
  },
  {
    toolName: 'Tubular Labs',
    category: 'Content & Experience',
  },
  {
    toolName: 'TwentyThree',
    category: 'Content & Experience',
  },
  {
    toolName: 'Unyque Creative Network',
    category: 'Content & Experience',
  },
  {
    toolName: 'Ustudio',
    category: 'Content & Experience',
  },
  {
    toolName: 'Vantrix',
    category: 'Content & Experience',
  },
  {
    toolName: 'Veed.me',
    category: 'Content & Experience',
  },
  {
    toolName: 'Vidello',
    category: 'Content & Experience',
  },
  {
    toolName: 'Video Amigo',
    category: 'Content & Experience',
  },
  {
    toolName: 'Videolicious',
    category: 'Content & Experience',
  },
  {
    toolName: 'Videoly',
    category: 'Content & Experience',
  },
  {
    toolName: 'VideoScribe',
    category: 'Content & Experience',
  },
  {
    toolName: 'Videospicer',
    category: 'Content & Experience',
  },
  {
    toolName: 'Vidlet',
    category: 'Content & Experience',
  },
  {
    toolName: 'Vidooly',
    category: 'Content & Experience',
  },
  {
    toolName: 'Vidyard',
    category: 'Content & Experience',
  },
  {
    toolName: 'Vigo',
    category: 'Content & Experience',
  },
  {
    toolName: 'Vimeo',
    category: 'Content & Experience',
  },
  {
    toolName: 'Viralize',
    category: 'Content & Experience',
  },
  {
    toolName: 'vooPlayer',
    category: 'Content & Experience',
  },
  {
    toolName: 'Vyond',
    category: 'Content & Experience',
  },
  {
    toolName: 'Watchity',
    category: 'Content & Experience',
  },
  {
    toolName: 'WeVideo',
    category: 'Content & Experience',
  },
  {
    toolName: 'WhipMedia',
    category: 'Content & Experience',
  },
  {
    toolName: 'Wibbitz',
    category: 'Content & Experience',
  },
  {
    toolName: 'Wideo',
    category: 'Content & Experience',
  },
  {
    toolName: 'Wirewax',
    category: 'Content & Experience',
  },
  {
    toolName: 'Wistia',
    category: 'Content & Experience',
  },
  {
    toolName: 'YouTube',
    category: 'Content & Experience',
  },
  {
    toolName: 'Zype',
    category: 'Content & Experience',
  },
  {
    toolName: 'Aerobatic',
    category: 'Content & Experience',
  },
  {
    toolName: 'Agility',
    category: 'Content & Experience',
  },
  {
    toolName: 'Alfresco',
    category: 'Content & Experience',
  },
  {
    toolName: 'Anymod CMS',
    category: 'Content & Experience',
  },
  {
    toolName: 'Apideck',
    category: 'Content & Experience',
  },
  {
    toolName: 'AppDrag',
    category: 'Content & Experience',
  },
  {
    toolName: 'Asbru Web Content Management',
    category: 'Content & Experience',
  },
  {
    toolName: 'Atex',
    category: 'Content & Experience',
  },
  {
    toolName: 'Ayna',
    category: 'Content & Experience',
  },
  {
    toolName: 'b2evolution',
    category: 'Content & Experience',
  },
  {
    toolName: 'Backbee',
    category: 'Content & Experience',
  },
  {
    toolName: 'Better than Paper',
    category: 'Content & Experience',
  },
  {
    toolName: 'Blogger',
    category: 'Content & Experience',
  },
  {
    toolName: 'Blue Utopia',
    category: 'Content & Experience',
  },
  {
    toolName: 'BoldGrid',
    category: 'Content & Experience',
  },
  {
    toolName: 'Bolt',
    category: 'Content & Experience',
  },
  {
    toolName: 'Boombox',
    category: 'Content & Experience',
  },
  {
    toolName: 'Boostport',
    category: 'Content & Experience',
  },
  {
    toolName: 'Brick Street Software',
    category: 'Content & Experience',
  },
  {
    toolName: 'Bridgeline Digital',
    category: 'Content & Experience',
  },
  {
    toolName: 'Butter CMS',
    category: 'Content & Experience',
  },
  {
    toolName: 'Carrd',
    category: 'Content & Experience',
  },
  {
    toolName: 'Caspio',
    category: 'Content & Experience',
  },
  {
    toolName: 'Censhare',
    category: 'Content & Experience',
  },
  {
    toolName: 'ChannelNet',
    category: 'Content & Experience',
  },
  {
    toolName: 'Cindr',
    category: 'Content & Experience',
  },
  {
    toolName: 'CloudCannon',
    category: 'Content & Experience',
  },
  {
    toolName: 'CloudCMS',
    category: 'Content & Experience',
  },
  {
    toolName: 'Cloudflare',
    category: 'Content & Experience',
  },
  {
    toolName: 'Cloudrexx',
    category: 'Content & Experience',
  },
  {
    toolName: 'CM3 Acora',
    category: 'Content & Experience',
  },
  {
    toolName: 'CMS2CMS',
    category: 'Content & Experience',
  },
  {
    toolName: 'Cmsbox',
    category: 'Content & Experience',
  },
  {
    toolName: 'Cockpit CMS',
    category: 'Content & Experience',
  },
  {
    toolName: 'Comfortable',
    category: 'Content & Experience',
  },
  {
    toolName: 'Concrete5',
    category: 'Content & Experience',
  },
  {
    toolName: 'Contao',
    category: 'Content & Experience',
  },
  {
    toolName: 'Contens',
    category: 'Content & Experience',
  },
  {
    toolName: 'Contentful',
    category: 'Content & Experience',
  },
  {
    toolName: 'Contentserv',
    category: 'Content & Experience',
  },
  {
    toolName: 'CoreDNA',
    category: 'Content & Experience',
  },
  {
    toolName: 'Coremedia',
    category: 'Content & Experience',
  },
  {
    toolName: 'Cosmic JS',
    category: 'Content & Experience',
  },
  {
    toolName: 'Countable',
    category: 'Content & Experience',
  },
  {
    toolName: 'CraftCMS',
    category: 'Content & Experience',
  },
  {
    toolName: 'Crafter Software',
    category: 'Content & Experience',
  },
  {
    toolName: 'Crossbase',
    category: 'Content & Experience',
  },
  {
    toolName: 'Crownpeak',
    category: 'Content & Experience',
  },
  {
    toolName: 'Decibel Insight',
    category: 'Content & Experience',
  },
  {
    toolName: 'Devhub',
    category: 'Content & Experience',
  },
  {
    toolName: 'Directus',
    category: 'Content & Experience',
  },
  {
    toolName: 'Divio',
    category: 'Content & Experience',
  },
  {
    toolName: 'Django Cms',
    category: 'Content & Experience',
  },
  {
    toolName: 'DNN Software',
    category: 'Content & Experience',
  },
  {
    toolName: 'dotCMS',
    category: 'Content & Experience',
  },
  {
    toolName: 'Doxim',
    category: 'Content & Experience',
  },
  {
    toolName: 'Drupal',
    category: 'Content & Experience',
  },
  {
    toolName: 'Duda',
    category: 'Content & Experience',
  },
  {
    toolName: 'Eazlee',
    category: 'Content & Experience',
  },
  {
    toolName: 'elcom',
    category: 'Content & Experience',
  },
  {
    toolName: 'Elementor',
    category: 'Content & Experience',
  },
  {
    toolName: 'Empoweren',
    category: 'Content & Experience',
  },
  {
    toolName: 'Escenic',
    category: 'Content & Experience',
  },
  {
    toolName: 'Exponent CMS',
    category: 'Content & Experience',
  },
  {
    toolName: 'ExpressionEngine',
    category: 'Content & Experience',
  },
  {
    toolName: 'eZ',
    category: 'Content & Experience',
  },
  {
    toolName: 'Firstfocus.Eu',
    category: 'Content & Experience',
  },
  {
    toolName: 'Flazio',
    category: 'Content & Experience',
  },
  {
    toolName: 'Forestry',
    category: 'Content & Experience',
  },
  {
    toolName: 'Frankly',
    category: 'Content & Experience',
  },
  {
    toolName: 'Getlandy',
    category: 'Content & Experience',
  },
  {
    toolName: 'Ghost',
    category: 'Content & Experience',
  },
  {
    toolName: 'Google Web Designer',
    category: 'Content & Experience',
  },
  {
    toolName: 'GraphCMS',
    category: 'Content & Experience',
  },
  {
    toolName: 'GrassFish',
    category: 'Content & Experience',
  },
  {
    toolName: 'Gutensite',
    category: 'Content & Experience',
  },
  {
    toolName: 'GX Software',
    category: 'Content & Experience',
  },
  {
    toolName: 'HighQ',
    category: 'Content & Experience',
  },
  {
    toolName: 'ImpressPages',
    category: 'Content & Experience',
  },
  {
    toolName: 'Incms',
    category: 'Content & Experience',
  },
  {
    toolName: 'Industrial Medium',
    category: 'Content & Experience',
  },
  {
    toolName: 'Ingeniux',
    category: 'Content & Experience',
  },
  {
    toolName: 'Ingenta',
    category: 'Content & Experience',
  },
  {
    toolName: 'Internal',
    category: 'Content & Experience',
  },
  {
    toolName: 'InterRed',
    category: 'Content & Experience',
  },
  {
    toolName: 'Jadu',
    category: 'Content & Experience',
  },
  {
    toolName: 'Jalios',
    category: 'Content & Experience',
  },
  {
    toolName: 'Joomla',
    category: 'Content & Experience',
  },
  {
    toolName: 'Kentico',
    category: 'Content & Experience',
  },
  {
    toolName: 'Kirby',
    category: 'Content & Experience',
  },
  {
    toolName: 'Kirra',
    category: 'Content & Experience',
  },
  {
    toolName: 'Kontent',
    category: 'Content & Experience',
  },
  {
    toolName: 'Kotisivukone',
    category: 'Content & Experience',
  },
  {
    toolName: 'Kurtosys',
    category: 'Content & Experience',
  },
  {
    toolName: 'LaunchDarkly',
    category: 'Content & Experience',
  },
  {
    toolName: 'Lianacms.de',
    category: 'Content & Experience',
  },
  {
    toolName: 'Liferay',
    category: 'Content & Experience',
  },
  {
    toolName: 'LightCMS',
    category: 'Content & Experience',
  },
  {
    toolName: 'LiquidBlox',
    category: 'Content & Experience',
  },
  {
    toolName: 'liveSite',
    category: 'Content & Experience',
  },
  {
    toolName: 'Madcap Software',
    category: 'Content & Experience',
  },
  {
    toolName: 'Magnolia',
    category: 'Content & Experience',
  },
  {
    toolName: 'MainWP',
    category: 'Content & Experience',
  },
  {
    toolName: 'Makeswift',
    category: 'Content & Experience',
  },
  {
    toolName: 'MarketSnare',
    category: 'Content & Experience',
  },
  {
    toolName: 'Mburger',
    category: 'Content & Experience',
  },
  {
    toolName: 'Memberstack',
    category: 'Content & Experience',
  },
  {
    toolName: 'MetInfo',
    category: 'Content & Experience',
  },
  {
    toolName: 'Mezzanine',
    category: 'Content & Experience',
  },
  {
    toolName: 'Moboom',
    category: 'Content & Experience',
  },
  {
    toolName: 'Modx',
    category: 'Content & Experience',
  },
  {
    toolName: 'Mofuse',
    category: 'Content & Experience',
  },
  {
    toolName: 'Monsido',
    category: 'Content & Experience',
  },
  {
    toolName: 'Moonfruit',
    category: 'Content & Experience',
  },
  {
    toolName: 'Morweb.org',
    category: 'Content & Experience',
  },
  {
    toolName: 'MotoCMS',
    category: 'Content & Experience',
  },
  {
    toolName: 'Mura',
    category: 'Content & Experience',
  },
  {
    toolName: 'Navigate CMS',
    category: 'Content & Experience',
  },
  {
    toolName: 'Neos',
    category: 'Content & Experience',
  },
  {
    toolName: 'Nexstar',
    category: 'Content & Experience',
  },
  {
    toolName: 'Novius-OS',
    category: 'Content & Experience',
  },
  {
    toolName: 'October CMS',
    category: 'Content & Experience',
  },
  {
    toolName: 'Omcore',
    category: 'Content & Experience',
  },
  {
    toolName: 'One Minute Site',
    category: 'Content & Experience',
  },
  {
    toolName: 'OpenCMS',
    category: 'Content & Experience',
  },
  {
    toolName: 'Orchard Core',
    category: 'Content & Experience',
  },
  {
    toolName: 'Orckestra',
    category: 'Content & Experience',
  },
  {
    toolName: 'PageCloud',
    category: 'Content & Experience',
  },
  {
    toolName: 'Pagekit',
    category: 'Content & Experience',
  },
  {
    toolName: 'Pantheon',
    category: 'Content & Experience',
  },
  {
    toolName: 'PaperThin',
    category: 'Content & Experience',
  },
  {
    toolName: 'Papoo',
    category: 'Content & Experience',
  },
  {
    toolName: 'Perch',
    category: 'Content & Experience',
  },
  {
    toolName: 'Percussion',
    category: 'Content & Experience',
  },
  {
    toolName: 'Pimcore',
    category: 'Content & Experience',
  },
  {
    toolName: 'PixelSilk',
    category: 'Content & Experience',
  },
  {
    toolName: 'Plone',
    category: 'Content & Experience',
  },
  {
    toolName: 'Prismic',
    category: 'Content & Experience',
  },
  {
    toolName: 'ProcessWire',
    category: 'Content & Experience',
  },
  {
    toolName: 'ProudSyrup',
    category: 'Content & Experience',
  },
  {
    toolName: 'Publiz',
    category: 'Content & Experience',
  },
  {
    toolName: 'PushAlert',
    category: 'Content & Experience',
  },
  {
    toolName: 'PushOwl',
    category: 'Content & Experience',
  },
  {
    toolName: 'Pyro',
    category: 'Content & Experience',
  },
  {
    toolName: 'Quadient',
    category: 'Content & Experience',
  },
  {
    toolName: 'Quest AI',
    category: 'Content & Experience',
  },
  {
    toolName: 'QuickSilk',
    category: 'Content & Experience',
  },
  {
    toolName: 'Quintype',
    category: 'Content & Experience',
  },
  {
    toolName: 'Radiant CMS',
    category: 'Content & Experience',
  },
  {
    toolName: 'Rainmaker Platform',
    category: 'Content & Experience',
  },
  {
    toolName: 'Raisingit',
    category: 'Content & Experience',
  },
  {
    toolName: 'RallyMind',
    category: 'Content & Experience',
  },
  {
    toolName: 'Readz',
    category: 'Content & Experience',
  },
  {
    toolName: 'Realmacsoftware',
    category: 'Content & Experience',
  },
  {
    toolName: 'Roadiz',
    category: 'Content & Experience',
  },
  {
    toolName: 'Rooftop',
    category: 'Content & Experience',
  },
  {
    toolName: 'Roxen',
    category: 'Content & Experience',
  },
  {
    toolName: 'Scrivito',
    category: 'Content & Experience',
  },
  {
    toolName: 'SDL',
    category: 'Content & Experience',
  },
  {
    toolName: 'Sheety',
    category: 'Content & Experience',
  },
  {
    toolName: 'Shimbi Labs',
    category: 'Content & Experience',
  },
  {
    toolName: 'ShoutCMS',
    category: 'Content & Experience',
  },
  {
    toolName: 'SilverStripe',
    category: 'Content & Experience',
  },
  {
    toolName: 'Silvrback',
    category: 'Content & Experience',
  },
  {
    toolName: 'SiteCake',
    category: 'Content & Experience',
  },
  {
    toolName: 'Siteleaf',
    category: 'Content & Experience',
  },
  {
    toolName: 'Skuid',
    category: 'Content & Experience',
  },
  {
    toolName: 'Solodev',
    category: 'Content & Experience',
  },
  {
    toolName: 'Squarespace',
    category: 'Content & Experience',
  },
  {
    toolName: 'Squidex',
    category: 'Content & Experience',
  },
  {
    toolName: 'Squiz',
    category: 'Content & Experience',
  },
  {
    toolName: 'Strikingly',
    category: 'Content & Experience',
  },
  {
    toolName: 'Structr',
    category: 'Content & Experience',
  },
  {
    toolName: 'Subhub',
    category: 'Content & Experience',
  },
  {
    toolName: 'Subrion',
    category: 'Content & Experience',
  },
  {
    toolName: 'Superdesk',
    category: 'Content & Experience',
  },
  {
    toolName: 'Symphony',
    category: 'Content & Experience',
  },
  {
    toolName: 'Tagplay',
    category: 'Content & Experience',
  },
  {
    toolName: 'Terminal Four',
    category: 'Content & Experience',
  },
  {
    toolName: 'The Grid',
    category: 'Content & Experience',
  },
  {
    toolName: 'TitanCMS',
    category: 'Content & Experience',
  },
  {
    toolName: 'Transifex',
    category: 'Content & Experience',
  },
  {
    toolName: 'Tumblr',
    category: 'Content & Experience',
  },
  {
    toolName: 'Typepad',
    category: 'Content & Experience',
  },
  {
    toolName: 'Typo3',
    category: 'Content & Experience',
  },
  {
    toolName: 'uCoz',
    category: 'Content & Experience',
  },
  {
    toolName: 'Umbraco',
    category: 'Content & Experience',
  },
  {
    toolName: 'Unicorn Platform',
    category: 'Content & Experience',
  },
  {
    toolName: 'Unqork',
    category: 'Content & Experience',
  },
  {
    toolName: 'Usablenet',
    category: 'Content & Experience',
  },
  {
    toolName: 'Veriday',
    category: 'Content & Experience',
  },
  {
    toolName: 'Voog',
    category: 'Content & Experience',
  },
  {
    toolName: 'Wagtail',
    category: 'Content & Experience',
  },
  {
    toolName: 'Webcube',
    category: 'Content & Experience',
  },
  {
    toolName: 'Webiny',
    category: 'Content & Experience',
  },
  {
    toolName: 'Webnode',
    category: 'Content & Experience',
  },
  {
    toolName: 'Webnodes',
    category: 'Content & Experience',
  },
  {
    toolName: 'Weebly',
    category: 'Content & Experience',
  },
  {
    toolName: 'Wezen',
    category: 'Content & Experience',
  },
  {
    toolName: 'Widgy',
    category: 'Content & Experience',
  },
  {
    toolName: 'Wix',
    category: 'Content & Experience',
  },
  {
    toolName: 'Wordpress',
    category: 'Content & Experience',
  },
  {
    toolName: 'WP Engine',
    category: 'Content & Experience',
  },
  {
    toolName: 'Xlinesoft',
    category: 'Content & Experience',
  },
  {
    toolName: 'Yola',
    category: 'Content & Experience',
  },
  {
    toolName: 'Zenario CMS',
    category: 'Content & Experience',
  },
  {
    toolName: 'Zesty.io',
    category: 'Content & Experience',
  },
  {
    toolName: 'Ziber',
    category: 'Content & Experience',
  },
  {
    toolName: 'Zudy',
    category: 'Content & Experience',
  },
  {
    toolName: '1plusX',
    category: 'Data',
  },
  {
    toolName: '360science mDesktop',
    category: 'Data',
  },
  {
    toolName: 'Aaadata',
    category: 'xcxccxcxcxcxcx',
  },
  {
    toolName: 'Aberdeen',
    category: 'Data',
  },
  {
    toolName: 'Accudata',
    category: 'Data',
  },
  {
    toolName: 'AccuMail Frameworks',
    category: 'Data',
  },
  {
    toolName: 'AccuValid',
    category: 'Data',
  },
  {
    toolName: 'AccuZIP',
    category: 'Data',
  },
  {
    toolName: 'Ace Metrix',
    category: 'Data',
  },
  {
    toolName: 'Active Measure',
    category: 'Data',
  },
  {
    toolName: 'ActivePrime',
    category: 'Data',
  },
  {
    toolName: 'Adobe Target',
    category: 'Data',
  },
  {
    toolName: 'Adobe Analytics',
    category: 'Data',
  },
  {
    toolName: 'Acxiom',
    category: 'Data',
  },
  {
    toolName: 'Adara',
    category: 'Data',
  },
  {
    toolName: 'Adjusti.coŒåäóæ',
    category: 'Data',
  },
  {
    toolName: 'Admiral',
    category: 'Data',
  },
  {
    toolName: 'adsquare',
    category: 'Data',
  },
  {
    toolName: 'AeroLeads',
    category: 'Data',
  },
  {
    toolName: 'Alesco Data',
    category: 'Data',
  },
  {
    toolName: 'Alexa.com',
    category: 'Data',
  },
  {
    toolName: 'Alliant',
    category: 'Data',
  },
  {
    toolName: 'AnalyticsIQ',
    category: 'Data',
  },
  {
    toolName: 'Ataccama',
    category: 'Data',
  },
  {
    toolName: 'Atomic Mail Verifier',
    category: 'Data',
  },
  {
    toolName: 'Audience Manager',
    category: 'Data',
  },
  {
    toolName: 'Salesforce CRM',
    category: 'data',
  },
  {
    toolName: 'Adobe Cloud',
    category: 'data',
  },
  {
    toolName: 'BiScience',
    category: 'Data',
  },
  {
    toolName: 'Blue Sheep',
    category: 'Data',
  },
  {
    toolName: 'Bombora',
    category: 'Data',
  },
  {
    toolName: 'Bounceless.io',
    category: 'Data',
  },
  {
    toolName: 'BuiltWith',
    category: 'Data',
  },
  {
    toolName: 'Bulk Email Checker',
    category: 'Data',
  },
  {
    toolName: 'Captify',
    category: 'Data',
  },
  {
    toolName: 'Carry',
    category: 'Data',
  },
  {
    toolName: 'Claritas',
    category: 'Data',
  },
  {
    toolName: 'ClearBit',
    category: 'Data',
  },
  {
    toolName: 'ClearBrain',
    category: 'Data',
  },
  {
    toolName: 'CleverTouch',
    category: 'Data',
  },
  {
    toolName: 'Cloudingo',
    category: 'Data',
  },
  {
    toolName: 'Codec',
    category: 'Data',
  },
  {
    toolName: 'Competitors App',
    category: 'Data',
  },
  {
    toolName: 'Comscore',
    category: 'Data',
  },
  {
    toolName: 'Connexity',
    category: 'Data',
  },
  {
    toolName: 'Connotate',
    category: 'Data',
  },
  {
    toolName: 'Contify',
    category: 'Data',
  },
  {
    toolName: 'Convertrmedia',
    category: 'Data',
  },
  {
    toolName: 'Corus',
    category: 'Data',
  },
  {
    toolName: 'Cross Pixel',
    category: 'Data',
  },
  {
    toolName: 'Cuebiq',
    category: 'Data',
  },
  {
    toolName: 'Datadog',
    category: 'Data',
  },
  {
    toolName: 'Data Ladder',
    category: 'Data',
  },
  {
    toolName: 'Databroker',
    category: 'Data',
  },
  {
    toolName: 'Datactics',
    category: 'Data',
  },
  {
    toolName: 'Datafinder',
    category: 'Data',
  },
  {
    toolName: 'Dataline',
    category: 'Data',
  },
  {
    toolName: 'Dataloader Io',
    category: 'Data',
  },
  {
    toolName: 'Datanyze',
    category: 'Data',
  },
  {
    toolName: 'DataSift',
    category: 'Data',
  },
  {
    toolName: 'DataValidation - Email List Cleaning Service',
    category: 'Data',
  },
  {
    toolName: 'Datonics',
    category: 'Data',
  },
  {
    toolName: 'DBC Solutions',
    category: 'Data',
  },
  {
    toolName: 'DealSignal',
    category: 'Data',
  },
  {
    toolName: 'Deluxe',
    category: 'Data',
  },
  {
    toolName: 'DemandShore',
    category: 'Data',
  },
  {
    toolName: 'Digitalaudience',
    category: 'Data',
  },
  {
    toolName: 'DiscoverOrg',
    category: 'Data',
  },
  {
    toolName: 'Dock.io',
    category: 'Data',
  },
  {
    toolName: 'Dstillery',
    category: 'Data',
  },
  {
    toolName: 'DTScout',
    category: 'Data',
  },
  {
    toolName: 'Dun & Bradstreet',
    category: 'Data',
  },
  {
    toolName: 'DupeCatcher',
    category: 'Data',
  },
  {
    toolName: 'eDataSource',
    category: 'Data',
  },
  {
    toolName: 'Egon',
    category: 'Data',
  },
  {
    toolName: 'Elastic',
    category: 'Data',
  },
  {
    toolName: 'Eloqua B2C Marketing',
    category: 'Data',
  },
  {
    toolName: 'Eloqua B2B Marketing',
    category: 'Data',
  },
  {
    toolName: 'Eloqua Fusion Marketing',
    category: 'Data',
  },
  {
    toolName: 'Eloqua Marketing Automation',
    category: 'Data',
  },
  {
    toolName: 'Eloqua Responsys Campaign Management',
    category: 'Data',
  },
  {
    toolName: 'Eloqua CrowdTwist Loyalty and Engagement',
    category: 'Data',
  },
  {
    toolName: 'Eloqua Bluekai Data Management Platform',
    category: 'Data',
  },
  {
    toolName: 'Eloqua Audience Segmentation',
    category: 'Data',
  },
  {
    toolName: 'Eloqua Unity Customer Data Platform',
    category: 'Data',
  },
  {
    toolName: 'Eloqua Infinity Behavioral Intelligence',
    category: 'Data',
  },
  {
    toolName: 'Eloqua Maxymiser Testing and Optimization',
    category: 'Data',
  },
  {
    toolName: 'Email Checker',
    category: 'Data',
  },
  {
    toolName: 'Email List Validation',
    category: 'Data',
  },
  {
    toolName: 'Email List Verify',
    category: 'Data',
  },
  {
    toolName: 'Emodo',
    category: 'Data',
  },
  {
    toolName: 'Epsilon',
    category: 'Data',
  },
  {
    toolName: 'Equifax',
    category: 'Data',
  },
  {
    toolName: 'Exact Data',
    category: 'Data',
  },
  {
    toolName: 'Exchangeleads',
    category: 'Data',
  },
  {
    toolName: 'Exelate Data Marketplace',
    category: 'Data',
  },
  {
    toolName: 'Experian',
    category: 'Data',
  },
  {
    toolName: 'Eyeota',
    category: 'Data',
  },
  {
    toolName: 'Factual Inc',
    category: 'Data',
  },
  {
    toolName: 'Find That Email',
    category: 'Data',
  },
  {
    toolName: 'FreeSight',
    category: 'Data',
  },
  {
    toolName: 'Freshaddress Inc',
    category: 'Data',
  },
  {
    toolName: 'GeoCTRL',
    category: 'Data',
  },
  {
    toolName: 'Geopointe',
    category: 'Data',
  },
  {
    toolName: 'Gimbal',
    category: 'Data',
  },
  {
    toolName: 'Google Tag Manager',
    category: 'Data',
  },
  {
    toolName: 'Google Trends',
    category: 'Data',
  },
  {
    toolName: 'Google Analytics',
    category: 'Data',
  },
  {
    toolName: 'Gravy Analytics',
    category: 'Data',
  },
  {
    toolName: 'HG Insights',
    category: 'Data',
  },
  {
    toolName: 'Hiveminds',
    category: 'Data',
  },
  {
    toolName: 'Hivewyre',
    category: 'Data',
  },
  {
    toolName: 'IDG',
    category: 'Data',
  },
  {
    toolName: 'Info Checkpoint Data Quality',
    category: 'Data',
  },
  {
    toolName: 'Infogroup',
    category: 'Data',
  },
  {
    toolName: 'Informatica',
    category: 'Data',
  },
  {
    toolName: 'Infutor',
    category: 'Data',
  },
  {
    toolName: 'InsideView',
    category: 'Data',
  },
  {
    toolName: 'IRI',
    category: 'Data',
  },
  {
    toolName: 'iSpot.tv',
    category: 'Data',
  },
  {
    toolName: 'iugum Data Software',
    category: 'Data',
  },
  {
    toolName: 'Jambo',
    category: 'Data',
  },
  {
    toolName: 'Jornaya',
    category: 'Data',
  },
  {
    toolName: 'Komiko',
    category: 'Data',
  },
  {
    toolName: 'Lead Coin',
    category: 'Data',
  },
  {
    toolName: 'Lead Wrench',
    category: 'Data',
  },
  {
    toolName: 'Lead411',
    category: 'Data',
  },
  {
    toolName: 'Leadcrunch',
    category: 'Data',
  },
  {
    toolName: 'LeadGenius',
    category: 'Data',
  },
  {
    toolName: 'LeadGibbon',
    category: 'Data',
  },
  {
    toolName: 'Leadiro',
    category: 'Data',
  },
  {
    toolName: 'Leads Explorer',
    category: 'Data',
  },
  {
    toolName: 'Leadspace',
    category: 'Data',
  },
  {
    toolName: 'LexisNexis',
    category: 'Data',
  },
  {
    toolName: 'Lifesight',
    category: 'Data',
  },
  {
    toolName: 'linkedin',
    category: 'Data',
  },
  {
    toolName: 'Liquid',
    category: 'Data',
  },
  {
    toolName: 'LiveRamp',
    category: 'Data',
  },
  {
    toolName: 'LolaGrove',
    category: 'Data',
  },
  {
    toolName: 'loqate',
    category: 'Data',
  },
  {
    toolName: 'MailBoxValidator',
    category: 'Data',
  },
  {
    toolName: 'Mattermark',
    category: 'Data',
  },
  {
    toolName: 'Mblast',
    category: 'Data',
  },
  {
    toolName: 'Melissa',
    category: 'Data',
  },
  {
    toolName: 'MeritDirect',
    category: 'Data',
  },
  {
    toolName: 'Merkle',
    category: 'Data',
  },
  {
    toolName: 'MightySignal',
    category: 'Data',
  },
  {
    toolName: 'MIOvantage',
    category: 'Data',
  },
  {
    toolName: 'Miraget',
    category: 'Data',
  },
  {
    toolName: 'MountainTop Data',
    category: 'Data',
  },
  {
    toolName: 'mTraction',
    category: 'Data',
  },
  {
    toolName: 'MVF',
    category: 'Data',
  },
  {
    toolName: 'Narratiive',
    category: 'Data',
  },
  {
    toolName: 'Netline Corporation',
    category: 'Data',
  },
  {
    toolName: 'Neura',
    category: 'Data',
  },
  {
    toolName: 'NeverBounce',
    category: 'Data',
  },
  {
    toolName: 'Nielsen',
    category: 'Data',
  },
  {
    toolName: 'Node',
    category: 'Data',
  },
  {
    toolName: 'Normalator',
    category: 'Data',
  },
  {
    toolName: 'ObservePoint',
    category: 'Data',
  },
  {
    toolName: 'Oceanos',
    category: 'Data',
  },
  {
    toolName: 'Openprise',
    category: 'Data',
  },
  {
    toolName: 'Oracle',
    category: 'Data',
  },
  {
    toolName: 'Owler',
    category: 'Data',
  },
  {
    toolName: 'ownerIQ',
    category: 'Data',
  },
  {
    toolName: 'Passage Technology',
    category: 'Data',
  },
  {
    toolName: 'Pathmatics',
    category: 'Data',
  },
  {
    toolName: 'Peachtree Data',
    category: 'Data',
  },
  {
    toolName: 'Peer Mountain',
    category: 'Data',
  },
  {
    toolName: 'People Data Labs',
    category: 'Data',
  },
  {
    toolName: 'Permission.io',
    category: 'Data',
  },
  {
    toolName: 'Picalike',
    category: 'Data',
  },
  {
    toolName: 'PitchBook',
    category: 'Data',
  },
  {
    toolName: 'Pitney Bowes',
    category: 'Data',
  },
  {
    toolName: 'PlaceIQ',
    category: 'Data',
  },
  {
    toolName: 'Prospectify',
    category: 'Data',
  },
          
  {
    toolName: 'Quantcast',
    category: 'Data',
  },
  {
    toolName: 'Quick Email Verification',
    category: 'Data',
  },
  {
    toolName: 'Reach Marketing',
    category: 'Data',
  },
  {
    toolName: 'Readycontacts',
    category: 'Data',
  },
  {
    toolName: 'Repay Me',
    category: 'Data',
  },
  {
    toolName: 'Resonate',
    category: 'Data',
  },
  {
    toolName: 'ResponsePoint',
    category: 'Data',
  },
  {
    toolName: 'Revuze',
    category: 'Data',
  },
  {
    toolName: 'RingLead',
    category: 'Data',
  },
  {
    toolName: 'Rival IQ',
    category: 'Data',
  },
  {
    toolName: 'Salesforce Email Studio',
    category: 'Data',
  },
  {
    toolName: 'Salesforce CRM Tableau',
    category: 'Data',
  },

  {
    toolName: 'Salesforce Analyrics Cloud',
    category: 'Data',
  },
  {
    toolName: 'Salesforce Journey Builder',
    category: 'Data',
  },
  {
    toolName: 'Salesforce Interaction Studio',
    category: 'Data',
  },
  {
    toolName: 'Salesforce CDP',
    category: 'Data',
  },
  {
    toolName: 'Salesforce Pardot',
    category: 'Data',
  },
  {
    toolName: 'Salesforce Exact Target ',
    category: 'Data',
  },
  {
    toolName: 'Salesforce Datorama',
    category: 'Data',
  },
  {
    toolName: 'Salesforce Advertising Studio',
    category: 'Data',
  },
  {
    toolName: 'Salesforce Mobile Studio',
    category: 'Data',
  },
  {
    toolName: 'Salesforce Content Management',
    category: 'Data',
  },
  {
    toolName: 'Salesforce Google Marketing Platform',
    category: 'Data',
  },
  {
    toolName: 'Salesforce Loyalty Management',
    category: 'Data',
  },
  {
    toolName: 'Salesforce Datorama Reports',
    category: 'Data',
  },
  {
    toolName: 'SalesFuel',
    category: 'Data',
  },
  {
    toolName: 'Salesgenie',
    category: 'Data',
  },
  {
    toolName: 'Salesify',
    category: 'Data',
  },
  {
    toolName: 'SalesOptimize',
    category: 'Data',
  },
  {
    toolName: 'SalesRipe',
    category: 'Data',
  },
  {
    toolName: 'SAP',
    category: 'Data',
  },
  {
    toolName: 'Segmentify',
    category: 'Data',
  },
  {
    toolName: 'Sensor Tower',
    category: 'Data',
  },
  {
    toolName: 'ServiceMax',
    category: 'Data',
  },
  {
    toolName: 'Similarweb',
    category: 'Data',
  },
  {
    toolName: 'Sirdata',
    category: 'Data',
  },
  {
    toolName: 'Smarte',
    category: 'Data',
  },
  {
    toolName: 'SMI',
    category: 'Data',
  },
  {
    toolName: 'Socifi',
    category: 'Data',
  },
  {
    toolName: 'Spiceworks',
    category: 'Data',
  },
  {
    toolName: 'SQAD',
    category: 'Data',
  },
  {
    toolName: 'SRAX',
    category: 'Data',
  },
  {
    toolName: 'Statlistics',
    category: 'Data',
  },
  {
    toolName: 'SyncSoft',
    category: 'Data',
  },
  {
    toolName: 'Synthio',
    category: 'Data',
  },
  {
    toolName: 'Tealium',
    category: 'Data',
  },
  {
    toolName: 'TechTarget',
    category: 'Data',
  },
  {
    toolName: 'Terapeak',
    category: 'Data',
  },
  {
    toolName: 'Thalamus',
    category: 'Data',
  },
  {
    toolName: 'TMMData',
    category: 'Data',
  },
  {
    toolName: 'TowerData',
    category: 'Data',
  },
  {
    toolName: 'Tracedock',
    category: 'Data',
  },
  {
    toolName: 'TransUnion',
    category: 'Data',
  },
  {
    toolName: 'Travel Audience',
    category: 'Data',
  },
  {
    toolName: 'Truconnect',
    category: 'Data',
  },
  {
    toolName: 'Truth',
    category: 'Data',
  },
  {
    toolName: 'TTEC',
    category: 'Data',
  },
  {
    toolName: 'US Data Corporation',
    category: 'Data',
  },
  {
    toolName: 'V12 Data',
    category: 'Data',
  },
  {
    toolName: 'Valassis',
    category: 'Data',
  },
  {
    toolName: 'Validity',
    category: 'Data',
  },
  {
    toolName: 'VeriAS',
    category: 'Data',
  },
  {
    toolName: 'Versium',
    category: 'Data',
  },
  {
    toolName: 'Vinculum',
    category: 'Data',
  },
  {
    toolName: 'Voilanorbert',
    category: 'Data',
  },
  {
    toolName: 'Webbula',
    category: 'Data',
  },
  {
    toolName: 'WebLegit',
    category: 'Data',
  },
  {
    toolName: 'WhatRunsWhere',
    category: 'Data',
  },
  {
    toolName: 'Wibson',
    category: 'Data',
  },
  {
    toolName: 'WinPure',
    category: 'Data',
  },
  {
    toolName: 'WordWatch',
    category: 'Data',
  },
  {
    toolName: 'Xverify',
    category: 'Data',
  },
  {
    toolName: 'Yesdata',
    category: 'Data',
  },
  {
    toolName: 'Zeotap',
    category: 'Data',
  },
  {
    toolName: 'ZeroBounce',
    category: 'Data',
  },
  {
    toolName: 'ZoomInfo',
    category: 'Data',
  },
  {
    toolName: '10Duke',
    category: 'Data',
  },
  {
    toolName: '42 Technologies',
    category: 'Data',
  },
  {
    toolName: 'Absolutdata',
    category: 'Data',
  },
  {
    toolName: 'Actian Corporation',
    category: 'Data',
  },
  {
    toolName: 'Adverity',
    category: 'Data',
  },
  {
    toolName: 'Affinity Solutions',
    category: 'Data',
  },
  {
    toolName: 'After Data',
    category: 'Data',
  },
  {
    toolName: 'Aginity',
    category: 'Data',
  },
  {
    toolName: 'Aims',
    category: 'Data',
  },
  {
    toolName: 'Aito',
    category: 'Data',
  },
  {
    toolName: 'Algo',
    category: 'Data',
  },
  {
    toolName: 'Algolytics',
    category: 'Data',
  },
  {
    toolName: 'Altair',
    category: 'Data',
  },
  {
    toolName: 'Alteryx',
    category: 'Data',
  },
  {
    toolName: 'Altreva',
    category: 'Data',
  },
  {
    toolName: 'Analance',
    category: 'Data',
  },
  {
    toolName: 'Anaplan',
    category: 'Data',
  },
  {
    toolName: 'Anexinet',
    category: 'Data',
  },
  {
    toolName: 'Annalect',
    category: 'Data',
  },
  {
    toolName: 'Apptilaus',
    category: 'Data',
  },
  {
    toolName: 'Aptean',
    category: 'Data',
  },
  {
    toolName: 'Apteco',
    category: 'Data',
  },
  {
    toolName: 'Arcadia Enterprise',
    category: 'Data',
  },
  {
    toolName: 'Attest',
    category: 'Data',
  },
  {
    toolName: 'Attivio',
    category: 'Data',
  },
  {
    toolName: 'Avora',
    category: 'Data',
  },
  {
    toolName: 'Axent Intelligence',
    category: 'Data',
  },
  {
    toolName: 'Ayima',
    category: 'Data',
  },
  {
    toolName: 'B-Metrics',
    category: 'Data',
  },
  {
    toolName: 'Baremetrics',
    category: 'Data',
  },
  {
    toolName: 'Beckon',
    category: 'Data',
  },
  {
    toolName: 'Beemray',
    category: 'Data',
  },
  {
    toolName: 'BellaDati',
    category: 'Data',
  },
  {
    toolName: 'BI4Cloud',
    category: 'Data',
  },
  {
    toolName: 'Big Data For Humans',
    category: 'Data',
  },
  {
    toolName: 'Big Sofa',
    category: 'Data',
  },
  {
    toolName: 'Birst',
    category: 'Data',
  },
  {
    toolName: 'Bitam',
    category: 'Data',
  },
  {
    toolName: 'BlackSwan',
    category: 'Data',
  },
  {
    toolName: 'BlueVenn',
    category: 'Data',
  },
  {
    toolName: 'Board',
    category: 'Data',
  },
  {
    toolName: 'Bouquet',
    category: 'Data',
  },
  {
    toolName: 'BrandsEye',
    category: 'Data',
  },
  {
    toolName: 'Bridgei2i',
    category: 'Data',
  },
  {
    toolName: 'Business Insight Ltd',
    category: 'Data',
  },
  {
    toolName: 'CARTO',
    category: 'Data',
  },
  {
    toolName: 'CI Radar',
    category: 'Data',
  },
  {
    toolName: 'Cintell',
    category: 'Data',
  },
  {
    toolName: 'Civis Analytics',
    category: 'Data',
  },
  {
    toolName: 'Clario Analytics',
    category: 'Data',
  },
  {
    toolName: 'Clarisights',
    category: 'Data',
  },
  {
    toolName: 'ClearPredictions.com',
    category: 'Data',
  },
  {
    toolName: 'ClearStory Data',
    category: 'Data',
  },
  {
    toolName: 'Cloudera Enterprise',
    category: 'Data',
  },
  {
    toolName: 'Cognetik',
    category: 'Data',
  },
  {
    toolName: 'Competitor Monitor',
    category: 'Data',
  },
  {
    toolName: 'Connexica',
    category: 'Data',
  },
  {
    toolName: 'Contour BI',
    category: 'Data',
  },
  {
    toolName: 'Conversific',
    category: 'Data',
  },
  {
    toolName: 'Corporate Renaissance Group',
    category: 'Data',
  },
  {
    toolName: 'Course 5',
    category: 'Data',
  },
  {
    toolName: 'Crossbeam',
    category: 'Data',
  },
  {
    toolName: 'Crossix Solutions',
    category: 'Data',
  },
  {
    toolName: 'Daisy Intelligence',
    category: 'Data',
  },
  {
    toolName: 'Data Decisions Group',
    category: 'Data',
  },
  {
    toolName: 'Databook',
    category: 'Data',
  },
  {
    toolName: 'Datalicious',
    category: 'Data',
  },
  {
    toolName: 'Datastreams.io',
    category: 'Data',
  },
  {
    toolName: 'Datumize',
    category: 'Data',
  },
  {
    toolName: 'Dimensional Insight',
    category: 'Data',
  },
  {
    toolName: 'DMWay Analytics Engine',
    category: 'Data',
  },
  {
    toolName: 'Domo',
    category: 'Data',
  },
  {
    toolName: 'Drmetrix',
    category: 'Data',
  },
  {
    toolName: 'EazyBI Cloud',
    category: 'Data',
  },
  {
    toolName: 'Edge by Ascential',
    category: 'Data',
  },
  {
    toolName: 'Emcien',
    category: 'Data',
  },
  {
    toolName: 'Endor',
    category: 'Data',
  },
  {
    toolName: 'Enlyft',
    category: 'Data',
  },
  {
    toolName: 'Entrinsik',
    category: 'Data',
  },
  {
    toolName: 'Entytle',
    category: 'Data',
  },
  {
    toolName: 'Esri',
    category: 'Data',
  },
  {
    toolName: 'ExactAsk',
    category: 'Data',
  },
  {
    toolName: 'Express Analytics',
    category: 'Data',
  },
  {
    toolName: 'Figure Eight',
    category: 'Data',
  },
  {
    toolName: 'FindThatLead',
    category: 'Data',
  },
  {
    toolName: 'Firstofficer',
    category: 'Data',
  },
  {
    toolName: 'FirstRain',
    category: 'Data',
  },
  {
    toolName: 'Fishbowl',
    category: 'Data',
  },
  {
    toolName: 'flex.bi',
    category: 'Data',
  },
  {
    toolName: 'Focus Optimal',
    category: 'Data',
  },
  {
    toolName: 'Forrester',
    category: 'Data',
  },
  {
    toolName: 'Fractal',
    category: 'Data',
  },
  {
    toolName: 'FunnelAI',
    category: 'Data',
  },
  {
    toolName: 'Galigeo',
    category: 'Data',
  },
  {
    toolName: 'Glassbeam',
    category: 'Data',
  },
  {
    toolName: 'Globalwebindex',
    category: 'Data',
  },
  {
    toolName: 'Gnosis',
    category: 'Data',
  },
  {
    toolName: 'GoodData',
    category: 'Data',
  },
  {
    toolName: 'Govini',
    category: 'Data',
  },
  {
    toolName: 'Gpredictive',
    category: 'Data',
  },
  {
    toolName: 'Halo - The Supply Chain Intelligence Company',
    category: 'Data',
  },
  {
    toolName: 'Hitachi Vantara',
    category: 'Data',
  },
  {
    toolName: 'HTK',
    category: 'Data',
  },
  {
    toolName: 'IBM',
    category: 'Data',
  },
  {
    toolName: 'IBM Cognos',
    category: 'Data',
  },
  {
    toolName: 'IBM Watson',
    category: 'Data',
  },
  {
    toolName: 'IHS',
    category: 'Data',
  },
  {
    toolName: 'iKizmet',
    category: 'Data',
  },
  {
    toolName: 'Infogix',
    category: 'Data',
  },
  {
    toolName: 'Information Builders',
    category: 'Data',
  },
  {
    toolName: 'InfoZoom',
    category: 'Data',
  },
  {
    toolName: 'Insight Rocket',
    category: 'Data',
  },
  {
    toolName: 'Insightsoftware',
    category: 'Data',
  },
  {
    toolName: 'Intelex Technologies',
    category: 'Data',
  },
  {
    toolName: 'IntelliPhi',
    category: 'Data',
  },
  {
    toolName: 'Interana',
    category: 'Data',
  },
  {
    toolName: 'Izenda',
    category: 'Data',
  },
  {
    toolName: 'Jedox',
    category: 'Data',
  },
  {
    toolName: 'jepto',
    category: 'Data',
  },
  {
    toolName: 'Jigyasa Analytics',
    category: 'Data',
  },
  {
    toolName: 'Kaggle',
    category: 'Data',
  },
  {
    toolName: 'Kasko Digital',
    category: 'Data',
  },
  {
    toolName: 'Knowi',
    category: 'Data',
  },
  {
    toolName: 'Kognitio',
    category: 'Data',
  },
  {
    toolName: 'Kompyte',
    category: 'Data',
  },
  {
    toolName: 'Kyubit Business Intelligence',
    category: 'Data',
  },
  {
    toolName: 'LeadLake.com',
    category: 'Data',
  },
  {
    toolName: 'Leady',
    category: 'Data',
  },
  {
    toolName: 'Lineup Systems',
    category: 'Data',
  },
  {
    toolName: 'LinkNotions',
    category: 'Data',
  },
  {
    toolName: 'Locationsmart',
    category: 'Data',
  },
  {
    toolName: 'Logi Analytics',
    category: 'Data',
  },
  {
    toolName: 'LongView',
    category: 'Data',
  },
  {
    toolName: 'Looker',
    category: 'Data',
  },
  {
    toolName: 'Lucy',
    category: 'Data',
  },
  {
    toolName: 'Lumiary',
    category: 'Data',
  },
  {
    toolName: 'Luminoso Technologies',
    category: 'Data',
  },
  {
    toolName: 'Magento',
    category: 'Data',
  },
  {
    toolName: 'Manta',
    category: 'Data',
  },
  {
    toolName: 'Maplarge',
    category: 'Data',
  },
  {
    toolName: 'Marcom Logix',
    category: 'Data',
  },
  {
    toolName: 'Market Force Information',
    category: 'Data',
  },
  {
    toolName: 'Market Logic Software',
    category: 'Data',
  },
  {
    toolName: 'Marketresearchcom',
    category: 'Data',
  },
  {
    toolName: 'Maroon.Ai',
    category: 'Data',
  },
  {
    toolName: 'Megalytic',
    category: 'Data',
  },
  {
    toolName: 'Metacog',
    category: 'Data',
  },
  {
    toolName: 'Metric Insights',
    category: 'Data',
  },
  {
    toolName: 'Microsoft',
    category: 'Data',
  },
  {
    toolName: 'Microsoft Azure',
    category: 'Data',
  },
  
  {
    toolName: 'Microsoft Power Automate',
    category: 'Data',
  },

  {
    toolName: 'MicroStrategy',
    category: 'Data',
  },
  {
    toolName: 'Minitab',
    category: 'Data',
  },
  {
    toolName: 'Mobilewalla',
    category: 'Data',
  },
  {
    toolName: 'Mode',
    category: 'Data',
  },
  {
    toolName: 'Monkeydata',
    category: 'Data',
  },
  {
    toolName: 'Mu Sigma',
    category: 'Data',
  },
  {
    toolName: 'muoro',
    category: 'Data',
  },
  {
    toolName: 'My Telescope',
    category: 'Data',
  },
  {
    toolName: 'Nabler',
    category: 'Data',
  },
  {
    toolName: 'NetBaseSquid',
    category: 'Data',
  },
  {
    toolName: 'Nexla',
    category: 'Data',
  },
  {
    toolName: 'nexoya',
    category: 'Data',
  },
  {
    toolName: 'NGDATA',
    category: 'Data',
  },
  {
    toolName: 'Nuix',
    category: 'Data',
  },
  {
    toolName: 'NUmerator',
    category: 'Data',
  },
  {
    toolName: 'Nutonian',
    category: 'Data',
  },

  {
    toolName: 'Omnitude',
    category: 'Data',
  },
  {
    toolName: 'Oracle Data Mining',
    category: 'Data',
  },
  {
    toolName: 'Oracle Data Studio',
    category: 'Data',
  },

  {
    toolName: 'Opposure',
    category: 'Data',
  },
  {
    toolName: 'ORBIT Analytics',
    category: 'Data',
  },
  {
    toolName: 'OWOX',
    category: 'Data',
  },
  {
    toolName: 'Panamplify',
    category: 'Data',
  },
  {
    toolName: 'PanBI',
    category: 'Data',
  },
  {
    toolName: 'Panoply.io',
    category: 'Data',
  },
  {
    toolName: 'Panorama Software',
    category: 'Data',
  },
  {
    toolName: 'Parker Software',
    category: 'Data',
  },
  {
    toolName: 'PeriscopeIQ',
    category: 'Data',
  },
  {
    toolName: 'Phocas Software',
    category: 'Data',
  },
  {
    toolName: 'Pointillist',
    category: 'Data',
  },
  {
    toolName: 'PredictHQ',
    category: 'Data',
  },
  {
    toolName: 'Profitwell',
    category: 'Data',
  },
  {
    toolName: 'ProspectR',
    category: 'Data',
  },
  {
    toolName: 'Proton.ai',
    category: 'Data',
  },
  {
    toolName: 'Proxem Software',
    category: 'Data',
  },
  {
    toolName: 'Purple.ai',
    category: 'Data',
  },
  {
    toolName: 'Pyramid Analytics',
    category: 'Data',
  },
  {
    toolName: 'Q Research Software',
    category: 'Data',
  },
  {
    toolName: 'Qoints',
    category: 'Data',
  },
  {
    toolName: 'Qrious',
    category: 'Data',
  },
  {
    toolName: 'Quid',
    category: 'Data',
  },
  {
    toolName: 'Quirkos',
    category: 'Data',
  },
  {
    toolName: 'Rapidminer',
    category: 'Data',
  },
  {
    toolName: 'Retina AI',
    category: 'Data',
  },
  {
    toolName: 'Rubikloud Technologies',
    category: 'Data',
  },
  {
    toolName: 'SaasabiPro',
    category: 'Data',
  },
  {
    toolName: 'Salient',
    category: 'Data',
  },
  {
    toolName: 'SAS',
    category: 'Data',
  },
  {
    toolName: 'SAS Marketing Optimization',
    category: 'Data',
  },
  {
    toolName: 'Screen6',
    category: 'Data',
  },
  {
    toolName: 'Sellforte',
    category: 'Data',
  },
  {
    toolName: 'SigOpt',
    category: 'Data',
  },
  {
    toolName: 'Silvon Software',
    category: 'Data',
  },
  {
    toolName: 'Sisense',
    category: 'Data',
  },
  {
    toolName: 'SnapStrat',
    category: 'Data',
  },
  {
    toolName: 'Social Standards',
    category: 'Data',
  },
  {
    toolName: 'SoloSegment LLC',
    category: 'Data',
  },
  {
    toolName: 'Solver',
    category: 'Data',
  },
  {
    toolName: 'Sorter',
    category: 'Data',
  },
  {
    toolName: 'Soulskill',
    category: 'Data',
  },
  {
    toolName: 'SpagoBI',
    category: 'Data',
  },
  {
    toolName: 'SplashBI',
    category: 'Data',
  },
  {
    toolName: 'Splunk',
    category: 'Data',
  },
  {
    toolName: 'Spyglaz',
    category: 'Data',
  },
  {
    toolName: 'Squark',
    category: 'Data',
  },
  {
    toolName: 'STAQ',
    category: 'Data',
  },
  {
    toolName: 'StataCorp',
    category: 'Data',
  },
  {
    toolName: 'Statwizards',
    category: 'Data',
  },
  {
    toolName: 'Stay Upstream',
    category: 'Data',
  },
  {
    toolName: 'Stimulsoft',
    category: 'Data',
  },
  {
    toolName: 'Stox',
    category: 'Data',
  },
  {
    toolName: 'Stratifyd',
    category: 'Data',
  },
  {
    toolName: 'Striim',
    category: 'Data',
  },
  {
    toolName: 'SynGro',
    category: 'Data',
  },
  {
    toolName: 'TARGIT',
    category: 'Data',
  },
  {
    toolName: 'Tellius',
    category: 'Data',
  },
  {
    toolName: 'Temelio',
    category: 'Data',
  },
  {
    toolName: 'The Anylogic Company',
    category: 'Data',
  },
  {
    toolName: 'ThoughtSpot',
    category: 'Data',
  },
  {
    toolName: 'Thunderhead',
    category: 'Data',
  },
  {
    toolName: 'TIBCO Software',
    category: 'Data',
  },
  {
    toolName: 'Timi',
    category: 'Data',
  },
  {
    toolName: 'Tresata',
    category: 'Data',
  },
  {
    toolName: 'Twentyci',
    category: 'Data',
  },
  {
    toolName: 'Ubermetrics Delta',
    category: 'Data',
  },
  {
    toolName: 'Vantage',
    category: 'Data',
  },
  {
    toolName: 'vCube',
    category: 'Data',
  },
  {
    toolName: 'velocidi',
    category: 'Data',
  },
  {
    toolName: 'Vertica',
    category: 'Data',
  },
  {
    toolName: 'Viant',
    category: 'Data',
  },
  {
    toolName: 'VisualCue',
    category: 'Data',
  },
  {
    toolName: 'WatchMyCompetitor',
    category: 'Data',
  },
  {
    toolName: 'Webtrekk',
    category: 'Data',
  },
  {
    toolName: 'whenwhyhow',
    category: 'Data',
  },
  {
    toolName: 'Wiser',
    category: 'Data',
  },
  {
    toolName: 'Wizdee',
    category: 'Data',
  },
  {
    toolName: 'Wunderdata',
    category: 'Data',
  },
  {
    toolName: 'XAD spoteffects',
    category: 'Data',
  },
  {
    toolName: 'Yellowfin',
    category: 'Data',
  },
  {
    toolName: 'YouEye/ UserZoom',
    category: 'Data',
  },
  {
    toolName: 'ZAP Business Intelligence',
    category: 'Data',
  },
  {
    toolName: 'Zebra BI',
    category: 'Data',
  },
  {
    toolName: 'Zendesk',
    category: 'Data',
  },
  {
    toolName: 'Zeppelin',
    category: 'Data',
  },
  {
    toolName: 'Zignal Labs',
    category: 'Data',
  },
  {
    toolName: 'Zoined',
    category: 'Data',
  },
  {
    toolName: 'ZyloTech',
    category: 'Data',
  },
  {
    toolName: 'ActionIQ',
    category: 'Data',
  },
  {
    toolName: 'AgilOne',
    category: 'Data',
  },
  {
    toolName: 'Amperity',
    category: 'Data',
  },
  {
    toolName: 'Arm Treasure Data',
    category: 'Data',
  },
  {
    toolName: 'Ascent360',
    category: 'Data',
  },
  {
    toolName: 'BlueConic',
    category: 'Data',
  },
  {
    toolName: 'BlueShift',
    category: 'Data',
  },
  {
    toolName: 'Boxever',
    category: 'Data',
  },
  {
    toolName: 'CaliberMind',
    category: 'Data',
  },
  {
    toolName: 'Camp de Bases/Webedia',
    category: 'Data',
  },
  {
    toolName: 'Celebrus',
    category: 'Data',
  },
  {
    toolName: 'Cheetah Digital',
    category: 'Data',
  },
  {
    toolName: 'CommandersAct',
    category: 'Data',
  },
  {
    toolName: 'Conjura',
    category: 'Data',
  },
  {
    toolName: 'CrossEngage',
    category: 'Data',
  },
  {
    toolName: 'Custobar',
    category: 'Data',
  },
  {
    toolName: 'Datorama',
    category: 'Data',
  },
  {
    toolName: 'Ensighten',
    category: 'Data',
  },
  {
    toolName: 'Eulerian Technologies',
    category: 'Data',
  },
  {
    toolName: 'Evergage',
    category: 'Data',
  },
  {
    toolName: 'FirstHive',
    category: 'Data',
  },
  {
    toolName: 'Fospha/Blenheim Chalcot',
    category: 'Data',
  },
  {
    toolName: 'Gamooga',
    category: 'Data',
  },
  {
    toolName: 'Hariken',
    category: 'Data',
  },
  {
    toolName: 'Hive Marketing Cloud',
    category: 'Data',
  },
  {
    toolName: 'Hull',
    category: 'Data',
  },
  {
    toolName: 'Ikuu',
    category: 'Data',
  },
  {
    toolName: 'InSight360',
    category: 'Data',
  },
  {
    toolName: 'IntentHQ',
    category: 'Data',
  },
  {
    toolName: 'Invenna',
    category: 'Data',
  },
  {
    toolName: 'Jahia',
    category: 'Data',
  },
  {
    toolName: 'LayerFive',
    category: 'Data',
  },
  {
    toolName: 'Lemnisk',
    category: 'Data',
  },
  {
    toolName: 'Lexer',
    category: 'Data',
  },
  {
    toolName: 'Lytics',
    category: 'Data',
  },
  {
    toolName: 'Mediarithmics',
    category: 'Data',
  },
  {
    toolName: 'mParticle',
    category: 'Data',
  },
  {
    toolName: 'NectarOM',
    category: 'Data',
  },
  {
    toolName: 'NextUser',
    category: 'Data',
  },
  {
    toolName: 'Nominow',
    category: 'Data',
  },
  {
    toolName: 'ONEcount',
    category: 'Data',
  },
  {
    toolName: 'OpenJaw',
    category: 'Data',
  },
  {
    toolName: 'Optimove',
    category: 'Data',
  },
  {
    toolName: 'PRDCT',
    category: 'Data',
  },
  {
    toolName: 'Quaero',
    category: 'Data',
  },
  {
    toolName: 'QuickPivot',
    category: 'Data',
  },
  {
    toolName: 'Reachfive',
    category: 'Data',
  },
  {
    toolName: 'RedEye',
    category: 'Data',
  },
  {
    toolName: 'RedPoint Global',
    category: 'Data',
  },
  {
    toolName: 'Reltio',
    category: 'Data',
  },
  {
    toolName: 'Richpanel',
    category: 'Data',
  },
  {
    toolName: 'Scal-e',
    category: 'Data',
  },
  {
    toolName: 'Segment',
    category: 'Data',
  },
  {
    toolName: 'Selligent',
    category: 'Data',
  },
  {
    toolName: 'SessionM',
    category: 'Data',
  },
  {
    toolName: 'Signal',
    category: 'Data',
  },
  {
    toolName: 'Simon Data',
    category: 'Data',
  },
  {
    toolName: 'Squeezley',
    category: 'Data',
  },
  {
    toolName: 'TapPad',
    category: 'Data',
  },
  {
    toolName: 'teavaro',
    category: 'Data',
  },
  {
    toolName: 'Umbel',
    category: 'Data',
  },
  {
    toolName: 'UniFida',
    category: 'Data',
  },
  {
    toolName: 'Usermind',
    category: 'Data',
  },
  {
    toolName: 'VEMT',
    category: 'Data',
  },
  {
    toolName: 'WhiteRabbit',
    category: 'Data',
  },
  {
    toolName: 'Yeti Data',
    category: 'Data',
  },
  {
    toolName: 'Ysance',
    category: 'Data',
  },
  {
    toolName: 'Zaius',
    category: 'Data',
  },
  {
    toolName: '1010data',
    category: 'Data',
  },
  {
    toolName: 'ADVIZOR Solutions',
    category: 'Data',
  },
  {
    toolName: 'Andara',
    category: 'Data',
  },
  {
    toolName: 'anvizent',
    category: 'Data',
  },
  {
    toolName: 'AppDirect',
    category: 'Data',
  },
  {
    toolName: 'Autographic Insight',
    category: 'Data',
  },
  {
    toolName: 'Blue Green Analytics',
    category: 'Data',
  },
  {
    toolName: 'Bright Gauge',
    category: 'Data',
  },
  {
    toolName: 'Cambridge Intelligence',
    category: 'Data',
  },
  {
    toolName: 'Cervinodata',
    category: 'Data',
  },
  {
    toolName: 'ChartBlocks',
    category: 'Data',
  },
  {
    toolName: 'CHARTIO',
    category: 'Data',
  },
  {
    toolName: 'ChartMogul',
    category: 'Data',
  },
  {
    toolName: 'ChartsBin',
    category: 'Data',
  },
  {
    toolName: 'Chorally',
    category: 'Data',
  },
  {
    toolName: 'ClearPoint Strategy',
    category: 'Data',
  },
  {
    toolName: 'ClicData',
    category: 'Data',
  },
  {
    toolName: 'Cluvio',
    category: 'Data',
  },
  {
    toolName: 'CMaps Analytics',
    category: 'Data',
  },
  {
    toolName: 'Converge',
    category: 'Data',
  },
  {
    toolName: 'Cumul.io',
    category: 'Data',
  },
  {
    toolName: 'Cyfe',
    category: 'Data',
  },
  {
    toolName: 'D3 - Data Driven Documents',
    category: 'Data',
  },
  {
    toolName: 'Dapresy',
    category: 'Data',
  },
  {
    toolName: 'Dasheroo',
    category: 'Data',
  },
  {
    toolName: 'DashMetrics',
    category: 'Data',
  },
  {
    toolName: 'DashThis',
    category: 'Data',
  },
  {
    toolName: 'Data Hero',
    category: 'Data',
  },
  {
    toolName: 'Databox',
    category: 'Data',
  },
  {
    toolName: 'DataCopia',
    category: 'Data',
  },
  {
    toolName: 'DataDeck',
    category: 'Data',
  },
  {
    toolName: 'Datalion',
    category: 'Data',
  },
  {
    toolName: 'Datapine',
    category: 'Data',
  },
  {
    toolName: 'DataPlay',
    category: 'Data',
  },
  {
    toolName: 'Datawrapper',
    category: 'Data',
  },
  {
    toolName: 'DBXtra',
    category: 'Data',
  },
  {
    toolName: 'Dear Lucy',
    category: 'Data',
  },
  {
    toolName: 'Domotalk',
    category: 'Data',
  },
  {
    toolName: 'Dundas',
    category: 'Data',
  },
  {
    toolName: 'Easy Insight',
    category: 'Data',
  },
  {
    toolName: 'EspressDashboard',
    category: 'Data',
  },
  {
    toolName: 'Event Fabric',
    category: 'Data',
  },
  {
    toolName: 'Exago Inc',
    category: 'Data',
  },
  {
    toolName: 'Flourish',
    category: 'Data',
  },
  {
    toolName: 'Funnel',
    category: 'Data',
  },
  {
    toolName: 'FusionCharts',
    category: 'Data',
  },
  {
    toolName: 'Geckoboard',
    category: 'Data',
  },
  {
    toolName: 'Gephi',
    category: 'Data',
  },
  {
    toolName: 'GitKraken',
    category: 'Data',
  },
  {
    toolName: 'Glance IncŒåäóæ',
    category: 'Data',
  },
  {
    toolName: 'Google Data Studio',
    category: 'Data',
  },
  {
    toolName: 'Grafana',
    category: 'Data',
  },
  {
    toolName: 'Grow',
    category: 'Data',
  },
  {
    toolName: 'Growthbot',
    category: 'Data',
  },
  {
    toolName: 'HappyMetrix',
    category: 'Data',
  },
  {
    toolName: 'iCharts',
    category: 'Data',
  },
  {
    toolName: 'iDashboards',
    category: 'Data',
  },
  {
    toolName: 'Improvado',
    category: 'Data',
  },
  {
    toolName: 'InetSoft',
    category: 'Data',
  },
  {
    toolName: 'InfoCaptor',
    category: 'Data',
  },
  {
    toolName: 'Intellicus',
    category: 'Data',
  },
  {
    toolName: 'Jinfonet',
    category: 'Data',
  },
  {
    toolName: 'Juice Analytics',
    category: 'Data',
  },
  {
    toolName: 'Kilometer.io',
    category: 'Data',
  },
  {
    toolName: 'Klipfolio',
    category: 'Data',
  },
  {
    toolName: 'Leftronic',
    category: 'Data',
  },
  {
    toolName: 'Linkurious',
    category: 'Data',
  },
  {
    toolName: 'Lucify',
    category: 'Data',
  },
  {
    toolName: 'Malartu',
    category: 'Data',
  },
  {
    toolName: 'Maptive',
    category: 'Data',
  },
  {
    toolName: 'MarketSight',
    category: 'Data',
  },
  {
    toolName: 'MATLAB',
    category: 'Data',
  },
  {
    toolName: 'Mojix',
    category: 'Data',
  },
  {
    toolName: 'mTAB',
    category: 'Data',
  },
  {
    toolName: 'Naveego',
    category: 'Data',
  },
  {
    toolName: 'Neatly.io',
    category: 'Data',
  },
  {
    toolName: 'Nugit',
    category: 'Data',
  },
  {
    toolName: 'Numetric',
    category: 'Data',
  },
  {
    toolName: 'OfficeReports',
    category: 'Data',
  },
  {
    toolName: 'OQLIS',
    category: 'Data',
  },
  {
    toolName: 'Parabola.io',
    category: 'Data',
  },
  {
    toolName: 'Plecto',
    category: 'Data',
  },
  {
    toolName: 'Plotly',
    category: 'Data',
  },
  {
    toolName: 'Puppet',
    category: 'Data',
  },
  {
    toolName: 'Pxio',
    category: 'Data',
  },
  {
    toolName: 'Qeymetrics',
    category: 'Data',
  },
  {
    toolName: 'Qlik',
    category: 'Data',
  },
  {
    toolName: 'Quadrigram',
    category: 'Data',
  },
  {
    toolName: 'RAWGraphs',
    category: 'Data',
  },
  {
    toolName: 'Redash',
    category: 'Data',
  },
  {
    toolName: 'Reeport',
    category: 'Data',
  },
  {
    toolName: 'ReportDash',
    category: 'Data',
  },
  {
    toolName: 'ReportGarden',
    category: 'Data',
  },
  {
    toolName: 'Reportz',
    category: 'Data',
  },
  {
    toolName: 'Rilo Insights',
    category: 'Data',
  },
  {
    toolName: 'Roosboard',
    category: 'Data',
  },
  {
    toolName: 'Scaidata',
    category: 'Data',
  },
  {
    toolName: 'Screenful',
    category: 'Data',
  },
  {
    toolName: 'SharpCloud',
    category: 'Data',
  },
  {
    toolName: 'Slemma',
    category: 'Data',
  },
  {
    toolName: 'Spinify',
    category: 'Data',
  },
  {
    toolName: 'SpreadsheetWEB',
    category: 'Data',
  },
  {
    toolName: 'Statpedia',
    category: 'Data',
  },
  {
    toolName: 'Statsbot',
    category: 'Data',
  },
  {
    toolName: 'StatSilk',
    category: 'Data',
  },
  {
    toolName: 'Supermetrics',
    category: 'Data',
  },
  {
    toolName: 'Sweetspot Intelligence',
    category: 'Data',
  },
  {
    toolName: 'Swydo',
    category: 'Data',
  },
  {
    toolName: 'Tableau',
    category: 'Data',
  },
  {
    toolName: 'tanzle',
    category: 'Data',
  },
  {
    toolName: 'TapClicks',
    category: 'Data',
  },
  {
    toolName: 'Thinkmap',
    category: 'Data',
  },
  {
    toolName: 'Tiq.Solutions',
    category: 'Data',
  },
  {
    toolName: 'TrackAd',
    category: 'Data',
  },
  {
    toolName: 'Transpara',
    category: 'Data',
  },
  {
    toolName: 'Visage',
    category: 'Data',
  },
  {
    toolName: 'Visible VC Inc',
    category: 'Data',
  },
  {
    toolName: 'Visokio',
    category: 'Data',
  },
  {
    toolName: 'VisualizeROI',
    category: 'Data',
  },
  {
    toolName: 'Visualr',
    category: 'Data',
  },
  {
    toolName: 'Viur',
    category: 'Data',
  },
  {
    toolName: 'Whatagraph',
    category: 'Data',
  },
  {
    toolName: 'Wolfram Alpha',
    category: 'Data',
  },
  {
    toolName: 'Yaware.Dashboard',
    category: 'Data',
  },
  {
    toolName: 'ZoomData',
    category: 'Data',
  },
  {
    toolName: '7suite',
    category: 'Data',
  },
  {
    toolName: 'Adbert',
    category: 'Data',
  },
  {
    toolName: 'AdBrain',
    category: 'Data',
  },
  {
    toolName: 'Adex',
    category: 'Data',
  },
  {
    toolName: 'AdForm',
    category: 'Data',
  },
  {
    toolName: 'Adobe',
    category: 'Data',
  },
  {
    toolName: 'Adot',
    category: 'Data',
  },
  {
    toolName: 'Adsorcery',
    category: 'Data',
  },
  {
    toolName: 'Amobee',
    category: 'Data',
  },
  {
    toolName: 'Audiene Science',
    category: 'Data',
  },
  {
    toolName: 'Cablato',
    category: 'Data',
  },
  {
    toolName: 'Carbondmp',
    category: 'Data',
  },
  {
    toolName: 'Clear Analytics',
    category: 'Data',
  },
  {
    toolName: 'Cxense',
    category: 'Data',
  },
  {
    toolName: 'Datagres Perfaccel',
    category: 'Data',
  },
  {
    toolName: 'Datatonics',
    category: 'Data',
  },
  {
    toolName: 'DataXu',
    category: 'Data',
  },
  {
    toolName: 'Dentsu Aegis Network',
    category: 'Data',
  },
  {
    toolName: 'Erwin Data Modeler',
    category: 'Data',
  },
  {
    toolName: 'Google',
    category: 'Data',
  },
  {
    toolName: 'Ignite Technologies',
    category: 'Data',
  },
  {
    toolName: 'IgnitionOne',
    category: 'Data',
  },
  {
    toolName: 'KBM Group',
    category: 'Data',
  },
  {
    toolName: 'Lotame',
    category: 'Data',
  },
  {
    toolName: 'Mapp',
    category: 'Data',
  },
  {
    toolName: 'Media Innovation Group',
    category: 'Data',
  },
  {
    toolName: 'MediaMath',
    category: 'Data',
  },
  {
    toolName: 'Navegg',
    category: 'Data',
  },
  {
    toolName: 'Neodata Group',
    category: 'Data',
  },
  {
    toolName: 'Neustar',
    category: 'Data',
  },
  {
    toolName: 'NP6',
    category: 'Data',
  },
  {
    toolName: 'OnAudience',
    category: 'Data',
  },
  {
    toolName: 'Permutive',
    category: 'Data',
  },
  {
    toolName: 'Predicta',
    category: 'Data',
  },
  {
    toolName: 'Raima',
    category: 'Data',
  },
  {
    toolName: 'Relationship Onemarketing',
    category: 'Data',
  },
  {
    toolName: 'Shoppingminds',
    category: 'Data',
  },
  {
    toolName: 'Sojern',
    category: 'Data',
  },
  {
    toolName: 'Spocto',
    category: 'Data',
  },
  {
    toolName: 'Tail',
    category: 'Data',
  },
  {
    toolName: 'Teradata',
    category: 'Data',
  },
  {
    toolName: 'The Trade Desk',
    category: 'Data',
  },
  {
    toolName: 'Troparé Inc.',
    category: 'Data',
  },
  {
    toolName: 'Verve',
    category: 'Data',
  },
  {
    toolName: 'Vserv',
    category: 'Data',
  },
  {
    toolName: 'Whip Media',
    category: 'Data',
  },
  {
    toolName: 'Xaxis',
    category: 'Data',
  },
  {
    toolName: '6clicks',
    category: 'Data',
  },
  {
    toolName: 'Aircloak',
    category: 'Data',
  },
  {
    toolName: 'Ardoq',
    category: 'Data',
  },
  {
    toolName: 'Authentiq',
    category: 'Data',
  },
  {
    toolName: 'Baycloud Systems',
    category: 'Data',
  },
  {
    toolName: 'BigID',
    category: 'Data',
  },
  {
    toolName: 'Citizen',
    category: 'Data',
  },
  {
    toolName: 'Civic',
    category: 'Data',
  },
  {
    toolName: 'Cleantalk',
    category: 'Data',
  },
  {
    toolName: 'Collibra',
    category: 'Data',
  },
  {
    toolName: 'Commanders Act',
    category: 'Data',
  },
  {
    toolName: 'ComplyCloud ApS',
    category: 'Data',
  },
  {
    toolName: 'ConsentEye',
    category: 'Data',
  },
  {
    toolName: 'Consentua',
    category: 'Data',
  },
  {
    toolName: 'Cookiebot',
    category: 'Data',
  },
  {
    toolName: 'Crownpeak',
    category: 'Data',
  },
  {
    toolName: 'Data Legal Drive',
    category: 'Data',
  },
  {
    toolName: 'DataGrail',
    category: 'Data',
  },
  {
    toolName: 'Dataguise',
    category: 'Data',
  },
  {
    toolName: 'Didomi',
    category: 'Data',
  },
  {
    toolName: 'DPOrganizer',
    category: 'Data',
  },
  {
    toolName: 'Email Hippo',
    category: 'Data',
  },
  {
    toolName: 'Erwin',
    category: 'Data',
  },
  {
    toolName: 'Evidon',
    category: 'Data',
  },
  {
    toolName: 'FileFacets',
    category: 'Data',
  },
  {
    toolName: 'FireMon',
    category: 'Data',
  },
  {
    toolName: 'Fr.Gdata.be',
    category: 'Data',
  },
  {
    toolName: 'GatedContent.com',
    category: 'Data',
  },
  {
    toolName: 'Gdprchecklist',
    category: 'Data',
  },
  {
    toolName: 'identeco',
    category: 'Data',
  },
  {
    toolName: 'Integris Software',
    category: 'Data',
  },
  {
    toolName: 'Isatis Group',
    category: 'Data',
  },
  {
    toolName: 'Miradore Online',
    category: 'Data',
  },
  {
    toolName: 'Netwrix',
    category: 'Data',
  },
  {
    toolName: 'Nymity',
    category: 'Data',
  },
  {
    toolName: 'Obsequio Software',
    category: 'Data',
  },
  {
    toolName: 'Ogury',
    category: 'Data',
  },
  {
    toolName: 'OneTrust',
    category: 'Data',
  },
  {
    toolName: 'Osano',
    category: 'Data',
  },
  {
    toolName: 'Piwik PRO',
    category: 'Data',
  },
  {
    toolName: 'PlanetVerify',
    category: 'Data',
  },
  {
    toolName: 'PrivacyCheq',
    category: 'Data',
  },
  {
    toolName: 'Privacyradius',
    category: 'Data',
  },
  {
    toolName: 'Raptor Compliance',
    category: 'Data',
  },
  {
    toolName: 'SecuPi',
    category: 'Data',
  },
  {
    toolName: 'Secure Privacy',
    category: 'Data',
  },
  {
    toolName: 'SFBX',
    category: 'Data',
  },
  {
    toolName: 'Signatu',
    category: 'Data',
  },
  {
    toolName: 'SureCloud',
    category: 'Data',
  },
  {
    toolName: 'Symphonicsoft',
    category: 'Data',
  },
  {
    toolName: 'Tag Inspector',
    category: 'Data',
  },
  {
    toolName: 'Tessian',
    category: 'Data',
  },
  {
    toolName: 'Trunomi',
    category: 'Data',
  },
  {
    toolName: 'Trust Hub',
    category: 'Data',
  },
  {
    toolName: 'TrustArc',
    category: 'Data',
  },
  {
    toolName: 'Uport',
    category: 'Data',
  },
  {
    toolName: 'WireWheel.io',
    category: 'Data',
  },
  {
    toolName: '101 Data Solution',
    category: 'Data',
  },
  {
    toolName: 'Abiquo',
    category: 'Data',
  },
  {
    toolName: 'Abis-Cloud',
    category: 'Data',
  },
  {
    toolName: 'Adeptia',
    category: 'Data',
  },
  {
    toolName: 'Akamai',
    category: 'Data',
  },
  {
    toolName: 'Antworks',
    category: 'Data',
  },
  {
    toolName: 'APImatic',
    category: 'Data',
  },
  {
    toolName: 'Aplynk',
    category: 'Data',
  },
  {
    toolName: 'Appseconnect',
    category: 'Data',
  },
  {
    toolName: 'arcesb',
    category: 'Data',
  },
  {
    toolName: 'Astronomer',
    category: 'Data',
  },
  {
    toolName: 'Automation Anywhere',
    category: 'Data',
  },
  {
    toolName: 'Automation Edge',
    category: 'Data',
  },
  {
    toolName: 'Automaton',
    category: 'Data',
  },
  {
    toolName: 'Azuqua',
    category: 'Data',
  },
  {
    toolName: 'Bedrock Data',
    category: 'Data',
  },
  {
    toolName: 'Blendo',
    category: 'Data',
  },
  {
    toolName: 'Blendr',
    category: 'Data',
  },
  {
    toolName: 'Blockspring',
    category: 'Data',
  },
  {
    toolName: 'Blue Prism',
    category: 'Data',
  },
  {
    toolName: 'Burt',
    category: 'Data',
  },
  {
    toolName: 'Catalytic',
    category: 'Data',
  },
  {
    toolName: 'Celigo',
    category: 'Data',
  },
  {
    toolName: 'cFlow',
    category: 'Data',
  },
  {
    toolName: 'Cloud Elements',
    category: 'Data',
  },
  {
    toolName: 'CloudBolt',
    category: 'Data',
  },
  {
    toolName: 'CloudSponge',
    category: 'Data',
  },
  {
    toolName: 'CloverDX',
    category: 'Data',
  },
  {
    toolName: 'Confluent Cloud',
    category: 'Data',
  },
  {
    toolName: 'Connecting Software',
    category: 'Data',
  },
  {
    toolName: 'COZYROC',
    category: 'Data',
  },
  {
    toolName: 'Cyclr',
    category: 'Data',
  },
  {
    toolName: 'Data Loader',
    category: 'Data',
  },
  {
    toolName: 'Data Virtuality Pipes',
    category: 'Data',
  },
  {
    toolName: 'Dataddo',
    category: 'Data',
  },
  {
    toolName: 'DataFire',
    category: 'Data',
  },
  {
    toolName: 'Datagran',
    category: 'Data',
  },
  {
    toolName: 'Datalicious | Smarter Marketing',
    category: 'Data',
  },
  {
    toolName: 'Datameer',
    category: 'Data',
  },
  {
    toolName: 'Datarista',
    category: 'Data',
  },
  {
    toolName: 'DBAmp',
    category: 'Data',
  },
  {
    toolName: 'DBSync',
    category: 'Data',
  },
  {
    toolName: 'Dell',
    category: 'Data',
  },
  {
    toolName: 'Denodo',
    category: 'Data',
  },
  {
    toolName: 'Devart',
    category: 'Data',
  },
  {
    toolName: 'Diffbot',
    category: 'Data',
  },
  {
    toolName: 'Dizmo',
    category: 'Data',
  },
  {
    toolName: 'Dovetail',
    category: 'Data',
  },
  {
    toolName: 'Dreamfactory',
    category: 'Data',
  },
  {
    toolName: 'elastic.io',
    category: 'Data',
  },
  {
    toolName: 'Enigma',
    category: 'Data',
  },
  {
    toolName: 'Eone',
    category: 'Data',
  },
  {
    toolName: 'Etlworks',
    category: 'Data',
  },
  {
    toolName: 'Fivetran Inc',
    category: 'Data',
  },
  {
    toolName: 'Flashnode',
    category: 'Data',
  },
  {
    toolName: 'Flatly',
    category: 'Data',
  },
  {
    toolName: 'Flowgear',
    category: 'Data',
  },
  {
    toolName: 'Fluidinfo',
    category: 'Data',
  },
  {
    toolName: 'Ghostery',
    category: 'Data',
  },
  {
    toolName: 'Gridscale',
    category: 'Data',
  },
  {
    toolName: 'HVR',
    category: 'Data',
  },
  {
    toolName: 'IFTTT',
    category: 'Data',
  },
  {
    toolName: 'Impact',
    category: 'Data',
  },
  {
    toolName: 'import.io',
    category: 'Data',
  },
  {
    toolName: 'Import2',
    category: 'Data',
  },
  {
    toolName: 'ImportOmatic',
    category: 'Data',
  },
  {
    toolName: 'Infor',
    category: 'Data',
  },
  {
    toolName: 'Integromat',
    category: 'Data',
  },
  {
    toolName: 'Jitterbit',
    category: 'Data',
  },
  {
    toolName: 'Kissflow',
    category: 'Data',
  },
  {
    toolName: 'Kofax',
    category: 'Data',
  },
  {
    toolName: 'LiveRamp, An Acxiom Company',
    category: 'Data',
  },
  {
    toolName: 'Lobster',
    category: 'Data',
  },
  {
    toolName: 'LoginRadius',
    category: 'Data',
  },
  {
    toolName: 'Magic Software Enterprises',
    category: 'Data',
  },
  {
    toolName: 'Matillion',
    category: 'Data',
  },
  {
    toolName: 'MuleSoft',
    category: 'Data',
  },
  {
    toolName: 'MultCloud.com',
    category: 'Data',
  },
  {
    toolName: 'NextRoll',
    category: 'Data',
  },
  {
    toolName: 'Nimaya',
    category: 'Data',
  },
  {
    toolName: 'Nintex',
    category: 'Data',
  },
  {
    toolName: 'OneSaas',
    category: 'Data',
  },
  {
    toolName: 'Opentext',
    category: 'Data',
  },
  {
    toolName: 'OpenX',
    category: 'Data',
  },
  {
    toolName: 'Palantir Gotham',
    category: 'Data',
  },
  {
    toolName: 'Parabola',
    category: 'Data',
  },
  {
    toolName: 'Perspective ILM',
    category: 'Data',
  },
  {
    toolName: 'PieSync',
    category: 'Data',
  },
  {
    toolName: 'Pluga',
    category: 'Data',
  },
  {
    toolName: 'ProvenWorks',
    category: 'Data',
  },
  {
    toolName: 'Pubvantage',
    category: 'Data',
  },
  {
    toolName: 'Pushwoosh',
    category: 'Data',
  },
  {
    toolName: 'Qubit',
    category: 'Data',
  },
  {
    toolName: 'rakam',
    category: 'Data',
  },
  {
    toolName: 'Rakuten Advertising',
    category: 'Data',
  },
  {
    toolName: 'RapidiOnline',
    category: 'Data',
  },
  {
    toolName: 'Relay42',
    category: 'Data',
  },
  {
    toolName: 'Semarchy',
    category: 'Data',
  },
  {
    toolName: 'Singer.io',
    category: 'Data',
  },
  {
    toolName: 'Skyvia',
    category: 'Data',
  },
  {
    toolName: 'Skyvva',
    category: 'Data',
  },
  {
    toolName: 'SnapLogic',
    category: 'Data',
  },
  {
    toolName: 'Snowflake',
    category: 'Data',
  },
  {
    toolName: 'Softomotive',
    category: 'Data',
  },
  {
    toolName: 'Software AG',
    category: 'Data',
  },
  {
    toolName: 'SquareStack',
    category: 'Data',
  },
  {
    toolName: 'Stitch',
    category: 'Data',
  },
  {
    toolName: 'Sureshot',
    category: 'Data',
  },
  {
    toolName: 'SyncApps',
    category: 'Data',
  },
  {
    toolName: 'Synscort DMExpress',
    category: 'Data',
  },
  {
    toolName: 'Talend Open Source Integration',
    category: 'Data',
  },
  {
    toolName: 'Tamr',
    category: 'Data',
  },
  {
    toolName: 'Thinfilm',
    category: 'Data',
  },
  {
    toolName: 'TMM Data',
    category: 'Data',
  },
  {
    toolName: 'tray.io',
    category: 'Data',
  },
  {
    toolName: 'UIPath',
    category: 'Data',
  },
  {
    toolName: 'UnifiedUV',
    category: 'Data',
  },
  {
    toolName: 'Vertify Inc.',
    category: 'Data',
  },
  {
    toolName: 'Workato',
    category: 'Data',
  },
  {
    toolName: 'WorkFusion',
    category: 'Data',
  },
  {
    toolName: 'WP Fusion',
    category: 'Data',
  },
  {
    toolName: 'Wyzebulb',
    category: 'Data',
  },
  {
    toolName: 'Xplenty',
    category: 'Data',
  },
  {
    toolName: 'Youredi',
    category: 'Data',
  },
  {
    toolName: 'Zapier',
    category: 'Data',
  },
  {
    toolName: 'Ad-Juster',
    category: 'Data',
  },
  {
    toolName: 'Adclear',
    category: 'Data',
  },
  {
    toolName: 'Adcurve',
    category: 'Data',
  },
  {
    toolName: 'Adinton',
    category: 'Data',
  },
  {
    toolName: 'Adjust',
    category: 'Data',
  },
  {
    toolName: 'Adloop',
    category: 'Data',
  },
  {
    toolName: 'Admetrics',
    category: 'Data',
  },
  {
    toolName: 'Adtriba',
    category: 'Data',
  },
  {
    toolName: 'AgencyAnalytics',
    category: 'Data',
  },
  {
    toolName: 'Alight Analytics',
    category: 'Data',
  },
  {
    toolName: 'Align.ly',
    category: 'Data',
  },
  {
    toolName: 'Allocadia',
    category: 'Data',
  },
  {
    toolName: 'Alpine Metrics',
    category: 'Data',
  },
  {
    toolName: 'Analytic Partners',
    category: 'Data',
  },
  {
    toolName: 'AOL',
    category: 'Data',
  },
  {
    toolName: 'AppLink.io',
    category: 'Data',
  },
  {
    toolName: 'Appsumer',
    category: 'Data',
  },
  {
    toolName: 'AttributeApp',
    category: 'Data',
  },
  {
    toolName: 'Attribution',
    category: 'Data',
  },
  {
    toolName: 'AvantMetrics',
    category: 'Data',
  },
  {
    toolName: 'Beaconsoft',
    category: 'Data',
  },
  {
    toolName: 'Big Picture',
    category: 'Data',
  },
  {
    toolName: 'Biotron.io',
    category: 'Data',
  },
  {
    toolName: 'Bizible',
    category: 'Data',
  },
  {
    toolName: 'Black Ink',
    category: 'Data',
  },
  {
    toolName: 'BrightFunnel',
    category: 'Data',
  },
  {
    toolName: 'C3 Metrics',
    category: 'Data',
  },
  {
    toolName: 'Cake',
    category: 'Data',
  },
  {
    toolName: 'CareJourney',
    category: 'Data',
  },
  {
    toolName: 'Causalytics',
    category: 'Data',
  },
  {
    toolName: 'Cavea',
    category: 'Data',
  },
  {
    toolName: 'CiG',
    category: 'Data',
  },
  {
    toolName: 'ClickGum',
    category: 'Data',
  },
  {
    toolName: 'Commerce Signals',
    category: 'Data',
  },
  {
    toolName: 'conDati',
    category: 'Data',
  },
  {
    toolName: 'ContentSquare',
    category: 'Data',
  },
  {
    toolName: 'Conversion Logic',
    category: 'Data',
  },
  {
    toolName: 'DemandJump',
    category: 'Data',
  },
  {
    toolName: 'Digital Media Solutions',
    category: 'Data',
  },
  {
    toolName: 'DigitalPI',
    category: 'Data',
  },
  {
    toolName: 'Dreamdata',
    category: 'Data',
  },
  {
    toolName: 'Dynata',
    category: 'Data',
  },
  {
    toolName: 'Eletype',
    category: 'Data',
  },
  {
    toolName: 'Engage Hub',
    category: 'Data',
  },
  {
    toolName: 'Engagio',
    category: 'Data',
  },
  {
    toolName: 'Evolve24',
    category: 'Data',
  },
  {
    toolName: 'Exactag',
    category: 'Data',
  },
  {
    toolName: 'Faraday',
    category: 'Data',
  },
  {
    toolName: 'Ferrite Labs',
    category: 'Data',
  },
  {
    toolName: 'FlameAnalytics',
    category: 'Data',
  },
  {
    toolName: 'Formulate',
    category: 'Data',
  },
  {
    toolName: 'Fospha',
    category: 'Data',
  },
  {
    toolName: 'Full Circle Insights',
    category: 'Data',
  },
  {
    toolName: 'FunnelCake',
    category: 'Data',
  },
  {
    toolName: 'glean.info',
    category: 'Data',
  },
  {
    toolName: 'GoSquared',
    category: 'Data',
  },
  {
    toolName: 'Hive9',
    category: 'Data',
  },
  {
    toolName: 'Ignite Techonologies',
    category: 'Data',
  },
  {
    toolName: 'Indicative',
    category: 'Data',
  },
  {
    toolName: 'Inlineinsight',
    category: 'Data',
  },
  {
    toolName: 'Inlinemarket',
    category: 'Data',
  },
  {
    toolName: 'Intelliad.de',
    category: 'Data',
  },
  {
    toolName: 'IntelligenceWP',
    category: 'Data',
  },
  {
    toolName: 'Invicta',
    category: 'Data',
  },
  {
    toolName: 'Ipsos',
    category: 'Data',
  },
  {
    toolName: 'Jojka',
    category: 'Data',
  },
  {
    toolName: 'Keen',
    category: 'Data',
  },
  {
    toolName: 'KickFire',
    category: 'Data',
  },
  {
    toolName: 'Kochava',
    category: 'Data',
  },
  {
    toolName: 'Kvantum',
    category: 'Data',
  },
  {
    toolName: 'Latent View Analytics',
    category: 'Data',
  },
  {
    toolName: 'LeadsRx',
    category: 'Data',
  },
  {
    toolName: 'Lucidity',
    category: 'Data',
  },
  {
    toolName: 'Madtrix',
    category: 'Data',
  },
  {
    toolName: 'Manthan',
    category: 'Data',
  },
  {
    toolName: 'Marketing Evolution',
    category: 'Data',
  },
  {
    toolName: 'Marketing Town',
    category: 'Data',
  },
  {
    toolName: 'MarketSmith',
    category: 'Data',
  },
  {
    toolName: 'Mass Analytics',
    category: 'Data',
  },
  {
    toolName: 'MAXG',
    category: 'Data',
  },
  {
    toolName: 'Methodify',
    category: 'Data',
  },
  {
    toolName: 'MonsterInsights',
    category: 'Data',
  },
  {
    toolName: 'msights',
    category: 'Data',
  },
  {
    toolName: 'Musqot Marketing Technology',
    category: 'Data',
  },
  {
    toolName: 'Newlytics',
    category: 'Data',
  },
  {
    toolName: 'Odyssey Attribution',
    category: 'Data',
  },
  {
    toolName: 'OptiMine Software',
    category: 'Data',
  },
  {
    toolName: 'ORM Technologies',
    category: 'Data',
  },
  {
    toolName: 'Overstack',
    category: 'Data',
  },
  {
    toolName: 'Parse.ly',
    category: 'Data',
  },
  {
    toolName: 'Partnerize',
    category: 'Data',
  },
  {
    toolName: 'PeoplePattern',
    category: 'Data',
  },
  {
    toolName: 'PlaceLinks',
    category: 'Data',
  },
  {
    toolName: 'Plumb5.com',
    category: 'Data',
  },
  {
    toolName: 'Prismana',
    category: 'Data',
  },
  {
    toolName: 'Prodlytic',
    category: 'Data',
  },
  {
    toolName: 'Proof',
    category: 'Data',
  },
  {
    toolName: 'Protagonist',
    category: 'Data',
  },
  {
    toolName: 'Quantifind',
    category: 'Data',
  },
  {
    toolName: 'Radial',
    category: 'Data',
  },
  {
    toolName: 'Red Sift Platform',
    category: 'Data',
  },
  {
    toolName: 'Retailflux',
    category: 'Data',
  },
  {
    toolName: 'Roivenue',
    category: 'Data',
  },
  {
    toolName: 'Ruler Analytics',
    category: 'Data',
  },
  {
    toolName: 'ScanmarQED',
    category: 'Data',
  },
  {
    toolName: 'Sentiance',
    category: 'Data',
  },
  {
    toolName: 'SideTrade',
    category: 'Data',
  },
  {
    toolName: 'Signifikant.Biz',
    category: 'Data',
  },
  {
    toolName: 'Simple',
    category: 'Data',
  },
  {
    toolName: 'Singular',
    category: 'Data',
  },
  {
    toolName: 'SmartBridge',
    category: 'Data',
  },
  {
    toolName: 'Snowplow Analytics',
    category: 'Data',
  },
  {
    toolName: 'Softcrylic',
    category: 'Data',
  },
  {
    toolName: 'Spatially',
    category: 'Data',
  },
  {
    toolName: 'Strala',
    category: 'Data',
  },
  {
    toolName: 'stratEDGE',
    category: 'Data',
  },
  {
    toolName: 'Trackstreet',
    category: 'Data',
  },
  {
    toolName: 'TrafficGuard',
    category: 'Data',
  },
  {
    toolName: 'Trakken',
    category: 'Data',
  },
  {
    toolName: 'Trendemon',
    category: 'Data',
  },
  {
    toolName: 'Tru Measure',
    category: 'Data',
  },
  {
    toolName: 'VariaMetrix',
    category: 'Data',
  },
  {
    toolName: 'Wicked Reports',
    category: 'Data',
  },
  {
    toolName: 'Windsor.ai',
    category: 'Data',
  },
  {
    toolName: 'Wizaly',
    category: 'Data',
  },
  {
    toolName: '51degrees',
    category: 'Data',
  },
  {
    toolName: 'A1WebStats',
    category: 'Data',
  },
  {
    toolName: 'Air360',
    category: 'Data',
  },
  {
    toolName: 'Albacross Website Tracking',
    category: 'Data',
  },
  {
    toolName: 'Amazon',
    category: 'Data',
  },
  {
    toolName: 'Amplitude',
    category: 'Data',
  },
  {
    toolName: 'AMZ.one',
    category: 'Data',
  },
  {
    toolName: 'Analytics Toolkit',
    category: 'Data',
  },
  {
    toolName: 'Anodot',
    category: 'Data',
  },
  {
    toolName: 'AnswerRocket',
    category: 'Data',
  },
  {
    toolName: 'App Annie',
    category: 'Data',
  },
  {
    toolName: 'AppsFlyer',
    category: 'Data',
  },
  {
    toolName: 'AT INTERNET',
    category: 'Data',
  },
  {
    toolName: 'AWS Cloud9',
    category: 'Data',
  },
  {
    toolName: 'Bitly',
    category: 'Data',
  },
  {
    toolName: 'Buxton',
    category: 'Data',
  },
  {
    toolName: 'Calq',
    category: 'Data',
  },
  {
    toolName: 'Canecto',
    category: 'Data',
  },
  {
    toolName: 'Catchpoint',
    category: 'Data',
  },
  {
    toolName: 'Chartbeat',
    category: 'Data',
  },
  {
    toolName: 'Church Analytics',
    category: 'Data',
  },
  {
    toolName: 'Cision',
    category: 'Data',
  },
  {
    toolName: 'Clicktale',
    category: 'Data',
  },
  {
    toolName: 'Clicky',
    category: 'Data',
  },
  {
    toolName: 'CommuniGator (formerly Wow Analytics)',
    category: 'Data',
  },
  {
    toolName: 'CoolaData',
    category: 'Data',
  },
  {
    toolName: 'Countly',
    category: 'Data',
  },
  {
    toolName: 'Crazy Egg',
    category: 'Data',
  },
  {
    toolName: 'CustomerEngagePro',
    category: 'Data',
  },
  {
    toolName: 'Daddy Analytics',
    category: 'Data',
  },
  {
    toolName: 'datadecisions',
    category: 'Data',
  },
  {
    toolName: 'Decibel Insight',
    category: 'Data',
  },
  {
    toolName: 'Dragonflyai',
    category: 'Data',
  },
  {
    toolName: 'Dynamicaction',
    category: 'Data',
  },
  {
    toolName: 'eAnalytics',
    category: 'Data',
  },
  {
    toolName: 'Econda',
    category: 'Data',
  },
  {
    toolName: 'Engagement Labs',
    category: 'Data',
  },
  {
    toolName: 'etracker',
    category: 'Data',
  },
  {
    toolName: 'Eureka AI',
    category: 'Data',
  },
  {
    toolName: 'ExtraWatch',
    category: 'Data',
  },
  {
    toolName: 'Eyesee',
    category: 'Data',
  },
  {
    toolName: 'FICO',
    category: 'Data',
  },
  {
    toolName: 'FireStats',
    category: 'Data',
  },
  {
    toolName: 'Formisimo',
    category: 'Data',
  },
  {
    toolName: 'FoxMetrics',
    category: 'Data',
  },
  {
    toolName: 'FullStory',
    category: 'Data',
  },
  {
    toolName: 'Gauges',
    category: 'Data',
  },
  {
    toolName: 'gemiusHeatMap',
    category: 'Data',
  },
  {
    toolName: 'Glassbox',
    category: 'Data',
  },
  {
    toolName: 'Growth Intelligence',
    category: 'Data',
  },
  {
    toolName: 'HarePoint',
    category: 'Data',
  },
  {
    toolName: 'Heap Inc',
    category: 'Data',
  },
  {
    toolName: 'Heatclix',
    category: 'Data',
  },
  {
    toolName: 'Heatmap',
    category: 'Data',
  },
  {
    toolName: 'HeatMapCo',
    category: 'Data',
  },
  {
    toolName: 'HitLink',
    category: 'Data',
  },
  {
    toolName: 'HitSteps',
    category: 'Data',
  },
  {
    toolName: 'Hotjar',
    category: 'Data',
  },
  {
    toolName: 'InfoTrust',
    category: 'Data',
  },
  {
    toolName: 'Inspectlet',
    category: 'Data',
  },
  {
    toolName: 'Interceptd',
    category: 'Data',
  },
  {
    toolName: 'IQLECT',
    category: 'Data',
  },
  {
    toolName: 'Keen IO',
    category: 'Data',
  },
  {
    toolName: 'LeadBoxer',
    category: 'Data',
  },
  {
    toolName: 'Leadfeeder',
    category: 'Data',
  },
  {
    toolName: 'LeadLander',
    category: 'Data',
  },
  {
    toolName: 'Lexalytics',
    category: 'Data',
  },
  {
    toolName: 'Lityx',
    category: 'Data',
  },
  {
    toolName: 'Localytics',
    category: 'Data',
  },
  {
    toolName: 'Loceye',
    category: 'Data',
  },
  {
    toolName: 'Logaholic',
    category: 'Data',
  },
  {
    toolName: 'LogiKlu',
    category: 'Data',
  },
  {
    toolName: 'Lucky Orange',
    category: 'Data',
  },
  {
    toolName: 'Lumio Analytics',
    category: 'Data',
  },
  {
    toolName: 'Madkudu',
    category: 'Data',
  },
  {
    toolName: 'Makersights',
    category: 'Data',
  },
  {
    toolName: 'Matomo',
    category: 'Data',
  },
  {
    toolName: 'Mattersight Behavioral Analytics',
    category: 'Data',
  },
  {
    toolName: 'Memority',
    category: 'Data',
  },
  {
    toolName: 'Metrixa',
    category: 'Data',
  },
  {
    toolName: 'Minubo',
    category: 'Data',
  },
  {
    toolName: 'Mixpanel',
    category: 'Data',
  },
  {
    toolName: 'Moat',
    category: 'Data',
  },
  {
    toolName: 'Mouseflow',
    category: 'Data',
  },
  {
    toolName: 'MouseStats',
    category: 'Data',
  },
  {
    toolName: 'Moz',
    category: 'Data',
  },
  {
    toolName: 'Nec',
    category: 'Data',
  },
  {
    toolName: 'Netbiscuits',
    category: 'Data',
  },
  {
    toolName: 'New Relic',
    category: 'Data',
  },
  {
    toolName: 'NinjaCat',
    category: 'Data',
  },
  {
    toolName: 'Open Web Analytics (OWA)',
    category: 'Data',
  },
  {
    toolName: 'Opentracker Mobile App Analytics',
    category: 'Data',
  },
  {
    toolName: 'Oribi',
    category: 'Data',
  },
  {
    toolName: 'PaveAI',
    category: 'Data',
  },
  {
    toolName: 'Pendomonium',
    category: 'Data',
  },
  {
    toolName: 'Plusmargin',
    category: 'Data',
  },
  {
    toolName: 'Popcorn Metrics',
    category: 'Data',
  },
  {
    toolName: 'Ptengine',
    category: 'Data',
  },
  {
    toolName: 'Pulsate Predict',
    category: 'Data',
  },
  {
    toolName: 'RealEye',
    category: 'Data',
  },
  {
    toolName: 'Realeyes',
    category: 'Data',
  },
  {
    toolName: 'Revealytics',
    category: 'Data',
  },
  {
    toolName: 'Rubikloud',
    category: 'Data',
  },
  {
    toolName: 'Saleschoice',
    category: 'Data',
  },
  {
    toolName: 'SeeVolution',
    category: 'Data',
  },
  {
    toolName: 'Seldon',
    category: 'Data',
  },
  {
    toolName: 'SessionCam',
    category: 'Data',
  },
  {
    toolName: 'Sharepoint Vitals',
    category: 'Data',
  },
  {
    toolName: 'Silktide Insites',
    category: 'Data',
  },
  {
    toolName: 'Simple Analytics',
    category: 'Data',
  },
  {
    toolName: 'SiteTracker',
    category: 'Data',
  },
  {
    toolName: 'SkyStats',
    category: 'Data',
  },
  {
    toolName: 'Smartlook',
    category: 'Data',
  },
  {
    toolName: 'Snoobi Analytics',
    category: 'Data',
  },
  {
    toolName: 'Splitter',
    category: 'Data',
  },
  {
    toolName: 'StatCounter',
    category: 'Data',
  },
  {
    toolName: 'Statvoo.com',
    category: 'Data',
  },
  {
    toolName: 'Stetic',
    category: 'Data',
  },
  {
    toolName: 'Sticky',
    category: 'Data',
  },
  {
    toolName: 'StreamAnalyst',
    category: 'Data',
  },
  {
    toolName: 'Sutherland Global',
    category: 'Data',
  },
  {
    toolName: 'Tatvic',
    category: 'Data',
  },
  {
    toolName: 'TeleSign',
    category: 'Data',
  },
  {
    toolName: 'Tenjin',
    category: 'Data',
  },
  {
    toolName: 'Tracker.ly',
    category: 'Data',
  },
  {
    toolName: 'Trialfire',
    category: 'Data',
  },
  {
    toolName: 'Tune',
    category: 'Data',
  },
  {
    toolName: 'Upsight',
    category: 'Data',
  },
  {
    toolName: 'UserReplay',
    category: 'Data',
  },
  {
    toolName: 'Vantage',
    category: 'Data',
  },
  {
    toolName: 'Verto App Watch',
    category: 'Data',
  },
  {
    toolName: 'Voluum',
    category: 'Data',
  },
  {
    toolName: 'Websays',
    category: 'Data',
  },
  {
    toolName: 'Webtrends',
    category: 'Data',
  },
  {
    toolName: 'Weelytics',
    category: 'Data',
  },
  {
    toolName: 'Wolk',
    category: 'Data',
  },
  {
    toolName: 'Woopra',
    category: 'Data',
  },
  {
    toolName: 'Acunote',
    category: 'Management',
  },
  {
    toolName: 'AgileCraft',
    category: 'Management',
  },
  {
    toolName: 'Agilo Software',
    category: 'Management',
  },
  {
    toolName: 'All Seated',
    category: 'Management',
  },
  {
    toolName: 'Atlassian',
    category: 'Management',
  },
  {
    toolName: 'Axosoft',
    category: 'Management',
  },
  {
    toolName: 'Blossom',
    category: 'Management',
  },
  {
    toolName: 'BrandTotal',
    category: 'Management',
  },
  {
    toolName: 'Broadcom',
    category: 'Management',
  },
  {
    toolName: 'CollabNet VersionOne',
    category: 'Management',
  },
  {
    toolName: 'Demand Center',
    category: 'Management',
  },
  {
    toolName: 'Digite',
    category: 'Management',
  },
  {
    toolName: 'Epsilon',
    category: 'Management',
  },
  {
    toolName: 'Everlean',
    category: 'Management',
  },
  {
    toolName: 'FlowMotor',
    category: 'Management',
  },
  {
    toolName: 'Hailer Oy',
    category: 'Management',
  },
  {
    toolName: 'Innosabi',
    category: 'Management',
  },
  {
    toolName: 'Kanban Tool',
    category: 'Management',
  },
  {
    toolName: 'Kanbanchi',
    category: 'Management',
  },
  {
    toolName: 'KanbanFlow',
    category: 'Management',
  },
  {
    toolName: 'Kanbanize',
    category: 'Management',
  },
  {
    toolName: 'Kanboard',
    category: 'Management',
  },
  {
    toolName: 'Kantree',
    category: 'Management',
  },
  {
    toolName: 'Krosswall',
    category: 'Management',
  },
  {
    toolName: 'Leankit',
    category: 'Management',
  },
  {
    toolName: 'Markodojo',
    category: 'Management',
  },
  {
    toolName: 'OrangeScrum',
    category: 'Management',
  },
  {
    toolName: 'Pipefy',
    category: 'Management',
  },
  {
    toolName: 'PivotalTracker',
    category: 'Management',
  },
  {
    toolName: 'PlanView',
    category: 'Management',
  },
  {
    toolName: 'Polydone',
    category: 'Management',
  },
  {
    toolName: 'Powermemo',
    category: 'Management',
  },
  {
    toolName: 'Quickscrum',
    category: 'Management',
  },
  {
    toolName: 'Salesforce CRM',
    category: 'Management',
  },
  {
    toolName: 'Salesforce Slack',
    category: 'Management',
  },
  {
    toolName: 'Adobe Cloud',
    category: 'Management',
  },
  {
    toolName: 'Ravetree',
    category: 'Management',
  },
  {
    toolName: 'Retrium',
    category: 'Management',
  },
  {
    toolName: 'Robo.CEO',
    category: 'Management',
  },
  {
    toolName: 'Scrumdesk',
    category: 'Management',
  },
  {
    toolName: 'ScrumDo',
    category: 'Management',
  },
  {
    toolName: 'Scrumwise',
    category: 'Management',
  },
  {
    toolName: 'Scrumy',
    category: 'Management',
  },
  {
    toolName: 'Simple',
    category: 'Management',
  },
  {
    toolName: 'SmartQ',
    category: 'Management',
  },
  {
    toolName: 'SprintGround',
    category: 'Management',
  },
  {
    toolName: 'Sprintly',
    category: 'Management',
  },
  {
    toolName: 'StrategyOrchestrator',
    category: 'Management',
  },
  {
    toolName: 'Taiga',
    category: 'Management',
  },
  {
    toolName: 'Targetprocess',
    category: 'Management',
  },
  {
    toolName: 'Teamwork.com',
    category: 'Management',
  },
  {
    toolName: 'Trello',
    category: 'Management',
  },
  {
    toolName: 'VisionProject',
    category: 'Management',
  },
  {
    toolName: 'Vivify Scrum',
    category: 'Management',
  },
  {
    toolName: 'Workfront',
    category: 'Management',
  },
  {
    toolName: 'Yodiz',
    category: 'Management',
  },
  {
    toolName: 'ZenHub',
    category: 'Management',
  },
  {
    toolName: 'Accountflow',
    category: 'Management',
  },
  {
    toolName: 'Adaptive Insights',
    category: 'Management',
  },
  {
    toolName: 'Allocadia',
    category: 'Management',
  },
  {
    toolName: 'Aprimo',
    category: 'Management',
  },
  {
    toolName: 'BrandMaker',
    category: 'Management',
  },
  {
    toolName: 'Budgeta',
    category: 'Management',
  },
  {
    toolName: 'CCH Tagetik',
    category: 'Management',
  },
  {
    toolName: 'Centage',
    category: 'Management',
  },
  {
    toolName: 'Cloqqa',
    category: 'Management',
  },
  {
    toolName: 'CloudBudget',
    category: 'Management',
  },
  {
    toolName: 'Commissionly',
    category: 'Management',
  },
  {
    toolName: 'Dataccuity',
    category: 'Management',
  },
  {
    toolName: 'Flare Cloud Accounting',
    category: 'Management',
  },
  {
    toolName: 'Hive9',
    category: 'Management',
  },
  {
    toolName: 'IBM',
    category: 'Management',
  },
  {
    toolName: 'Infor',
    category: 'Management',
  },
  {
    toolName: 'Kivvit',
    category: 'Management',
  },
  {
    toolName: 'Lean-Case',
    category: 'Management',
  },
  {
    toolName: 'Longview',
    category: 'Management',
  },
  {
    toolName: 'Marmind',
    category: 'Management',
  },
  {
    toolName: 'Microsoft',
    category: 'Management',
  },
  {
    toolName: 'Neubrain',
    category: 'Management',
  },
  {
    toolName: 'Planful',
    category: 'Management',
  },
  {
    toolName: 'Planguru',
    category: 'Management',
  },
  {
    toolName: 'Poindexter',
    category: 'Management',
  },
  {
    toolName: 'Precisely',
    category: 'Management',
  },
  {
    toolName: 'Prevedere',
    category: 'Management',
  },
  {
    toolName: 'PricingHub',
    category: 'Management',
  },
  {
    toolName: 'Prophix',
    category: 'Management',
  },
  {
    toolName: 'Pumapay',
    category: 'Management',
  },
  {
    toolName: 'Quantrix',
    category: 'Management',
  },
  {
    toolName: 'Smarty Software',
    category: 'Management',
  },
  {
    toolName: 'Startegy',
    category: 'Management',
  },
  {
    toolName: 'Vena Solutions',
    category: 'Management',
  },
  {
    toolName: 'Vendavo',
    category: 'Management',
  },
  {
    toolName: 'Vistaar',
    category: 'Management',
  },
  {
    toolName: 'XLReporting',
    category: 'Management',
  },
  {
    toolName: '8X8',
    category: 'Management',
  },
  {
    toolName: '9Lenses',
    category: 'Management',
  },
  {
    toolName: '10000ft',
    category: 'Management',
  },
  {
    toolName: 'Active Collab',
    category: 'Management',
  },
  {
    toolName: 'Agency Spotter',
    category: 'Management',
  },
  {
    toolName: 'Agil Technologies',
    category: 'Management',
  },
  {
    toolName: 'Airtable',
    category: 'Management',
  },
  {
    toolName: 'Amazon Web Services',
    category: 'Management',
  },
  {
    toolName: 'Anydesk',
    category: 'Management',
  },
  {
    toolName: 'Anywhere365',
    category: 'Management',
  },
  {

  toolName: 'Attane',
  category: 'Management',
},
  {
    toolName: 'Awingu',
    category: 'Management',
  },
  {
    toolName: 'Azendoo',
    category: 'Management',
  },
  {
    toolName: 'Azeus Convene',
    category: 'Management',
  },
  {
    toolName: 'BeeCanvas',
    category: 'Management',
  },
  {
    toolName: 'Bitrix24',
    category: 'Management',
  },
  {
    toolName: 'Bloola',
    category: 'Management',
  },
  {
    toolName: 'BlueJeans',
    category: 'Management',
  },
  {
    toolName: 'Box',
    category: 'Management',
  },
  {
    toolName: 'Brandguardian',
    category: 'Management',
  },
  {
    toolName: 'Bubbl.us',
    category: 'Management',
  },
  {
    toolName: 'Cage',
    category: 'Management',
  },
  {
    toolName: 'Cardboard',
    category: 'Management',
  },
  {
    toolName: 'Chatwork',
    category: 'Management',
  },
  {
    toolName: 'Cinergix',
    category: 'Management',
  },
  {
    toolName: 'Circuit',
    category: 'Management',
  },
  {
    toolName: 'Cisco',
    category: 'Management',
  },
  {
    toolName: 'Clinked',
    category: 'Management',
  },
  {
    toolName: 'Cloudalize',
    category: 'Management',
  },
  {
    toolName: 'Cloudine',
    category: 'Management',
  },
  {
    toolName: 'Co:tunity',
    category: 'Management',
  },
  {
    toolName: 'Coggle',
    category: 'Management',
  },
  {
    toolName: 'Colabers',
    category: 'Management',
  },
  {
    toolName: 'CollaborateCloud',
    category: 'Management',
  },
  {
    toolName: 'Collaborazon',
    category: 'Management',
  },
  {
    toolName: 'Comindwork',
    category: 'Management',
  },
  {
    toolName: 'Conceptboard',
    category: 'Management',
  },
  {
    toolName: 'Convo',
    category: 'Management',
  },
  {
    toolName: 'Crugo',
    category: 'Management',
  },
  {
    toolName: 'Cuepin',
    category: 'Management',
  },
  {
    toolName: 'DeskAway',
    category: 'Management',
  },
  {
    toolName: 'Devada',
    category: 'Management',
  },
  {
    toolName: 'Docket',
    category: 'Management',
  },
  {
    toolName: 'DocRaptor',
    category: 'Management',
  },
  {
    toolName: 'Docxpresso',
    category: 'Management',
  },
  {
    toolName: 'Dokify',
    category: 'Management',
  },
  {
    toolName: 'Doodle',
    category: 'Management',
  },
  {
    toolName: 'Dream Broker',
    category: 'Management',
  },
  {
    toolName: 'Dropbox',
    category: 'Management',
  },
  {
    toolName: 'DropReview',
    category: 'Management',
  },
  {
    toolName: 'Eko',
    category: 'Management',
  },
  {
    toolName: 'eKyte',
    category: 'Management',
  },
  {
    toolName: 'Eloqua B2C Marketing',
    category: 'Management',
  },
  {
    toolName: 'Eloqua B2B Marketing',
    category: 'Management',
  },
  {
    toolName: 'Eloqua Fusion Marketing',
    category: 'Management',
  },
  {
    toolName: 'Eloqua Marketing Automation',
    category: 'Management',
  },
  {
    toolName: 'Eloqua Responsys Campaign Management',
    category: 'Management',
  },
  {
    toolName: 'Eloqua CrowdTwist Loyalty and Engagement',
    category: 'Management',
  },
  {
    toolName: 'Eloqua Bluekai Data Management Platform',
    category: 'Management',
  },
  {
    toolName: 'Eloqua Audience Segmentation',
    category: 'Management',
  },
  {
    toolName: 'Eloqua Unity Customer Data Platform',
    category: 'Management',
  },
  {
    toolName: 'Eloqua Infinity Behavioral Intelligence',
    category: 'Management',
  },
  {
    toolName: 'Eloqua Maxymiser Testing and Optimization',
    category: 'Management',
  },
  {
    toolName: 'EnjoyHQ',
    category: 'Management',
  },
  {
    toolName: 'Enterprise Hive',
    category: 'Management',
  },
  {
    toolName: 'Evernote',
    category: 'Management',
  },
  {
    toolName: 'Exo',
    category: 'Management',
  },
  {
    toolName: 'Featuremap',
    category: 'Management',
  },
  {
    toolName: 'Feng Office',
    category: 'Management',
  },
  {
    toolName: 'Figma',
    category: 'Management',
  },
  {
    toolName: 'Flowdock',
    category: 'Management',
  },
  {
    toolName: 'Front',
    category: 'Management',
  },
  {
    toolName: 'Frontify',
    category: 'Management',
  },
  {
    toolName: 'Fruux',
    category: 'Management',
  },
  {
    toolName: 'FYI',
    category: 'Management',
  },
  {
    toolName: 'Gladys',
    category: 'Management',
  },
  {
    toolName: 'Glasscubes',
    category: 'Management',
  },
  {
    toolName: 'Gluu',
    category: 'Management',
  },
  {
    toolName: 'Gmelius',
    category: 'Management',
  },
  {
    toolName: 'Google',
    category: 'Management',
  },
  {
    toolName: 'Gridle',
    category: 'Management',
  },
  {
    toolName: 'Harmon.ie',
    category: 'Management',
  },
  {
    toolName: 'Helpmonks',
    category: 'Management',
  },
  {
    toolName: 'Hibox',
    category: 'Management',
  },
  {
    toolName: 'Highfive',
    category: 'Management',
  },
  {
    toolName: 'Hightail',
    category: 'Management',
  },
  {
    toolName: 'Hive',
    category: 'Management',
  },
  {
    toolName: 'Hiver',
    category: 'Management',
  },
  {
    toolName: 'Honey',
    category: 'Management',
  },
  {
    toolName: 'Hotgloo',
    category: 'Management',
  },
  {
    toolName: 'Huddle',
    category: 'Management',
  },
  {
    toolName: 'Igloo',
    category: 'Management',
  },
  {
    toolName: 'Inforama',
    category: 'Management',
  },
  {
    toolName: 'Innocentive',
    category: 'Management',
  },
  {
    toolName: 'Intraboom',
    category: 'Management',
  },
  {
    toolName: 'Intwixt, Inc.',
    category: 'Management',
  },
  {
    toolName: 'InVision',
    category: 'Management',
  },
  {
    toolName: 'Invotra',
    category: 'Management',
  },
  {
    toolName: 'Jell',
    category: 'Management',
  },
  {
    toolName: 'Jive',
    category: 'Management',
  },
  {
    toolName: 'Joincube',
    category: 'Management',
  },
  {
    toolName: 'Joiqu',
    category: 'Management',
  },
  {
    toolName: 'Jostle',
    category: 'Management',
  },
  {
    toolName: 'Just Social',
    category: 'Management',
  },
  {
    toolName: 'Kinly',
    category: 'Management',
  },
  {
    toolName: 'Knote',
    category: 'Management',
  },
  {
    toolName: 'Loom',
    category: 'Management',
  },
  {
    toolName: 'Loomio',
    category: 'Management',
  },
  {
    toolName: 'Lumoflow',
    category: 'Management',
  },
  {
    toolName: 'M-Files',
    category: 'Management',
  },
  {
    toolName: 'MailClark',
    category: 'Management',
  },
  {
    toolName: 'MangoApps',
    category: 'Management',
  },
  {
    toolName: 'Marvelapp',
    category: 'Management',
  },
  {
    toolName: 'MeetingSphere',
    category: 'Management',
  },
  {
    toolName: 'MeshIQ',
    category: 'Management',
  },
  {
    toolName: 'Mindjet',
    category: 'Management',
  },
  {
    toolName: 'Miro',
    category: 'Management',
  },
  {
    toolName: 'Mitel',
    category: 'Management',
  },
  {
    toolName: 'Moovia',
    category: 'Management',
  },
  {
    toolName: 'Moxtra',
    category: 'Management',
  },
  {
    toolName: 'MURAL',
    category: 'Management',
  },
  {
    toolName: 'Noodle',
    category: 'Management',
  },
  {
    toolName: 'Notism',
    category: 'Management',
  },
  {
    toolName: 'Nuclino',
    category: 'Management',
  },
  {
    toolName: 'Offiria',
    category: 'Management',
  },
  {
    toolName: 'OI Engine',
    category: 'Management',
  },
  {
    toolName: 'OneHub',
    category: 'Management',
  },
  {
    toolName: 'OnStage',
    category: 'Management',
  },
  {
    toolName: 'Opal',
    category: 'Management',
  },
  {
    toolName: 'OpenSim',
    category: 'Management',
  },
  {
    toolName: 'Passageways',
    category: 'Management',
  },
  {
    toolName: 'PGi',
    category: 'Management',
  },
  {
    toolName: 'Pingpad',
    category: 'Management',
  },
  {
    toolName: 'Powernoodle',
    category: 'Management',
  },
  {
    toolName: 'PriorityMatrix',
    category: 'Management',
  },
  {
    toolName: 'Projecturf',
    category: 'Management',
  },
  {
    toolName: 'Quip',
    category: 'Management',
  },
  {
    toolName: 'Quire',
    category: 'Management',
  },
  {
    toolName: 'Ragic',
    category: 'Management',
  },
  {
    toolName: 'Redbooth',
    category: 'Management',
  },
  {
    toolName: 'RingCentral',
    category: 'Management',
  },
  {
    toolName: 'Ryver',
    category: 'Management',
  },

  {
    toolName: 'Salesforce Email Studio',
    category: 'Management',
  },
  {
    toolName: 'Salesforce Journey Builder',
    category: 'Management',
  },
  {
    toolName: 'Salesforce Interaction Studio',
    category: 'Management',
  },
  {
    toolName: 'Salesforce CDP',
    category: 'Management',
  },
  {
    toolName: 'Salesforce Pardot',
    category: 'Management',
  },
  {
    toolName: 'Salesforce Datorama',
    category: 'Management',
  },
  {
    toolName: 'Salesforce Advertising Studio',
    category: 'Management',
  },
  {
    toolName: 'Salesforce Mobile Studio',
    category: 'Management',
  },
  {
    toolName: 'Salesforce Content Management',
    category: 'Management',
  },
  {
    toolName: 'Salesforce Google Marketing Platform',
    category: 'Management',
  },
  {
    toolName: 'Salesforce Loyalty Management',
    category: 'Management',
  },
  {
    toolName: 'Salesforce Datorama Reports',
    category: 'Management',
  },
  {
    toolName: 'Samepage',
    category: 'Management',
  },
  {
    toolName: 'SAP',
    category: 'Management',
  },
  {
    toolName: 'ServiceMax',
    category: 'Management',
  },
  {
    toolName: 'Sharepoint',
    category: 'Management',
  },
  {
    toolName: 'Sharpr',
    category: 'Management',
  },
  {
    toolName: 'Sheetgo',
    category: 'Management',
  },
  {
    toolName: 'Shopperations Research & Technology, LLC',
    category: 'Management',
  },
  {
    toolName: 'Skype',
    category: 'Management',
  },
  {
    toolName: 'Slack',
    category: 'Management',
  },
  {
    toolName: 'Slickplan',
    category: 'Management',
  },
  {
    toolName: 'Slope',
    category: 'Management',
  },
  {
    toolName: 'Sococo',
    category: 'Management',
  },
  {
    toolName: 'Squadpod',
    category: 'Management',
  },
  {
    toolName: 'Squadzip',
    category: 'Management',
  },
  {
    toolName: 'Stack Overflow',
    category: 'Management',
  },
  {
    toolName: 'Stackfield',
    category: 'Management',
  },
  {
    toolName: 'Stormboard',
    category: 'Management',
  },
  {
    toolName: 'Swivl',
    category: 'Management',
  },
  {
    toolName: 'Syncplicity',
    category: 'Management',
  },
  {
    toolName: 'Synqion',
    category: 'Management',
  },
  {
    toolName: 'Tabidoo',
    category: 'Management',
  },
  {
    toolName: 'Talkspirit',
    category: 'Management',
  },
  {
    toolName: 'Tallyfox',
    category: 'Management',
  },
  {
    toolName: 'Tamashare',
    category: 'Management',
  },
  {
    toolName: 'Taskbeat',
    category: 'Management',
  },
  {
    toolName: 'Taskworld',
    category: 'Management',
  },
  {
    toolName: 'Teambition',
    category: 'Management',
  },
  {
    toolName: 'Teamplace',
    category: 'Management',
  },
  {
    toolName: 'Teamviewer',
    category: 'Management',
  },
  {
    toolName: 'Teamwire',
    category: 'Management',
  },
  {
    toolName: 'Teem',
    category: 'Management',
  },
  {
    toolName: 'Tessello',
    category: 'Management',
  },
  {
    toolName: 'Tibco',
    category: 'Management',
  },
  {
    toolName: 'Twiddla',
    category: 'Management',
  },
  {
    toolName: 'Twist',
    category: 'Management',
  },
  {
    toolName: 'Twoodo',
    category: 'Management',
  },
  {
    toolName: 'Upwave',
    category: 'Management',
  },
  {
    toolName: 'Valotalive',
    category: 'Management',
  },
  {
    toolName: 'Viadesk',
    category: 'Management',
  },
  {
    toolName: 'WeTransfer',
    category: 'Management',
  },
  {
    toolName: 'Wimi',
    category: 'Management',
  },
  {
    toolName: 'Wiredrive',
    category: 'Management',
  },
  {
    toolName: 'Woobot.io',
    category: 'Management',
  },
  {
    toolName: 'Workshare',
    category: 'Management',
  },
  {
    toolName: 'XaitPorter',
    category: 'Management',
  },
  {
    toolName: 'Xmind',
    category: 'Management',
  },
  {
    toolName: 'XWiki',
    category: 'Management',
  },
  {
    toolName: 'Yalla',
    category: 'Management',
  },
  {
    toolName: 'Yammer',
    category: 'Management',
  },
  {
    toolName: 'Zoho',
    category: 'Management',
  },
  {
    toolName: 'Zoom',
    category: 'Management',
  },
  {
    toolName: 'Aha!',
    category: 'Management',
  },
  {
    toolName: 'Bulbshare',
    category: 'Management',
  },
  {
    toolName: 'Conjoint.ly',
    category: 'Management',
  },
  {
    toolName: 'Craft',
    category: 'Management',
  },
  {
    toolName: 'Germ.io',
    category: 'Management',
  },
  {
    toolName: 'InnovateNow',
    category: 'Management',
  },
  {
    toolName: 'Intland Software',
    category: 'Management',
  },
  {
    toolName: 'Jama Software',
    category: 'Management',
  },
  {
    toolName: 'LaunchPad Central',
    category: 'Management',
  },
  {
    toolName: 'OneDesk',
    category: 'Management',
  },
  {
    toolName: 'ProdPad',
    category: 'Management',
  },
  {
    toolName: 'productboard',
    category: 'Management',
  },
  {
    toolName: 'ProductPlan',
    category: 'Management',
  },
  {
    toolName: 'Professional Systems Associates',
    category: 'Management',
  },
  {
    toolName: 'PTC',
    category: 'Management',
  },
  {
    toolName: 'Resource Guru',
    category: 'Management',
  },
  {
    toolName: 'Roadmunk',
    category: 'Management',
  },
  {
    toolName: 'Screenful',
    category: 'Management',
  },
  {
    toolName: 'SensorSix',
    category: 'Management',
  },
  {
    toolName: 'Sopheon',
    category: 'Management',
  },
  {
    toolName: 'Spotopp',
    category: 'Management',
  },
  {
    toolName: 'SuiteDash',
    category: 'Management',
  },
  {
    toolName: 'Validately',
    category: 'Management',
  },
  {
    toolName: 'Wizeline',
    category: 'Management',
  },
  {
    toolName: 'Wrike',
    category: 'Management',
  },
  {
    toolName: 'YouGile',
    category: 'Management',
  },
  {
    toolName: 'Aceproject',
    category: 'Management',
  },
  {
    toolName: 'Acteamo',
    category: 'Management',
  },
  {
    toolName: 'Actiondesk',
    category: 'Management',
  },
  {
    toolName: 'Admation',
    category: 'Management',
  },
  {
    toolName: 'Agilepoint',
    category: 'Management',
  },
  {
    toolName: 'Agiloft',
    category: 'Management',
  },
  {
    toolName: 'Agreedo',
    category: 'Management',
  },
  {
    toolName: 'Aha',
    category: 'Management',
  },
  {
    toolName: 'Apptivo',
    category: 'Management',
  },
  {
    toolName: 'Asana',
    category: 'Management',
  },
  {
    toolName: 'Assembla',
    category: 'Management',
  },
  {
    toolName: 'ATOMIZED',
    category: 'Management',
  },
  {
    toolName: 'Base.vn',
    category: 'Management',
  },
  {
    toolName: 'Basecamp',
    category: 'Management',
  },
  {
    toolName: 'BinFire',
    category: 'Management',
  },
  {
    toolName: 'Braid',
    category: 'Management',
  },
  {
    toolName: 'Breeze',
    category: 'Management',
  },
  {
    toolName: 'Brightpod',
    category: 'Management',
  },
  {
    toolName: 'BrightWork',
    category: 'Management',
  },
  {
    toolName: 'Bubble Group',
    category: 'Management',
  },
  {
    toolName: 'Buddy',
    category: 'Management',
  },
  {
    toolName: 'Casual',
    category: 'Management',
  },
  {
    toolName: 'Celonis',
    category: 'Management',
  },
  {
    toolName: 'Celoxis',
    category: 'Management',
  },
  {
    toolName: 'Changepoint',
    category: 'Management',
  },
  {
    toolName: 'Claris',
    category: 'Management',
  },
  {
    toolName: 'Clarizen',
    category: 'Management',
  },
  {
    toolName: 'ClickUp',
    category: 'Management',
  },
  {
    toolName: 'CloudApp',
    category: 'Management',
  },
  {
    toolName: 'CloudCoach',
    category: 'Management',
  },
  {
    toolName: 'ClubHouse',
    category: 'Management',
  },
  {
    toolName: 'Copper',
    category: 'Management',
  },
  {
    toolName: 'Cronycle',
    category: 'Management',
  },
  {
    toolName: 'Decisions',
    category: 'Management',
  },
  {
    toolName: 'DoneDone',
    category: 'Management',
  },
  {
    toolName: 'DronaHQ',
    category: 'Management',
  },
  {
    toolName: 'EasyProjects',
    category: 'Management',
  },
  {
    toolName: 'Encode',
    category: 'Management',
  },
  {
    toolName: 'Eylean',
    category: 'Management',
  },
  {
    toolName: 'Fingertip',
    category: 'Management',
  },
  {
    toolName: 'Float',
    category: 'Management',
  },
  {
    toolName: 'Flovate',
    category: 'Management',
  },
  {
    toolName: 'Flow',
    category: 'Management',
  },
  {
    toolName: 'Flowhaven',
    category: 'Management',
  },
  {
    toolName: 'Freedcamp',
    category: 'Management',
  },
  {
    toolName: 'Function Point',
    category: 'Management',
  },
  {
    toolName: 'FunctionFox',
    category: 'Management',
  },
  {
    toolName: 'Gantter',
    category: 'Management',
  },
  {
    toolName: 'GeniusProject',
    category: 'Management',
  },
  {
    toolName: 'Harvest',
    category: 'Management',
  },
  {
    toolName: 'HelloFocus',
    category: 'Management',
  },
  {
    toolName: 'HeyOrca',
    category: 'Management',
  },
  {
    toolName: 'Hitask',
    category: 'Management',
  },
  {
    toolName: 'Husky Marketing Planner',
    category: 'Management',
  },
  {
    toolName: 'Ignitur',
    category: 'Management',
  },
  {
    toolName: 'Inloox',
    category: 'Management',
  },
  {
    toolName: 'InMotionNow',
    category: 'Management',
  },
  {
    toolName: 'Insightly',
    category: 'Management',
  },
  {
    toolName: 'Integrify',
    category: 'Management',
  },
  {
    toolName: 'Intervals',
    category: 'Management',
  },
  {
    toolName: 'itdesign',
    category: 'Management',
  },
  {
    toolName: 'Jessie',
    category: 'Management',
  },
  {
    toolName: 'K2',
    category: 'Management',
  },
  {
    toolName: 'Kelloo',
    category: 'Management',
  },
  {
    toolName: 'Kintone',
    category: 'Management',
  },
  {
    toolName: 'Lately',
    category: 'Management',
  },
  {
    toolName: 'Leankor',
    category: 'Management',
  },
  {
    toolName: 'LiquidPlanner',
    category: 'Management',
  },
  {
    toolName: 'ManyWho',
    category: 'Management',
  },
  {
    toolName: 'MavenLink',
    category: 'Management',
  },
  {
    toolName: 'Mavim',
    category: 'Management',
  },
  {
    toolName: 'Mediatool',
    category: 'Management',
  },
  {
    toolName: 'Meister',
    category: 'Management',
  },
  {
    toolName: 'Monday',
    category: 'Management',
  },
  {
    toolName: 'MonkeyWorks',
    category: 'Management',
  },
  {
    toolName: 'Olifano',
    category: 'Management',
  },
  {
    toolName: 'Opptimo',
    category: 'Management',
  },
  {
    toolName: 'Orchestly',
    category: 'Management',
  },
  {
    toolName: 'Outplanr',
    category: 'Management',
  },
  {
    toolName: 'Paymo',
    category: 'Management',
  },
  {
    toolName: 'PeopleVine',
    category: 'Management',
  },
  {
    toolName: 'Pie',
    category: 'Management',
  },
  {
    toolName: 'Planbox',
    category: 'Management',
  },
  {
    toolName: 'Plutio',
    category: 'Management',
  },
  {
    toolName: 'Podio',
    category: 'Management',
  },
  {
    toolName: 'PPO',
    category: 'Management',
  },
  {
    toolName: 'Precursive',
    category: 'Management',
  },
  {
    toolName: 'Process Street',
    category: 'Management',
  },
  {
    toolName: 'ProcessGold',
    category: 'Management',
  },
  {
    toolName: 'ProcessKit',
    category: 'Management',
  },
  {
    toolName: 'ProcessMaker',
    category: 'Management',
  },
  {
    toolName: 'ProjectManager',
    category: 'Management',
  },
  {
    toolName: 'ProofHub',
    category: 'Management',
  },
  {
    toolName: 'ProWorkflow',
    category: 'Management',
  },
  {
    toolName: 'Pyrus',
    category: 'Management',
  },
  {
    toolName: 'Quick Base',
    category: 'Management',
  },
  {
    toolName: 'RationalPlan',
    category: 'Management',
  },
  {
    toolName: 'Re4m.io',
    category: 'Management',
  },
  {
    toolName: 'Redmine',
    category: 'Management',
  },
  {
    toolName: 'ResourceGuru',
    category: 'Management',
  },
  {
    toolName: 'RoboHead',
    category: 'Management',
  },
  {
    toolName: 'Ruum',
    category: 'Management',
  },
  {
    toolName: 'Scoro',
    category: 'Management',
  },
  {
    toolName: 'Screendragon',
    category: 'Management',
  },
  {
    toolName: 'Smartsheet',
    category: 'Management',
  },
  {
    toolName: 'SpringCM',
    category: 'Management',
  },
  {
    toolName: 'Stevill',
    category: 'Management',
  },
  {
    toolName: 'Surwayne',
    category: 'Management',
  },
  {
    toolName: 'SweetProcess',
    category: 'Management',
  },
  {
    toolName: 'Synergist',
    category: 'Management',
  },
  {
    toolName: 'Tabbli',
    category: 'Management',
  },
  {
    toolName: 'Tadabase',
    category: 'Management',
  },
  {
    toolName: 'Tallyfy',
    category: 'Management',
  },
  {
    toolName: 'Taskfeed',
    category: 'Management',
  },
  {
    toolName: 'TaskRay',
    category: 'Management',
  },
  {
    toolName: 'Taskrow',
    category: 'Management',
  },
  {
    toolName: 'Teamelio',
    category: 'Management',
  },
  {
    toolName: 'TeamGantt',
    category: 'Management',
  },
  {
    toolName: 'TeamGrid',
    category: 'Management',
  },
  {
    toolName: 'ThoughtWorks',
    category: 'Management',
  },
  {
    toolName: 'Todoist',
    category: 'Management',
  },
  {
    toolName: 'Toggl',
    category: 'Management',
  },
  {
    toolName: 'Tonkean',
    category: 'Management',
  },
  {
    toolName: 'Toodledo',
    category: 'Management',
  },
  {
    toolName: 'Unaty',
    category: 'Management',
  },
  {
    toolName: 'Updatey',
    category: 'Management',
  },
  {
    toolName: 'Upland Software',
    category: 'Management',
  },
  {
    toolName: 'Viewpath',
    category: 'Management',
  },
  {
    toolName: 'Visionflow',
    category: 'Management',
  },
  {
    toolName: 'WittyParrot',
    category: 'Management',
  },
  {
    toolName: 'Workamajig',
    category: 'Management',
  },
  {
    toolName: 'Workboard',
    category: 'Management',
  },
  {
    toolName: 'WorkflowMax',
    category: 'Management',
  },
  {
    toolName: 'Workgroups DaVinci',
    category: 'Management',
  },
  {
    toolName: 'Workstack',
    category: 'Management',
  },
  {
    toolName: 'WorkZone',
    category: 'Management',
  },
  {
    toolName: 'Wunderlist',
    category: 'Management',
  },
  {
    toolName: 'Zenkit',
    category: 'Management',
  },
  {
    toolName: 'Zenpilot',
    category: 'Management',
  },
  {
    toolName: 'Ziflow',
    category: 'Management',
  },
  {
    toolName: 'Zoom.ai',
    category: 'Management',
  },
  {
    toolName: '15Five',
    category: 'Management',
  },
  {
    toolName: '99 Designs',
    category: 'Management',
  },
  {
    toolName: 'Ascendify',
    category: 'Management',
  },
  {
    toolName: 'Avature',
    category: 'Management',
  },
  {
    toolName: 'BambooHR',
    category: 'Management',
  },
  {
    toolName: 'Beamery',
    category: 'Management',
  },
  {
    toolName: 'BetterWorks',
    category: 'Management',
  },
  {
    toolName: 'BirdDogHR',
    category: 'Management',
  },
  {
    toolName: 'BlockLancer',
    category: 'Management',
  },
  {
    toolName: 'Bounty0x',
    category: 'Management',
  },
  {
    toolName: 'Brazen',
    category: 'Management',
  },
  {
    toolName: 'Bullhorn',
    category: 'Management',
  },
  {
    toolName: 'Bunch',
    category: 'Management',
  },
  {
    toolName: 'Cambeo Retail',
    category: 'Management',
  },
  {
    toolName: 'Canwork',
    category: 'Management',
  },
  {
    toolName: 'CATS',
    category: 'Management',
  },
  {
    toolName: 'Cegid',
    category: 'Management',
  },
  {
    toolName: 'Centrical',
    category: 'Management',
  },
  {
    toolName: 'Claro',
    category: 'Management',
  },
  {
    toolName: 'ClearCompany',
    category: 'Management',
  },
  {
    toolName: 'Clinch',
    category: 'Management',
  },
  {
    toolName: 'Cobrainer',
    category: 'Management',
  },
  {
    toolName: 'Comeet',
    category: 'Management',
  },
  {
    toolName: 'cooleaf',
    category: 'Management',
  },
  {
    toolName: 'Crelate Talent',
    category: 'Management',
  },
  {
    toolName: 'Darwinbox',
    category: 'Management',
  },
  {
    toolName: 'DeepTalent',
    category: 'Management',
  },
  {
    toolName: 'Echospan',
    category: 'Management',
  },
  {
    toolName: 'Engagedly',
    category: 'Management',
  },
  {
    toolName: 'Envato Studio',
    category: 'Management',
  },
  {
    toolName: 'Fetcher',
    category: 'Management',
  },
  {
    toolName: 'Ffrizby',
    category: 'Management',
  },
  {
    toolName: 'Freelancer',
    category: 'Management',
  },
  {
    toolName: 'FurstPerson, Inc.',
    category: 'Management',
  },
  {
    toolName: 'GigTricks',
    category: 'Management',
  },
  {
    toolName: 'GoCo',
    category: 'Management',
  },
  {
    toolName: 'gr8 People',
    category: 'Management',
  },
  {
    toolName: 'Greenhouse',
    category: 'Management',
  },
  {
    toolName: 'HelloTeam',
    category: 'Management',
  },
  {
    toolName: 'HireHive',
    category: 'Management',
  },
  {
    toolName: 'HireMojo',
    category: 'Management',
  },
  {
    toolName: 'HireVue',
    category: 'Management',
  },
  {
    toolName: 'HRBoss',
    category: 'Management',
  },
  {
    toolName: 'iCIMS',
    category: 'Management',
  },
  {
    toolName: 'Ideal',
    category: 'Management',
  },
  {
    toolName: 'Isaak',
    category: 'Management',
  },
  {
    toolName: 'JazzHR',
    category: 'Management',
  },
  {
    toolName: 'JobTarget',
    category: 'Management',
  },
  {
    toolName: 'Jobtip',
    category: 'Management',
  },
  {
    toolName: 'Jobvite',
    category: 'Management',
  },
  {
    toolName: 'Kand.io',
    category: 'Management',
  },
  {
    toolName: 'Kazoo HR',
    category: 'Management',
  },
  {
    toolName: 'Kenjo',
    category: 'Management',
  },
  {
    toolName: 'Lattice',
    category: 'Management',
  },
  {
    toolName: 'Lever',
    category: 'Management',
  },
  {
    toolName: 'LinkedIn',
    category: 'Management',
  },
  {
    toolName: 'Lytmus',
    category: 'Management',
  },
  {
    toolName: 'Newton Software',
    category: 'Management',
  },
  {
    toolName: 'Officevibe',
    category: 'Management',
  },
  {
    toolName: 'OnRecruit',
    category: 'Management',
  },
  {
    toolName: 'Oracle',
    category: 'Management',
  },
  {
    toolName: 'PageUp',
    category: 'Management',
  },
  {
    toolName: 'PeopleFluent',
    category: 'Management',
  },
  {
    toolName: 'Perview',
    category: 'Management',
  },
  {
    toolName: 'Pomello',
    category: 'Management',
  },
  {
    toolName: 'PowerToFly',
    category: 'Management',
  },
  {
    toolName: 'Recruitee',
    category: 'Management',
  },
  {
    toolName: 'RecruiterBox',
    category: 'Management',
  },
  {
    toolName: 'Reflektive',
    category: 'Management',
  },
  {
    toolName: 'Rise.Global',
    category: 'Management',
  },
  {
    toolName: 'RockContent',
    category: 'Management',
  },
  {
    toolName: 'Saba',
    category: 'Management',
  },
  {
    toolName: 'SelfStir',
    category: 'Management',
  },
  {
    toolName: 'Shortlist',
    category: 'Management',
  },
  {
    toolName: 'SilkRoad Technologies',
    category: 'Management',
  },
  {
    toolName: 'Skilo',
    category: 'Management',
  },
  {
    toolName: 'SmartRecruiters',
    category: 'Management',
  },
  {
    toolName: 'Smashfly',
    category: 'Management',
  },
  {
    toolName: 'Spekit Inc.',
    category: 'Management',
  },
  {
    toolName: 'SwoopTalent',
    category: 'Management',
  },
  {
    toolName: 'Symphony Talent',
    category: 'Management',
  },
  {
    toolName: 'Talemetry',
    category: 'Management',
  },
  {
    toolName: 'Talention',
    category: 'Management',
  },
  {
    toolName: 'Talentsoft',
    category: 'Management',
  },
  {
    toolName: 'Target Internet',
    category: 'Management',
  },
  {
    toolName: 'Team Improver',
    category: 'Management',
  },
  {
    toolName: 'Teamable',
    category: 'Management',
  },
  {
    toolName: 'TinyPulse',
    category: 'Management',
  },
  {
    toolName: 'TopTal',
    category: 'Management',
  },
  {
    toolName: 'Visier',
    category: 'Management',
  },
  {
    toolName: 'Vonq',
    category: 'Management',
  },
  {
    toolName: 'Weekdone',
    category: 'Management',
  },
  {
    toolName: 'WhoKnows',
    category: 'Management',
  },
  {
    toolName: 'Wonderkind',
    category: 'Management',
  },
  {
    toolName: 'Wopify',
    category: 'Management',
  },
  {
    toolName: 'Work Team',
    category: 'Management',
  },
  {
    toolName: 'Workable',
    category: 'Management',
  },
  {
    toolName: 'Workday',
    category: 'Management',
  },
  {
    toolName: 'Workshape',
    category: 'Management',
  },
  {
    toolName: 'Xoxoday',
    category: 'Management',
  },
  {
    toolName: 'Youpic',
    category: 'Management',
  },
  {
    toolName: 'Zapiens',
    category: 'Management',
  },
  {
    toolName: 'Zokri',
    category: 'Management',
  },
  {
    toolName: 'Aberdeen Group',
    category: 'Management',
  },
  {
    toolName: 'Airstack',
    category: 'Management',
  },
  {
    toolName: 'B2B Marketing',
    category: 'Management',
  },
  {
    toolName: 'CabinetM',
    category: 'Management',
  },
  {
    toolName: 'Capterra',
    category: 'Management',
  },
  {
    toolName: 'CB Insights',
    category: 'Management',
  },
  {
    toolName: 'chiefmartec.com',
    category: 'Management',
  },
  {
    toolName: 'CMSWire',
    category: 'Management',
  },
  {
    toolName: 'Crunchbase',
    category: 'Management',
  },
  {
    toolName: 'Digital',
    category: 'Management',
  },
  {
    toolName: 'Digital Clarity Group',
    category: 'Management',
  },
  {
    toolName: 'Digital Marketing Depot',
    category: 'Management',
  },
  {
    toolName: 'Discover Cloud',
    category: 'Management',
  },
  {
    toolName: 'Experts Exchange',
    category: 'Management',
  },
  {
    toolName: 'Forrester Research',
    category: 'Management',
  },
  {
    toolName: 'G2',
    category: 'Management',
  },
  {
    toolName: 'Gartner',
    category: 'Management',
  },
  {
    toolName: 'GetApp',
    category: 'Management',
  },
  {
    toolName: 'IDC Research',
    category: 'Management',
  },
  {
    toolName: 'Influencermarketinghub',
    category: 'Management',
  },
  {
    toolName: 'IntelliPhi',
    category: 'Management',
  },
  {
    toolName: 'IT Central Station',
    category: 'Management',
  },
  {
    toolName: 'LUMA Partners',
    category: 'Management',
  },
  {
    toolName: 'MarTech Advisor',
    category: 'Management',
  },
  {
    toolName: 'MarTech Today',
    category: 'Management',
  },
  {
    toolName: 'MarTech Zone',
    category: 'Management',
  },
  {
    toolName: 'Martechbase',
    category: 'Management',
  },
  {
    toolName: 'Martechforum',
    category: 'Management',
  },
  {
    toolName: 'Mintel',
    category: 'Management',
  },
  {
    toolName: 'OpenLantern',
    category: 'Management',
  },
  {
    toolName: 'ProductHunt',
    category: 'Management',
  },
  {
    toolName: 'Promarketer',
    category: 'Management',
  },
  {
    toolName: 'Raab Associates',
    category: 'Management',
  },
  {
    toolName: 'Real Story Group',
    category: 'Management',
  },
  {
    toolName: 'SaasGenius',
    category: 'Management',
  },
  {
    toolName: 'SiriusDecisions',
    category: 'Management',
  },
  {
    toolName: 'Software Advice',
    category: 'Management',
  },
  {
    toolName: 'TechLeaders.io',
    category: 'Management',
  },
  {
    toolName: 'TechnologyAdvice',
    category: 'Management',
  },
  {
    toolName: 'TechTarget',
    category: 'Management',
  },
  {
    toolName: 'The Drum',
    category: 'Management',
  },
  {
    toolName: 'Topo',
    category: 'Management',
  },
  {
    toolName: 'Trust Radius',
    category: 'Management',
  },
  {
    toolName: 'UKTN',
    category: 'Management',
  },
  {
    toolName: 'VBProfiles',
    category: 'Management',
  },
  {
    toolName: 'Ventana Research',
    category: 'Management',
  },
  {
    toolName: 'VentureScanner',
    category: 'Management',
  },
  {
    toolName: '6sense',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Addaptive',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Adobe Experience Manager',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Agent3',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Allvue Systems',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Ampliz',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Anaplan',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Artesian',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Bilin Technology',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Bizible',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Celsius International',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Cyance',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Demandbase',
    category: 'Social & Relationships',
  },
  {
    toolName: 'DemandFarm',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Duedil',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Dun & Bradstreet',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Eloqua B2C Marketing',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Eloqua B2B Marketing',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Eloqua Fusion Marketing',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Eloqua Marketing Automation',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Eloqua Responsys Campaign Management',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Eloqua CrowdTwist Loyalty and Engagement',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Eloqua Bluekai Data Management Platform',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Eloqua Audience Segmentation',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Eloqua Unity Customer Data Platform',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Eloqua Infinity Behavioral Intelligence',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Eloqua Maxymiser Testing and Optimization',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Engagio',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Everstring',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Fiind',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Folloze',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Groove',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Growth intel',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Infer',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Influ2',
    category: 'Social & Relationships',
  },
  {
    toolName: 'InsideView',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Integrate',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Intentsify',
    category: 'Social & Relationships',
  },
  {
    toolName: 'International Data Group',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Jabmo',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Kapta',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Kwanzoo',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Lane Four',
    category: 'Social & Relationships',
  },
  {
    toolName: 'LeanData',
    category: 'Social & Relationships',
  },
  {
    toolName: 'LinkedIn',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Madison Logic',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Adobe Marketo Engage',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Metadata',
    category: 'Social & Relationships',
  },
  {
    toolName: 'MRP',
    category: 'Social & Relationships',
  },
  {
    toolName: 'N.Rich',
    category: 'Social & Relationships',
  },
  {
    toolName: 'PFL',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Radiate B2B',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Recotap',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Revenue Accelerators',
    category: 'Social & Relationships',
  },
  {
    toolName: 'RollWorks',
    category: 'Social & Relationships',
  },

  {
    toolName: 'Salesforce Email Studio',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Salesforce Journey Builder',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Salesforce Interaction Studio',
    category: 'Social & Relationships',
  },
   {
    toolName: 'Salesforce IoT Cloud',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Salesforce CDP',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Salesforce Chatter',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Salesforce Pardot',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Salesforce Datorama',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Salesforce Advertising Studio',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Salesforce Mobile Studio',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Salesforce Content Management',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Salesforce Google Marketing Platform',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Salesforce Loyalty Management',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Salesforce Datorama Reports',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Salesforce Service Cloud ',
    category: 'Social & Relationships',
  },
   {
    toolName: 'Salesforce Sales Cloud',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Salesforce Self-Service',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Salesforce Social Studio',
    category: 'Social & Relationships',
  },
  {
    toolName: 'SalesIntel',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Terminus',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Traction Complete',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Triblio',
    category: 'Social & Relationships',
  },
  {
    toolName: 'TrueInfluence',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Vendemore',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Adcorn',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Advocately',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Aimia',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Aklamio',
    category: 'Social & Relationships',
  },
  {
    toolName: 'All Digital Rewards',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Ambassador',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Ambassify',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Amplifinity',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Annex Cloud',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Antavo',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Antics DMS',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Apex Loyalty',
    category: 'Social & Relationships',
  },
  {
    toolName: 'ApolloBravo',
    category: 'Social & Relationships',
  },
  {
    toolName: 'AppsolutelyŒåäóæ',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Auctio',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Augeo',
    category: 'Social & Relationships',
  },
  {
    toolName: 'AwardIt',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Salesforce CRM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Adobe Cloud',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Bambu',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Bbrandit',
    category: 'Social & Relationships',
  },
  {
    toolName: 'BCaster',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Belly',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Bigdoor',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Binkt',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Bluestar Loyalty',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Bond Brand Loyalty',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Bonusway',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Boomuhrang',
    category: 'Social & Relationships',
  },
  {
    toolName: 'BoostCom',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Brierley + Partners',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Buff',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Bunchball',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Buyapowo',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Buzi',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Buzzpoints',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Campaigned',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Cheetah Digital',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Chirpify',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Churnbuster',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Citygro',
    category: 'Social & Relationships',
  },
  {
    toolName: 'ClarusCommerce',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Clutch',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Como Premium',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Connexions Loyalty',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Crowdskout',
    category: 'Social & Relationships',
  },
  {
    toolName: 'CrowdTwist',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Currency Alliance',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Cylo',
    category: 'Social & Relationships',
  },
  {
    toolName: 'DataCandy',
    category: 'Social & Relationships',
  },
  {
    toolName: 'DSMN9',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Dynamic Signal',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Engage:Value',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Epsilon',
    category: 'Social & Relationships',
  },
  {
    toolName: 'EveryoneSocial',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Exchange Solutions',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Extole',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Facebook',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Fanize',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Fanmiles',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Fielo',
    category: 'Social & Relationships',
  },
  {
    toolName: 'FiveStars',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Flocktory',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Flok',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Forewards',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Friendbuy',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Gapple AMP',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Genius Referrals',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Girafi',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Givex',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Gleantap',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Global Loyalty',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Hello Referrals',
    category: 'Social & Relationships',
  },
  {
    toolName: 'ICF Next',
    category: 'Social & Relationships',
  },
  {
    toolName: 'IM Loyalty',
    category: 'Social & Relationships',
  },
  {
    toolName: 'incentivesmart',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Incentivit',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Influitive',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Invite Referrals',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Invitebox',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Instagram',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Twitter',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Pinterest',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Snapchat',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Youtube',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Kangaroo Rewards',
    category: 'Social & Relationships',
  },
  {
    toolName: 'KeyoCoin',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Koalia',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Kobe',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Koomalooma',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Level Up',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Lolli',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Loopy Loyalty',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Lootly',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Loyalty Gator',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Loyalty Prime',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Loyalty Works',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Loyaltybox',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Loyaltylion',
    category: 'Social & Relationships',
  },
  {
    toolName: 'LoyaltyMatch',
    category: 'Social & Relationships',
  },
  {
    toolName: 'LoyaltyPlant',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Loyaltytools',
    category: 'Social & Relationships',
  },
  {
    toolName: 'LoyalX',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Loyalzoo',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Loyax Loyalty Platform',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Loylap',
    category: 'Social & Relationships',
  },
  {
    toolName: 'LoyLogic',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Loystar',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Loyyal',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Marsello',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Mention Me',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Merkle',
    category: 'Social & Relationships',
  },
  {
    toolName: 'MioDatos',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Momentum Protocol',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Neon Flare',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Nextbee',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Oappso Loyalty',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Omnistar Tell',
    category: 'Social & Relationships',
  },
  {
    toolName: 'One Click Politics',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Online Rewards',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Open Loyalty',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Ovato',
    category: 'Social & Relationships',
  },
  {
    toolName: 'PassKit',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Paytronix',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Perkville',
    category: 'Social & Relationships',
  },
  {
    toolName: 'PlumReward',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Pobuca Loyalty',
    category: 'Social & Relationships',
  },
  {
    toolName: 'PostBeyond',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Punchh',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Qiibee',
    category: 'Social & Relationships',
  },
  {
    toolName: 'RAP Index',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Redeal',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Referanza',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Referral Candy',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Referral Rock',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Referral Saasquatch',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Referralhero',
    category: 'Social & Relationships',
  },
  {
    toolName: 'ReferralMagic',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Referrizer',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Refersion',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Registria',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Repeat Returns',
    category: 'Social & Relationships',
  },
  {
    toolName: 'RepeatRewards',
    category: 'Social & Relationships',
  },
  {
    toolName: 'ReveMarketing',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Rise.ai',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Roialty',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Rybbon',
    category: 'Social & Relationships',
  },
  {
    toolName: 'S Loyalty',
    category: 'Social & Relationships',
  },
  {
    toolName: 'SailPlay',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Salsa Engage',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Sandblock',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Scrummy Club',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Sendoso',
    category: 'Social & Relationships',
  },
  {
    toolName: 'SessionM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Slyce',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Smarp',
    category: 'Social & Relationships',
  },
  {
    toolName: 'SmartLoyalty',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Smile.io',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Snipp',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Social Chorus',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Social Spiral',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Social&Loyal',
    category: 'Social & Relationships',
  },
  {
    toolName: 'SocialHP',
    category: 'Social & Relationships',
  },
  {
    toolName: 'SocioSquares',
    category: 'Social & Relationships',
  },
  {
    toolName: 'socxo',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Splio',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Spread Family',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Spring',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Springup',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Square',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Stamp Me',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Storm',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Subaj Global Network',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Talkable',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Tango Card',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Thanx',
    category: 'Social & Relationships',
  },
  {
    toolName: 'The Rouge Project',
    category: 'Social & Relationships',
  },
  {
    toolName: 'ThirdShelf',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Trippki',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Untorch',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Upland Software',
    category: 'Social & Relationships',
  },
  {
    toolName: 'UpViral',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Vauchar',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Vemt',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Viral Loops',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Vouchery',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Walmoo',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Warply',
    category: 'Social & Relationships',
  },
  {
    toolName: 'WaveToGet',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Whisqr',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Win Win Marketing',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Wrapp',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Yotpo',
    category: 'Social & Relationships',
  },
  {
    toolName: 'YoYo Wallet',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Zapper',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Zinrelo',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Zuberance',
    category: 'Social & Relationships',
  },
  {
    toolName: 'AddSource',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Aircall',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Allomedia',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Aloware',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Ameyo',
    category: 'Social & Relationships',
  },
  {
    toolName: 'AnswerConnect',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Avanser',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Avidtrak',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Avizent Ltd',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Blueface',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Bowtie',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Business Systems',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Call Box',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Call IQ',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Call Sumo',
    category: 'Social & Relationships',
  },
  {
    toolName: 'CallAction',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Callcap',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Calldrip',
    category: 'Social & Relationships',
  },
  {
    toolName: 'CallDynamics',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Calley',
    category: 'Social & Relationships',
  },
  {
    toolName: 'CallFinder',
    category: 'Social & Relationships',
  },
  {
    toolName: 'CallFire',
    category: 'Social & Relationships',
  },
  {
    toolName: 'CALLN',
    category: 'Social & Relationships',
  },
  {
    toolName: 'CallOne',
    category: 'Social & Relationships',
  },
  {
    toolName: 'CallPro CRM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Callr',
    category: 'Social & Relationships',
  },
  {
    toolName: 'CallRail',
    category: 'Social & Relationships',
  },
  {
    toolName: 'CallSource',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Callstats.io',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Calltouch',
    category: 'Social & Relationships',
  },
  {
    toolName: 'CallTracker',
    category: 'Social & Relationships',
  },
  {
    toolName: 'CallTrackingMetrics',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Calltracks Ltd',
    category: 'Social & Relationships',
  },
  {
    toolName: 'CallTrax Plus',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Ciara',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Circleloop',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Clearview',
    category: 'Social & Relationships',
  },
  {
    toolName: 'ClickPoint Software',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Clixtell',
    category: 'Social & Relationships',
  },
  {
    toolName: 'CloudCall',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Conduze',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Contact LEADer',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Convirza',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Convoso',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Coztel',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Delacon',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Dexem',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Dial 800',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Dialer360',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Dialogtech',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Dialpad',
    category: 'Social & Relationships',
  },
  {
    toolName: 'ExecVision',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Fireflies',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Five9',
    category: 'Social & Relationships',
  },
  {
    toolName: 'FluentStream',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Foehn',
    category: 'Social & Relationships',
  },
  {
    toolName: 'FoneDynamics',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Freshworks',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Fuze',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Gong',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Gryphon',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Grypp Corp',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Hoiio',
    category: 'Social & Relationships',
  },
  {
    toolName: 'inConcert',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Infinity',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Interact io',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Invoca',
    category: 'Social & Relationships',
  },
  {
    toolName: 'IOVOX',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Jet Interactive',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Jiminny',
    category: 'Social & Relationships',
  },
  {
    toolName: 'JustCall',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Kaleyra',
    category: 'Social & Relationships',
  },
  {
    toolName: 'KeyMetric',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Kixie',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Koll',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Leadmesh',
    category: 'Social & Relationships',
  },
  {
    toolName: 'LeadsPedia',
    category: 'Social & Relationships',
  },
  {
    toolName: 'LiveOps',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Magnetis',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Marchex',
    category: 'Social & Relationships',
  },
  {
    toolName: 'MaTelSo',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Mattersight',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Mediahawk',
    category: 'Social & Relationships',
  },
  {
    toolName: 'MessageBird',
    category: 'Social & Relationships',
  },
  {
    toolName: 'MightyCall',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Mitto',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Natterbox',
    category: 'Social & Relationships',
  },
  {
    toolName: 'NectarDesk',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Nextiva',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Nfon',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Nimbata',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Novocall',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Oak Innovation',
    category: 'Social & Relationships',
  },
  {
    toolName: 'On-Time',
    category: 'Social & Relationships',
  },
  {
    toolName: 'OnviCord',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Optico',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Outleads',
    category: 'Social & Relationships',
  },
  {
    toolName: 'PhoneTrack',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Phonewagon',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Phonexa',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Response Tap',
    category: 'Social & Relationships',
  },
  {
    toolName: 'ResponseiQ',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Retreaver',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Ringba',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Ringio',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Ringostat',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Rostrvm',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Ruler Analytics',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Sharpen',
    category: 'Social & Relationships',
  },
  {
    toolName: 'SmartAction',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Speik',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Talkdesk',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Telmetrics',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Tenfold',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Toky',
    category: 'Social & Relationships',
  },
  {
    toolName: 'TrackDrive',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Twilio',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Virtualq',
    category: 'Social & Relationships',
  },
  {
    toolName: 'VoiceBase',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Voiptime Cloud',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Vonage',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Waybeo',
    category: 'Social & Relationships',
  },
  {
    toolName: 'WhatConverts',
    category: 'Social & Relationships',
  },
  {
    toolName: 'whoscalling',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Wingman',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Xant',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Zifftalk',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Adobe',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Appbot',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Appfollow',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Asgaros Forum',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Audiense',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Awar.io',
    category: 'Social & Relationships',
  },
  {
    toolName: 'BazaarVoice',
    category: 'Social & Relationships',
  },
  {
    toolName: 'bbPress',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Besedo',
    category: 'Social & Relationships',
  },
  {
    toolName: 'BirdEye',
    category: 'Social & Relationships',
  },
  {
    toolName: 'BoonEx',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Bqool',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Broadly',
    category: 'Social & Relationships',
  },
  {
    toolName: 'BuddyPress',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Buffer',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Cackle',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Chlu',
    category: 'Social & Relationships',
  },
  {
    toolName: 'ClearlyHere',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Clearpoll',
    category: 'Social & Relationships',
  },
  {
    toolName: 'CMNTY',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Connectivity',
    category: 'Social & Relationships',
  },
  {
    toolName: 'ConsumerAffairs',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Copiny',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Crowded Communities',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Crowdstack',
    category: 'Social & Relationships',
  },
  {
    toolName: 'CustomerLobby',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Discourse',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Disqus',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Duplie',
    category: 'Social & Relationships',
  },
  {
    toolName: 'eKomi',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Feedaty',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Feedback Company',
    category: 'Social & Relationships',
  },
  {
    toolName: 'FeedbackExpress',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Feefo',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Field Solution Group',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Flarum',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Flipnpik',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Forumbee',
    category: 'Social & Relationships',
  },
  {
    toolName: 'FuelCycle',
    category: 'Social & Relationships',
  },
  {
    toolName: 'GetSatisfaction',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Grade.us',
    category: 'Social & Relationships',
  },
  {
    toolName: "Hashtag'd",
    category: 'Social & Relationships',
  },
  {
    toolName: 'HigherLogic',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Hivebrite',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Honeycommb',
    category: 'Social & Relationships',
  },
  {
    toolName: 'HowTank',
    category: 'Social & Relationships',
  },
  {
    toolName: 'inGage Networks',
    category: 'Social & Relationships',
  },
  {
    toolName: 'InLoop',
    category: 'Social & Relationships',
  },
  {
    toolName: 'inSided',
    category: 'Social & Relationships',
  },
  {
    toolName: 'IntenseDebate',
    category: 'Social & Relationships',
  },
  {
    toolName: 'introNetworks',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Invision Community',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Jamroom',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Jive',
    category: 'Social & Relationships',
  },
  {
    toolName: 'JomSocial',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Judge.me',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Kiyoh',
    category: 'Social & Relationships',
  },
  {
    toolName: 'KudoBuzz',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Lipscore',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Makerpad',
    category: 'Social & Relationships',
  },
  {
    toolName: 'MightyNetworks',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Mobilize',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Muut',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Nabble',
    category: 'Social & Relationships',
  },
  {
    toolName: 'NationBuilder',
    category: 'Social & Relationships',
  },
  {
    toolName: 'NiceJob',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Ning',
    category: 'Social & Relationships',
  },
  {
    toolName: 'NodeBB',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Nucode',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Okendo',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Olery',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Oracle',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Orankl',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Oxwall',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Personify',
    category: 'Social & Relationships',
  },
  {
    toolName: 'PlushForums',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Podium',
    category: 'Social & Relationships',
  },
  {
    toolName: 'PowerReviews',
    category: 'Social & Relationships',
  },
  {
    toolName: 'PublicInput.com',
    category: 'Social & Relationships',
  },
  {
    toolName: 'QashBack',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Reevoo',
    category: 'Social & Relationships',
  },
  {
    toolName: 'RenegadeWorks',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Reputation.com',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Reputology',
    category: 'Social & Relationships',
  },
  {
    toolName: 'RevenueJump',
    category: 'Social & Relationships',
  },
  {
    toolName: 'ReviewBuzz',
    category: 'Social & Relationships',
  },
  {
    toolName: 'ReviewCaddy',
    category: 'Social & Relationships',
  },
  {
    toolName: 'ReviewInc',
    category: 'Social & Relationships',
  },
  {
    toolName: 'ReviewPoint',
    category: 'Social & Relationships',
  },
  {
    toolName: 'ReviewPush',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Reviews',
    category: 'Social & Relationships',
  },
  {
    toolName: 'ReviewTrackers',
    category: 'Social & Relationships',
  },
  {
    toolName: 'ReviewTrail',
    category: 'Social & Relationships',
  },
  {
    toolName: 'ReviewWave',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Reziew',
    category: 'Social & Relationships',
  },
  {
    toolName: 'SafeHarbor',
    category: 'Social & Relationships',
  },
  {
    toolName: 'SearchBlox',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Shindig',
    category: 'Social & Relationships',
  },
  {
    toolName: 'ShopperApproved',
    category: 'Social & Relationships',
  },
  {
    toolName: 'ShoutAboutUs',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Side Project Stack',
    category: 'Social & Relationships',
  },
  {
    toolName: 'SocialEngine',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Soconnect',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Spectrum',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Spot.IM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Starfish Reviews',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Telligent',
    category: 'Social & Relationships',
  },
  {
    toolName: 'TestFreaks',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Testseek',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Toucantech',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Tribe Community Platform',
    category: 'Social & Relationships',
  },
  {
    toolName: 'TrustPilot',
    category: 'Social & Relationships',
  },
  {
    toolName: 'TrustSpot',
    category: 'Social & Relationships',
  },
  {
    toolName: 'TurnTo Networks',
    category: 'Social & Relationships',
  },
  {
    toolName: 'UBBCentral',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Unidays',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Vanilla Forums',
    category: 'Social & Relationships',
  },
  {
    toolName: 'vBulletin',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Verified Reviews',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Viafoura',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Vicomi',
    category: 'Social & Relationships',
  },
  {
    toolName: 'VisionCritical',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Welcome.AI',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Witview',
    category: 'Social & Relationships',
  },
  {
    toolName: 'WorkOutLoud',
    category: 'Social & Relationships',
  },
  {
    toolName: 'WotLab',
    category: 'Social & Relationships',
  },
  {
    toolName: 'XenForo',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Zapnito',
    category: 'Social & Relationships',
  },
  {
    toolName: 'ZetaBoards',
    category: 'Social & Relationships',
  },
  {
    toolName: '360Dialog',
    category: 'Social & Relationships',
  },
  {
    toolName: 'acobot',
    category: 'Social & Relationships',
  },
  {
    toolName: 'ActiveChat',
    category: 'Social & Relationships',
  },
  {
    toolName: 'ADNSMS',
    category: 'Social & Relationships',
  },
  {
    toolName: 'AgentIQ',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Alice Labs',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Amplify.ai',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Answerable',
    category: 'Social & Relationships',
  },
  {
    toolName: 'ApexChat',
    category: 'Social & Relationships',
  },
  {
    toolName: 'ASAPP',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Automat.ai',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Blue Green Analytics',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Boost Ai',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Brazen',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Bunting',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Cbox',
    category: 'Social & Relationships',
  },
  {
    toolName: 'chat.center',
    category: 'Social & Relationships',
  },
  {
    toolName: 'ChatBeacon',
    category: 'Social & Relationships',
  },
  {
    toolName: 'ChatBot',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Chatbotpack',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Chatfuel',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Chatlayer',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Chatlio',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Chatly',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Chatra',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Clarabridge',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Click4Assistance',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Clickdesk',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Cliengo',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Client Chat Live',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Closer',
    category: 'Social & Relationships',
  },
  {
    toolName: 'CM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'COGNIGY.AI',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Cognitive Virtual Assistant',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Collect.chat',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Cometchat',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Comm100 Live Chat',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Conversable',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Corezoid',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Creative Virtual',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Crisp Chat',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Cugic',
    category: 'Social & Relationships',
  },
  {
    toolName: 'CustomerGauge',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Dashly',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Delighted',
    category: 'Social & Relationships',
  },
  {
    toolName: 'DeskMoz',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Directly',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Discussnow',
    category: 'Social & Relationships',
  },
  {
    toolName: "Don't Go",
    category: 'Social & Relationships',
  },
  {
    toolName: 'Dossier',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Drift',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Drips',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Eckoh',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Eltropy',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Enghouseinteractive',
    category: 'Social & Relationships',
  },
  {
    toolName: 'erxes Inc',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Escalate AI',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Exceed.ai',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Eyelevel',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Floatbot',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Flow XO',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Flow.ai',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Formilla.com',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Freshchat',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Gamalon',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Giosg',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Gist',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Go4Clients',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Gobot',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Gosquared',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Greenbureau',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Gubagoo',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Gupshup',
    category: 'Social & Relationships',
  },
  {
    toolName: 'HappyFox',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Hej',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Helloumi',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Heyday',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Heymarket',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Howazit',
    category: 'Social & Relationships',
  },

  {
    toolName: 'HubSpot Marketing Hub',
    category: 'Social & Relationships',
  },
  {
    toolName: 'HubSpot Sales Hub',
    category: 'Social & Relationships',
  },
  {
    toolName: 'HubSpot CMS Hub',
    category: 'Social & Relationships',
  },
  {
    toolName: 'HubSpot Operations Hub',
    category: 'Social & Relationships',
  },
  {
    toolName: 'HubSpot Service Hub',
    category: 'Social & Relationships',
  },
  {
    toolName: 'iAdvize',
    category: 'Social & Relationships',
  },
  {
    toolName: 'IMImobile',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Inbenta Chatbots',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Inmobile',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Insent Inc',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Interactions',
    category: 'Social & Relationships',
  },
  {
    toolName: 'InTheChat',
    category: 'Social & Relationships',
  },
  {
    toolName: 'iReachm',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Jenny',
    category: 'Social & Relationships',
  },
  {
    toolName: 'JivoChat',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Jrny',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Kasisto',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Kindly',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Knowwhere',
    category: 'Social & Relationships',
  },
  {
    toolName: 'LadderChat',
    category: 'Social & Relationships',
  },
  {
    toolName: 'LeadChat',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Leadoo',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Leadsruptive',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Lekane',
    category: 'Social & Relationships',
  },
  {
    toolName: 'LemonTalk',
    category: 'Social & Relationships',
  },
  {
    toolName: 'LiveAgent',
    category: 'Social & Relationships',
  },
  {
    toolName: 'LiveChat',
    category: 'Social & Relationships',
  },
  {
    toolName: 'LiveHelpNow',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Liveperson',
    category: 'Social & Relationships',
  },
  {
    toolName: 'LoyJoy GmbH',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Maisie',
    category: 'Social & Relationships',
  },
  {
    toolName: 'ManyChat',
    category: 'Social & Relationships',
  },
  {
    toolName: 'MarketLinc',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Massively.ai',
    category: 'Social & Relationships',
  },
  {
    toolName: 'MessageMedia',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Messengerpeople',
    category: 'Social & Relationships',
  },
  {
    toolName: 'MindMeld',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Mioot',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Mobile Monkey',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Morph.ai',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Moxie',
    category: 'Social & Relationships',
  },
  {
    toolName: 'MyLiveChat',
    category: 'Social & Relationships',
  },
  {
    toolName: 'NeuraFlash',
    category: 'Social & Relationships',
  },
  {
    toolName: 'niki.ai',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Ninchat',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Nuance',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Nudgespot',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Obi 4 Wan',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Olark',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Omnibot',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Ongair',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Opinion Lab',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Ottspott',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Parkersoftware',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Promoter.io',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Provide Support Live Chat',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Pubble',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Pure Chat',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Qualified.com',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Quiq',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Ramen',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Redhelper',
    category: 'Social & Relationships',
  },
  {
    toolName: 'ReplyBuy',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Retently',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Reve Chat',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Rocket.Chat',
    category: 'Social & Relationships',
  },
  {
    toolName: 'ROKO Labs',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Sakari',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Satisfi Labs',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Saysimple',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Sently',
    category: 'Social & Relationships',
  },
  {
    toolName: 'ServiceDock',
    category: 'Social & Relationships',
  },
  {
    toolName: 'SiteGlue',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Smart Convos',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Smartifik',
    category: 'Social & Relationships',
  },
  {
    toolName: 'SmartNumber',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Smartsupp',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Smilee.io',
    category: 'Social & Relationships',
  },
  {
    toolName: 'SMS-Magic',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Snap Engage',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Solvemate',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Subiz',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Superest',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Swivl',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Talkus',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Tawk.to',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Telegram',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Textmagic',
    category: 'Social & Relationships',
  },
  {
    toolName: 'The Bot Platform',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Tidio Chat',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Tokbox',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Trengo',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Tripetto',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Twyla',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Ubisend',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Ultimate.Ai',
    category: 'Social & Relationships',
  },
  {
    toolName: 'unblu',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Upcue',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Userlike',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Velaro',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Velocidata Raptor',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Verloop',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Voicify',
    category: 'Social & Relationships',
  },
  {
    toolName: 'VOIQ',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Voysis',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Watermelon',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Webim',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Whisbi',
    category: 'Social & Relationships',
  },
  {
    toolName: 'WhosOn',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Wizu',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Xeno',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Yeti Text',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Zipwhip',
    category: 'Social & Relationships',
  },
  {
    toolName: '1CRM',
    category: 'Social & Relationships',
  },
  {
    toolName: '1CRM-System',
    category: 'Social & Relationships',
  },
  {
    toolName: '1sales',
    category: 'Social & Relationships',
  },
  {
    toolName: 'AbacusNext',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Accelo',
    category: 'Social & Relationships',
  },
  {
    toolName: 'ACT!',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Actricity',
    category: 'Social & Relationships',
  },
  {
    toolName: 'AcuityCRM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Adapt',
    category: 'Social & Relationships',
  },
  {
    toolName: 'AddressTwo',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Adito',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Adsoup',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Agendize',
    category: 'Social & Relationships',
  },
  {
    toolName: 'AgileCRM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Akti',
    category: 'Social & Relationships',
  },
  {
    toolName: 'AllClients',
    category: 'Social & Relationships',
  },
  {
    toolName: 'amoCRM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'AMTANGEE',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Anthill CRM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Apis CRM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Apptivo',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Aptean',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Apto',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Ascent360',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Atollon',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Aurea',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Avidian',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Azorus',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Backbone',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Backstop Solutions Group',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Baseline',
    category: 'Social & Relationships',
  },
  {
    toolName: 'BigContacts',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Black Ice CRM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Blackbaud',
    category: 'Social & Relationships',
  },
  {
    toolName: 'BlueCamroo',
    category: 'Social & Relationships',
  },
  {
    toolName: 'BoomTown',
    category: 'Social & Relationships',
  },
  {
    toolName: 'BPA Solutions',
    category: 'Social & Relationships',
  },
  {
    toolName: 'BSI',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Buddy CRM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Bullhorn',
    category: 'Social & Relationships',
  },
  {
    toolName: 'BuzzFlow',
    category: 'Social & Relationships',
  },
  {
    toolName: 'CampaignerCRM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Capillary',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Capsule CRM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'CAS CRM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'CentralStationCRM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Centrium CRM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'ChildCareCRM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Chime',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Cirrus Shield',
    category: 'Social & Relationships',
  },
  {
    toolName: 'CiviCRM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Claritysoft',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Clear C2',
    category: 'Social & Relationships',
  },
  {
    toolName: 'ClearView CRM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'CleverTim',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Cobra CRM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'combit',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Comidor',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Commence',
    category: 'Social & Relationships',
  },
  {
    toolName: 'CompanyHub CRM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Composity',
    category: 'Social & Relationships',
  },
  {
    toolName: 'ContactEase (by Cole Valley)',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Contactually',
    category: 'Social & Relationships',
  },
  {
    toolName: 'ConvergeHub',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Copper',
    category: 'Social & Relationships',
  },
  {
    toolName: 'CorkCRM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Cosential',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Creatio',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Creedenz',
    category: 'Social & Relationships',
  },
  {
    toolName: 'CRM-Service',
    category: 'Social & Relationships',
  },
  {
    toolName: 'CRM.me',
    category: 'Social & Relationships',
  },
  {
    toolName: 'CRMNext',
    category: 'Social & Relationships',
  },
  {
    toolName: 'CRMPRO',
    category: 'Social & Relationships',
  },
  {
    toolName: 'CURSOR-CRM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'CustomerTimes',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Dataline.Eu',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Datalyse',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Daylite for Mac',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Daypaio',
    category: 'Social & Relationships',
  },
  {
    toolName: 'DigitalCRM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Dimelo',
    category: 'Social & Relationships',
  },
  {
    toolName: 'DinamikCRM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Donodoo',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Dquip',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Easiware',
    category: 'Social & Relationships',
  },
  {
    toolName: 'edrone',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Efficy',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Eligamo',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Emediaone',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Epona',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Erpisto CRM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'erxes',
    category: 'Social & Relationships',
  },
  {
    toolName: 'EspoCRM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'eWay-CRM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Exsalerate CRM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'eZnet CRM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Five CRM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'FLG 360',
    category: 'Social & Relationships',
  },
  {
    toolName: 'FLOWFACT',
    category: 'Social & Relationships',
  },
  {
    toolName: 'FreeAgent CRM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'FreeCRM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Fundstack',
    category: 'Social & Relationships',
  },
  {
    toolName: 'GEDSYS Intraware',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Gold-Vision CRM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Goldmine',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Gopher Leads',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Graphinium',
    category: 'Social & Relationships',
  },
  {
    toolName: 'GreenRope',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Gro CRM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Hatchbuck',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Haystack',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Highrise',
    category: 'Social & Relationships',
  },
  {
    toolName: 'IBM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'illuminate.ae',
    category: 'Social & Relationships',
  },
  {
    toolName: 'InfoFlo',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Infor',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Insightly',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Insignio CRM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'InStream',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Intapp',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Intouch',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Intrix CRM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Introhive',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Ivinex',
    category: 'Social & Relationships',
  },
  {
    toolName: 'JobNimbus',
    category: 'Social & Relationships',
  },
  {
    toolName: 'junariCRM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Jungo',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Kapture',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Karma CRM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Keap',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Kreato',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Launchpad CRM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'LeadsLive',
    category: 'Social & Relationships',
  },
  {
    toolName: 'LegrandCRM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Lemonsoft',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Less Annoying CRM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Lime CRM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Lime Light CRM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'LionDesk',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Livespace',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Logicbox',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Lunni',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Maarketer',
    category: 'Social & Relationships',
  },
  {
    toolName: 'MarketSharp',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Maximizer',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Membook',
    category: 'Social & Relationships',
  },
  {
    toolName: 'MethodCRM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Microsoft',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Mindbody',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Moskit',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Mothernode',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Neon',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Netric',
    category: 'Social & Relationships',
  },
  {
    toolName: 'NetSuite',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Nexj',
    category: 'Social & Relationships',
  },
  {
    toolName: 'NextGuestCRM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Nimble',
    category: 'Social & Relationships',
  },
  {
    toolName: 'no CRM.io',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Nutshell',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Odoo',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Ofisim',
    category: 'Social & Relationships',
  },
  {
    toolName: 'OnePageCRM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'OnSite CRM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'ontraport',
    category: 'Social & Relationships',
  },
  {
    toolName: 'OpenCRM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'OroCRM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Outseta',
    category: 'Social & Relationships',
  },
  {
    toolName: 'OutSystems',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Pegasystems',
    category: 'Social & Relationships',
  },
  {
    toolName: 'PeopleVine',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Perfectview',
    category: 'Social & Relationships',
  },
  {
    toolName: 'PipelineDeals',
    category: 'Social & Relationships',
  },
  {
    toolName: 'PipelineManager',
    category: 'Social & Relationships',
  },
  {
    toolName: 'PiSA sales',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Plutio',
    category: 'Social & Relationships',
  },
  {
    toolName: 'ProfiCRM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Propeller CRM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Quick Base',
    category: 'Social & Relationships',
  },
  {
    toolName: 'QuickDesk',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Quisa',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Reallysimplesystems',
    category: 'Social & Relationships',
  },
  {
    toolName: 'RealOffice360',
    category: 'Social & Relationships',
  },
  {
    toolName: 'RealTime CRM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Redhorse Systems',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Redtail Technology',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Relenta',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Repbox',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Resco Mobile CRM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'retailCRM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Rethink',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Revamp CRM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Ricochet360',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Robo CRM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Rsoft CRM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Sage CRM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'SalesBabu',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Salesboom',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Salesflare',
    category: 'Social & Relationships',
  },
  {
    toolName: 'SalesFundaa CRM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Salesmanna',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Salesmate',
    category: 'Social & Relationships',
  },
  {
    toolName: 'SalesNexus',
    category: 'Social & Relationships',
  },
  {
    toolName: 'SalesOutlook CRM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'SalezShark',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Salpo CRM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'SanityOS',
    category: 'Social & Relationships',
  },
  {
    toolName: 'SAP',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Scibu',
    category: 'Social & Relationships',
  },
  {
    toolName: 'SecondCRM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Sellsy',
    category: 'Social & Relationships',
  },
  {
    toolName: 'SET',
    category: 'Social & Relationships',
  },
  {
    toolName: 'SimpleChurch CRM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Simplicate',
    category: 'Social & Relationships',
  },
  {
    toolName: 'smallbizcrm',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Smarketing Cloud',
    category: 'Social & Relationships',
  },
  {
    toolName: 'snapADDY',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Snapforce',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Soffront',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Softfolio',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Solve',
    category: 'Social & Relationships',
  },
  {
    toolName: 'SoulCRM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Sparkstone Technology',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Spiro',
    category: 'Social & Relationships',
  },
  {
    toolName: 'SprinxCRM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Streak',
    category: 'Social & Relationships',
  },
  {
    toolName: 'SugarCRM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Sugester',
    category: 'Social & Relationships',
  },
  {
    toolName: 'SuiteCRM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'SuperOffice',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Sweepbright',
    category: 'Social & Relationships',
  },
  {
    toolName: 'SwiftCRM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Swoffice',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Symvolli',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Tactica',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Taimer',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Talisma',
    category: 'Social & Relationships',
  },
  {
    toolName: 'TargetX',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Teamgate',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Teamleader',
    category: 'Social & Relationships',
  },
  {
    toolName: 'TecArt',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Telenotes CRM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'The Fish Tank',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Tier1',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Tye',
    category: 'Social & Relationships',
  },
  {
    toolName: 'UGRU',
    category: 'Social & Relationships',
  },
  {
    toolName: 'UPilot',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Upsales',
    category: 'Social & Relationships',
  },
  {
    toolName: 'UR Important CRM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'vCita',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Veeva',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Vertec',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Veryconnect',
    category: 'Social & Relationships',
  },
  {
    toolName: 'VIENNA Advantage',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Vine Oy',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Vinsales',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Virtuous',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Visionera',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Voyado',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Vtenext',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Vtiger',
    category: 'Social & Relationships',
  },
  {
    toolName: 'WakeUpSales',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Wealthbox',
    category: 'Social & Relationships',
  },
  {
    toolName: 'webCRM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'weclapp',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Wem',
    category: 'Social & Relationships',
  },
  {
    toolName: 'White Fluffy Cloud',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Wice',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Wild Apricot',
    category: 'Social & Relationships',
  },
  {
    toolName: 'WindsCRM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Workbooks',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Worketc',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Workwise',
    category: 'Social & Relationships',
  },
  {
    toolName: 'X2CRM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Yetiforce',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Ysura',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Zendesk',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Zenrez',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Zeta Global',
    category: 'Social & Relationships',
  },
  {
    toolName: 'ZimpluCRM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Zoho',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Zoomaworks',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Zootle',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Zurple',
    category: 'Social & Relationships',
  },
  {
    toolName: 'ZynBit',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Zyprr',
    category: 'Social & Relationships',
  },
  {
    toolName: '[24]7',
    category: 'Social & Relationships',
  },
  {
    toolName: 'ACF Technologies',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Akio',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Akita',
    category: 'Social & Relationships',
  },
  {
    toolName: 'AlphaBlues',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Amity',
    category: 'Social & Relationships',
  },
  {
    toolName: 'AnswerDash',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Appcues',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Apptentive',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Appzi',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Aquire',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Arbit, Inc.',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Array',
    category: 'Social & Relationships',
  },
  {
    toolName: 'AskNicely',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Astute Knowledge',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Azuredesk',
    category: 'Social & Relationships',
  },
  {
    toolName: 'BetterDocs',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Bold360',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Bolstra',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Boostopia',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Bright Pattern',
    category: 'Social & Relationships',
  },
  {
    toolName: 'C-Desk',
    category: 'Social & Relationships',
  },
  {
    toolName: 'C2 ATOM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Calabrio',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Callmaker',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Casengo',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Cayzu',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Chameleon',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Chataroo',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Chattermill',
    category: 'Social & Relationships',
  },
  {
    toolName: 'CHI Express',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Churnzero',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Cisco',
    category: 'Social & Relationships',
  },
  {
    toolName: 'ClickInsightsIO',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Clicktools',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Client Heartbeat',
    category: 'Social & Relationships',
  },
  {
    toolName: 'ClientSuccess',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Concentrix',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Confirm.It',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Conntac',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Consulink',
    category: 'Social & Relationships',
  },
  {
    toolName: 'CredSpark',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Critizr',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Cross_CRM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Customer Alliance',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Customer Labs Inc',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Customer Sure',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Customer Thermometer',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Customerville',
    category: 'Social & Relationships',
  },
  {
    toolName: 'CX Company',
    category: 'Social & Relationships',
  },
  {
    toolName: 'CXONCLOUD',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Deepdesk',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Deskero',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Digital Genius',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Dixa',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Dobility',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Docusign',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Dressipi',
    category: 'Social & Relationships',
  },
  {
    toolName: 'dunnhumby',
    category: 'Social & Relationships',
  },
  {
    toolName: 'ecomengine',
    category: 'Social & Relationships',
  },
  {
    toolName: 'eGain',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Elevio',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Emojics',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Emolytics',
    category: 'Social & Relationships',
  },
  {
    toolName: 'EnjoyHQ',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Envision',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Eptica Entreprise Suite',
    category: 'Social & Relationships',
  },
  {
    toolName: 'eTouchPoint',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Evaluat',
    category: 'Social & Relationships',
  },
  {
    toolName: 'ExperienceFellow',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Feedb',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Feedback INN',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Feedback Lite',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Feedbackify',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Feedbackly',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Feedbackstr',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Feedier',
    category: 'Social & Relationships',
  },
  {
    toolName: 'FocalScope',
    category: 'Social & Relationships',
  },
  {
    toolName: 'FocusVision',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Foresee',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Freshdesk',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Gainsight',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Gamelayer',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Gatherup',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Gavagai',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Genesys',
    category: 'Social & Relationships',
  },
  {
    toolName: 'GetFeedback',
    category: 'Social & Relationships',
  },
  {
    toolName: 'GetinQ',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Giva Inc',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Gladly',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Glia',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Gnatta',
    category: 'Social & Relationships',
  },
  {
    toolName: 'GoToAssist',
    category: 'Social & Relationships',
  },
  {
    toolName: 'GovQA',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Greenwich AIM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Growhold',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Guest Comment',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Guideblocks',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Happyfox',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Happyornot',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Heeduser',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Help.com',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Helpcrunch',
    category: 'Social & Relationships',
  },
  {
    toolName: 'HelpOnClick Live Chat',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Helppier',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Helprace',
    category: 'Social & Relationships',
  },
  {
    toolName: 'HelpScout',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Helpshift',
    category: 'Social & Relationships',
  },
  {
    toolName: 'HelpSite',
    category: 'Social & Relationships',
  },
  {
    toolName: 'HelpSumo',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Helpware',
    category: 'Social & Relationships',
  },
  {
    toolName: 'IDavatars',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Informedy',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Informizely',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Inisoft',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Inline Manual',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Inmoment',
    category: 'Social & Relationships',
  },
  {
    toolName: 'InnerTrends',
    category: 'Social & Relationships',
  },
  {
    toolName: 'inQuba CX',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Inquisium',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Insocial',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Intercom',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Intouch Insight',
    category: 'Social & Relationships',
  },
  {
    toolName: 'iPerceptions',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Issuetrak',
    category: 'Social & Relationships',
  },
  {
    toolName: 'iSupport',
    category: 'Social & Relationships',
  },
  {
    toolName: 'itouchvision',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Jitbit',
    category: 'Social & Relationships',
  },
  {
    toolName: 'JourneyXP',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Kademi',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Kapture CRM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Kayako',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Keatext',
    category: 'Social & Relationships',
  },
  {
    toolName: "know'N'act",
    category: 'Social & Relationships',
  },
  {
    toolName: 'Kofax',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Kundo',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Kustomer',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Kylie',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Layer Customer Conversation Platform',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Leaddesk',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Livecall',
    category: 'Social & Relationships',
  },
  {
    toolName: 'LiveZilla',
    category: 'Social & Relationships',
  },
  {
    toolName: 'LobbyCentral',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Logicalware',
    category: 'Social & Relationships',
  },
  {
    toolName: 'LogMeIn',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Loop',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Lumoa',
    category: 'Social & Relationships',
  },
  {
    toolName: 'M-ize',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Maritz CX',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Medallia',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Media Tech Solutions',
    category: 'Social & Relationships',
  },
  {
    toolName: 'MetrixLab',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Mindtouch',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Mopinion',
    category: 'Social & Relationships',
  },
  {
    toolName: 'MyFeelBack',
    category: 'Social & Relationships',
  },
  {
    toolName: 'MyGuide',
    category: 'Social & Relationships',
  },
  {
    toolName: 'MyNextAdvice',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Neosperience',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Newgen',
    category: 'Social & Relationships',
  },
  {
    toolName: 'next4biz',
    category: 'Social & Relationships',
  },
  {
    toolName: 'NICE Ltd',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Nicereply',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Nickelled',
    category: 'Social & Relationships',
  },
  {
    toolName: 'NotificationX',
    category: 'Social & Relationships',
  },
  {
    toolName: 'OdinAnswers',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Omnicus',
    category: 'Social & Relationships',
  },
  {
    toolName: 'OMQ service center',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Otrs',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Pendo',
    category: 'Social & Relationships',
  },
  {
    toolName: 'PeopleMetrics',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Pertinent',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Pisano',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Pitney Bowes',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Planhat',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Pointzi',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Polljoy',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Potentiate',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Preferred Patron Loyalty',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Primary Intelligence',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Promoter Ninja for NPS',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Proonto',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Pulse insights',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Pulse Solutions',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Puzzel',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Qeryz',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Qualaroo',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Qualtrics',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Quandago',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Quantum Metric',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Questback',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Re:Desk',
    category: 'Social & Relationships',
  },
  {
    toolName: 'ReachOut',
    category: 'Social & Relationships',
  },
  {
    toolName: 'ReplyOne',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Reputada',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Requuest',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Responster',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Revuze',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Sabio',
    category: 'Social & Relationships',
  },
  {
    toolName: 'salesEQUITY',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Salesmachine',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Sandsiv',
    category: 'Social & Relationships',
  },
  {
    toolName: 'SatisMeter',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Satmetrix',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Satsum',
    category: 'Social & Relationships',
  },
  {
    toolName: 'ServiceNow Customer Service Management',
    category: 'Social & Relationships',
  },
  {
    toolName: 'ServiceRocket',
    category: 'Social & Relationships',
  },
  {
    toolName: 'ServiceSource',
    category: 'Social & Relationships',
  },
  {
    toolName: 'ServiceTick Ltd??',
    category: 'Social & Relationships',
  },
  {
    toolName: 'ServIntuit',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Sightcall',
    category: 'Social & Relationships',
  },
  {
    toolName: 'SightMill',
    category: 'Social & Relationships',
  },
  {
    toolName: 'SignupLab',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Skilljar',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Slaask',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Slapfive',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Smaply',
    category: 'Social & Relationships',
  },
  {
    toolName: 'SmarterTools',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Smartlook',
    category: 'Social & Relationships',
  },
  {
    toolName: 'SmileBack',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Snap Surveys',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Socialcee',
    category: 'Social & Relationships',
  },
  {
    toolName: 'SocialSurvey',
    category: 'Social & Relationships',
  },
  {
    toolName: 'SoDash',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Solvvy',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Sparkcentral Customer Engagement Platform',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Spectoos',
    category: 'Social & Relationships',
  },
  {
    toolName: 'SpiceCSM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Sprinklr',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Starred',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Statbot',
    category: 'Social & Relationships',
  },
  {
    toolName: 'StellaConnect',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Strikedeck',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Sundown',
    category: 'Social & Relationships',
  },
  {
    toolName: 'SupportBee',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Supportbench',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Surveyme',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Surveypal',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Survicate',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Synap',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Tabsurvey',
    category: 'Social & Relationships',
  },
  {
    toolName: 'TalkToTheManager',
    category: 'Social & Relationships',
  },
  {
    toolName: 'TapMango',
    category: 'Social & Relationships',
  },
  {
    toolName: 'TeamSupport',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Temper',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Tender Support',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Thematic',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Thunderhead',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Ticksy',
    category: 'Social & Relationships',
  },
  {
    toolName: 'TopBox',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Totango',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Totango Spark',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Tracker',
    category: 'Social & Relationships',
  },
  {
    toolName: 'trueAct',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Trustfuel',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Twentify',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Tyler Technologies',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Unbird',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Upvoty',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Usabilla',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Usan',
    category: 'Social & Relationships',
  },
  {
    toolName: 'UsefulFeedback!',
    category: 'Social & Relationships',
  },
  {
    toolName: 'UserEcho',
    category: 'Social & Relationships',
  },
  {
    toolName: 'UseResponse',
    category: 'Social & Relationships',
  },
  {
    toolName: 'userinput.io',
    category: 'Social & Relationships',
  },
  {
    toolName: 'UserIQ',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Userlane',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Usermind',
    category: 'Social & Relationships',
  },
  {
    toolName: 'UserVoice',
    category: 'Social & Relationships',
  },
  {
    toolName: 'UXprobe',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Verascape',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Verint',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Viavoo',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Vibevision',
    category: 'Social & Relationships',
  },
  {
    toolName: 'VidGrid',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Vitally',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Vivocha',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Vocalcom',
    category: 'Social & Relationships',
  },
  {
    toolName: 'VWO',
    category: 'Social & Relationships',
  },
  {
    toolName: 'WalkMe',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Waypoint Group',
    category: 'Social & Relationships',
  },
  {
    toolName: 'WebEngage',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Whatfix',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Wizper',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Wizville',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Wondeeflow',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Wootric',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Worthix',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Woxxer',
    category: 'Social & Relationships',
  },
  {
    toolName: 'WP Engine',
    category: 'Social & Relationships',
  },
  {
    toolName: 'YesInsights',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Yonyx',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Zaius',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Zeffi',
    category: 'Social & Relationships',
  },
  {
    toolName: 'zenloop',
    category: 'Social & Relationships',
  },
  {
    toolName: 'ZephyrTel',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Zingtree',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Zinputs',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Zonka',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Zuora',
    category: 'Social & Relationships',
  },
  {
    toolName: '6Connex',
    category: 'Social & Relationships',
  },
  {
    toolName: '10to8',
    category: 'Social & Relationships',
  },
  {
    toolName: '123Signup',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Active Network',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Acuity Scheduling',
    category: 'Social & Relationships',
  },
  {
    toolName: 'AddEvent',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Appmiral',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Appointlet',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Arkadin',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Arlo',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Arrangr',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Attend',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Attendease',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Attendify',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Attendium',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Aventri',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Azavista',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Banzai',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Beatswitch',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Billetto',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Bizly',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Bizzabo',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Blocktix',
    category: 'Social & Relationships',
  },
  {
    toolName: 'BlueJeans Network',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Bookitbee',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Boomset',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Brandscopic',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Brella',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Bright Talk',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Brown Paper Tickets',
    category: 'Social & Relationships',
  },
  {
    toolName: 'BusyConf',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Calendly',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Certain',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Chatroll',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Cisco Systems',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Citrix Systems',
    category: 'Social & Relationships',
  },
  {
    toolName: 'ClickMeeting',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Cogsworth',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Conference Manager',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Conferences I/O',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Conferize',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Confetti',
    category: 'Social & Relationships',
  },
  // {
  //   toolName: 'ConstantContact',
  //   category: 'Social & Relationships',
  // },
  {
    toolName: 'ContractZen',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Conversationstarter',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Converve',
    category: 'Social & Relationships',
  },
  {
    toolName: 'ConvoSpark',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Crankwheel',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Cronofy',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Crowd Mics',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Crowdcast',
    category: 'Social & Relationships',
  },
  {
    toolName: 'CrowdComms',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Crystal Interactive',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Cvent',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Demodesk',
    category: 'Social & Relationships',
  },
  {
    toolName: 'DirectPoll',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Doo',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Doubledutch',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Dryfta',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Duuzra',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Easy Webinar',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Engagez',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Eshow',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Evenito',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Evenium',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Event Espresso',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Event Farm',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Event-Attendance Pro Web',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Eventable',
    category: 'Social & Relationships',
  },
  {
    toolName: 'EventAct',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Eventbase Technology',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Eventbee',
    category: 'Social & Relationships',
  },
  {
    toolName: 'EventBookings',
    category: 'Social & Relationships',
  },
  {
    toolName: 'EventBoost',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Eventbrite',
    category: 'Social & Relationships',
  },
  {
    toolName: 'EventChain',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Eventcube',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Eventdex',
    category: 'Social & Relationships',
  },
  {
    toolName: 'EventForte',
    category: 'Social & Relationships',
  },
  {
    toolName: 'EventGeek',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Eventials',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Eventilla',
    category: 'Social & Relationships',
  },
  {
    toolName: 'EventLeaf',
    category: 'Social & Relationships',
  },
  {
    toolName: 'EventMobi',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Eventogy',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Eventory',
    category: 'Social & Relationships',
  },
  {
    toolName: 'EventRebels',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Eventry',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Events.com',
    category: 'Social & Relationships',
  },
  {
    toolName: 'EventsAIR',
    category: 'Social & Relationships',
  },
  {
    toolName: 'EventsForce',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Eventsity',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Eventsquid',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Eventtia',
    category: 'Social & Relationships',
  },
  {
    toolName: 'eventuosity',
    category: 'Social & Relationships',
  },
  {
    toolName: 'EventXtra',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Eventzilla',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Ever Webinar',
    category: 'Social & Relationships',
  },
  {
    toolName: 'EverThere',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Evite',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Evvnt',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Explara',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Feathr',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Fonteva Events',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Gevme',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Glisser Ltd',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Goombal',
    category: 'Social & Relationships',
  },
  {
    toolName: 'GoTo',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Greenvelope',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Grip',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Guest Manager',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Guidebook',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Hakema',
    category: 'Social & Relationships',
  },
  {
    toolName: 'HelloSponsor',
    category: 'Social & Relationships',
  },
  {
    toolName: 'High Attendance',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Hobnob',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Hubb',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Hubilo',
    category: 'Social & Relationships',
  },
  {
    toolName: 'iCapture',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Idloom-Events',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Indico',
    category: 'Social & Relationships',
  },
  {
    toolName: 'InEvent',
    category: 'Social & Relationships',
  },
  {
    toolName: 'insightXM',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Instant Teleseminar',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Integrate',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Intermedia',
    category: 'Social & Relationships',
  },
  {
    toolName: 'InvitePeople',
    category: 'Social & Relationships',
  },
  {
    toolName: 'INXPO',
    category: 'Social & Relationships',
  },
  {
    toolName: 'IQPolls',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Jetwebinar',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Jifflenow',
    category: 'Social & Relationships',
  },
  {
    toolName: 'JoinMe',
    category: 'Social & Relationships',
  },
  {
    toolName: 'jrni',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Kimbia',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Kollective',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Lenos',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Lineupr',
    category: 'Social & Relationships',
  },
  {
    toolName: 'LiveCube',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Livestorm',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Livestream LLC',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Lumi',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Lyyti',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Maestro Conference',
    category: 'Social & Relationships',
  },
  {
    toolName: 'MediaPlatform',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Meetapp',
    category: 'Social & Relationships',
  },
  {
    toolName: 'MeetingHand',
    category: 'Social & Relationships',
  },
  {
    toolName: 'MeetingMojo',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Meetup',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Mentimeter',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Mesensei',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Metooo',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Mikogo',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Mitingu',
    category: 'Social & Relationships',
  },
  {
    toolName: 'momencio',
    category: 'Social & Relationships',
  },
  {
    toolName: 'My Event Guru',
    category: 'Social & Relationships',
  },
  {
    toolName: 'MyGuestlist',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Networkr LLC',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Nutickets',
    category: 'Social & Relationships',
  },
  {
    toolName: 'ON24',
    category: 'Social & Relationships',
  },
  {
    toolName: 'OnStream',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Opens',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Oxynade',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Pathable',
    category: 'Social & Relationships',
  },
  {
    toolName: 'PGi',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Plann3r',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Planning Pod',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Poll Everywhere',
    category: 'Social & Relationships',
  },
  {
    toolName: 'PopBookings',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Presentain',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Primoevents',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Qumu',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Radario Marketing Platform',
    category: 'Social & Relationships',
  },
  {
    toolName: 'ReadyTalk',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Rock Content',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Sched',
    category: 'Social & Relationships',
  },
  {
    toolName: 'SetMore',
    category: 'Social & Relationships',
  },
  {
    toolName: 'ShowGizmo',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Sli.do',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Socialtables',
    category: 'Social & Relationships',
  },
  {
    toolName: 'SpeakerRate',
    category: 'Social & Relationships',
  },
  {
    toolName: 'SpinGo',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Splash',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Spotme',
    category: 'Social & Relationships',
  },
  {
    toolName: 'SummitSync',
    category: 'Social & Relationships',
  },
  {
    toolName: 'SuperEvent',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Swapcard',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Swoogo',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Symphony',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Tagkast',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Talque',
    category: 'Social & Relationships',
  },
  {
    toolName: 'The Ticket Fairy',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Ticketbud',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Ticketleap',
    category: 'Social & Relationships',
  },
  {
    toolName: 'TicketTailor',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Tito',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Tix',
    category: 'Social & Relationships',
  },
  {
    toolName: 'TMI Expos',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Topi',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Trumba',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Universe',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Veedeeo',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Venuu',
    category: 'Social & Relationships',
  },
  {
    toolName: 'vFairs',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Visit by Ges',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Vizito',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Wavecast',
    category: 'Social & Relationships',
  },
  {
    toolName: 'WebinarIgnition',
    category: 'Social & Relationships',
  },
  {
    toolName: 'WebinarJam',
    category: 'Social & Relationships',
  },
  {
    toolName: 'WebinarNinja',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Whova',
    category: 'Social & Relationships',
  },
  {
    toolName: 'WorkCast',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Xert',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Yapp',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Zerista',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Zingiri',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Zkipster',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Zoom',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Zuant',
    category: 'Social & Relationships',
  },
  {
    toolName: '#Paid',
    category: 'Social & Relationships',
  },
  {
    toolName: '247',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Acorn: The Influence Company',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Adhive',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Adly',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Affable.ai',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Ahalogy',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Apprl',
    category: 'Social & Relationships',
  },
  {
    toolName: 'AspireIQ',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Attentive.ly',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Beatly',
    category: 'Social & Relationships',
  },
  {
    toolName: 'billo',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Bloglovin',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Bluenod',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Brandplug',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Buzzoole',
    category: 'Social & Relationships',
  },
  {
    toolName: 'BuzzStream',
    category: 'Social & Relationships',
  },
  {
    toolName: 'BuzzSumo',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Capssion',
    category: 'Social & Relationships',
  },
  {
    toolName: 'CARRO',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Cision',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Collective Bias',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Commetric INA',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Cooperatize',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Crowdly',
    category: 'Social & Relationships',
  },
  {
    toolName: 'DSMN8',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Endorsify',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Epictions',
    category: 'Social & Relationships',
  },
  {
    toolName: 'ExpertVoice',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Famebit',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Flicron',
    category: 'Social & Relationships',
  },
  {
    toolName: 'friendz',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Gatsby',
    category: 'Social & Relationships',
  },
  {
    toolName: 'GetEvangelized',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Ghostlamp',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Grapevine',
    category: 'Social & Relationships',
  },
  {
    toolName: 'GroupHigh',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Guest Crew',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Hashoff',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Humanz',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Hypr',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Ifluenz',
    category: 'Social & Relationships',
  },
  {
    toolName: 'indaHash',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Influencer',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Influencity',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Influenster',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Influential',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Influenxio',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Influo',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Intellifluence',
    category: 'Social & Relationships',
  },
  {
    toolName: 'IZEA',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Julius',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Juulr',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Klear',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Kred',
    category: 'Social & Relationships',
  },
  {
    toolName: 'LaunchMetrics',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Linkalyze',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Linqia',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Markerly',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Matchmade',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Mavrck',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Megan Media',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Moon',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Musefind',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Neoreach',
    category: 'Social & Relationships',
  },
  {
    toolName: 'NinjaMetrics',
    category: 'Social & Relationships',
  },
  {
    toolName: 'NinjaOutreach',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Ocast',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Onalytica',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Peadler, LLC',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Peg',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Pitchbox',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Popular Chips',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Publicfast',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Qube Media',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Reelio',
    category: 'Social & Relationships',
  },
  {
    toolName: 'RhythmOne',
    category: 'Social & Relationships',
  },
  {
    toolName: 'ROI Influencer Media',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Samyroad',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Shout',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Sideqik',
    category: 'Social & Relationships',
  },
  {
    toolName: 'SociaBuzz',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Sociafluence',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Social Animal',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Socialbook',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Spitche',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Sway Group',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Sysomos MAP',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Tagger',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Tailify',
    category: 'Social & Relationships',
  },
  {
    toolName: 'TapInfluence',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Tellagence',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Terakeet',
    category: 'Social & Relationships',
  },
  {
    toolName: 'TeraReach',
    category: 'Social & Relationships',
  },
  {
    toolName: 'The Shelf',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Tidal Labs',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Traackr',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Trail Technology LTD',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Upfluence',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Vizsense',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Voco NetworksŒåäóæ',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Wehype',
    category: 'Social & Relationships',
  },
  {
    toolName: 'XPO APP',
    category: 'Social & Relationships',
  },
  {
    toolName: 'ZINE',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Zoomph',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Zyper',
    category: 'Social & Relationships',
  },
  {
    toolName: '4C',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Abovo',
    category: 'Social & Relationships',
  },
  {
    toolName: 'AddThis',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Affinio',
    category: 'Social & Relationships',
  },
  {
    toolName: 'AgoraPulse',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Amplifr',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Ampsy',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Apostle',
    category: 'Social & Relationships',
  },
  {
    toolName: 'AppAction',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Archie',
    category: 'Social & Relationships',
  },
  {
    toolName: 'ArchiveSocial',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Aretheyhappy',
    category: 'Social & Relationships',
  },
  {
    toolName: 'askfm',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Audience AI',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Awario',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Beevolve',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Big Propeller',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Birdsong Analytics',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Blinkfire Analytics',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Blog2Social',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Blogmeter',
    category: 'Social & Relationships',
  },
  {
    toolName: 'BoomSonar',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Boosterberg',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Bottlenose',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Brand24',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Brandbastion',
    category: 'Social & Relationships',
  },
  {
    toolName: 'BrandChats',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Brandle',
    category: 'Social & Relationships',
  },
  {
    toolName: 'BrandMentions',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Brandwatch',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Buzzigo Social',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Buzzilla',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Buzzinga',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Buzzmonitor',
    category: 'Social & Relationships',
  },
  {
    toolName: 'BuzzSpice',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Candid',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Captiv8',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Clkim',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Cloud Campaign',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Cloud90',
    category: 'Social & Relationships',
  },
  {
    toolName: 'CoffeeBean',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Comarch',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Combin',
    category: 'Social & Relationships',
  },
  {
    toolName: 'commun.it',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Communicator Cloud',
    category: 'Social & Relationships',
  },
  {
    toolName: 'ContentCal',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Contento',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Conversocial',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Conviva',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Cool Tabs',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Coosto',
    category: 'Social & Relationships',
  },
  {
    toolName: 'CreatorIQ',
    category: 'Social & Relationships',
  },
  {
    toolName: 'CrewFire',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Crisp',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Crowdbabble',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Crowdfire',
    category: 'Social & Relationships',
  },
  {
    toolName: 'CrowdRiff',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Crystal Project',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Curalate',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Dataminr',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Demographics Pro',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Dialogfeed',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Digimind',
    category: 'Social & Relationships',
  },
  {
    toolName: 'dlvr.it',
    category: 'Social & Relationships',
  },
  {
    toolName: 'DM Pilot',
    category: 'Social & Relationships',
  },
  {
    toolName: 'eCairn',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Echosec',
    category: 'Social & Relationships',
  },
  {
    toolName: 'eClincher',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Edgar',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Emphatic',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Engage121',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Engagement Labs',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Everypost',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Expandi.io',
    category: 'Social & Relationships',
  },
  {
    toolName: 'FaceDominator',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Facelift',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Falcon Social',
    category: 'Social & Relationships',
  },
  {
    toolName: 'FanBooster',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Fanpage Karma',
    category: 'Social & Relationships',
  },
  {
    toolName: 'FANPOINT',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Fixwidgets.com',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Flye',
    category: 'Social & Relationships',
  },
  {
    toolName: 'FollowUs.com',
    category: 'Social & Relationships',
  },
  {
    toolName: 'FourSixty',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Frrole',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Futuri',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Gain',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Geofeedia',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Germin8 Social Intelligence',
    category: 'Social & Relationships',
  },
  {
    toolName: 'GetSocial',
    category: 'Social & Relationships',
  },
  {
    toolName: 'GetSocial.io',
    category: 'Social & Relationships',
  },
  {
    toolName: 'GladCloud',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Glance',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Good Audience',
    category: 'Social & Relationships',
  },
  {
    toolName: 'GraphyStories',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Gremlin Social',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Growth Champ',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Grum',
    category: 'Social & Relationships',
  },
  {
    toolName: 'GumGum',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Hashtagify',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Hashtagio',
    category: 'Social & Relationships',
  },
  {
    toolName: 'HeadTalker',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Hearsay Systems',
    category: 'Social & Relationships',
  },
  {
    toolName: 'HelloSociety',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Hookle',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Hootsuite',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Hopper HQ',
    category: 'Social & Relationships',
  },
  {
    toolName: 'HubNami',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Hypegrowth',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Iconosquare',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Inclick Track',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Infegy',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Insightsatlas',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Instagress',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Jooicer',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Juicer',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Keepcon',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Keyhole',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Khoros',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Kicksta',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Klarity',
    category: 'Social & Relationships',
  },
  {
    toolName: 'KUKU.io',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Ladderr',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Lately Inc',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Later',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Lead Connect',
    category: 'Social & Relationships',
  },
  {
    toolName: 'LeadsBridge',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Lefty',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Lexalytics',
    category: 'Social & Relationships',
  },
  {
    toolName: 'LIFTR',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Lightful',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Linkfluence',
    category: 'Social & Relationships',
  },
  {
    toolName: 'ListenFirst Media',
    category: 'Social & Relationships',
  },
  {
    toolName: 'LiveWorld',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Locowise',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Loomly',
    category: 'Social & Relationships',
  },
  {
    toolName: 'M-Brain',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Madgicx',
    category: 'Social & Relationships',
  },
  {
    toolName: 'ManageFlitter',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Manalto',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Marple',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Mastodon',
    category: 'Social & Relationships',
  },
  {
    toolName: 'MavSocial',
    category: 'Social & Relationships',
  },
  {
    toolName: 'MediaFunnel',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Mediatoolkit',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Meltwater',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Mention',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Mentionlytics',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Metigy',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Metricool',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Minds',
    category: 'Social & Relationships',
  },
  {
    toolName: 'MissingLettr',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Modern',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Moodwire',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Moz',
    category: 'Social & Relationships',
  },
  {
    toolName: 'myPresences',
    category: 'Social & Relationships',
  },
  {
    toolName: 'myRosys',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Mzinga',
    category: 'Social & Relationships',
  },
  {
    toolName: 'NapoleonCat',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Narrow',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Naytev',
    category: 'Social & Relationships',
  },
  {
    toolName: 'NetBase:',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Netvibes',
    category: 'Social & Relationships',
  },
  {
    toolName: 'NewsWhip',
    category: 'Social & Relationships',
  },
  {
    toolName: 'NewzSocial',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Nexalogy',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Ninchi',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Notify',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Nouncy',
    category: 'Social & Relationships',
  },
  {
    toolName: 'NUVI',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Octane AI',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Octoly',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Oktopost',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Ollco',
    category: 'Social & Relationships',
  },
  {
    toolName: 'OneAll',
    category: 'Social & Relationships',
  },
  {
    toolName: 'oneQube',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Orlo',
    category: 'Social & Relationships',
  },
  {
    toolName: 'PageFreezer',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Pagelanes',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Pattern89',
    category: 'Social & Relationships',
  },
  {
    toolName: 'PeakFeed',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Peepeth',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Planable',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Planogr.am',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Postcron',
    category: 'Social & Relationships',
  },
  {
    toolName: 'PostPickr',
    category: 'Social & Relationships',
  },
  {
    toolName: 'PostSpeaker',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Predy',
    category: 'Social & Relationships',
  },
  {
    toolName: 'PromoRepublic',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Publing',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Pulsar',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Quintly',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Quuu',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Rankur',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Rarog',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Rascasse',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Rebrandly',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Relationdesk',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Remesh',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Reportei',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Repucaution',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Repuso',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Revive Social',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Rignite',
    category: 'Social & Relationships',
  },
  {
    toolName: 'RiteTag',
    category: 'Social & Relationships',
  },
  {
    toolName: 'RocketLink',
    category: 'Social & Relationships',
  },
  {
    toolName: 'SCHelper',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Scoreboard Social',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Scrunch',
    category: 'Social & Relationships',
  },
  {
    toolName: 'SeekMetrics',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Send Social Media',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Sendible',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Sentiment',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Sentisis',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Shareablee',
    category: 'Social & Relationships',
  },
  {
    toolName: 'ShareKit.io',
    category: 'Social & Relationships',
  },
  {
    toolName: 'ShareThis',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Signal Media',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Simplify360',
    category: 'Social & Relationships',
  },
  {
    toolName: 'SkedSocial',
    category: 'Social & Relationships',
  },
  {
    toolName: 'smartBeemo',
    category: 'Social & Relationships',
  },
  {
    toolName: 'SmarterQueue',
    category: 'Social & Relationships',
  },
  {
    toolName: 'SMhack',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Smintly',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Snaplytics',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Snaptrends',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Sniply',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Soci',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Social HorsePower',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Social Seeder',
    category: 'Social & Relationships',
  },
  {
    toolName: 'SocialAnimal',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Socialbakers',
    category: 'Social & Relationships',
  },
  {
    toolName: 'SocialBee',
    category: 'Social & Relationships',
  },
  {
    toolName: 'SocialBrands',
    category: 'Social & Relationships',
  },
  {
    toolName: 'SocialChamp',
    category: 'Social & Relationships',
  },
  {
    toolName: 'SocialFlow',
    category: 'Social & Relationships',
  },
  {
    toolName: 'SocialHub',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Sociality.io',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Socialius',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Sociall',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Sociallybuzz',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Sociallymap',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Socialmetrix',
    category: 'Social & Relationships',
  },
  {
    toolName: 'SocialMotus',
    category: 'Social & Relationships',
  },
  {
    toolName: 'SocialNative',
    category: 'Social & Relationships',
  },
  {
    toolName: 'SocialOomph',
    category: 'Social & Relationships',
  },
  {
    toolName: 'SocialPano',
    category: 'Social & Relationships',
  },
  {
    toolName: 'SocialPilot',
    category: 'Social & Relationships',
  },
  {
    toolName: 'SocialRank',
    category: 'Social & Relationships',
  },
  {
    toolName: 'SocialStatus',
    category: 'Social & Relationships',
  },
  {
    toolName: 'SocialToaster',
    category: 'Social & Relationships',
  },
  {
    toolName: 'SocialVolt',
    category: 'Social & Relationships',
  },
  {
    toolName: 'SocialWally',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Sociamonials',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Socioboard',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Socioh',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Sociota',
    category: 'Social & Relationships',
  },
  {
    toolName: 'SoClever',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Soldsie',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Sotrender',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Soworker',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Sparkle',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Spokal',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Sprout Social',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Sqwarkr',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Stacker',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Stackla',
    category: 'Social & Relationships',
  },
  {
    toolName: 'StatSocial',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Steem',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Stencil',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Storify',
    category: 'Social & Relationships',
  },
  {
    toolName: 'StoryBox',
    category: 'Social & Relationships',
  },
  {
    toolName: 'storycafe',
    category: 'Social & Relationships',
  },
  {
    toolName: 'StoryStream',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Strea.ma',
    category: 'Social & Relationships',
  },
  {
    toolName: 'streamchartz',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Swat.io',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Synthesio',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Sysomos',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Tagboard',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Taggbox',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Tailwind',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Talkwalker',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Tawkers',
    category: 'Social & Relationships',
  },
  {
    toolName: 'ThinkUp',
    category: 'Social & Relationships',
  },
  {
    toolName: 'ThoughtBuzz',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Thryv',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Tint',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Toneden',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Tracx',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Tradable Bits',
    category: 'Social & Relationships',
  },
  {
    toolName: 'TrendSpottr',
    category: 'Social & Relationships',
  },
  {
    toolName: 'TS Platform',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Tweepsmap',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Tweet Eye',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Tweet Full',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Tweet Ninja',
    category: 'Social & Relationships',
  },
  {
    toolName: 'TweetBinder',
    category: 'Social & Relationships',
  },
  {
    toolName: 'TweetFavy',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Tweriod',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Twilert',
    category: 'Social & Relationships',
  },
  {
    toolName: 'TwineSocial',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Ubiq',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Unamo',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Underhood',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Unified',
    category: 'Social & Relationships',
  },
  {
    toolName: 'UnionMetrics',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Unmetric',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Vaizle',
    category: 'Social & Relationships',
  },
  {
    toolName: 'ViralMint',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Viralstat',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Viraltag',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Waaffle',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Walls.io',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Warbble',
    category: 'Social & Relationships',
  },
  {
    toolName: 'webZunder',
    category: 'Social & Relationships',
  },
  {
    toolName: 'WeLikeIt',
    category: 'Social & Relationships',
  },
  {
    toolName: 'WeLink',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Worphy',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Yala',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Zanroo',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Zenia.Ai',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Zopto',
    category: 'Social & Relationships',
  },
  {
    toolName: 'Zuum',
    category: 'Social & Relationships',
  },
]
