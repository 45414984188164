import React, { Component, useState, useEffect, useRef } from 'react'
import IndividualSegmentedContainer from '../data-visualizations/individual-segmented-container/segmented-container'
import RadialChart from '../data-visualizations/pie-chart/PieChart'
import HeatMap from '../data-visualizations/heatmap/heatmap'
import fire from '../firebase'
import { loadingController } from '@ionic/core'
import './dashboard.css'
import '../../App.css'
import { PlatformService } from '../../services/platform.service'
import ToolTable from '../data-visualizations/tool-table'
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonItem,
  IonRow,
  IonLabel,
  IonPopover,
} from '@ionic/react'
import { IonSpinner, IonContent, IonLoading } from '@ionic/react'
import { IndividualHttpService } from '../../http_services/individual.http_service'
import InfoIcon from '@material-ui/icons/Info'
import { SkillHttpService } from '../../http_services/skill.http_service'
import { useParams } from 'react-router-dom'
import Axios from 'axios'
import { GestureSharp } from '@material-ui/icons'
import Popper from '@material-ui/core/Popper'
import HelmetMetaData from '../core/HelmetData/HelmetData'
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from 'react-share'
import LinkedInCertificate from 'react-linkedin-certification'
import Cert from '../../assets/images/Purple Pink Gradient Course Completion Certificate (2).png'

export default function IndividualDashboardShared() {
  const [showLoading, setShowLoading] = useState(true)
  const { profileId } = useParams()
  const [userId, setUserId] = useState(profileId)
  const [dashboardData, setDashboardData] = useState(null)
  const [showRolePopover, setShowRolePopover] = useState(false)
  const [showRolePopover2, setShowRolePopover2] = useState(false)
  const [showRolePopover3, setShowRolePopover3] = useState(false)
  // const [shouldShowModal, setShouldShowModal] = useState(false);

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal
    const cancelToken = Axios.CancelToken.source()
    const fetchData = async () => {
      const axiosUrl = global.apiBaseUrl + `/individuals?id=${userId}`

      const responseAxios = await Axios.get(axiosUrl, {
        cancelToken: cancelToken.token,
      })
        .then((res) => {
          return res.data[0]
        })
        .catch((error) => {
          if (Axios.isCancel(error)) {
            console.log('Request canceled!')
          } else if (error.response) {
            // Request made and server responded
            console.log(error.response.data)
            console.log(error.response.status)
            console.log(error.response.headers)
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request)
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message)
          }
        })

      const skillUrl =
        global.apiBaseUrl + '/skills/new?action=individual_dashboard'

      const responseFetch = await fetch(skillUrl, {
        method: 'POST',
        redirect: 'follow', // manual, *follow, error
        headers: { 'Content-Type': 'application/json' },
        mode: 'cors',
        body: JSON.stringify({ individual_id: userId }),
        signal: signal,
      })
        .then((resp) => resp.json())
        .then((dashboardData) => {
          return dashboardData
        })
        .catch((error) => {
          if (typeof error.json === 'function') {
            error
              .json()
              .then((jsonError) => {
                console.log('Json error from API')
                console.log(jsonError)
              })
              .catch((genericError) => {
                console.log('Generic error from API')
                console.log(error.statusText)
              })
          } else {
            console.log('Fetch error')
            console.log(error)
          }
        })

      setDashboardData({ user: responseAxios, data: responseFetch })
    }
    setTimeout(() => {
      fetchData()
      setShowLoading(false)
    }, 1000)
    return () => {
      cancelToken.cancel()
      controller.abort()
    }
  }, [userId])

  const renderSegmentedContainer = () => {
    return [
      <IndividualSegmentedContainer key="renderSegmentedContainer"
        marketData={dashboardData.data.strategy}
        roleData={dashboardData.data.roles}
        toolData={dashboardData.data.tools}
      />,
    ]
  }

  const renderName = () => {
    return [
      <div key='divInRenderNameInIndDashShared' lines='none'>
        <div
          style={{
            textAlign: 'center',
            fontSize: '32px',
          }}
        >
          {dashboardData.user.first_name} {dashboardData.user.last_name}
        </div>
      </div>,
    ]
  }

  const renderMaxRole = (obj) => {
    if (Object.keys(obj).length === 0) {
      return
    }
    return Object.keys(obj)
      .reduce((a, b) => (obj[a] > obj[b] ? a : b))
      .toUpperCase()
  }
  if (profileId ? false : true) {
    return (
      <div>
        <p>no profileId present return to home</p>
      </div>
    )
  }

  if (!dashboardData) {
    return [
      // <div style={{background: 'red', margin: 'auto',width: '80%', height: '70vh'}}>
      <IonContent
      //  style={{width: '100%', width:'100%'}}
      >
        {/* <IonSpinner name="bubbles" style={{width: '60%', height: '60%',}}/> */}
        <IonLoading
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={'Loading Score...'}
          duration={5000}
        />
      </IonContent>,
      // no data yet...
      // </div>,
    ]
  } else {
    const SocialMediaShare = () => {
      return (
        <>
          <HelmetMetaData></HelmetMetaData>
          <Popper className='socialMediaPopper' open={true} transition>
            <FacebookShareButton
              url={`https://stacklr.com/individual-dashboard/${userId}`}
              className='socialMediaButton'
            >
              <FacebookIcon size={36} />
            </FacebookShareButton>
            <TwitterShareButton
              url={`https://stacklr.com/individual-dashboard/${userId}`}
              className='socialMediaButton'
            >
              <TwitterIcon size={36} />
            </TwitterShareButton>
            <LinkedinShareButton
              url={`https://stacklr.com/individual-dashboard/${userId}`}
              separator=':: '
              className='socialMediaButton'
            >
              <LinkedinIcon size={36} />
            </LinkedinShareButton>
          </Popper>
        </>
      )
    }

    return [
      <ion-list className='certt'>
        <div className='socialMediaPopper'>
          <a
            href={`https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=Stacklr Assessment&organizationId=81457406&issueYear=2022&issueMonth=0&certUrl=https://stacklr.com/individual-dashboard/${userId}`}
          >
            <img
              src='https://download.linkedin.com/desktop/add2profile/buttons/en_US.png '
              alt='LinkedIn Add to Profile button'
            ></img>
            ,
          </a>
        </div>
        ,
        <img
          className='cert'
          src={Cert}
          width='60%'
          height='50%'
          padding='5px'
          textAlign='center'
        />
        <div className='certName'>{renderName()}</div>
        <div className='certScore'>
          <ion-title
            id='bold-fonte'
            style={{ textAlign: 'center', fontSize: '40px' }}
          >
            {dashboardData.data.total_score}
            {dashboardData.data.best_categories}
            {renderMaxRole(dashboardData.data.roles)}
          </ion-title>
        </div>
        <ion-title
          style={{ textAlign: 'center', fontSize: '18px' }}
        ></ion-title>
      </ion-list>,
    ]
  }
}