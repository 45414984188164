import React from 'react'

import './SoftwareClients.css'
import mainLaptopImage from '../assets/images/w-image.png'
import mainLaptopImages from '../assets/images/Group 6356097.jpg'
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'




const SoftwareClients = () => {

    return (
        <div className='clientPage'>
            {/* <div className='clientPage-11'> */}
            <div className='header11'>
                <h1 className='header2'>Insights for Martech software companies</h1>
                <div className='para1'>Leverage insights from the targeted community of Martech users, influencers, and decision makers</div>
            </div>
<div className='para-image'> <img
                    className='para-img'
                    src={mainLaptopImages}

                /></div>
                <div className='para2'>
                <h2 className='para-title'>100+ Points of Clarity</h2>
                <div className='card-para-one'> 100+ Points of Clarity equips you to identify specific opportunities.</div>
                
              

<ul className='para-group'>
                        <li className='para-one'><FontAwesomeIcon icon={faCircleCheck} style={{color: "#44AF3B", paddingTop: "5px"}} />
Maximize insight from actual Martech users
                        </li>
                        <li className='para-two'><FontAwesomeIcon icon={faCircleCheck} style={{color: "#44AF3B", paddingTop: "5px"}} />Benchmark user software experience</li>
                        <li className='para-three'><FontAwesomeIcon icon={faCircleCheck} style={{color: "#44AF3B", paddingTop: "5px"}} />User experience by levels, roles, and expertise
                        </li>
                        <li className='para-four'><FontAwesomeIcon icon={faCircleCheck} style={{color: "#44AF3B", paddingTop: "5px"}} />Multiple proprietary sources of rating data
                        </li>
                    </ul>

                    <button  onClick={() => window.open(
                  'https://stacklr.zoholandingpage.com/100Pointsofclarity/'
                ) } className='rectangle-button-one'>Signup for 100+ POC</button>

                </div>
           

            {/* <div className='card-one'>
                <div className='card-one-title'>100+ Points of Clarity</div>
                <div className='card-one-para'>Get access to insights on how your user's rate, think, and experience, your software. 100+ Points of Clarity equips you to identify specific opportunities.   </div>
                <button   onClick={() => window.open(
                  'https://stacklr.zoholandingpage.com/100Pointsofclarity/'
                )
              } className='card-three-button'>Contact Us</button>

            </div> */}

            {/* <div className='card-two'>
                <div className='card-two-title'>Targeted Advertising </div>
                <div className='card-two-para'>Take your advertising ROI to the next level with Stacklr Ads. Stacklr’s online advertising solutions help you target the ideal customers for your software. </div>
                <button  onClick={() => window.open(
                  'https://stacklr.zoholandingpage.com/targetedadvertising/'
                ) }className='card-three-button'>Contact Us</button>
            </div>


            <div className='card-three'>
                <div className='card-three-title'>User Leads</div>
                <div className='card-three-para'>Need to drive more targeted revenue?
                    Get targeted leads. Stacklr provides you with leads who are already interested in software like yours.
                </div>
                <button  onClick={() => window.open(
                  'https://stacklr.zoholandingpage.com/userleads/'
                ) }className='card-three-button'>Contact Us</button>            </div> */}



{/* 
            <div className='para2'>


             


                <div className='para-right'>
                    <h2 className='para-title'>Stacklr gives you unrivaled insight from a targeted community of Martech users, influencers, and decision makers:</h2>
                    <ul className='para-group'>
                        <li className='para-one'>Maximize your software’s potential with insight from actual Martech users
                        </li>
                        <li className='para-two'>Benchmark user software experience</li>
                        <li className='para-three'>Understand how users at different levels and roles use your software.
                        </li>
                        <li className='para-four'>Get 100+ Points of Clarity with insights from multiple proprietary sources of data
                        </li>
                    </ul>
                </div>
            </div> */}



            {/* <div className='rectangle-one'>
                <div className='rectangle-title'>Go deeper</div>
                <div className='rectangle-para'>Connect with people who use your software the most. Your Go Deeper page is where Stacklr users go to learn more about your software. Claim your free Go Deeper page to get started!</div>
                <button  onClick={() => window.open(
                  'https://stacklr.zoholandingpage.com/100Pointsofclarity/'
                ) } className='rectangle-button'>Review your highlights</button>
            </div> */}


        </div>


       
        // </div>


        


    )
}

export default SoftwareClients