import React, { Component } from 'react';
import {IonItem, IonRow, IonCol, IonItemDivider} from "@ionic/react";


export default class ToolTable extends Component {

    renderIndividualRows() {
        return this.props.data.map((tool, index) => {
            return [
                <IonRow key={`item-${index}`}>
                    <IonCol>
                        {tool.tool_name}
                    </IonCol>
                    <IonCol>
                        {tool.score}
                    </IonCol>
                </IonRow>
            ]
        })
    }

    renderIndividualTools() {
        return [
            <IonItem lines='full'>
                <IonCol>
                    Tool Name
                </IonCol>
                <IonCol>
                    Score
                </IonCol>
            </IonItem>,
            this.renderIndividualRows()
        ]
    }

    renderRows() {
        const keys = Object.keys(this.props.data);

        return keys.map((key, index) => {
            return [
                <IonRow key={`item-${index}`}>
                    <IonCol>
                        {key}
                    </IonCol>
                    <IonCol>
                        {this.props.data[key].frequency}
                    </IonCol>
                    <IonCol>
                        {this.props.data[key].max}
                    </IonCol>
                    <IonCol>
                        {this.props.data[key].average}
                    </IonCol>
                </IonRow>
            ]
        })
    }

    renderGroupTools() {
        return [
            <IonItem lines='full'>
                <IonCol>
                    Tool Name
                </IonCol>
                <IonCol>
                    Times Reported
                </IonCol>
                <IonCol>
                    Max Score
                </IonCol>
                <IonCol>
                    Average Score
                </IonCol>
            </IonItem>,
            this.renderRows()
        ]
    }

    renderTools() {
        if (window.location.href.includes('individual')) {
            return this.renderIndividualTools()
        } else {
            return this.renderGroupTools()
        }
    }

    render() {
        return [
            <div style={{overflowY: 'scroll', height: '400px'}}>
                {this.renderTools()}
            </div>
        ]
    }
}
