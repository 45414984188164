import { YouTube } from '@material-ui/icons';
import { DEFAULT_STORE } from './constants';

function reducer(state = DEFAULT_STORE, action) {
    switch(action.type) {
      case "SET_SCORE":
        return {...state, currentScore: action.value}
      case "SET_SKILLS":
        return {...state, sortedSkills: action.value}
      case "SET_TOP_SKILLS":
        return {...state, topSkills: action.value}
      case "SET_ALL_AVERAGES":
        return {...state, averageRatings: action.value}
      case "SET_RUNNING_SCORE":
        return {...state, runningScore: action.value}
      case "SET_TOOL":
        return {
          ...state,
          toolAction: action.value,
          tool: action.tool
        };
      default:
        return state
    }
}

export default reducer;

