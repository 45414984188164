import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import FilterListIcon from '@mui/icons-material/FilterList';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import Popper from '@mui/material/Popper';
import PopupState, { bindToggle, bindPopper } from 'material-ui-popup-state';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';

import { IonLoading, IonContent } from '@ionic/react';

const columns = [
  { field: 'id', headerName: 'ID', width: 100 },
  {
    field: 'name',
    headerName: 'Name',
    width: 300
  },
  {
    field: 'function',
    headerName: 'Function',
    width: 300
  },
  {
    field: 'strategy',
    headerName: 'Strategy',
    width: 300
  },
  {
    field: 'users',
    headerName: 'Users',
    width: 300
  },
  {
    field: 'user_roles',
    headerName: 'User Roles',
    width: 300
  },
  {
    field: 'raters',
    headerName: 'Raters by assessment',
    width: 300
  },
  {
    field: 'rating',
    headerName: 'Rating',
    width: 300
  },
  {
    field: 'rating_through_assessment',
    headerName: 'Rating by assessment',
    width: 300
  }
];

const  filters = [
  {
    name: 'All',
    action: 'all_tools'
  },
  {
    name: 'Weekly',
    action: 'tools_weekly'
  },
  {
    name: 'In 6 months',
    action: 'tools_in_six_months'
  }
];

export default function ToolsDataGrid() {
  const [showLoading, setShowLoading] = useState(false);
  const [data, setData] = useState({ total: 0, tools: []});
  const [page, setPage] = useState(1);
  const pageSize = 20;
  const [action, setAction] = useState('all_tools');

  const fetchData = () => {
    setShowLoading(true)

    const offset = page > 1 ? (page * pageSize) - pageSize : 0;
    const url = `${global.apiBaseUrl}/tools/new?action=${action}&offset=${offset}&limit=${pageSize}`;

    fetch(url, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors'
    })
      .then((resp) => resp.json())
      .then((data) => {
        setData(data)
        setShowLoading(false);
      }).catch(() => {
        setShowLoading(false);
      })
  }

  useEffect(fetchData, [action, page]);
  
  const FiltersToolbar = () => (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 }
      }}
    >
      <Typography
        sx={{ flex: '1 1 100%' }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        Tools
      </Typography>

      <PopupState variant="popper" popupId="filters-popup-popper">
        {(popupState) => (
          <div>
            <IconButton {...bindToggle(popupState)}>
              <FilterListIcon />
            </IconButton>
            <Popper {...bindPopper(popupState)} transition>
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                  <Paper>
                    <List dense sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                      {filters.map((filter) => {
                        const labelId = `checkbox-list-secondary-label-${filter.action}`;
                        return (
                          <ListItem
                            key={filter.action}
                            secondaryAction={
                              <Checkbox
                                edge="end"
                                onChange={() => setAction(filter.action)}
                                checked={action === filter.action}
                                inputProps={{ 'aria-labelledby': labelId }}
                              />
                            }
                            disablePadding
                          >
                            <ListItemButton>
                              <ListItemText id={labelId} primary={filter.name} />
                            </ListItemButton>
                          </ListItem>
                        );
                      })}
                    </List>
                  </Paper>
                </Fade>
              )}
            </Popper>
          </div>
        )}
      </PopupState>
    </Toolbar>
  );

  return (
    <Box sx={{ height: 95 * 9, width: '100%' }}>
      <IonContent>
        <IonLoading
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={'Loading Tools...'}
        />
        <DataGrid
          columns={columns}
          rowHeight={90}
          rows={data.tools}
          rowCount={data.total}
          paginationMode="server"
          pageSize={pageSize}
          page={page}
          onPageChange={(newPage) => setPage(newPage)}
          style={{ whiteSpace: 'pre-wrap' }}
          checkboxSelection
          disableSelectionOnClick
          components={{ Toolbar: FiltersToolbar }}
          pagination
        />
      </IonContent>
    </Box>
  );
}
