import React from 'react'
import ResourceHeroImage from '../assets/images/Screen Shot 2021-10-26 at 10.43.44 PM.png'
import TebImage from '../assets/images/resources_TEB-2x.png'
import StakkidImage from '../assets/logos/1Asset 8.png'

import ScottBrinkerPhoto from '../assets/images/community-influncer_Scott-Brinker.jpeg'

function ResourcePartners() {
  return (
    <div className=''>
      <img src={ResourceHeroImage} width='100%' paddingTop='9px' />
      <table width='80%'>
        <tbody>
          <div id='resources-partners-header-container'>
            <tr>
              <td>
                <h1>Find a Stacklr Resource Partner</h1>{' '}
              </td>
            </tr>
          </div>
          <div id='resources-learn-more-container'>
            <tr>
              <td>
                <hr />
              </td>
            </tr>
            <div id='experts-bench-text-container'>
              <tr>
                <td className='align-middle'>
                  <img src={TebImage} width='200' />
                </td>
                <td width='10%'></td>
                <td width='70%'>
                  The Experts Bench (Teb) enables marketing optimization through
                  a range of consulting, professional services and staffing for
                  marketing technologies. Teb offers a competitive analysis of
                  your Stacklr company score and solutions for
                  martech&nbsp;gaps.
                  <br />
                  <br />
                  <a href='http://www.tebww.com' target='_blank'>
                    LEARN MORE
                  </a>
                </td>
              </tr>
            </div>

            <tr>
              <td colspan='2'>
                <hr />
              </td>
            </tr>
            <div id='experts-bench-text-container'>
              <tr>
                <td className='align-middle'>
                  <img
                    src='https://cdn.chiefmartec.com/wp-content/uploads/2021/09/chiefmartec-logo-165x41.png'
                    width='200'
                  />
                </td>
                <td width='0%'></td>
                <td width='10%'></td>
                <td width='70%'>
                  Stacklr partners with Chief Martec for the latest insights on
                  marketing technology changes.
                  <br />
                  <br />
                  <a href='https://chiefmartec.com/' target='_blank'>
                    LEARN MORE
                  </a>
                </td>
              </tr>
            </div>

            <tr>
              <td colspan='2'>
                <hr />
              </td>
            </tr>
            <div id='experts-bench-text-container'>
              <tr>
                <td className='align-middle'>
                  <img
                    src='https://pbs.twimg.com/profile_images/1158830616974020608/UVDZeFsY_400x400.jpg'
                    width='200'
                  />
                </td>
                <td width='10%'></td>
                <td width='70%'>
                  The CDP Institute partners with Stacklr to provide
                  vendor-neutral information about issues, methods, and
                  technology related to customer data management and educational
                  materials on CDP.
                  <br />
                  <br />
                  <a href='https://www.cdpinstitute.org/' target='_blank'>
                    LEARN MORE
                  </a>
                </td>
              </tr>
            </div>
          </div>
        </tbody>
      </table>
    </div>

    // <div className="">
    //     <img src={ResourceHeroImage} width="100%" />
    //     <table width="80%">
    //         <tbody>
    //             <div id="resources-partners-header-container">
    //                 <tr>
    //                     <td style="padding: 0px 0 10px 0; font-family: Arial, sans-serif; font-size: 30px;" colspan="2">Find a <strong>STAKKID</strong> Resource Partner</td>
    //                 </tr>
    //             </div>
    //             <div id="resources-learn-more-container">
    //                 <tr>
    //                     <td style="padding: 0px 0 30px 0; font-family: Arial, sans-serif; font-size: 30px;" colspan="2"><hr/></td>
    //                 </tr>
    //             <div id="experts-bench-text-container">
    //                 <tr>
    //                     <td style="padding: 0px 0 20px 0;" width="20%"><img src="https://www.agilealliance.org/wp-content/uploads/2016/07/teb-logo.jpg" width="200"/></td>
    //                     <td width="80%" style="font-family: Arial, sans-serif; font-size: 15px; padding-left: 20px; padding-bottom: 30px;">The Experts Bench (Teb) enables marketing optimization through a range of consulting, professional services and staffing for marketing technologies. Teb offers a competitive analysis of your STAKKID company score and solutions for martech&nbsp;gaps.<br/><br/>

    //                     <a href="http://www.tebww.com" target="_blank" style="text-decoration: none; font-weight: bold; color: #18355f;">LEARN MORE</a>
    //                     </td>
    //                 </tr>
    //             </div>
    //             </div>

    //                 <tr>
    //                     <td style="padding: 0px 0 30px 0; font-family: Arial, sans-serif; font-size: 30px;" colspan="2"><hr/></td>
    //                 </tr>
    //             <div id="resources-individual-partner-container">
    //                 <tr>
    //                     <td style="padding: 0px 0 20px 0;" width="20%" valign="middle"><img src="https://anandthaker.com/wp-content/uploads/2018/01/Chiefmartec-1-e1516649869888.png" width="200"/></td>
    //                     <td width="80%" style="font-family: Arial, sans-serif; font-size: 15px; padding-left: 20px;">STAKKID partnered with Chief Martech for the latest insights on marketing technology&nbsp;changes.<br/><br/>
    //                     <a href="https://chiefmartec.com/" target="_blank" style="text-decoration: none; font-weight: bold; color: #18355f;">LEARN MORE</a>
    //                     </td>
    //             </tr>
    //             </div>
    //         </tbody>
    //     </table>
    // </div>

    // <div className="x">
    //     <img src={ResourceHeroImage} width="100%" />
    //     <div id="resources-partners-header-container">
    //         <h1><b>STAKKID</b> Partners</h1>
    //     </div>
    //     <div id="resources-learn-more-container">
    //         <img src={TebImage} id="resources-teb-image" />
    //         <div id="experts-bench-text-container">
    //             <p>
    //                 The Experts Bench, founded in 2001, specializes in helping companies optimize
    //                 their marketing activities through a range of staffing, consulting, and professional services.
    //                 By engaging our Experts, each of whom has mastered a specific marketing
    //                 function and industry, they’re able to execute superior marketing across multiple initiatives
    //                 while decreasing cycle times, lowering costs, and keeping fulltime headcount low.
    //                 Whenever you want the latest talent, techniques, and solutions focused on bringing your
    //                 marketing strategies to life, The Experts Bench is the answer.
    //             </p>
    //             <button id="learn-more-button">Learn More</button>
    //         </div>
    //     </div>
    //     <div >
    //         <div id="resources-individual-partner-container">
    //             <img src={ScottBrinkerPhoto} id="resources-individual-partner-photo"/>
    //             <p>
    //                 With deep roots in computer science and video gaming, Scott Brinker brings to marketing a
    //                 unique perspective. He recently spoke with David Lewis on DemandGen radio to provide insights
    //                 on the rise of Marketing operations as a role and function within marketing organizations and
    //                 talk about what’s happening across the MarTech landscape.
    //                 <br />
    //                 <a href="/">Learn More...</a>
    //             </p>
    //         </div>
    //     </div>
    // </div>
  )
}

export default ResourcePartners
