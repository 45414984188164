import React from 'react';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';
import {CardElement, useStripe, useElements} from '@stripe/react-stripe-js';
import {RouteService} from "../services/route.service";
import {TeamHttpService} from "../http_services/team.http_service";
import {CompanyHttpService} from "../http_services/company.http_service";
import {IndividualHttpService} from "../http_services/individual.http_service";
import fire from 'firebase'

// const cardElementOptions = {
//     style: {
//         base: {
//             fontSize: '16px',
//             'placeholder': {
//                 color: 'navy'
//             }
//         },
//         invalid: {
//             color: 'red',
//             iconColor: 'blue'
//         }
//     },
//     hidePostalCode: true,
// }

export default class Billing extends React.Component {
    constructor(props){
        super(props);

        this.params = RouteService.params();
        let total = 0;
        this.type = '';
        this.licenses = '';

        if (this.params.bundle === 'company') {
            total = 5000;
            this.type = 'Company';
            this.licenses = '500+';
        } else if (this.params.bundle === 'group') {
            total = 1500;
            this.type = 'Group';
            this.licenses = '31-500';
        } else {
            total = 100;
            this.type = 'Team';
            this.licenses = '0-30';
        }

        this.state = {
            total: total,
            show: false,
            email: '',
            password: '',
            firstName: '',
            lastName: '',
            address: '',
            aptInfo: '',
            city: '',
            addressState: '',
            zipCode: '',
            cardNumber: '',
            cardDate: '',
            cardSecurityCode: '',
            authorityAccept: false,
            attnAccept: false,
            attn: '',
            processing: false
        }
    };

    handleShow(){
        this.setState({
            show: !this.state.show
        })
    };

    handleChange(event){
        this.setState({
            [event.target.name]: event.target.value
        })
    };

    addCompetitiveReview(e){
        if (e.target.checked) {
            this.setState({
                total: this.state.total + 1500
            })
        } else {
            this.setState({
                total: this.state.total - 1500
            })
        }
    };

    async handleSubmit(event){
        event.preventDefault();
        let response = '';
        const firebase_user = await fire.auth().currentUser;
        const res = await new IndividualHttpService().where({firebase_uid: firebase_user.uid});
        const user = res[0];
        if (this.type === 'team') {
            response = await new TeamHttpService().create({admin_email: user.email});
            await new IndividualHttpService().update({id: user.id, team_id: response.id, team_admin: true})
        } else {
            response = await new CompanyHttpService().create({admin_email: user.email});
            await new IndividualHttpService().update({id: user.id, company_id: response.id, company_admin: true})
        }
        this.handleShow()
    };

    render(){
        return(
            <div className="page-padding">
                <h1>Billing</h1>
                <hr />
                <div className="modal-body row d-flex justify-content-center">
                        <div className="col-md-4 d-flex justify-content-center" id="license-blocks" style={{margin: '0px 40px'}}>
                            <h5 id="billing-float-margins" style={{textAlign: 'center'}}>{this.type}</h5>
                            <p id="billing-float-margins">{this.licenses} licenses</p>
                            <label id="billing-float-margins" style={{textAlign: 'center'}}>
                                ${this.state.total} per year
                                <br />
                                <br />
                            {/*<button id="main-stakkid-button">Change</button>*/}
                            </label>
                            {/* <p id="billing-float-margins">$1500 per year</p>
                            <br /> */}
                        </div>
                        <div className="col-md-4" id="license-blocks" style={{margin: '0px 40px'}}>
                            <h5 style={{float: 'left'}}>Add Competitive Review</h5> 
                            <br />
                            <br />
                            <label>
                                <input type="checkbox" style={{margin: '7px'}} onChange={(e) => this.addCompetitiveReview(e)} />
                                $1500
                            </label>
                            <br />
                            <br />
                            <p>Find out how your competition does. Compare your stats to other companies.</p>
                        </div>
                </div>
                <h4>Create Account</h4>
                <form className="modal-body row d-flex justify-content-around">
                    <label className="col-md-3" id="profile-text">
                        Email
                        <br />
                        <input 
                            id="profile-input"
                            type="text"
                            name="email"
                            placeholder="Email"
                            value={this.state.email}
                            onChange={(event) => this.handleChange(event)}
                        />
                    </label>
                    <label className="col-md-3" id="profile-text">
                        Password
                        <br />
                        <input 
                            id="profile-input"
                            type="password"
                            name="password" 
                            placeholder="Password"
                            value={this.state.password}
                            onChange={(event) => this.handleChange(event)}
                        />
                        <small><p>Use 8 or more characters with letters <br /> numbers and symbols</p></small>
                    </label>
                </form>
                <div className="modal-body row d-flex justify-content-center">
                    <div className="col-md-6">
                        <h4>Billing Address</h4>
                        <form>
                            <label className="col-md-6" id="profile-text">
                                First Name
                                <br />
                                <input 
                                    id="profile-input"
                                    type="text"
                                    name="firstName"
                                    placeholder="First Name"
                                    value={this.state.firstName}
                                    onChange={(event) => this.handleChange(event)}
                                />
                            </label>
                            <label className="col-md-5" id="profile-text">
                                Last Name
                                <br />
                                <input 
                                    id="profile-input"
                                    type="text"
                                    name="lastName"
                                    placeholder="Last Name"
                                    value={this.state.lastName}
                                    onChange={(event) => this.handleChange(event)}
                                />
                            </label>
                            <label className="col-md-12" id="profile-text">
                                Address
                                <br />
                                <input style={{width: '100%'}} 
                                    id="profile-input"
                                    type="text"
                                    name="address"
                                    placeholder="Street Address"
                                    value={this.state.address}
                                    onChange={(event) => this.handleChange(event)}
                                />
                            </label>
                            <label className="col-md-12" id="profile-text">
                                <br />
                                <input style={{width: '100%'}} 
                                    id="profile-input"
                                    type="text"
                                    name="aptInfo"
                                    placeholder="apt #"
                                    value={this.state.aptInfo}
                                    onChange={(event) => this.handleChange(event)}
                                />
                            </label>
                            <label className="col-md-12" id="profile-text">
                                City
                                <br />
                                <input style={{width: '100%'}} 
                                    id="profile-input"
                                    type="text"
                                    name="city"
                                    placeholder="City"
                                    value={this.state.city}
                                    onChange={(event) => this.handleChange(event)}
                                />
                            </label>
                            <label className="col-md-2" id="profile-text">
                                State
                                <br />
                                <input style={{width: '55px'}} 
                                    id="profile-input"
                                    type="text"
                                    name="addressState"
                                    placeholder="State"
                                    value={this.state.addressState}
                                    onChange={(event) => this.handleChange(event)}
                                />
                            </label>
                            <label className="col-md-3" id="profile-text">
                                Zip Code
                                <br />
                                <input style={{width: '100px'}} 
                                    id="profile-input"
                                    type="number"
                                    name="zipCode"
                                    placeholder="Zip Code"
                                    value={this.state.zipCode}
                                    onChange={(event) => this.handleChange(event)}
                                />
                            </label>
                        </form>
                    </div>
                    <div className="col-md-6">
                        <h4>Payment</h4>
                        {/* <CardElementContainer> */}
                        {/* </CardElementContainer> */}
                        {/* <form> */}
                            <hr />
                            {/* <CardElement options={cardElementOptions}/> */}
                            {/* <CardElement /> */}
                            <br />
                            {/* <label className="col-md-10" id="profile-text">
                                Card Number
                                <br />
                                <input 
                                    id="profile-input"
                                    type="number"
                                    name="cardNumber"
                                    placeholder="Card Number"
                                    value={this.state.cardNumber}
                                    onChange={(event) => this.handleChange(event)}
                                />
                            </label>
                            <label className="col-md-4" id="profile-text">
                                Date
                                <br />
                                <input style={{width: '80px'}} 
                                    id="profile-input"
                                    type="number"
                                    name="cardDate"
                                    placeholder="Ex. 01/12"
                                    value={this.state.cardDate}
                                    onChange={(event) => this.handleChange(event)}
                                />
                            </label>
                            <label className="col-md-4" id="profile-text">
                                Security Code
                                <br />
                                <input style={{width: '100px'}} 
                                    id="profile-input"
                                    type="number"
                                    name="cardSecurityCode"
                                    placeholder="Ex. 1234"
                                    value={this.state.cardSecurityCode}
                                    onChange={(event) => this.handleChange(event)}
                                />
                            </label> */}
                            <Accordion defaultActiveKey="0">
                                {/*<Card>*/}
                                {/*    <Accordion.Toggle as={Card.Header} eventKey="0">*/}
                                {/*    Credit Card Info*/}
                                {/*    </Accordion.Toggle>*/}
                                {/*    <Accordion.Collapse eventKey="0">*/}
                                {/*    <Card.Body>*/}
                                {/*        <CardElement />*/}
                                {/*        </Card.Body>*/}
                                {/*    </Accordion.Collapse>*/}
                                {/*</Card>*/}
                                <Card>
                                    <Accordion.Toggle as={Card.Header} eventKey="0">
                                    Invoice
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        <label className="col-md-8">
                                            <input type="checkbox" style={{margin: '7px'}} name="authorityAccept" value={this.state.authorityAccept} />
                                            Bill Me
                                            <small><p>By Selecting this I accept the authority for my company</p></small>
                                        </label>
                                        <label className="col-md-8">
                                            <input type="checkbox" style={{margin: '7px'}} name="attnAccept" value={this.state.attnAccept} />
                                            Bill Me
                                            <small>
                                                <p>By Selecting this I accept the authority for my company under the attention of:</p>
                                            </small>
                                            <div style={{float: 'right'}}>
                                            ATTN
                                            <br />
                                            <label className="col-md-2">
                                                <input type="text" name="attn" value={this.state.attn} onChange={(event) => this.handleChange(event)} />
                                            </label>
                                            </div>
                                        </label>
                                    </Card.Body>
                                </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        {/* </form> */}
                    </div>
                </div>
                <div style={{textAlign: 'center'}}>
                    <div id="billing-total-box">
                        <h4 style={{paddingTop: '8%'}}>Total <br /> ${this.state.total}</h4>
                    </div>
                    <button onClick={(event) => this.handleSubmit(event)} style={{marginTop: '10px'}} id="main-stakkid-button">Submit</button>
                </div>
                <Modal show={this.state.show}>
                    <Modal.Header closeButton onClick={() => this.handleShow()}>
                    </Modal.Header>
                    <Modal.Body style={{textAlign: 'center'}}>
                        <h4>THANK YOU</h4>
                        <p>A confirmation email has been sent to your email.</p>
                        <p>Your confirmation #12345678</p>
                        <p>Now Let's Send those licenses to your employees!</p>
                        <button id="main-stakkid-button">Next</button>
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
};
