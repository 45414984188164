import React from 'react';
import {IndividualHttpService} from "../http_services/individual.http_service";
import {TeamHttpService} from "../http_services/team.http_service";
import fire from 'firebase'
import {IonItem, IonList, IonTitle} from "@ionic/react";

export default class TeamLicensePage extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            emails: [{
                email1: '',
                email2: '',
                email3: '',
                email4: '',
                email5: '',
            }],
            currentIndividuals: [],
            currentUser: {}
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (nextProps.user !== this.props.user) {
            return true
        } else return nextState !== this.state;
    }

    async componentWillMount() {
        await this.getCurrentIndividuals()
    }

    async getCurrentIndividuals() {
        const firebase = await fire.auth().currentUser;
        const response = await new IndividualHttpService().where({firebase_uid: firebase.uid});
        const user = response[0];
        const individuals = await new IndividualHttpService().where({team_id: user.team_id});
        this.setState({currentUser: user, currentIndividuals: [...individuals]})
    }

    async submit() {
        const emails = Object.values(this.state.emails[0]);
        emails.map(async (email) => {
            if (email === '') { return; }
            await this.createOrUpdateUserWithTeamId(email)
        })
    }

    async createOrUpdateUserWithTeamId(email) {
        const response = await new IndividualHttpService().where({email: email});
        let user = {};
        if (response.length !== 0) {
            user = response[0];
        }

        if (response.length === 0) {
            const newUser = await new IndividualHttpService().create({email: email, company_id: this.state.currentUser.company_id, team_id: this.state.currentUser.team_id})
        } else {
            await new IndividualHttpService().update({id: user.id, company_id: this.state.currentUser.company_id, team_id: this.state.currentUser.team_id})
        }
    }

    update(e) {
        const copy = [...this.state.emails];
        copy[0][e.target.name] = e.target.value;
        this.setState({emails: copy})
    }

    renderCurrentIndividuals() {
        if (this.state.currentIndividuals.length === 0) { return; }
        return this.state.currentIndividuals.map((team) => {
            return [
                <IonItem>
                    {team.email}
                </IonItem>
            ]
        })
    }


    render(){
        return(
            <div className="page-padding">
                <h1>Digital Marketing Team Licenses</h1>
                <hr />
                <h3 style={{float: 'left'}}>Add Accounts </h3> <p style={{float: 'left', margin: '10px 15px'}}>## licenses left</p>
                <br />
                <br />
                <hr />
                <p>Add the accounts</p>
                <label id="profile-text">
                    <input id="profile-input" type="text" name="email1" placeholder="Email" onChange={(e) => {this.update(e)}} />
                </label>
                <br />
                <label id="profile-text">
                    <input id="profile-input" type="text" name="email2" placeholder="Email" onChange={(e) => {this.update(e)}}/>
                </label>
                <br />
                <label id="profile-text">
                    <input id="profile-input" type="text" name="email3" placeholder="Email" onChange={(e) => {this.update(e)}} />
                </label>
                <br />
                <label id="profile-text">
                    <input id="profile-input" type="text" name="email4" placeholder="Email" onChange={(e) => {this.update(e)}} />
                </label>
                <br />
                <label id="profile-text">
                    <input id="profile-input" type="text" name="email5" placeholder="Email" onChange={(e) => {this.update(e)}} />
                    <input id="main-stakkid-button" style={{width: '140px', marginLeft: '100px'}} type="submit" value="Add" onClick={() => this.submit()}/>
                </label>
                <br />
                {/* <div>
                    <h5>Bulk Import</h5>
                    <p>Add several Team Administrators at once</p>
                    <button id="main-stakkid-button">Upload CSV</button>
                </div> */}
                <br />
                <IonList>
                    <IonTitle>
                        Current Individuals
                    </IonTitle>
                    {this.renderCurrentIndividuals()}
                </IonList>
                <hr />
            </div>
        )
    }
};
