import React, { useState, useEffect } from 'react'
import fire from './firebase'
import stacklrIcon from '../assets/icons/stacks-1-128x128.png'
import cubesIcon from '../assets/icons/3d-cube-128x128.png'
// import adIcon from '../assets/icons/ad-promo-128x128.png'
import commerceIcon from '../assets/icons/commerce-256x256.png'
import contentIcon from '../assets/icons/content-256x256.png'
import managementIcon from '../assets/icons/management-256x256.png'
import relationshipsIcon from '../assets/icons/relationships-256x256.png'
// import salesIcon from '../assets/icons/sales-128x128.png'
import dataIcon from '../assets/icons/data-256x256.png'
// import growthIcon from '../assets/icons/stock-market-512x512.png'
import mobileShopIcon from '../assets/icons/mobile-shop-256x256.png'
import adIcon from '../assets/icons/online-advertising-256x256.png'

import '../App.css'
import cx from 'clsx'
import Grid from '@material-ui/core/Grid'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import { useContainedCardHeaderStyles } from '@mui-treasury/styles/cardHeader/contained'
import { useSoftRiseShadowStyles } from '@mui-treasury/styles/shadow/softRise'
import { useFadedShadowStyles } from '@mui-treasury/styles/shadow/faded'

import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Rating from '@material-ui/lab/Rating'
import { useDispatch, useSelector} from 'react-redux'
import CircularProgress from '@material-ui/core/CircularProgress'

import Axios from 'axios'

import WordLimit from 'react-word-limit';
import { tools } from '../data/toolNamesAndCategories'
import { loadAllAvgRatingsAction } from '../actions'
import { useSelect } from '@mui/base'
import { EighteenUpRatingSharp } from '@mui/icons-material'
import { SkillRating } from './Rating'


// let j; 
// for (j=0;j< tools.length; j++) {
//   tools[j].averageRating = 0
// }



export default function RateSkill({isCustom}) {
  const [showLoading, setShowLoading] = useState(true)
  const [userId, setUserId] = useState(null)
  const currentUser = fire.auth().currentUser
  const [data, setData] = useState(null)
  const cardHeaderStyles = useContainedCardHeaderStyles();
    const cardShadowStyles = useSoftRiseShadowStyles({ inactive: true });
    const cardHeaderShadowStyles = useFadedShadowStyles();

  const ratings = useSelector((state) => state.averageRatings)
  // const [result, setResult] = useState(null)
  const dispatch = useDispatch()

  const fetchAvrRating = () => {
    if(isCustom){
      dispatch(loadAllAvgRatingsAction);
    }
  }

  useEffect(() => {
    fetchAvrRating();
    const controller = new AbortController()
    const signal = controller.signal
    const cancelToken = Axios.CancelToken.source()
    const fetchData = async () => {
      const axiosUrl =
        global.apiBaseUrl + `/individuals?firebase_uid=${currentUser.uid}`
      let responseAxios = ''
      if (userId == null) {
        responseAxios = await Axios.get(axiosUrl, {
          cancelToken: cancelToken.token,
        })
          .then((res) => {
            const individualId = res.data[0].id
            setUserId(individualId)
            return individualId
          })
          .catch((error) => {
            if (Axios.isCancel(error)) {
              console.log('Request canceled!')
            } else if (error.response) {
              // Request made and server responded
              console.log(error.response.data)
              console.log(error.response.status)
              console.log(error.response.headers)
            } else if (error.request) {
              // The request was made but no response was received
              console.log(error.request)
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message)
            }
          })
      }

      const skillUrl =
        global.apiBaseUrl +
        '/skills/new?action=individual_top_skills_by_category'

      const responseFetch = await fetch(skillUrl, {
        method: 'POST',
        // crossDomain:true,
        redirect: 'follow', // manual, *follow, error
        headers: { 'Content-Type': 'application/json' },
        mode: 'cors',
        body: JSON.stringify({
          individual_id: userId == null ? responseAxios : userId,
          number_of_skills: 3,
        }),
        signal: signal,
      })
        .then((resp) => resp.json())
        .then((data) => {
          console.log('skill fetch successfull in useEffect')
          return data
        })
        .catch((error) => {
          if (typeof error.json === 'function') {
            error
              .json()
              .then((jsonError) => {
                console.log('Json error from API')
                console.log(jsonError)
              })
              .catch((genericError) => {
                console.log('Generic error from API')
                console.log(genericError)
                console.log(genericError.statusText)
              })
          } else {
            console.log('Fetch error')
            console.log(error)
          }
        })
      setData(responseFetch)
    }
    setTimeout(() => {
      fetchData()
      setShowLoading(false)
    }, 1000)
    return () => {
      cancelToken.cancel()
      controller.abort()
    }
  }, [currentUser])

  const useStyles = makeStyles(({ breakpoints, spacing }) => ({
    loadingContainer: {
      margin: 'auto',
      width: '80%',
      height: '80vh',
      display: 'flex',
      flexDirection: 'column',
      alignSelf: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      justifySelf: 'center',
    },
    gridContainer: {
      marginBottom: '50px',
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'row',
      // border: '2px dashed orange',
      fontFamily: 'Roboto',
      marginLeft: '10px',
    },
    // headerSpan: {
    //   borderRadius: '8px',
    //   background: '#21A3A3',
    //   width: '58%',
    //   minWidth: '300px',
    //   padding: '20px 10px',
    //   textAlign: 'center',
    //   margin: 'auto',
    //   marginTop: '1rem',
    //   marginBottom: '2rem',
    //   color: 'white',
    // },
    // gridHeader: {
    //   placeSelf: 'center',
    //   width: 'auto',
    //   fontFamily: 'Roboto',
    //   fontWeight: 600,
    //   letterSpacing: '2px',
    // },
    grid: {
      display: 'flex',

      justifyContent: 'space-between',
      width: '100%',
      // margin: '50px',
    },
    cardHeader: {
      position: 'relative',
      paddingBottom: '20px',
      marginTop: '-20px',
      top: '-2rem',
    },
    card: {
      marginTop: 40,
      // borderRadius: spacing(1.5),

      transition: '0.3s',
      width: '100%',
      overflow: 'initial',
      background: '#ffffff',
      margin: 'auto',
      letterSpacing: '4px',
      minWidth: '335px',
    },
    // box: {
    //   display: 'flex',
    //   justifyContent: 'flex-end',
    //   float: 'right',
    //   width: '20%',

    // },
    // boxx: {
    //   display: 'flex',
    //   justifyContent: 'center',
      
    //   // fontSize: '0.9rem',
    // },

    skillName: {
      fontSize: '0.9rem',
    },
    content: {
      // bottom: '15px',
      // opacity: '0.8',
      // zIndex: '1',
      height: '50%',

      paddingBottom: '48%',
      borderRadius: spacing(2),
      backgroundColor: '#fff',
      // position: 'relative',
      [breakpoints.up('md')]: {
        width: '78%',

        // marginLeft: spacing(-3),
        marginTop: 20,
        // transform: 'translateX(-28px)',
      },
      '&:after': {
        content: '" "',
        position: 'absolute',
        // top: 0,
        // left: 0,
        width: '70%',
        height: 'auto',
        borderRadius: spacing(2), // 16
        // opacity: 0.7,
      },
    },
  }))
  const classes = useStyles()

  function SimpleRating({
    individualId,
    rating,
    name,
    category,
    isCustom,
    ratings,
    score,
    skill_avg,
  }) {
    console.log("name", name)
    console.log("category", category)
    const labels = {
      0: 'Unrated',
      1: 'Lower',
      2: 'Low',
      3: 'Medium',
      4: 'High',
      5: 'Essential',
    }
    const [value, setValue] = React.useState('')
    const [hover, setHover] = React.useState(-1)

    useEffect(()=>{
  if(isCustom)
  {
    setValue(ratings[name] || 0)
  } else{
    setValue(rating)
  }
    }, [])

    async function submitSkillRating(
      individualId,
      skillName,
      skillCategory,
      skillRating
    ) {
      const skillUrl =
        global.apiBaseUrl + '/skills/new?action=update_skill_rating'
      const response = await fetch(skillUrl, {
        method: 'POST',
        redirect: 'follow', // manual, *follow, error
        headers: { 'Content-Type': 'application/json' },
        mode: 'cors',
        body: JSON.stringify({
          individual_id: individualId,
          tool_name: skillName,
          category: skillCategory,
          rating: skillRating,
        }),
      })
        .then((resp) => resp.json())
        .then((data) => {
          console.log('data response in submit rating successfull')
          console.log(data)
          return data
        })
        .catch((error) => {
          if (typeof error.json === 'function') {
            error
              .json()
              .then((jsonError) => {
                console.log('Json error from API')
                console.log(jsonError)
              })
              .catch((genericError) => {
                console.log('Generic error from API')
                console.log(genericError)
                console.log(genericError.statusText)
              })
          } else {
            console.log('Fetch error')
            console.log(error)
          }
        })
    }
    const changeRating = (rating) => {
      submitSkillRating(individualId, name, category, rating)
    }

    
    return ( 


<>
{/* <CardHeader
className={[classes.cardHeader,cardHeaderShadowStyles.root]}
classes={cardHeaderStyles}
        title={category}
        // avatar={icons[category]}
        // subheader={''}
        /> */}


      
      <Box
        component='fieldset'
        mb={3}
        borderColor='transparent'
        style={{ zIndex: '10' }}
      >

        
        <div className={classes.boxx} >
          <Typography
            component='legend'
            style={{float: 'left', maxWidth: '100%', fontSize: '0.8rem' }}
          >
            
            <WordLimit limit={10}>{name}</WordLimit>
          </Typography>

          {/*<Typography
            style={{
              float: 'right',
              marginRight: '0.85rem',
              fontSize: '0.7rem',
            }}
          >
            avg : <span style={{ fontSize: '1.4rem' }}>{skill_avg}</span>
          </Typography>*/}
        </div>

        

        <div className={classes.box}>
        {/* rating={result.averageRating} */}
        {
          isCustom ? 
          <SkillRating className='search-result-rating' rating={value}/>
          :
          <Rating
            style={{ float: 'left'}}
            size='small'
            name={name + 'rating'}
            value={value}
            onChangeActive={(event, newHover) => {
              setHover(newHover)
            }}
            onChange={(event, newValue) => {
              event.preventDefault()
              if (newValue == value || newValue == null) {
                return
              }
              setValue(newValue)
              changeRating(newValue)
            }}
          />
        }
        </div>
      
      </Box>
      
      </>
    )


    
  }
  const Skills = ({ data, ratings, individual_id, icons, isCustom }) => {
    let skillsInfo = data
    console.log("skillsInfo", skillsInfo)
    console.log("ratings", ratings)
    const cardHeaderStyles = useContainedCardHeaderStyles()
    const cardShadowStyles = useSoftRiseShadowStyles({ inactive: true })
    const cardHeaderShadowStyles = useFadedShadowStyles()

    return [
      <Grid container spacing={5} className={classes.grid}>
        {Object.keys(skillsInfo).map((category, index) => {
          return [
            <Grid item xs={6} sm={4} md={2} key={index}>
              <CardContent className={classes.content}>
                {skillsInfo[category].map((skill, idx) => {
                  console.log(
                    "first: " + skill[0] + ' second: ' + skill[1] + ' third: ' + skill[2] + ' fourth: ' + skill[3]
                  )
                  return (
                    <SimpleRating
                      key={idx}
                      rating={skill[2]}
                      ratings={ratings}
                      name={skill[0]}
                      score={skill[1]}
                      category={category}
                      individualId={individual_id}
                      skill_avg={skill[3] ? skill[3] : 'undefined'}
                      isCustom
                    />
                  )
                })}
              </CardContent>
            </Grid>,
          ]
        })}
      </Grid>,
    ]
  }
  if (data == null) {
    return (
      <div className={classes.loadingContainer}>
        <h4>Loading your top Skills...</h4>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <CircularProgress />
          <CircularProgress color='secondary' />
          <CircularProgress color='tertiary' />
        </div>
      </div>
    )
  } else if (Object.keys(data)) {
    console.log('data == true data; ')
    const icons = {
      'Ad & Promo': adIcon,
      'Content & Experience': contentIcon,
      'Commerce & Sales': mobileShopIcon,
      Management: managementIcon,
      Data: dataIcon,
      'Social & Relationships': relationshipsIcon,
    }
    return [
      <div className={classes.gridContainer}>
        {isCustom ? 
        <Skills
        key='skillsComponent'
        ratings={ratings}
        data={data}
        individual_id={userId}
        icons={icons}
        isCustom
      />
        : 
        <Skills
          key='skillsComponent'
          data={data}
          ratings={{}}
          individual_id={userId}
          icons={icons}
        />
        }
      </div>,
    ]
  }
}
