import React, { Component, useState } from 'react'
import IndividualSegmentedContainer from '../data-visualizations/individual-segmented-container/segmented-container'
import RadialChart from '../data-visualizations/pie-chart/PieChart'
import HeatMap from '../data-visualizations/heatmap/heatmap'
import fire from '../firebase'
import { loadingController } from '@ionic/core'
import './dashboard.css'
import '../../App.css'
import { PlatformService } from '../../services/platform.service'
import ToolTable from '../data-visualizations/tool-table'
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonItem,
  IonRow,
  IonLabel,
  IonPopover,
} from '@ionic/react'

import Popups from '../Modal-2'
import linkedinLogo1 from '../../assets/logos/en_US.png'
import linkedinLogo2 from '../../assets/logos/linkedin-share-button-icon.png'

import { IndividualHttpService } from '../../http_services/individual.http_service'
import InfoIcon from '@material-ui/icons/Info'
import { SkillHttpService } from '../../http_services/skill.http_service'
import Popper from '@material-ui/core/Popper'
import HelmetMetaData from '../core/HelmetData/HelmetData'
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from 'react-share'
import { SocialIcon } from 'react-social-icons'

import { Link } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress';
import LinkedInCertificate from 'react-linkedin-certification'
import Circles from '../Circles'
import RateSkills from '../../components/rate-skill'
import PdfLogic from '../PdfLogic'
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

















const useStyles = theme => ({
  loadingContainer: {
    margin: 'auto',
    width: '80%',
    height: '80vh',
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    justifySelf: 'center',
  },
  homeLink: {
    // border: '1px solid blue',
    marginTop: '2rem',
    borderRadius: '8px', cursor: 'pointer',
    background: '#6e4e90',
    boxShadow: '8px 10px 10px 1px rgba(0,0,0,0.5)',
    '&:hover': {
      transform: 'translateY(-5px) scale(1.005) translateZ(0)',
      // boxShadow: '0 24px 36px rgba(0,0,0,0.11) 0 24px 46px gray',
    }
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row'
  },
})





class IndividualDashboard extends Component {

  // console.log('props before constructor - individualdashboard comp',props)
  constructor(props) {
    super(props)

    this.state = {
      user: {},
      data: {},
      showRolePopover: false,
      showRolePopoverr: false,
      showRolePopoverrr: false,
      showModal: false, // Add a state variable to control the modal

      // shouldShowModal: false,
      // setShouldShowModal: false,
    }
  }


  toggleModal = () => {
    this.setState((prevState) => ({
      showModal: !prevState.showModal,
    }));
  };




  async componentWillMount() {
    console.log('compwilmount')
    const spinner = await loadingController.create({
      spinner: 'crescent',
      message: 'Loading Score...',
    })

    await spinner.present()
    try {
      const user = await fire.auth().currentUser
      const railsUser = await new IndividualHttpService().where({
        firebase_uid: user.uid,
      })
      this.setState({ user: railsUser[0] })
      await this.fetchData(railsUser[0].id)
    } catch (e) {
      console.log(e)
      await spinner.dismiss()
      return
    }
    await spinner.dismiss()
  }
  async fetchData(individual_id) {
    const url = global.apiBaseUrl + '/skills/new?action=individual_dashboard'
    fetch(url, {
      method: 'POST',
      redirect: 'follow', // manual, *follow, error
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors',
      body: JSON.stringify({ individual_id: individual_id }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log("data", data)
        this.setState({ data: data })
      })
  }



  renderSegmentedContainer() {
    return [
      <IndividualSegmentedContainer
        marketData={this.state.data.strategy}
        roleData={this.state.data.roles}
        toolData={this.state.data.tools}
      />,
    ]
  }
  renderSocialMediaShare() {
    return (
      <>
        <HelmetMetaData></HelmetMetaData>
        <a
          className='socialShare'
          style={{
            fontSize: '25px',
          }}
        >
          Post Score
        </a>{' '}
        <div className='socialMediaPop' open={true} transition>
          {/*{' '}
          <FacebookShareButton
            url={`https://stacklr.com/individual-dashboard/${this.state.user.id}`}
            className='socialMediaButton'
          >
            <FacebookIcon size={36} />
          </FacebookShareButton>
          <TwitterShareButton
            url={`https://stacklr.com/individual-dashboard/${this.state.user.id}`}
            className='socialMediaButton'
          >
            <TwitterIcon size={36} />
          </TwitterShareButton>{' '}
    */}

          <LinkedinShareButton
            url={`https://stacklr.com/individual-dashboard/${this.state.user.id}`}
            separator=':: '
            className='socialMediaButton'
            style={{
              borderRadius: "20px"
            }}
          >
            <LinkedinIcon size={49} scroll={false} />
          </LinkedinShareButton>

         
        </div>
      
      </>
    )
  }


  
  

  renderCertificateShare() {
    return [
      <div>
        <LinkedInCertificate
          size={36}
          lang='en_US'
          name='Stacklr Score'
          certUrl={`https://stacklr.com/individual-dashboard/${this.state.user.id}`}
        />
      </div>,
    ]
  }

  renderName() {
    if (
      this.state.user.first_name === null ||
      this.state.user.last_name === null
    ) {
      return
    }
    return [
      <ion-item lines='none'>
        <ion-title
          style={{
            textAlign: 'left',
            fontSize: '32px',
            width: '50%',
            paddingLeft: '5px',
          }}
        >
          {this.state.user.first_name} {this.state.user.last_name}
        </ion-title>
      </ion-item>,
    ]
  }

  renderMaxRole(obj) {
    if (Object.keys(obj).length === 0) {
      return
    }
    return Object.keys(obj)
      .reduce((a, b) => (obj[a] > obj[b] ? a : b))
      .toUpperCase()
  }

  export =(type, name)=>{
    
    html2canvas(document.querySelector(`#capture`)).then(canvas => {
      let dataURL = canvas.toDataURL('image/png');

      if (type === 'pdf') {
        const pdf = new jsPDF({
          orientation: "landscape",
          unit: "in",
          format: [14, 10]
        });

        pdf.addImage(dataURL, 'PNG', .6, .6);
        pdf.save(`${name}.pdf`);

      } else if (type === 'png') {
        var link = document.createElement('a');
        link.download = `${name}.png`;
        link.href = dataURL;
        link.click();
      }
    });
 }

  

  exportPDF = () => {
    const input = document.getElementById("IndividualDashboard");
    html2canvas(input, { logging: true, letterRendering: 1, useCORS: true }).then(canvas => {
      const imgWidth = 208;
      const imgHeight = canvas.height * imgWidth / canvas.width;
      const imgData = canvas.toDataURL('img/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
      pdf.save("stacklrscorecard.pdf");
    });
  }



  render() {
    if (this.state.data === undefined || Object.keys(this.state.data).length === 0) {
      return [
        <div style={{ width: '100%', height: '100%', }}>
          {/* <h1>No data found... try again later</h1> */}
        </div>
      ]
    }


    // return [

    //   <div>
    //     <div className="IndividualDashboard">

    //       <header id="IndividualDashboard" className="IndividualDashboard-Header">

    //         <ion-list>
    //           <div className='float-container'>
    //             <div className='float-child'>{this.renderName()}</div>
    //             <div className='float-childd'>

    //               <p style={{}}>
    //                 <div className='socialMediaPoppers'>
    //                   {this.renderSocialMediaShare()}
    //                 </div>
    //               </p>
    //               <div className='socialMediaPoppers-2'>
    //                 <Link
    //                   to={`/individual-dashboard/${this.state.user.id}`}
    //                   style={{
    //                     color: 'inherit',
    //                     textDecoration: 'none',
    //                     fontSize: '25px',
    //                   }}
    //                 >
    //                   Post Certificate{' '}
    //                   <SocialIcon
    //                     style={{ size: '36' }}
    //                     url='https://linkedin.com/in/jaketrent'
    //                   />
    //                 </Link>
    //               </div>
    //             </div>

    //             <div className='float-children'>
    //               <ion-item lines='none'>
    //                 <ion-title
    //                   style={{
    //                     textAlign: 'center',
    //                     marginLeft: '45%',

    //                     fontSize: '32px',
    //                     // width: '40%',
    //                   }}
    //                 >
    //                   My Stacklr® Score:
    //                 </ion-title>
    //               </ion-item>
    //             </div>
    //           </div>
    //           <ion-item lines='none'>
    //             <ion-title
    //               id='bold-fonteee'
    //               style={{
    //                 textAlign: 'center',
    //                 marginLeft: '45%',
    //                 padding: '2px',
    //                 fontSize: '50px',
    //                 fontWeight: 'bold',

    //                 // color: '#000000',
    //               }}
    //             >
    //               {this.state.data.total_score}
    //               {this.state.data.best_categories}
    //               {this.renderMaxRole(this.state.data.roles)}
    //             </ion-title>
    //             {/* <ion-title
    //           id='bold-fonteee'
    //           style={{
    //             marginLeft: '-8%',
    //             padding: 'none',

    //             fontSize: '50px',
    //             color: '#8a65b8',
    //           }}
    //         >
    //           {this.state.data.best_categories}
    //         </ion-title>
    //         <ion-title
    //           id='bold-fonteee'
    //           style={{
    //             marginLeft: '-20%',
    //             padding: 'none',

    //             fontSize: '50px',
    //             color: '#4ec251',
    //           }}
    //         >
    //           {this.renderMaxRole(this.state.data.roles)}
    //         </ion-title> */}
            
    //           </ion-item>

          

    //         </ion-list>
    //         <ion-row>

    //         <ion-title id='main-card-center'
    //           >
    //             {/* <ion-item lines='none' style={{
    //               // textAlign: 'center',
    //             }}> */}
                

    //             <button

    //               id='main-card-stakkid-button-3'
    //               onClick={() => (window.location.href = '/initial')}
    //               style={{ borderRadius: '20px', padding: '10px' }}
    //             >
    //               Update My Score
    //             </button>

    //             <button id='main-card-stakkid-button-5'
    //               style={{ borderRadius: '20px', padding: '10px'}}
    //               onClick={this.exportPDF}>Download My Score</button>

    //             {/* </ion-item> */}


    //           </ion-title>
    //           </ion-row>

    //         <ion-grid
    //           style={{
    //             display: 'flex',
    //             flexDirection: 'column',
    //             paddingTop: '72px',

    //           }}
    //         >
    //           <ion-row
    //             style={{
    //             }}
    //           >
    //             <ion-col className='Chart-03'  
    //             style={{ 
    //               paddingLeft: '6px',
    //              marginLeft: '0' 

    //             }}>
    //               <IonItem lines='none'>{this.renderSegmentedContainer()}</IonItem>
    //             </ion-col>
    //             <ion-col className='Chart-01'>
    //               <div style={{ marginLeft: '0' }}>
    //                 <RadialChart data={this.state.data.category} />
    //               </div>
    //             </ion-col>

    //             <ion-col className='Chart-02'>
    //               <HeatMap data={this.state.data.category_strategy} />
    //             </ion-col>             
    //           </ion-row>
    //         </ion-grid>
    //         <hr />
    //         <br />
    //         <br />

    //         <Circles />,
    //         <RateSkills isCustom />,
    //       </header>
    //     </div>
    //   </div>,
    //   <br />,
    //   <br />,




    //   // this.renderSegmentedContainer(),


    //   <>
    //     <div>
    //       <hr />
    //       <p
    //         style={{
    //           fontSize: '18px',
    //           paddingLeft: '15px',
    //           paddingRight: '15px',
    //           paddingBottom: '15px',
    //         }}
    //       >
    //         <span style={{ fontSize: '36px' }}>Next step for you?</span>
    //         <br />
    //         <span style={{ fontSize: '26px' }}>1.</span> When you look at your
    //         pie chart with your category strengths and your tool grid, what
    //         mastery jumps out? Is there a weaker area that you need to advance
    //         in?
    //         <br />
    //         <span style={{ fontSize: '26px' }}>2.</span> What are your career
    //         goals? Do you want to go deep in one technology or category (see
    //         Beast and Crusher definitions below)? Do you want to go broad across
    //         categories? Do you want to some of each? Your answer drives how you
    //         plan your future technology.
    //         <br />
    //         <span style={{ fontSize: '26px' }}>3.</span> If your company were to
    //         do a Stacklr with tool inventory, how does your score overlay
    //         theirs? Do you have strengths in their tool choices for digital
    //         transformation? Do they know all of your strengths? Are they
    //         leveraging yours?
    //         <br />
    //         <span style={{ fontSize: '26px', paddingBottom: '25px' }}>
    //           4.
    //         </span>{' '}
    //         Share your score with the world on LinkedIn.
    //       </p>
    //     </div>
    //     <Popups />


    //   </>,
    // ]

    

    return [
      <div>

{this.state.showModal && (
        <IonPopover
          isOpen={this.state.showModal}
          onDidDismiss={this.toggleModal}
          cssClass='dashboard-capture-modal'
        >
          <IonCard>
            <IonCardHeader>
              <IonLabel>Capture Dashboard</IonLabel>
            </IonCardHeader>
            <IonCardContent>
              <p>Instructions for capturing the dashboard:</p>
              <ul>
                <li>
                  <strong>Mac:</strong> Press Shift + Command + 4 and drag to
                  select the area you want to capture. The screenshot will be
                  saved to your desktop.
                </li>
                <li>
                  <strong>PC:</strong> Press the PrtScn (Print Screen) key to
                  capture the entire screen, or use Snipping Tool to select a
                  specific area. You can paste the screenshot into an image
                  editor and save it.
                </li>
              </ul>
            </IonCardContent>
          </IonCard>
        </IonPopover>
      )}
        <ion-list>
          <div className='float-container'>
            {/* <div className='float-child'>{this.renderName()}</div> */}
            <div className='float-childd'>
              <p style={{}}>
                <div className='socialMediaPoppers'>
                  {this.renderSocialMediaShare()}
                </div>
              </p>
              <div className='socialMediaPoppers-2'>
              <Link
                to={`/individual-dashboard/${this.state.user.id}`}
                style={{
                  // marginRight: '20px',
                  color: 'inherit',
                  textDecoration: 'none',
                  fontSize: '25px',
                }}
              >
                Post Certificate{' '}
                <SocialIcon
                  style={{ size: '36' }}
                  url='https://linkedin.com/in/jaketrent'
                />
              </Link>
              </div>
            </div>

            <div className='float-children'>
              <ion-item lines='none'>
                <ion-title
                  style={{
                    textAlign: 'center',
                    fontSize: '32px',
                    width: '40%',
                    marginLeft: '45%',

                  }}
                >
                  My Stacklr® Score:
                </ion-title>
              </ion-item>
            </div>
          </div>
          <ion-item lines='none'>
            <ion-title
              id='bold-fonteee'
              style={{
                textAlign: 'center',
                marginLeft: '45%',
                padding: '2px',
                fontSize: '50px',
                fontWeight: 'bold',

                // color: '#000000',
              }}
            >
              {this.state.data.total_score}
              {this.state.data.best_categories}
              {this.renderMaxRole(this.state.data.roles)}
            </ion-title>
            {/* <ion-title
              id='bold-fonteee'
              style={{
                marginLeft: '-8%',
                padding: 'none',

                fontSize: '50px',
                color: '#8a65b8',
              }}
            >
              {this.state.data.best_categories}
            </ion-title>
            <ion-title
              id='bold-fonteee'
              style={{
                marginLeft: '-20%',
                padding: 'none',

                fontSize: '50px',
                color: '#4ec251',
              }}
            >
              {this.renderMaxRole(this.state.data.roles)}
            </ion-title> */}
          </ion-item>

          <ion-title style={{}}>
            <ion-item lines='none'  style={{
                  // textAlign: 'center',
                }}>
                
              <button
               
                id='main-card-stakkid-button-3-1'
                onClick={() => (window.location.href = '/initial')}
              >
                Update My Score
              </button>

              
            </ion-item>

            <button
                id='main-card-stakkid-button-3-1-2'
                onClick={this.toggleModal}
       
      >
        Capture Dashboard
      </button>

          </ion-title>
        </ion-list>

        <ion-grid
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <ion-row
            style={{
              paddingTop: '32px',
            }}
          >
            <ion-col className='Chart-03'>
              <IonItem lines='none'>{this.renderSegmentedContainer()}</IonItem>
            </ion-col>
            <ion-col className='Chart-01'>
              <div style={{ marginLeft: '0' }}>
                <RadialChart data={this.state.data.category} />
              </div>
            </ion-col>

            <ion-col className='Chart-02'>
              <div>
                <HeatMap data={this.state.data.category_strategy} />
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>,
      <br />,
      <br />,

      <Circles />,
      <RateSkills isCustom/>,

      // this.renderSegmentedContainer(),

      <>
        <div>
          <hr /> 
          <p
            style={{
              fontSize: '18px',
              paddingLeft: '15px',
              paddingRight: '15px',
              paddingBottom: '15px',
            }}
          >
            <span style={{ fontSize: '36px' }}>Next step for you?</span>
            <br />
            <span style={{ fontSize: '26px' }}>1.</span> When you look at your
            pie chart with your category strengths and your tool grid, what
            mastery jumps out? Is there a weaker area that you need to advance
            in?
            <br />
            <span style={{ fontSize: '26px' }}>2.</span> What are your career
            goals? Do you want to go deep in one technology or category (see
            Beast and Crusher definitions below)? Do you want to go broad across
            categories? Do you want to some of each? Your answer drives how you
            plan your future technology.
            <br />
            <span style={{ fontSize: '26px' }}>3.</span> If your company were to
            do a Stacklr with tool inventory, how does your score overlay
            theirs? Do you have strengths in their tool choices for digital
            transformation? Do they know all of your strengths? Are they
            leveraging yours?
            <br />
            <span style={{ fontSize: '26px', paddingBottom: '25px' }}>
              4.
            </span>{' '}
            Share your score with the world on LinkedIn.
          </p>
        </div>
        <Popups />
      </>,
    ]

    
  }
}

export default withStyles(useStyles)(IndividualDashboard)
