import React, { useState, useEffect, useMemo } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import firebase from 'firebase';
import { FixedSizeList as List } from 'react-window';
import {
  IonButton,
  IonPopover,
  IonRange,
  IonCard,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonCardContent,
  IonText,
  IonItem,
  IonCardHeader,
  IonCheckbox,
  IonLabel,
  IonList,
  IonListHeader,
  IonSelect,
  IonSelectOption,
  IonPage,
  IonItemDivider,
  IonSearchbar,
} from '@ionic/react';
import { tools } from '../data/toolNamesAndCategories';
import { UiSearchService } from '../services/ui-search.service';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteIcon from '@material-ui/icons/Delete';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
                                                                                                                                                                                                                                                                                                                                                                                                                                                                   
import {
  addSkillAction,
  loadScoreAction,
  loadSkillsAction,
  loadAllAvgRatingsAction,
  removeSkillAction,
  updateSkillAction,
  loadTopSkillsAction,
} from '../actions';
import {
  UPDATE_INITIAL_ASSESSMENT_SCORE,
  UPDATE_INITIAL_ASSESSMENT_SKILLS,
} from '../constants';
import { SkillHttpService } from '../http_services/skill.http_service';
import { CrudHttpService, update } from '../http_services/crud.http_service';
import '../App.css';
import './add-tool.css';
import RatingStar from './Rating';
import { SkillRating } from './Rating';
import Joyride from 'react-joyride';
import { Steps } from 'intro.js-react';
import 'intro.js/introjs.css';




let j;
for (j = 0; j < tools.length; j++) {
  tools[j].averageRating = 0;
}
const allTools = tools;

const UpdateTool = ({
  skill = {
    id: '',
    toolName: '',
    job: true,
    role: '',
    score: 1,
  },
  category
}) => {
  const dispatch = useDispatch();

  const averageRatings = useSelector((store) => store.averageRatings);

  const addAvgRatingsToTools = () => {
    let j;
    for (j = 0; j < allTools.length; j++) {
      if (
        averageRatings &&
        averageRatings.hasOwnProperty(allTools[j].toolName)
      ) {
        allTools[j].averageRating = averageRatings[allTools[j].toolName];
      }
    }
    return allTools;
  };

  const allSkills = addAvgRatingsToTools();

  const filterTools = () => {
    return [allSkills].filter((tool) => {
      return tool.category === skill.category;
    });
  };


 
  

  const [toolId, setToolId] = useState(null);
  const [toolName, setToolName] = useState(skill.toolName);
  const [rating, setRating] = useState(skill.rating);
  const [role, setRole] = useState(skill.role);
  const [job, setJob] = useState(skill.job);

  const [strategy, setStrategy] = useState(skill.strategy);
  // const [category, setCategory] = useState(skill.category);
  const [score, setScore] = useState(skill.score);
  const [isTopSkill, setIsTopSkill] = useState(skill.isTopSkill);
  const [results, setResults] = useState(filterTools());
  const [searchDisabled, setSearchDisabled] = useState(true);
  const [showJobPopover, setShowJobPopover] = useState(false);

  const [showRolePopover, setShowRolePopover] = useState(false);
  const [showStrategyPopover, setShowStrategyPopover] = useState(false);
  const [showScorePopover, setShowScorePopover] = useState(false);
  const [showRatePopover, setShowRatePopover] = useState(false);
  const [showRatingPopover, setShowRatingPopover] = useState(false);

  useEffect(() => {
    if (skill.toolName != undefined) {
      setToolId(skill.id);
      setToolName(skill.toolName);
      setJob(skill.job);
      setRating(skill.rating);
      setRole(skill.role);
      setScore(skill.score);
      setIsTopSkill(skill.isTopSkill);
      setStrategy(skill.strategy);
    }
  }, [skill]);

  const init = () => {
     setToolName('');
     setJob(true);
     setRating(0);
     setRole('');
     setToolId(null);
     setScore(0);
     setStrategy('');
  }

  const search = (e) => {
    const val = e.target.value;
    setToolName(val);
    setSearchDisabled(false);
    const results = UiSearchService.search(tools, val, ['toolName']);
    setResults(results);
    console.log(setResults(results))

  };

  const renderSearch = () => {
    if (searchDisabled === true) {
      return;
    }

    // console.log('renderSearch', skill);

    return results.slice(0, 883).map((result, index) => {
      // if ( this.stat[result.toolName] ) {
      //   result.averageRating =
      // }
      return [
        <IonItem
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
          className="addToolPopover"
          button={true}
          key={`${result - index}`}
          onClick={(e) => {
            setToolName(result.toolName);
          }}
        >
          {result.toolName}&nbsp;
          <SkillRating
            className="search-result-rating"
            rating={result.averageRating}
          />
        </IonItem>,
      ];
    });
  };

  const renderToolIfSelected = () => {
    if (toolName !== '') {
      return (
        <IonItem lines="none">
          <IonLabel>
            <h2>{toolName}</h2>
          </IonLabel>
          <ClearIcon
            onClick={(e) => {
              setToolName('');
              // setJob('');
              setRole('');
              setRating(0);
              setScore(0);
            }}
          />
        </IonItem>
      );
    }
  };
  const remove = () => {
    // this.props.removeSkill(this.props.skill)
    const response = dispatch(removeSkillAction(skill));
    response.then((res) => {
      init();
    });
  }
  const renderRateSkillIfTopSkill = () => {
    if (isTopSkill == false) {
      return;
    }
    return (
      <>
        <IonItem key={toolName} lines="none">
          <InfoIcon
            onMouseOver={(e) => {
              setShowRatingPopover(true);
            }}
          />
          <IonPopover
            style={{ padding: '20px' }}
            cssClass="addTooltipPopover"
            isOpen={showRatingPopover}
            onDidDismiss={(e) => {
              setShowRatingPopover(false);
            }}
          >
            Rate your overall experience with this tool.
            <RatingStar name="rate" />= Poor
            <RatingStar name="rating" />= Excellent
          </IonPopover>
          <IonLabel>Rate your experience with this tool</IonLabel>
        </IonItem>
        <IonItem
          style={{ overflow: 'visible' }}
          key={`skill${toolName}`}
          lines="none"
        >
          <IonRange
            onIonChange={(e) => updateRating(e)}
            id="rating"
            value={rating}
            onClick={(e) => {
              updateRating(e);
            }}
            // value={this.remove()}
            pin={true}
            min={1}
            max={5}
            ticks={true}
            snaps={true}
          >
            <IonLabel slot="start">1</IonLabel>
            <IonLabel slot="end">5</IonLabel>
          </IonRange>
        </IonItem>
        <IonItem key={`skills${toolName}`} lines="none">
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <RatingStar name="rate" />
            <RatingStar name="rating" />
          </div>
        </IonItem>
      </>
    );
  };

  const updateScore = (e) => {
    setScore(e.target.value);
  };

  async function submit() {
    const updatedSkill = {
      category: category,
      strategy: strategy,
      tool_name: toolName,
      job: job,
      role: role,
      rating: rating,
      score: score,
    };
    const response = dispatch(addSkillAction(updatedSkill));
     response.then((res) => {
      init();
     });
  }


  const updateJob = (e) => {
    setJob(e.target.value);
  };

  const updateRole = (e) => {
    setRole(e.target.value);
  };

  const updateStrategy = (e) => {
    setStrategy(e.target.value);
  };

  const updateRating = (e) => {
    setRating(e.target.value);
  };

  async function submitUpdate() {
    const updatedSkill = {
      category: category,
      strategy: strategy,
      tool_name: toolName,
      job: job,
      role: role,
      rating: rating,
      score: score,
      id: skill.id,
    };
    dispatch(updateSkillAction(updatedSkill));
    setTimeout(
      function () {
        dispatch(loadSkillsAction);
        dispatch(loadScoreAction);
      },
      2000 //quick fix to avoid race condition
    );
  }

  return (
    <>
 
        
      <IonCard className="IonCardWrap" style={{ padding: '0px 40px' }}>
        <IonCardHeader>
          <IonToolbar>
            <IonButtons slot="end">
              {toolId && (
                <IonButton
                  onClick={(e) => {
                    remove();
                    // dispatch(removeSkillAction(skill));
                    // dispatch(loadSkillsAction);
                    // dispatch(loadScoreAction);
                  }}
                >
                  <DeleteIcon />
                </IonButton>
              )}{' '}
            </IonButtons>

            <IonTitle style={{ padding: '0px 20px' }}> Add Tool</IonTitle>
          </IonToolbar>
        </IonCardHeader>
        <IonCardContent className="IonCardStuff">
          <IonList>
            <IonItem  id="tool-list" lines="none">
              <IonSearchbar
                debounce={500}
                id="tool-name"
                placeholder="Select a Tool"
                value={toolName}
                onIonFocus={(e) => search(e)}
                onIonChange={(e) => search(e)}
              />
            </IonItem>
            <div style={{ maxHeight: '300px', overflowY: 'scroll' }}>
              {renderSearch()}
            </div>
            {renderToolIfSelected()}


            <IonItem lines="none">
              <InfoIcon
                onMouseOver={(e) => {
                  setShowJobPopover(true);
                }}
              />
              <IonPopover
                style={{ padding: '20px' }}
                cssClass="addTooltipPopover"
                isOpen={showJobPopover}
                onDidDismiss={(e) => {
                  setShowJobPopover(false);
                }}
              >
               If you use this tool on your current job.
              </IonPopover>
              <IonLabel>I use this tool on my current job</IonLabel>
            </IonItem>
            <IonItem>
              <IonSelect
                // interface="popover"
                id="job"
                value={job}
                placeholder="Select Answer"
                onIonChange={(e) => updateJob(e)}
                className="custom-role"
              >

             <IonSelectOption value={true}>Yes</IonSelectOption>
             <IonSelectOption value={false}>No</IonSelectOption>
               
              </IonSelect>
            </IonItem>



            <IonItem lines="none">
              <InfoIcon
                onMouseOver={(e) => {
                  setShowRolePopover(true);
                }}
              />
              <IonPopover
                style={{ padding: '20px' }}
                cssClass="addTooltipPopover"
                isOpen={showRolePopover}
                onDidDismiss={(e) => {
                  setShowRolePopover(false);
                }}
              >
                This is the role you play in using the tool. It could be the
                role in your current position or how you have used it in the
                past.
                <b>Planner</b> – Project management expert in campaign planning, managing, and optimal timeline integrations. MarTech stack Influencer.

<b>Analyst</b> – Analyst responsible for dashboard kpi’s, campaign ROI, trends, analysis and optimization. MarTech stack Influencer.

<b>User</b> – Any digital marketer with a license to the platform for execution insight or analysis

<b>Leader</b> – Director level to CMO. Overall budget responsibility. Platform/vendor decision maker.

<b>Technologist</b> – Resident expert on data integrations, tool and platform optimization, and Influencer to overall Martech stack.
              </IonPopover>
              <IonLabel>My current role</IonLabel>
            </IonItem>
            <IonItem>
              <IonSelect
                // interface="popover"                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        
                id="role"
                value={role}
                placeholder="Select Role"
                onIonChange={(e) => updateRole(e)}
                className="custom-role"
              >

             <IonSelectOption value="p">Planner</IonSelectOption>
               
                <IonSelectOption value="a">Analyst</IonSelectOption>
                <IonSelectOption value="u">User</IonSelectOption>
                <IonSelectOption value="l">Leader</IonSelectOption>
                <IonSelectOption value="i">Technologist</IonSelectOption>
              </IonSelect>
            </IonItem>
            <IonItem lines="none">
              <InfoIcon
                onMouseOver={(e) => {
                  setShowStrategyPopover(true);
                }}
              />
              <IonPopover
                style={{ padding: '20px' }}
                cssClass="addTooltipPopover"
                isOpen={showStrategyPopover}
                onDidDismiss={(e) => {
                  setShowStrategyPopover(false);
                }}
              >
                This is the strategy in using the tool.
              </IonPopover>
              <IonLabel>My strategy that this tool supported</IonLabel>
            </IonItem>
            

            <IonItem>
              <IonSelect
                // interface="popover"                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        
                id="strategy"
                value={strategy}
                placeholder="Select Strategy"
                onIonChange={(e) => updateStrategy(e)}
                className="custom-role"
              >
                <IonSelectOption value="Attraction">Attraction</IonSelectOption>
                <IonSelectOption value="Engagement">Engagement</IonSelectOption>
                <IonSelectOption className="role3" value="Analysis & Optimization">
                  Analysis & 
                  Optimization
                </IonSelectOption>
              </IonSelect>
            </IonItem>


            



            <IonItem lines="none">
              <InfoIcon
                onMouseOver={(e) => {
                  setShowScorePopover(true);
                }}
              />
              <IonPopover
                style={{ padding: '20px' }}
                cssClass="addTooltipPopover"
                isOpen={showScorePopover}
                onDidDismiss={(e) => {
                  setShowScorePopover(false);
                }}
              >
                Rank your level of competency with tool with 1 being a novice, 3
                being intermediate, and 5 being an expert.
              </IonPopover>
              <IonLabel>My expertise level with tool</IonLabel>
            </IonItem>
            <IonItem style={{ overflow: 'visible' }} lines="none">
              <IonRange
                onIonChange={(e) => updateScore(e)}
                id="score"
                value={score}
                onClick={(e) => {
                  updateScore(e);
                }}
                // value={this.remove()}
                pin={true}
                min={1}
                max={5}
                ticks={true}
                snaps={true}
              >
                <IonLabel slot="start">1</IonLabel>
                <IonLabel slot="end">5</IonLabel>
              </IonRange>
            </IonItem>
            <IonItem>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <div>Novice</div>
                <div>Expert</div>
              </div>
            </IonItem>

            {renderRateSkillIfTopSkill()}
          </IonList>

          {(toolId == '' || toolId === null) && (
            <IonButton
              onIonChange={(e) => updateScore(e)}
              expand="half"
              // shape='round'
              size="large"
              style={{
                width: '60px',
                marginLeft: '45%',
                marginTop: '0%',
                '--background': '#911af8',
                '--border-radius': '30px',
                fontSize: '12px',
              }}
              id="save_btn"
              onClick={() => submit()}
            >
              SAVE
            </IonButton>
          )}

          {toolId != '' && toolId !== null && (
            <IonButton
              onIonChange={(e) => updateScore(e)}
              expand="half"
              // shape='round'
              size="large"
              style={{
                width: '70px',
                marginLeft: '35%',
                marginTop: '5%',
                '--background': '#911af8',
                '--border-radius': '30px',
                fontSize: '12px',
              }}
              onClick={() => submitUpdate()}
            >
              UPDATE
            </IonButton>
          )}
        </IonCardContent>
      </IonCard>
     
    </>
    
  );
};

export default UpdateTool;
