import firebase from 'firebase'
import { CATEGORY_OBJECTS, SET_SCORE, SET_SKILLS, SET_RUNNING_SCORE, SET_TOP_SKILLS, SET_ALL_AVERAGES } from './constants'
import { IndividualHttpService } from './http_services/individual.http_service'
import { SkillHttpService } from './http_services/skill.http_service'

function addSkillAction(skill) {
  return async function addSkill(dispatch, getState) {
    const user = await firebase.auth().currentUser
    const railsUser = await new IndividualHttpService().where({
      firebase_uid: user.uid,
    })
    skill['individual_id'] = railsUser && railsUser[0].id
    const skillCreateResponse = await new SkillHttpService().create(skill)
    dispatch(loadSkillsAction)
    dispatch(loadScoreAction);
  }
}

function updateSkillAction(skill) {
  console.log('updateSkillAction called', skill)
  return async function updateSkill(dispatch, getState) {
    const user = await firebase.auth().currentUser
    const railsUser = await new IndividualHttpService().where({
      firebase_uid: user.uid,
    })
    skill['individual_id'] = railsUser && railsUser[0].id
    const skillCreateResponse = await new SkillHttpService().update(skill)
  }
}

function removeSkillAction(skill) {
  return async function removeSkill(dispatch, getState) {
    const response = await new SkillHttpService().delete(skill.id)
     dispatch(loadSkillsAction);
     dispatch(loadScoreAction);
  }
}

async function loadScoreAction(dispatch, getState) {
  const user = await firebase.auth().currentUser

  const railsUser = await new IndividualHttpService().where({
    firebase_uid: user.uid,
  })

  const getTotalScoreResponse = await fetch(
    global.apiBaseUrl + `/individuals/${railsUser && railsUser[0].id }`,
    {
      method: 'GET',
      redirect: 'follow', // manual, *follow, error
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors',
    }
  )

  const totalScore = await getTotalScoreResponse.json()

  dispatch({ type: SET_SCORE, value: totalScore.total_score })
}

async function loadScoreActionCurrent(dispatch, getState) {
  const user = await firebase.auth().currentUser


  const railsUser = await new IndividualHttpService().where({
    firebase_uid: user.uid,
  })

  const getTotalScoreResponseCurrent = await fetch(
    global.apiBaseUrl + 'skills/new?action=total_score',
    {
      method: 'POST',
      redirect: 'follow', // manual, *follow, error
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors',
      body: JSON.stringify({ individual_id: railsUser && railsUser[0].id })
    }
  )

  const totalScore = await getTotalScoreResponseCurrent.json()

  dispatch({ type: SET_RUNNING_SCORE, value: totalScore })
}
async function loadAllAvgRatingsAction(dispatch) {

  const user = await firebase.auth().currentUser 

  if (user) {
     const railsUser = await new IndividualHttpService().where({
       firebase_uid: user.uid,
     });

     const getAllAvgRatings = await fetch(
       global.apiBaseUrl + '/skills/new?action=all_avg_ratings',
       {
         method: 'POST',
         redirect: 'follow', // manual, *follow, error
         headers: { 'Content-Type': 'application/json' },
         mode: 'cors',
         body: JSON.stringify({ individual_id: railsUser && railsUser[0].id }),
       }
     );
    
    const allAvgRatings = await getAllAvgRatings.json();
    dispatch({ type: SET_ALL_AVERAGES, value: allAvgRatings });

  } else {

     const getAllAvgRatings = await fetch(
       global.apiBaseUrl + '/skills/new?action=all_avg_ratings',
       {
         method: 'POST',
         redirect: 'follow', // manual, *follow, error
         headers: { 'Content-Type': 'application/json' },
         mode: 'cors',
         body: JSON.stringify({ individual_id: null }),
       }
     );
    
    const allAvgRatings = await getAllAvgRatings.json();
    dispatch({ type: SET_ALL_AVERAGES, value: allAvgRatings });
  }
}
async function loadTopSkillsAction(dispatch) {
  const numberOfSkills = 3
  const user = await firebase.auth().currentUser
 
  const railsUser = await new IndividualHttpService().where({
    firebase_uid: user.uid,
  })
  
  const getTopSkillsResponse = await fetch(
    global.apiBaseUrl + '/skills/new?action=individual_top_skills_by_category',
    {
      method: 'POST',
      redirect: 'follow', // manual, *follow, error
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors',
      body: JSON.stringify({ individual_id: railsUser && railsUser[0].id,
      number_of_skills: numberOfSkills }),
    }
  )
  const topSkillsByCategoryArray = await getTopSkillsResponse.json()
  
  let topSkillsObject = {}
    
  Object.keys(topSkillsByCategoryArray).map((category) => {
    let skillsObject = []
    
    topSkillsByCategoryArray[category].map((skillArray, index) => {
      let skill= {} 
        skill.id = skillArray[4]
        skill.toolName=skillArray[0]
        skill.score=skillArray[1]    
        skill.rating=skillArray[2]
        skill.strategy=skillArray[3]
        skill.averageRating=skillArray[5]
        skill.rank= index + 1
      
      skillsObject.push(skill) 
    })
    topSkillsObject[category] = skillsObject
  })
  dispatch({ type: SET_TOP_SKILLS, value: topSkillsObject})
}
async function loadSkillsAction(dispatch, getState) {
  const user = await firebase.auth().currentUser

  const railsUser = await new IndividualHttpService().where({
    firebase_uid: user.uid,
  })
  
  const getAllSkillsResponse = await fetch(
    global.apiBaseUrl + '/skills/new?action=all_skills',
    {
      method: 'POST',
      redirect: 'follow', // manual, *follow, error
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors',
      body: JSON.stringify({ individual_id: railsUser && railsUser[0].id }),
    }
  )
  
  const allSkillsPreKeyChange = await getAllSkillsResponse.json()

  const allSkills =
    allSkillsPreKeyChange &&
    allSkillsPreKeyChange.map((skill) => {
      skill['toolName'] = skill['tool_name']
      return skill
    })

  let localSortedSkills = {}

  CATEGORY_OBJECTS.map((category) => {
    if (category.categoryTitle) {
      // localSortedSkills[category.categoryName] = {
      //   Attraction: [],
      //   Engagement: [],
      //   'Analysis & Optimization': [],
      // }

      localSortedSkills[category.categoryName] = []
    }
  })

  if (allSkills) {
    allSkills.forEach((skill) => {
      // if (!localSortedSkills[skill.category]) {
      //   localSortedSkills[skill.category] = {}
      // }

      // if (!localSortedSkills[skill.category][skill.strategy]) {
      //   localSortedSkills[skill.category][skill.strategy] = []
      // }

      // localSortedSkills[skill.category][skill.strategy].push(skill)

      if (!Array.isArray(localSortedSkills[skill.category])) {
        localSortedSkills[skill.category] = [];
      }

      // if (!localSortedSkills[skill.category][skill.strategy]) {
      //   localSortedSkills[skill.category][skill.strategy] = []
      // }

      localSortedSkills[skill.category].push(skill)

    })
  }

  dispatch({ type: SET_SKILLS, value: localSortedSkills })
}

export {
  loadTopSkillsAction,
  loadAllAvgRatingsAction,
  loadScoreAction,
  loadScoreActionCurrent,
  loadSkillsAction,
  removeSkillAction,
  addSkillAction,
  updateSkillAction,
}