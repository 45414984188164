import React from 'react'
import {
  YAxis,
  HorizontalBarSeries,
  VerticalBarSeries,
  Borders,
  FlexibleXYPlot,
  XAxis,
} from 'react-vis'
import './barchart.css'
import { PlatformService } from '../../services/platform.service'

class Barchart extends React.Component {
  createLabels() {
    if (this.props.data[0].length === undefined) {
      const data = this.props.data.map((obj) => {
        return { ...obj, label: obj.x.toString() }
      })
      return [data]
    } else {
      return this.props.data.map((arr) => {
        return arr.map((obj) => {
          return { ...obj, label: obj.x.toString() }
        })
      })
    }
  }

  renderHorizontalBars() {
    return this.createLabels().map((arr) => {
      return [
        <HorizontalBarSeries
          animation
          data={arr}
          color='gray'
          fill='#2A428C'
        />,
      ]
    })
  }

  renderMobile() {
    return [
      <FlexibleXYPlot
        id='bar-plot'
        height={400}
        width={350}
        yType='ordinal'
        style={{
          overflow: 'visible',
          paddingLeft: '50px',
          paddingRight: '50px',
        }}
        animation={true}
      >
        <YAxis />
        <XAxis
          tickValues={[...Array(this.props.ticks).keys()].map((x) => ++x)}
        />
        {this.renderHorizontalBars()}
      </FlexibleXYPlot>,
    ]
  }

  render() {
    if (PlatformService.mobile()) {
      return this.renderMobile()
    }
    return (
      <div>
        <FlexibleXYPlot
          id='bar-plot'
          height={350}
          width={300}
          yType='ordinal'
          style={{
            overflow: 'visible',
            paddingLeft: '60px',
          }}
          animation={true}
        >
          <YAxis />
          {/* <XAxis /> */}
          {/* // <XAxis
          //   tickValues={[...Array(this.props.ticks).keys()].map((x) => ++x)}
          // /> */}
          {this.renderHorizontalBars()}
          {/*<LabelSeries*/}
          {/*    animation*/}
          {/*    data={data}*/}
          {/*    labelAnchorX='text-after-edge'*/}
          {/*    labelAnchorY='middle'*/}
          {/*/>*/}
        </FlexibleXYPlot>
      </div>
    )
  }
}
export default Barchart

