import React from 'react';

export default class TeamProfile extends React.Component {
    constructor(){
        super()

        this.state = {
            team: '',
            company: ''
        }
    };

    handleChange(event){
        this.setState({
            [event.target.name]: event.target.value
        })
    };

    render(){
        return(
            <div className="page-padding">
                <h1>Team Profile</h1>
                <hr />
                <h4>Team Admin</h4>
                <p>stakkiduser@example.com</p>
                <button id="disabled-stakkid-button" >
                    Edit
                </button>
                <button id="disabled-stakkid-button" >
                    Update Password
                </button>
                <form>
                    <label id="profile-text">
                        Team
                        <br />
                        <input
                            id="profile-input"
                            type="text"
                            name="team"
                            disabled="true"
                            style={{backgroundColor: 'lightgray'}}
                            value={this.state.team}
                            onChange={(event) => this.handleChange(event)}
                        />
                        <button id="disabled-stakkid-button" disabled="true">
                            Edit
                        </button>
                    </label>
                    <br />
                    <label id="profile-text">
                        Company
                        <br />
                        <input
                            id="profile-input"
                            type="text"
                            name="company"
                            disabled="true"
                            style={{backgroundColor: 'lightgray'}}
                            value={this.state.company}
                            onChange={(event) => this.handleChange(event)}
                        />
                        <button id="disabled-profile-button" disabled="true">
                            <svg class="bi bi-x" width="3em" height="3em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" d="M11.854 4.146a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708-.708l7-7a.5.5 0 0 1 .708 0z"/>
                                <path fill-rule="evenodd" d="M4.146 4.146a.5.5 0 0 0 0 .708l7 7a.5.5 0 0 0 .708-.708l-7-7a.5.5 0 0 0-.708 0z"/>
                            </svg>
                        </button>
                    </label>
                    <br />
                    <input id="main-stakkid-button" type="submit" value="Save" />
                </form>
            </div>
        )
    }
};