import React, { useRef } from "react";
import Dropdown from 'react-bootstrap/Dropdown';

// import { Dropdown } from 'react-bootstrap/Dropdown';
import { Link } from 'react-router-dom';
import { Scrollbar } from "smooth-scrollbar-react";


function Selector() {



    return (
        <Dropdown autoClose={true} >
            <Dropdown.Toggle style={{ marginLeft: "5%", marginTop: "8px", fontFamily: "DM Sans", backgroundColor: "#FF0000", borderRadius: "20px", fontWeight: "bolder" }}
                variant="success" id="dropdown-basic" size="lg"
            >
                Find a Martech software
            </Dropdown.Toggle>


                <Dropdown.Menu  style={{ marginLeft: "95%", height: "500px", overflowY: "scroll" }}>

                    <Dropdown.Item href="/software/3">33Across</Dropdown.Item>
                    <Dropdown.Item href="/software/4">Abyssale</Dropdown.Item>
                    <Dropdown.Item href="/software/5">ACA Network</Dropdown.Item>
                    <Dropdown.Item href="/software/6">Acuity</Dropdown.Item>
                    <Dropdown.Item href="/software/8">Adacado</Dropdown.Item>
                    <Dropdown.Item href="/software/10">Adaptly</Dropdown.Item>
                    <Dropdown.Item href="/software/7">Ad Reform</Dropdown.Item>
                    <Dropdown.Item href="/software/36">Adobe Advertising</Dropdown.Item>
                    <Dropdown.Item href="/software/40">Adobe After Effects</Dropdown.Item>
                    <Dropdown.Item href="/software/30">Adobe Analytics</Dropdown.Item>
                    <Dropdown.Item href="/software/33">Adobe Audience Manager</Dropdown.Item>
                    <Dropdown.Item href="/software/35">Adobe Campaign</Dropdown.Item>
                    <Dropdown.Item href="/software/910">Adobe Commerce</Dropdown.Item>
                    <Dropdown.Item href="/software/31">Adobe Customer Journey</Dropdown.Item> 
                    <Dropdown.Item href="/software/29">Adobe Experience Manager</Dropdown.Item>
                    <Dropdown.Item href="/software/39">Adobe Illustrator</Dropdown.Item>
                    <Dropdown.Item href="/software/42">Adobe InDesign</Dropdown.Item>
                    <Dropdown.Item href="/software/38">Adobe Photoshop</Dropdown.Item>
                    <Dropdown.Item href="/software/41">Adobe Premiere</Dropdown.Item>
                    <Dropdown.Item href="/software/32">Adobe Real Time CDP</Dropdown.Item>
                    <Dropdown.Item href="/software/46">Adobe Rush</Dropdown.Item>
                    <Dropdown.Item href="/software/44">Adobe Spark</Dropdown.Item>
                    <Dropdown.Item href="/software/43">Adobe Substance</Dropdown.Item>
                    <Dropdown.Item href="/software/34">Adobe Target</Dropdown.Item>
                    <Dropdown.Item href="/software/37">Adobe Workfront</Dropdown.Item>
                    <Dropdown.Item href="/software/53">Adroll</Dropdown.Item>
                    <Dropdown.Item href="/software/6990">AgoraPulse</Dropdown.Item>
                    <Dropdown.Item href="/software/3537">Ahrefs</Dropdown.Item>
                    <Dropdown.Item href="/software/1115">Algolia</Dropdown.Item>
                    <Dropdown.Item href="/software/66">Amazon</Dropdown.Item>
                    <Dropdown.Item href="/software/5254">Asana</Dropdown.Item>
                    <Dropdown.Item href="/software/80">BannerFlow</Dropdown.Item>
                    <Dropdown.Item href="/software/5258">Basecamp</Dropdown.Item>
                    <Dropdown.Item href="/software/3364">BloomReach</Dropdown.Item>
                    <Dropdown.Item href="/software/93">BuySellAds</Dropdown.Item>
                    <Dropdown.Item href="/software/94">Cablato</Dropdown.Item>
                    <Dropdown.Item href="/software/5759">CallRail</Dropdown.Item>
                    <Dropdown.Item href="/software/2213">Canva</Dropdown.Item>
                    <Dropdown.Item href="/software/95">Cardlytics</Dropdown.Item>
                    <Dropdown.Item href="/software/99">ChartBoost</Dropdown.Item>
                    <Dropdown.Item href="/software/100">Choozle</Dropdown.Item>
                    <Dropdown.Item href="/software/3979">ClearBit (Data Activation Platform)</Dropdown.Item>
                    <Dropdown.Item href="/software/5272">ClickUp</Dropdown.Item>
                    <Dropdown.Item href="/software/2606">Constant Contact</Dropdown.Item>
                    <Dropdown.Item href="/software/107">Criteo</Dropdown.Item>
                    <Dropdown.Item href="/software/3803">Drupal</Dropdown.Item>
                    <Dropdown.Item href="/software/131">Facebook Business</Dropdown.Item>
                    <Dropdown.Item href="/software/2639">GetResponse</Dropdown.Item>
                    {/* <Dropdown.Item href="/software/6491">Gladly</Dropdown.Item> */}

                    <Dropdown.Item href="/software/762">Google Ads</Dropdown.Item>
                    <Dropdown.Item href="/software/4461">Google Data Studio</Dropdown.Item>
                    <Dropdown.Item href="/software/139">Google Marketing Hub</Dropdown.Item>
                    <Dropdown.Item href="/software/6792">GoTo</Dropdown.Item>
                    <Dropdown.Item href="/software/5537">Groove</Dropdown.Item>
                    <Dropdown.Item href="/software/764">Hootsuite</Dropdown.Item>
                    <Dropdown.Item href="/software/1898">HubSpot Marketing Hub</Dropdown.Item>
                    <Dropdown.Item href="/software/1899">Hubspot Sales Hub</Dropdown.Item>
                    <Dropdown.Item href="/software/3419">Insider</Dropdown.Item>

                    <Dropdown.Item href="/software/6265">Keap</Dropdown.Item>
                    <Dropdown.Item href="/software/376">LinkedIn</Dropdown.Item>
                    <Dropdown.Item href="/software/2666">MailChimp</Dropdown.Item>
                    <Dropdown.Item href="/software/3850">MarketSnare</Dropdown.Item>
                    <Dropdown.Item href="/software/167">MediaMath</Dropdown.Item>
                    <Dropdown.Item href="/software/5811">MessageBird</Dropdown.Item>
                    <Dropdown.Item href="/software/3594">Moz</Dropdown.Item>
                    <Dropdown.Item href="/software/1056">NetSuite</Dropdown.Item>
                    <Dropdown.Item href="/software/6839">On24</Dropdown.Item>
                    <Dropdown.Item href="/software/1993">Outreach</Dropdown.Item>
                    <Dropdown.Item href="/software/3874">Pantheon</Dropdown.Item>
                    <Dropdown.Item href="/software/5923">Podium</Dropdown.Item>
                    <Dropdown.Item href="/software/875">Roku</Dropdown.Item>
                    <Dropdown.Item href="/software/78">Salesforce CRM</Dropdown.Item>
                    <Dropdown.Item href="/software/1273">Segment</Dropdown.Item>

                    <Dropdown.Item href="/software/215">Scoota</Dropdown.Item>
                    <Dropdown.Item href="/software/3616">Screaming Frog</Dropdown.Item>
                    <Dropdown.Item href="/software/3624">SEMrush</Dropdown.Item>
                    <Dropdown.Item href="/software/3201">SendInBlue</Dropdown.Item>
                    <Dropdown.Item href="/software/2738">SendPulse</Dropdown.Item>
                    <Dropdown.Item href="/software/1530">Shopify</Dropdown.Item>
                    <Dropdown.Item href="/software/3208">Sitecore</Dropdown.Item>
                    <Dropdown.Item href="/software/5181">Slack</Dropdown.Item>
                    <Dropdown.Item href="/software/473">Slick Text</Dropdown.Item>
                    <Dropdown.Item href="/software/5347">SmartSheet</Dropdown.Item>
                    <Dropdown.Item href="/software/814">Smartly.io</Dropdown.Item>
                    <Dropdown.Item href="/software/225">Sojern</Dropdown.Item>

                    <Dropdown.Item href="/software/7235">Sprout Social</Dropdown.Item>
                    <Dropdown.Item href="/software/582">StackAdapt</Dropdown.Item>
                    <Dropdown.Item href="/software/5017">Trello</Dropdown.Item>
                    <Dropdown.Item href="/software/505">Twilio</Dropdown.Item>
                    <Dropdown.Item href="/software/838">Twitter</Dropdown.Item>
                    <Dropdown.Item href="/software/894">Unruly</Dropdown.Item>
                    <Dropdown.Item href="/software/5844">Vonage</Dropdown.Item>
                    <Dropdown.Item href="/software/3943">Wordpress</Dropdown.Item>
                    <Dropdown.Item href="/software/3944">WP Engine</Dropdown.Item>
                    <Dropdown.Item href="/software/5243">Wrike</Dropdown.Item>
                    {/* <Dropdown.Item href="/software/264">Xaxis</Dropdown.Item> */}

                    <Dropdown.Item href="/software/523">Yahoo (DSP)</Dropdown.Item>
                    <Dropdown.Item href="/software/5219">Yammer</Dropdown.Item>
                    {/* <Dropdown.Item href="/software/5220">Zandr</Dropdown.Item> */}
                    <Dropdown.Item href="/software/3251">Zeta (Programatic)</Dropdown.Item>
                    <Dropdown.Item href="/software/5220">Zoom</Dropdown.Item>
             
                 

                    {/* 
                    <Dropdown.Item as={Link} to="/software/5243">Salesforce CDP</Dropdown.Item>
                    <Dropdown.Item as={Link} to="/software/5219">Salesforce advertising studio</Dropdown.Item>
                    <Dropdown.Item as={Link} to="/software/5243">Final Cut Pro</Dropdown.Item>
                    <Dropdown.Item as={Link} to="/software/5219">iMovie</Dropdown.Item>
                    <Dropdown.Item as={Link} to="/software/5220">Salesforce</Dropdown.Item> */}




                    


                </Dropdown.Menu>


        </Dropdown>
    )
}

export default Selector