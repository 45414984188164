import firebase from 'firebase'
import 'firebase/auth'
import { IndividualHttpService } from '../http_services/individual.http_service'

require('firebase/firestore')
const firebaseConfig = {
  apiKey: 'AIzaSyDT3XHEEz3lGUQXD944S0sxt_RWxKYmDj8',
  authDomain: 'stakkid.firebaseapp.com',
  databaseURL: 'https://stakkid.firebaseio.com',
  projectId: 'stakkid',
  storageBucket: 'stakkid.appspot.com',
  messagingSenderId: '482089264227',
  appId: '1:482089264227:web:721ee55f835bbba8b48456',
  measurementId: 'G-H0DN4H82P7',
  client_id: '86cga7rnk1tgfv',
  client_secret: 'OAT4JUmE17Uk7rKi',
}

const fire = firebase.initializeApp(firebaseConfig)
export const auth = firebase.auth()

fire.functions() //.useFunctionsEmulator('http://localhost:5001');

export const db = firebase.firestore()
const provider = new firebase.auth.GoogleAuthProvider()

provider.setCustomParameters({ prompt: 'select_account' })
// export const signInWithGoogle = () => auth.signInWithPopup(provider).then(result => {
//   console.log('result =>', result);
//   window.location.href = "/initial";

// });
export const sendPasswordResetEmail = async (email) => {
  try {
    await fire.auth().sendPasswordResetEmail(email)
    alert('Password reset link sent!')
  } catch (error) {
    console.log(error)
    alert(error.message)
  }
}

export const signInWithGoogle = async () => {
  let result = await auth.signInWithPopup(provider)
  console.log('result =>', result)
  if (result && result.user.uid) {

    const response = await new IndividualHttpService().upsertByFirebaseUid({
      firebase_uid: result.user.uid,
      email: result.user.email,
    })

    
    window.location.href = '/initial'
  }
}

export const signUpWithLinkedIn = async () => {
  console.log("LINKEDINPROVIDER")
  return auth
    .setPersistence(firebase.auth.Auth.Persistence.SESSION)

    .then(() => {
      const linkedInProvider = new firebase.auth.OAuthProvider('linkedin.com')
    
      linkedInProvider.addScope('r_emailaddress')
      linkedInProvider.addScope('r_liteprofile')
      auth
        .signInWithPopup(linkedInProvider)
        .then(async (result) => {
          console.log("linkedinUser", result)
          console.group('LinkedIn')
          console.log(result)

          const response = await new IndividualHttpService().upsertByFirebaseUid({
            firebase_uid: result.user.uid,
            email: result.user.email,
          })
          console.log("response",response)

          console.groupEnd()
          window.location.href = '/initial'
          return result
        })
        .catch((error) => {
          console.group('LinkedIn - Error')
          console.log(error)
          console.groupEnd()
          throw error
        })
    })
}

const googleAuth = require('google-auth-library')
const SCOPES = ['https://www.googleapis.com/auth/cloud-platform']

async function getAccessToken() {
  const serviceAccount = require('.//config.json')
  const jwtClient = new googleAuth.JWT(
    serviceAccount.client_email,
    null,
    serviceAccount.private_key,
    SCOPES,
    null
  )
  return jwtClient.authorize().then((tokens) => tokens.access_token)
}

const fetch = require('node-fetch')
const GCIP_API_BASE = 'https://identitytoolkit.googleapis.com/v2'

async function addIdpConfig(
  projectId,
  accessToken,
  idpId,
  clientId,
  clientSecret
) {
  const uri = `${GCIP_API_BASE}/projects/${projectId}/defaultSupportedIdpConfigs?idpId=${idpId}`
  const options = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({
      name: `projects/${projectId}/defaultSupportedIdpConfigs/${idpId}`,
      enabled: true,
      clientId: clientId,
      clientSecret: clientSecret,
    }),
  }
  return fetch(uri, options).then((response) => {
    if (response.ok) {
      return response.json()
    } else if (response.status == 409) {
      throw new Error('IdP configuration already exists. Update it instead.')
    } else {
      throw new Error('Server error.')
    }
  })
}

;(async () => {
  const projectId = 'stakkid'
  const accessToken = await getAccessToken()
  const idpId = 'linkedin.com'
  const clientId = '86cga7rnk1tgfv'
  const clientSecret = 'OAT4JUmE17Uk7rKi'
  try {
    await addIdpConfig(projectId, accessToken, idpId, clientId, clientSecret)
  } catch (err) {
    console.error(err.message)
  }
})().catch(console.error)

export default fire
