import { useState, useEffect } from 'react';
import fire from '../firebase'
import { loadingController } from '@ionic/core'
import './dashboard.css'
import '../../App.css'
import { IndividualHttpService } from '../../http_services/individual.http_service'
// import { DataGridPremium } from '@material-ui/data-grid-premium'
import { DataGridPremium } from '@mui/x-data-grid-premium'
import { DataGrid } from '@material-ui/data-grid'

import { rest } from 'lodash'
import Protect from 'react-app-protect'
import 'react-app-protect/dist/index.css'
import { Link } from 'react-router-dom'
import { LicenseInfo } from '@mui/x-license-pro';

LicenseInfo.setLicenseKey('e95f9ce60382403b5915046fed4a4eb2Tz02MDA5MSxFPTE3MDgyMDU3ODE1MzksUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y');


const columns = [
  { field: 'id', headerName: 'ID', width: 300 },
  {
    field: 'toolName',
    headerName: 'Tool Name',
    width: 150,
    editable: true,
  },
  {
    field: 'users',
    headerName: 'Number of Users',
    type: 'number',

    width: 150,
    editable: true,
  },
  {
    field: 'superUsers',
    headerName: 'Number of Super Users',
    type: 'number',

    width: 150,
    editable: true,
  },
  {
    field: 'dominantRole',
    headerName: 'Dominant Role',
    width: 150,
    editable: true,
  },
  {
    field: 'dominantStrategy',
    headerName: 'Dominant Strategy',
    width: 150,
    editable: true,
  },
  {
    field: 'dominantCategory',
    headerName: 'Dominant Function',
    width: 200,
    editable: true,
  },
  {
    field: 'shortDes',
    headerName: 'Short Des',
    width: 100,
    editable: true,
  },
  {
    field: 'action',
    headerName: 'Action',
    sortable: false,
    renderCell: (cellValues) => {
      return <Link to={{
        pathname: `/tools-dashboard/edit/${cellValues.row.id}`,
        state: cellValues.row
      }}>Edit</Link>;
    }
  },
  {
    field: 'view',
    headerName: 'View',
    sortable: false,
    renderCell: (cellValues) => {
      return <Link to={{
        pathname: `/software/${cellValues.row.id}`,
        refInfo: cellValues.row
      }} className='ml-0' >View</Link>
    }
  },
]

function ToolsDashboard() {
  const [user, setUser] = useState({});
  const [data, setData] = useState([]);
  const [topSkills, setTopSkills] = useState({});


  useEffect(() => {
    async function fetchData() {
      try {
        const spinner = await loadingController.create({
          spinner: 'crescent',
          message: 'Loading Dashboard Capture',
        })
        await spinner.present()

        const currentUser = fire.auth().currentUser;
        if (currentUser) {
          const railsUser = await new IndividualHttpService().where({
            firebase_uid: currentUser.uid,
          });
          setUser(railsUser[0]);

          const url = global.apiBaseUrl + `/tools/new?action=tools_dashboard`;
          const response = await fetch(url, {
            method: 'POST',
            redirect: 'follow', // manual, *follow, error
            headers: { 'Content-Type': 'application/json' },
            mode: 'cors',
            body: JSON.stringify({ firebase_uid: currentUser.uid }),
          });
          const responseData = await response.json();
          const rows = responseData.map((tool) => {
            let dominantRole = 'dominant_role' in tool ? tool.dominant_role : "";
            let dominantStrategy = 'dominant_strategy' in tool ? tool.dominant_strategy : "";
            let dominantCategory = 'dominant_category' in tool ? tool.dominant_category : "";
            const newObj = {
              id: tool.id,
              toolName: tool.tool_name,
              superUsers: tool.super_users,
              users: tool.users,
              dominantRole: dominantRole,
              dominantStrategy: dominantStrategy,
              dominantCategory: dominantCategory,
              shortDes: tool.short_des,
            };
            return newObj;
          });
          setData(rows);
        }
        await spinner.dismiss();
      } catch (e) {
        console.log('error');
        console.log(e);
      }
    }

    fetchData();
  }, []);

  return (
    <Protect sha512='707339bd291f9aae93e137fc2bbe8dbece059dac65a948396cac0f319c0e0a035dec00d6a42083148fac29fc972806ab4f928d661d471c86b74df76f2c1e74ee'>
      <div style={{ height: 90 * 9, width: '100%' }}>
        <div className='add-tool'>
          <Link to='/tools-dashboard/new' className='btn btn-primary'>Add New Tool</Link>
        </div>
        <DataGrid
          // rowHeight={90}
          rows={data}
          columns={columns}
          // pageSize={20}
          // rowsPerPageOptions={[20]}
          checkboxSelection
          disableSelectionOnClick
          initialState={{
            aggregation: {
              model: {
                superUsers: 'sum',
                users: 'sum',

              },
            },
          }}
          experimentalFeatures={{ aggregation: true }}
          style={{ whiteSpace: 'pre-wrap' }}
        />
      </div>
    </Protect>
  );
}



export default ToolsDashboard;

