import React, { Component } from 'react'
import IndividualSegmentedContainer from '../data-visualizations/individual-segmented-container/segmented-container'
import RadialChart from '../data-visualizations/pie-chart/PieChart'
import HeatMap from '../data-visualizations/heatmap/heatmap'
import fire from '../firebase'
import { loadingController } from '@ionic/core'
import './dashboard.css'
import { PlatformService } from '../../services/platform.service'
import ToolTable from '../data-visualizations/tool-table'
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonItem,
  IonTitle,
} from '@ionic/react'
import { IndividualHttpService } from '../../http_services/individual.http_service'
import { TeamHttpService } from '../../http_services/team.http_service'

export default class TeamDashboard extends Component {
  constructor(props) {
    super(props)

    this.state = {
      user: {},
      data: {},
      team: {},
    }
    // this.setData()
  }

  async componentWillMount() {
    const spinner = await loadingController.create({
      spinner: 'crescent',
      message: 'Please wait...',
    })
    await spinner.present()
    try {
      const user = await fire.auth().currentUser
      const railsUser = await new IndividualHttpService().where({
        firebase_uid: user.uid,
      })
      console.log('railsUser', railsUser)
      const team = await new TeamHttpService().find(railsUser[0].team_id)
      console.log('team', team)
      this.setState({ user: railsUser[0], team: team })
      await this.fetchData(team.id)
    } catch (e) {
      console.log(e)
      await spinner.dismiss()
      return
    }
    await spinner.dismiss()
  }

  async fetchData(team_id) {
    const url =
      // 'https://stakkid.herokuapp.com/skills/new?action=team_dashboard'
      // const local = 'http://localhost:3000/skills/new?action=team_dashboard'
      global.apiBaseUrl + '/skills/new?action=team_dashboard'
    fetch(url, {
      method: 'POST',
      redirect: 'follow', // manual, *follow, error

      headers: { 'Content-Type': 'application/json' },
      mode: 'cors',
      body: JSON.stringify({ team_id: team_id }),
    })
      .then((resp) => resp.json())
      .then((data) => this.setState({ data: data }))
  }

  renderSegmentedContainer() {
    if (!PlatformService.desktop()) {
      return [
        <ion-card>
          <ion-card-header>Top Tools:</ion-card-header>
          <ToolTable data={this.state.data.tool_names} />
        </ion-card>,
      ]
    }
    return [
      <IndividualSegmentedContainer
        marketData={this.state.data.strategy}
        roleData={this.state.data.roles}
        toolData={this.state.data.tools}
      />,
    ]
  }

  renderName() {
    if (
      this.state.user.first_name === null ||
      this.state.user.last_name === null
    ) {
      return
    }
    return [
      <ion-item lines='none'>
        <ion-title style={{ textAlign: 'center', fontSize: '32px' }}>
          {this.state.user.first_name} {this.state.user.last_name}
        </ion-title>
      </ion-item>,
    ]
  }

  renderMaxRole(obj) {
    if (Object.keys(obj).length === 0) {
      return
    }
    return Object.keys(obj)
      .reduce((a, b) => (obj[a] > obj[b] ? a : b))
      .toUpperCase()
  }

  render() {
    if (Object.keys(this.state.data).length === 0) {
      return [
        <>
          <IonCard>
            <IonCardHeader>
              <IonTitle>We're sorry...</IonTitle>
            </IonCardHeader>
            <IonCardContent>
              <IonItem lines='none'>
                There was an issue loading the information requested. Please try
                again later.
              </IonItem>
            </IonCardContent>
          </IonCard>
        </>,
      ]
    }
    return [
      <ion-list>
        {this.renderName()}
        <ion-item lines='none'>
          <ion-title style={{ textAlign: 'center', fontSize: '18px' }}>
            Your STAKKID Score:
          </ion-title>
        </ion-item>
        <ion-item lines='full'>
          <ion-title style={{ textAlign: 'center', fontSize: '40px' }}>
            {this.state.data.total_score}C
            {this.renderMaxRole(this.state.data.roles)}
          </ion-title>
        </ion-item>
      </ion-list>,
      <ion-row style={{ paddingTop: '32px' }}>
        <ion-col className='Chart-01'>
          <RadialChart data={this.state.data.category} />
        </ion-col>
        <ion-col className='Chart-02'>
          <HeatMap data={this.state.data.category_strategy} />
        </ion-col>
      </ion-row>,
      this.renderSegmentedContainer(),
    ]
  }
}
