import React, { Component } from 'react'
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonPopover,
  IonItem,
} from '@ionic/react'
import Barchart from '../barchart'
import ToolTable from '../tool-table'
import InfoIcon from '@material-ui/icons/Info'

export default class IndividualSegmentedContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedTab: 'role',
    }
  }

  formatMarketData() {
    const strategies = this.props.marketData
    const data = [
      {
        x: this.makeSkillScoreOneIfZero(strategies['Analysis & Optimization']),
        y: 'Analysis',
      },
      {
        x: this.makeSkillScoreOneIfZero(strategies.Engagement),
        y: 'Engagement',
      },
      {
        x: this.makeSkillScoreOneIfZero(strategies.Attraction),
        y: 'Attraction',
      },
    ]
    return data
  }

  formatRoleData() {
    const roles = this.props.roleData
    const data = [
      { x: this.makeSkillScoreOneIfZero(roles.i), y: 'Technologist' },
      { x: this.makeSkillScoreOneIfZero(roles.l), y: 'Leader' },
      { x: this.makeSkillScoreOneIfZero(roles.u), y: 'User' },
      { x: this.makeSkillScoreOneIfZero(roles.a), y: 'Analyst' },
      { x: this.makeSkillScoreOneIfZero(roles.p), y: 'Planner' },
    ]
    return data
  }

  makeSkillScoreOneIfZero(skill) {
    if ([0, undefined].includes(skill)) {
      return 0.1
    }
    return skill
  }

  changeTabs(event) {
    this.setState({ selectedTab: event.target.value })
  }

  renderMarketing() {
    if (Object.keys(this.props.marketData).length === 0) {
      return [
        <IonItem lines='none'>
          No data yet.
        </IonItem>,
      ]
    }
    return [<Barchart data={this.formatMarketData()} ticks={4} />]
  }

  renderTools() {
    if (this.props.toolData.length === 0) {
      return [
        <IonItem lines='none'>
          No data yet.
        </IonItem>,
      ]
    }
    return [<ToolTable data={this.props.toolData} />]
  }

  renderLevels() {
    return [<ion-item lines='none'>levels</ion-item>]
  }

  renderRole() {
    if (Object.keys(this.props.roleData).length === 0) {
      return [
        <IonItem lines='none'>
          No data yet.
        </IonItem>,
      ]
    }
    const arr = Object.values(this.props.roleData)
    const ticks = Math.max(...arr)
    return [<Barchart data={this.formatRoleData()} ticks={ticks} />]
  }

  renderTab() {
    if (this.state.selectedTab === 'marketing') {
      return this.renderMarketing()
    } else if (this.state.selectedTab === 'tools') {
      return this.renderTools()
    } else if (this.state.selectedTab === 'levels') {
      return this.renderLevels()
    } else {
      return this.renderRole()
    }
  }

  renderTabs() {
    return (
      <ion-segment value={this.state.selectedTab}>
        <ion-segment-button
          onClick={(event) => this.changeTabs(event)}
          value='role'
          color='primary'
        >
          <ion-text style={{ fontSize: '13px' }}>Role</ion-text>
        </ion-segment-button>
        <ion-segment-button
          onClick={(event) => this.changeTabs(event)}
          value='marketing'
          color='primary'
        >
          <ion-text style={{ fontSize: '13px' }}>Strategies</ion-text>
        </ion-segment-button>

        <ion-segment-button
          onClick={(event) => this.changeTabs(event)}
          value='tools'
          color='primary'
        >
          <ion-text style={{ fontSize: '13px' }}>Tools</ion-text>
        </ion-segment-button>
      </ion-segment>
    )
  }

  render() {
    return [
      <div>
        <ion-card-header>{this.renderTabs()}</ion-card-header>
        <ion-card-content>{this.renderTab()}</ion-card-content>
      </div>,
    ]
  }
}
