import React, { Component } from 'react'
import {
  IonText,
  IonCardHeader,
  IonItem,
  IonSegment,
  IonCard,
  IonCardContent,
  IonSegmentButton,
  IonLabel,
  IonSelect,
  IonSelectOption,
} from '@ionic/react'
import Barchart from '../barchart'
import ToolTable from '../tool-table'
import { TeamHttpService } from '../../../http_services/team.http_service'

export default class GroupSegmentedContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedTab: 'marketing',
      teams: [],
      selectedTeam: {},
      teamsData: [],
      selectedTeamData: {},
    }
  }

  async componentDidMount() {
    const teams = await new TeamHttpService().where({
      company_id: this.props.company.id,
    })
    this.getTeamsData(teams)
    this.setState({ teams: teams })
    if (teams.length !== 0) {
      this.setState({ selectedTeam: teams[0] })
    }
  }

  async getTeamsData(teams) {
    for (const team of teams) {
      const url = global.apiBaseUrl + '/skills/new?action=team_dashboard'
      const results = await fetch(url, {
        method: 'POST',
        redirect: 'follow', // manual, *follow, error

        headers: { 'Content-Type': 'application/json' },
        mode: 'cors',
        body: JSON.stringify({ team_id: team.id }),
      }).then((resp) => resp.json())
      results.name = team.name
      this.setState({ teamsData: [...this.state.teamsData, results] }, () => {
        this.setState({ selectedTeamData: this.state.teamsData[0] })
      })
    }
  }

  formatMarketData(strategies) {
    const companyData = [
      {
        x: this.makeSkillScoreOneIfZero(strategies['Analysis & Optimization']),
        y: 'Analysis',
      },
      {
        x: this.makeSkillScoreOneIfZero(strategies.Engagement),
        y: 'Engagement',
      },
      {
        x: this.makeSkillScoreOneIfZero(strategies.Attraction),
        y: 'Attraction',
      },
    ]
    const teamData = [
      {
        x: this.makeSkillScoreOneIfZero(
          this.state.selectedTeamData.strategy['Analysis & Optimization']
        ),
        y: 'Analysis',
      },
      {
        x: this.makeSkillScoreOneIfZero(
          this.state.selectedTeamData.strategy.Engagement
        ),
        y: 'Engagement',
      },
      {
        x: this.makeSkillScoreOneIfZero(
          this.state.selectedTeamData.strategy.Attraction
        ),
        y: 'Attraction',
      },
    ]
    return [companyData, teamData]
  }

  formatRoleData(roles) {
    const companyData = [
      { x: this.makeSkillScoreOneIfZero(roles.i), y: 'Implementer' },
      { x: this.makeSkillScoreOneIfZero(roles.l), y: 'Leader' },
      { x: this.makeSkillScoreOneIfZero(roles.u), y: 'User' },
      { x: this.makeSkillScoreOneIfZero(roles.a), y: 'Analyzer' },
      { x: this.makeSkillScoreOneIfZero(roles.p), y: 'Planner' },
    ]
    const teamData = [
      {
        x: this.makeSkillScoreOneIfZero(this.state.selectedTeamData.roles.i),
        y: 'Implementer',
      },
      {
        x: this.makeSkillScoreOneIfZero(this.state.selectedTeamData.roles.l),
        y: 'Leader',
      },
      {
        x: this.makeSkillScoreOneIfZero(this.state.selectedTeamData.roles.u),
        y: 'User',
      },
      {
        x: this.makeSkillScoreOneIfZero(this.state.selectedTeamData.roles.a),
        y: 'Analyzer',
      },
      {
        x: this.makeSkillScoreOneIfZero(this.state.selectedTeamData.roles.p),
        y: 'Planner',
      },
    ]
    return [companyData, teamData]
  }

  makeSkillScoreOneIfZero(skill) {
    if ([0, undefined].includes(skill)) {
      return 1
    }
    return skill
  }

  updateTeam(e) {
    const newSelectedTeam = this.state.teams.find(
      (team) => team.name === e.target.value
    )
    const newSelectedTeamData = this.state.teamsData.find(
      (team) => team.name === e.target.value
    )
    this.setState({
      selectedTeamData: newSelectedTeamData,
      selectedTeam: newSelectedTeam,
    })
  }

  changeTabs(event) {
    this.setState({ selectedTab: event.target.value })
  }

  renderMarketing() {
    if (Object.keys(this.state.selectedTeamData).length === 0) {
      return
    }
    if (Object.keys(this.state.selectedTeamData.strategy).length === 0) {
      return [
        <IonItem lines='none'>
          This team is not reporting any marketing data yet.
        </IonItem>,
      ]
    }
    const companyData = this.formatMarketData(this.props.marketData)
    return [<Barchart data={companyData} ticks={30} />]
  }

  renderTools() {
    if (Object.keys(this.state.selectedTeamData.tools).length === 0) {
      return [
        <IonItem lines='none'>
          This team is not reporting any tool data yet.
        </IonItem>,
      ]
    }
    return [<ToolTable data={this.props.toolData} />]
  }

  renderLevels() {
    return [<IonItem lines='none'>levels</IonItem>]
  }

  renderRole() {
    if (Object.keys(this.state.selectedTeamData).length === 0) {
      return
    }
    if (Object.keys(this.state.selectedTeamData.strategy).length === 0) {
      return [
        <IonItem lines='none'>
          This team is not reporting any role data yet.
        </IonItem>,
      ]
    }
    const arr = Object.values(this.props.roleData)
    const ticks = Math.max(...arr)
    const companyData = this.formatRoleData(this.props.roleData)
    return [<Barchart data={companyData} ticks={ticks} />]
  }

  renderTab() {
    if (this.state.selectedTab === 'marketing') {
      return this.renderMarketing()
    } else if (this.state.selectedTab === 'tools') {
      return this.renderTools()
    } else if (this.state.selectedTab === 'levels') {
      return this.renderLevels()
    } else {
      return this.renderRole()
    }
  }

  renderTabs() {
    return (
      <IonSegment value={this.state.selectedTab}>
        <IonSegmentButton
          onClick={(event) => this.changeTabs(event)}
          value='marketing'
          color='primary'
        >
          <IonText style={{ fontSize: '16px' }}>Marketing Strategies</IonText>
        </IonSegmentButton>
        <IonSegmentButton
          onClick={(event) => this.changeTabs(event)}
          value='tools'
          color='primary'
        >
          <IonText style={{ fontSize: '16px' }}>Tools</IonText>
        </IonSegmentButton>
        <IonSegmentButton
          onClick={(event) => this.changeTabs(event)}
          value='role'
          color='primary'
        >
          <IonText style={{ fontSize: '16px' }}>Role</IonText>
        </IonSegmentButton>
      </IonSegment>
    )
  }

  renderTeamSelectOptions() {
    return this.state.teams.map((team, index) => {
      return [
        <IonSelectOption key={`option-${index}`} value={`${team.name}`}>
          {team.name}
        </IonSelectOption>,
      ]
    })
  }

  renderTeamSelector() {
    if (Object.keys(this.state.teamsData).length === 0) {
      return
    }
    return [
      <IonItem>
        <IonLabel>Team</IonLabel>
        <IonSelect
          interface='popover'
          onIonChange={(e) => {
            this.updateTeam(e)
          }}
        >
          {this.renderTeamSelectOptions()}
        </IonSelect>
      </IonItem>,
    ]
  }

  render() {
    return [
      <IonCard>
        <IonCardHeader>{this.renderTabs()}</IonCardHeader>
        <IonCardContent>
          {this.renderTeamSelector()}
          {this.renderTab()}
        </IonCardContent>
      </IonCard>,
    ]
  }
}
