import React, { useState } from 'react'

import Modal from '@material-ui/core/Modal'
import Typography from '@material-ui/core/Typography'
import ClearIcon from '@material-ui/icons/Clear'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'

const useStylesInitialAssessment = makeStyles({
  root: {
    color: 'white',
    backgroundColor: 'rgba(0, 139, 192, 1.0)',
    '&:hover': {
      backgroundColor: 'rgba(0, 139, 192, 1.0)',
    },
  },
  modal: {
    margin: 'auto',
    maxWidth: '650px',
  },
  unorderedList: {
    padding: '40px',
    '& li': {
      padding: '20px',
    },
  },
})

function Popup() {
  const [shouldShowModal, setShouldShowModal] = useState(true)
  const classes = useStylesInitialAssessment()

  return (
    <div className='first-modal'>
      <Modal
        style={{ top: '20%' }}
        className={`custom-modal ${classes.modal}`}
        size='lg'
        open={shouldShowModal}
      >
        <Paper style={{ padding: '40px' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              maxWidth: '100%',
            }}
          >
            <Button
              onClick={(e) => {
                setShouldShowModal(false)
              }}
            >
              <ClearIcon />
            </Button>
          </div>

          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Typography
              style={{ fontWeight: '500', fontSize: '2.95rem' }}
              variant='h2'
              component='h2'
            >
              Fast, Easy and Simple
            </Typography>
          </div>
          <ol className={classes.unorderedList}>
            <li>
              <Typography variant='h5' component='h5'>
                Select your strongest three tools in each category and strategy*
              </Typography>
            </li>
            <li>
              <Typography variant='h5' component='h5'>
                Select the role in which you use the tool
              </Typography>
            </li>
            <li>
              <Typography variant='h5' component='h5'>
                Score your skill competency
              </Typography>
            </li>
          </ol>

          <Typography
            style={{ fontWeight: '100', fontSize: '1.00rem' }}
            variant='h6'
            component='h6'
          >
            * You may select more than three tools. Select your top three tools
            or choose from the dropdown. You can also cull your choices with a
            key word, i.e., “data”.
          </Typography>
        </Paper>
      </Modal>
    </div>
  )
}

export default Popup
