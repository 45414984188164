import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

export default class TeamProfile extends React.Component {
    constructor(){
        super()

        this.state = {
            companyName: '',
            companyLegalName: '',
            industry: '',
            companySize: null,
            address: '',
            city: '',
            companyState: '',
            zipCode: ''
        }
    }

    handleChange(event){
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    render(){
        return(
            <div className="page-padding">
                <h1>Company Profile</h1>
                <hr />
                <h4>Company Admin</h4>
                <p>stakkiduser@example.com</p>
                <button id="disabled-stakkid-button">
                    Edit
                </button>
                <button id="disabled-stakkid-button">
                    Update Password
                </button>
                <form>
                    <label className="col-md-5" id="profile-text">
                        Company Name
                        <br />
                        <input 
                            id="profile-input"
                            type="text"
                            name="companyName"
                            placeholder="Company Name"
                            value={this.state.companyName}
                            onChange={(event) => this.handleChange(event)}
                        />
                    </label>
                    <label className="col-md-5" id="profile-text">
                        Company Legal Name
                        <br />
                        <input 
                            id="profile-input"
                            type="text"
                            name="companyLegalName" 
                            placeholder="Company Legal Name"
                            value={this.state.companyLegalName}
                            onChange={(event) => this.handleChange(event)}
                        />
                    </label>
                    <label className="col-md-5" id="profile-text">
                        Industry
                        <br />
                        <input
                            id="profile-input"
                            type="text"
                            name="industry"
                            placeholder="Industry"
                            value={this.state.industry}
                            onChange={(event) => this.handleChange(event)}
                        />
                    </label>

                    <label className="col-md-5" id="profile-text">
                    Type
                    <Dropdown as={ButtonGroup}>
                    <Button id="company-profile-dropdown">Ex. Private</Button>
                    <Dropdown.Toggle className="company-profile-dropdown-arrow" split id="dropdown-split-basic" />
                    <Dropdown.Menu>
                        <Dropdown.Item value="public">Public</Dropdown.Item>
                        <Dropdown.Item value="private">Private</Dropdown.Item>
                        <Dropdown.Item value="non-profit">Non-Profit</Dropdown.Item>
                    </Dropdown.Menu>
                    </Dropdown>
                    </label>
                    <h4>Company Size</h4>
                    <label className="col-md-2">
                        5-500
                        <input
                            style={{textAlign: 'right'}}
                            id="profile-input"
                            type="radio"
                            name="companySize"
                        />
                    </label>
                    <label className="col-md-2">
                        501-1000
                        <input 
                            id="profile-input"
                            type="radio"
                            name="companySize"
                        />
                    </label>
                    <label className="col-md-2">
                        1001-2000
                        <input 
                            id="profile-input"
                            type="radio"
                            name="companySize"
                        />
                    </label>
                    <label className="col-md-2">
                        2001-5000
                        <input 
                            id="profile-input"
                            type="radio"
                            name="companySize"
                        />
                    </label>
                    <label className="col-md-2">
                        5000+
                        <input 
                            id="profile-input"
                            type="radio"
                            name="companySize"
                        />
                    </label>
                    <h3>HQ Address</h3>
                    <label className="col-md-10" id="profile-text">
                        Address
                        <br />
                        <input 
                            id="profile-input"
                            type="text"
                            name="address"
                            placeholder="Address"
                            value={this.state.address}
                            onChange={(event) => this.handleChange(event)}
                        />
                    </label>
                    <label className="col-md-11" id="profile-text">
                        City
                        <br />
                        <input 
                            id="profile-input"
                            type="text"
                            name="city"
                            placeholder="City"
                            value={this.state.city}
                            onChange={(event) => this.handleChange(event)}
                        />
                    </label>
                    <label className="col-md-2" id="profile-text">
                        State
                        <br />
                        <input style={{width: '55px'}} 
                            id="profile-input"
                            type="text"
                            name="addressState"
                            placeholder="State"
                            value={this.state.addressState}
                            onChange={(event) => this.handleChange(event)}
                        />
                    </label>
                    <label className="col-md-3" id="profile-text">
                        Zip Code
                        <br />
                        <input style={{width: '100px'}} 
                            id="profile-input"
                            type="number"
                            name="zipCode"
                            placeholder="Zip Code"
                            value={this.state.zipCode}
                            onChange={(event) => this.handleChange(event)}
                        />
                    </label>
                    <br />
                    <input style={{marginTop: '3%'}} id="main-stakkid-button" type="submit" value="Save" />
                </form>
            </div>
        )
    }
};