import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'

import 'react-responsive-carousel/lib/styles/carousel.min.css'
import fire from './firebase'
import Billing from './Billing'
import { IndividualHttpService } from '../http_services/individual.http_service'
import { Carousel } from 'react-responsive-carousel'
import mainStakkidPhoto from '../assets/images/home_img-top.3bd98f4d-Recovered.jpg'
import mainStakkidPhoto2 from '../assets/images/AdobeStock_222880408_Preview-Recovered.jpg'
import mainStakkidPhoto3 from '../assets/images/home_img-top.3bd98f4d-2new.jpg'
import StakkidImage from '../assets/logos/1Asset 7.png'
import LoginDialogBox from './LoginDialogBox'
import { navigate, redirectTo } from '@reach/router'

import InfoPhoto from '../assets/images/Untitled design.jpg'

import { Button } from './Button'
// import './LandingPage.css'
// import '../App.css'
import './Carousel.css'

export default class CarouselSlide extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      fireErrors: '',
      showLogin: false,
      showCreateAccount: false,
      showAccountConfirmed: false,
    }

    this.signup = this.signup.bind(this)
    this.login = this.login.bind(this)
    this.logout = this.logout.bind(this)
    this.handleShowLoginModal = this.handleShowLoginModal.bind(this)
    this.handleShowCreateAccount = this.handleShowCreateAccount.bind(this)
  }

  async login(email, password) {
    try {
      await fire.auth().signInWithEmailAndPassword(email.trim(), password)
      const user = await fire.auth().currentUser
      await new IndividualHttpService().upsertByFirebaseUid({
        firebase_uid: user.uid,
        email: user.email,
      })
      if (user) {
        // window.location.href = "https://community.stakkid.com";
        window.location.href = '/initial'
      }
      // window.location.href = "/initial";
    } catch (error) {
      console.log(error)
      alert(error.message)
    }

    this.handleShowLoginModal()
  }

  async signup(firstName, lastName, email, password) {    
    try {
      await fire.auth().createUserWithEmailAndPassword(email.trim(), password)
      const user = await fire.auth().currentUser
      const response = await new IndividualHttpService().upsertByFirebaseUid({
        firebase_uid: user.uid,
        email: user.email,
        first_name: firstName,
        last_name: lastName
      })
      if (user) {
        // window.location.href = "https://community.stakkid.com";
        window.location.href = '/initial'
      }
    } catch (error) {
      console.log(error)
      alert(error.message)
    }

    this.handleShowAccountConfirmed()
  }

  async sendPasswordResetEmail(email) {
    try {
      await fire.auth().sendPasswordResetEmail(email)
      alert('Password reset link sent!')
    } catch (error) {
      console.log(error)
      alert(error.message)
    }
  }

  handleShowCreateAccount() {
    this.setState({
      showLogin: false,
    })
    this.setState({
      showCreateAccount: !this.state.showCreateAccount,
    })
  }

  handleShowAccountConfirmed() {
    this.setState({
      showCreateAccount: false,
    })
    this.setState({
      showAccountConfirmed: !this.state.showAccountConfirmed,
    })
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleShowLoginModal() {
    this.setState({
      showLogin: !this.state.showLogin,
    })
  }

  render() {
    let errorNotification = this.state.fireErrors ? (
      <div className='Error'>{this.state.fireErrors}</div>
    ) : null
    return (
      <div className='hero-container'>
        <LoginDialogBox
          fireErrors={this.state.fireErrors}
          showLogin={this.state.showLogin}
          handleShowCreateAccount={this.handleShowCreateAccount}
          handleShowLoginModal={this.handleShowLoginModal}
          login={this.login}
          showCreateAccount={this.state.showCreateAccount}
          signup={this.signup}
          handleShowAccountConfirmed={this.handleShowAccountConfirmed}
          showAccountConfirmed={this.state.showAccountConfirmed}
        />

        <Carousel
          className='slides'
          autoPlay
          interval={5000}
          infiniteLoop
          showArrows={false}
          showThumbs={false}
          showStatus={false}
        >
          <div className='slide1'>
            <img
              className='slide-studying'
              src={mainStakkidPhoto}
              alt='studying in park'
              width='100%'
              height='auto'
            />
            <div className='slide1-text'>
              MarTech is complex <br />{' '}
              <img className='slide-title' src={StakkidImage} /> is simple
              <br />
              <div className='slide1-text-p'>
                The link to people, tools and experience{' '}
              </div>
            </div>

            <div className='hero-btns'>
              {!this.props.userData && (
                <Button
                  className='btns'
                  buttonStyle='btn--outline'
                  buttonSize='btn--large'
                  href='javascript:void(0);'
                  onClick={(event) => this.handleShowCreateAccount(event)}
                >
                  GET STARTED
                </Button>
              )}
              <Button
                className='btns'
                buttonStyle='btn--primary'
                buttonSize='btn--large'
                onClick={() => (window.location.href = '/my-score')}
              >
                HOW IT WORKS
              </Button>
            </div>
          </div>
          <div className='slide2'>
            <img
              className='slide-writing'
              src={mainStakkidPhoto2}
              alt='boy writing'
              width='100%'
              height='auto'
            />
            <div className='slide2-text'>
              What is your
              <br />
              MarTech stack? <br />
              <div className='slide2-text-p'>
                See your custom
                <img className='slide-title-2' src={StakkidImage} />
                dashboard instantly
              </div>
            </div>
          </div>
          <div className='slide3'>
            <img
              className='slide-friends'
              src={mainStakkidPhoto3}
              alt='friends hanging '
              width='100%'
              height='auto'
            />
            <div className='slide3-text'>
              <img className='slide-title-3' src={StakkidImage} />
              <div className='slide3-text-p1'>
                The power to see <br />
              </div>
              <div div className='slide3-text-p'>
                What you've achieved and need <br /> In every category, level,
                and strategy{' '}
              </div>
            </div>
          </div>
        </Carousel>
      </div>
    )
  }
}
