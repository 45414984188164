import React from 'react';
import fire from 'firebase'
import {IndividualHttpService} from "../http_services/individual.http_service";
import {TeamHttpService} from "../http_services/team.http_service";
import {IonItem, IonList, IonTitle} from "@ionic/react";


export default class CompanyLicensePage extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            admins: [{
                admin1: '',
                admin2: '',
                admin3: '',
                admin4: '',
                admin5: '',
            }],
            teams: [{
                team1: '',
                team2: '',
                team3: '',
                team4: '',
                team5: '',
            }],
            currentTeams: [],
        }
    };

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (nextProps.user !== this.props.user) {
            return true
        } else return nextState !== this.state;
    }

    async componentWillMount() {
        await this.getCurrentTeams()
    }

    async getCurrentTeams() {
        const firebase = await fire.auth().currentUser;
        const response = await new IndividualHttpService().where({firebase_uid: firebase.uid});
        const user = response[0];
        const teams = await new TeamHttpService().where({company_id: user.company_id});
        this.setState({currentTeams: [...teams]})
    }

    async submit() {
        const firebase = await fire.auth().currentUser;
        const response = await new IndividualHttpService().where({firebase_uid: firebase.uid});
        const user = response[0];
        if (user.company_id === null) { return; }
        if (user.company_admin === false) { return; }
        const adminKeys = Object.keys(this.state.admins[0]);
        const teamKeys = Object.keys(this.state.teams[0]);
        adminKeys.map(async (key, index) => {
            if (this.state.admins[0][key] === '') {return}
            if (this.state.teams[0][teamKeys[index]] === '') {return}
            const team = await new TeamHttpService().create({name: this.state.teams[0][teamKeys[index]], company_id: user.company_id});
            this.createOrUpdateUserWithCompanyId(this.state.admins[0][key], user.company_id, team);
        })
    }

    async createOrUpdateUserWithCompanyId(email, company, team) {
        const response = await new IndividualHttpService().where({email: email});
        let user = {};
        if (response.length !== 0) {
            user = response[0];
        }

        if (response.length === 0) {
            const newUser = await new IndividualHttpService().create({email: email, company_id: company, team_id: team.id, team_admin: true})
        } else {
            await new IndividualHttpService().update({id: user.id, company_id: company, team_id: team.id, team_admin: true})
        }
    }

    updateAdmins(e) {
        const copy = [...this.state.admins];
        copy[0][e.target.name] = e.target.value;
        this.setState({admins: copy})
    }

    updateTeams(e) {
        const copy = [...this.state.teams];
        copy[0][e.target.name] = e.target.value;
        this.setState({teams: copy})
    }

    renderCurrentTeams() {
        if (this.state.currentTeams.length === 0) { return; }
        return this.state.currentTeams.map((team) => {
            return [
                <IonItem>
                    {team.name}
                </IonItem>
            ]
        })
    }

    render(){
        return(
            <div className="page-padding">
                <h1>Licenses (Company Level)</h1>
                <hr />
                <div className="modal-body row d-flex justify-content-between">
                    <div className="col-md-5" id="license-blocks">
                        <h5 id="billing-float-margins">Small Businesses </h5>
                        <p id="billing-float-margins">31-150 licenses</p>
                        <p id="billing-float-margins">$1500 per year</p>
                    </div>
                    <div className="col-md-5" id="license-blocks">
                        <h5 style={{float: 'left'}}>Need more licenses? </h5>
                        <button id="main-stakkid-button" style={{float: 'right'}}>Upgrade</button>
                    </div>
                </div>
                <div className="modal-body row d-flex justify-content-end">
                    <div className="col-md-5" id="license-blocks">
                        <h6 style={{fontWeight: 'bold', float: 'right', marginRight: '23px'}}>$1500</h6>
                        <h5>Add Competitive Review </h5>
                        <p style={{float: 'left'}}>Find out how your competition does, <br /> compare your stats to other companies</p>
                        <button id="main-stakkid-button" style={{float: 'right'}}>Upgrade</button>
                    </div>
                </div>
                <h4>Add Teams</h4>
                <hr />
                <p>Add the Team Admin Teams</p>
                <label id="profile-text">
                    <input id="profile-input" type="text" name="team1" placeholder="Team Name" onChange={(e) => this.updateTeams(e)} />
                    <input id="profile-input" type="text" name="admin1" placeholder="Email" onChange={(e) => this.updateAdmins(e)} />
                </label>
                <br />
                <label id="profile-text">
                    <input id="profile-input" type="text" name="team2" placeholder="Team Name" onChange={(e) => this.updateTeams(e)} />
                    <input id="profile-input" type="text" name="admin2" placeholder="Email" onChange={(e) => this.updateAdmins(e)} />
                </label>
                <br />
                <label id="profile-text">
                    <input id="profile-input" type="text" name="team3" placeholder="Team Name" onChange={(e) => this.updateTeams(e)} />
                    <input id="profile-input" type="text" name="admin3" placeholder="Email" onChange={(e) => this.updateAdmins(e)} />
                </label>
                <br />
                <label id="profile-text">
                    <input id="profile-input" type="text" name="team4" placeholder="Team Name" onChange={(e) => this.updateTeams(e)} />
                    <input id="profile-input" type="text" name="admin4" placeholder="Email" onChange={(e) => this.updateAdmins(e)} />
                </label>
                <br />
                <label id="profile-text">
                    <input id="profile-input" type="text" name="team5" placeholder="Team Name" onChange={(e) => this.updateTeams(e)} />
                    <input id="profile-input" type="text" name="admin5" placeholder="Email" onChange={(e) => this.updateAdmins(e)} />
                    <input id="main-stakkid-button" style={{width: '140px', marginLeft: '100px'}} type="submit" value="Add" onClick={() => this.submit()} />
                </label>
                <br />
                {/* <div>
                    <h5>Bulk Import</h5>
                    <p>Add several Team Administrators at once</p>
                    <button id="main-stakkid-button">Upload CSV</button>
                </div> */}
                <br />
                <IonList>
                    <IonTitle>
                        Current Teams
                    </IonTitle>
                    {this.renderCurrentTeams()}
                </IonList>
                <hr />
            </div>
        )
    }
};
